import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'officesMapWardrobe';
    sizeManagement?: 'self' | 'parent';
}

export const withOfficesMapWardrobeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'officesMapWardrobe',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.40001 5.57137C8.40001 3.58315 10.0118 1.97137 12 1.97137H12.2448C15.8377 1.97137 17.2138 6.65669 14.1915 8.59962L13.1467 9.27129C13.0731 9.31861 13.0286 9.4001 13.0286 9.4876V9.82777L20.7092 15.3139C21.5372 15.9053 22.0286 16.8602 22.0286 17.8778C22.0286 19.6179 20.6179 21.0285 18.8778 21.0285H5.12218C3.38207 21.0285 1.97144 19.6179 1.97144 17.8778C1.97144 16.8602 2.46285 15.9053 3.29085 15.3139L10.9714 9.82777V9.4876C10.9714 8.70013 11.3719 7.9667 12.0343 7.54087L13.0791 6.86919C14.3744 6.03651 13.7846 4.02852 12.2448 4.02852H12C11.1479 4.02852 10.4571 4.71928 10.4571 5.57137V6.21423C10.4571 6.7823 9.99664 7.2428 9.42858 7.2428C8.86051 7.2428 8.40001 6.7823 8.40001 6.21423V5.57137ZM12 11.6211L4.48654 16.9879C4.19914 17.1931 4.02858 17.5246 4.02858 17.8778C4.02858 18.4818 4.5182 18.9714 5.12218 18.9714H18.8778C19.4818 18.9714 19.9714 18.4818 19.9714 17.8778C19.9714 17.5246 19.8009 17.1931 19.5135 16.9879L12 11.6211Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.47998 3.50001C5.47998 2.10825 6.60822 0.980011 7.99998 0.980011H8.17132C10.6864 0.980011 11.6497 4.25973 9.53403 5.61978L8.80264 6.08995C8.75112 6.12307 8.71998 6.18012 8.71998 6.24137V6.47949L14.0964 10.3198C14.676 10.7338 15.02 11.4022 15.02 12.1145C15.02 13.3326 14.0325 14.32 12.8145 14.32H3.1855C1.96743 14.32 0.97998 13.3326 0.97998 12.1145C0.97998 11.4022 1.32397 10.7338 1.90357 10.3198L7.27998 6.47949V6.24137C7.27998 5.69014 7.56027 5.17674 8.02395 4.87866L8.75533 4.40848C9.66204 3.8256 9.24921 2.42001 8.17132 2.42001H7.99998C7.40351 2.42001 6.91998 2.90354 6.91998 3.50001V3.95001C6.91998 4.34766 6.59763 4.67001 6.19998 4.67001C5.80234 4.67001 5.47998 4.34766 5.47998 3.95001V3.50001ZM7.99998 7.73482L2.74055 11.4916C2.53938 11.6353 2.41998 11.8673 2.41998 12.1145C2.41998 12.5373 2.76272 12.88 3.1855 12.88H12.8145C13.2372 12.88 13.58 12.5373 13.58 12.1145C13.58 11.8673 13.4606 11.6353 13.2594 11.4916L7.99998 7.73482Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
