import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'graduationCap';
    sizeManagement?: 'self' | 'parent';
}

export const withGraduationCapIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'graduationCap',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4458 4.93645C12.5455 4.44131 11.4545 4.44131 10.5542 4.93645L4.07504 8.50001L10.5542 12.0636C11.4545 12.5587 12.5455 12.5587 13.4458 12.0636L19.925 8.50001L13.4458 4.93645ZM9.59041 3.18401C11.0908 2.35879 12.9092 2.35879 14.4096 3.18401L22.4819 7.62379C22.8015 7.79955 23 8.13532 23 8.50001C23 8.8647 22.8015 9.20047 22.4819 9.37623L20 10.7413V16.9788C20 18.0597 19.5666 19.2134 18.5685 19.9235C16.7323 21.23 14.457 22 12 22C9.54297 22 7.26768 21.23 5.43148 19.9235C4.43342 19.2134 4 18.0597 4 16.9788V10.7413L1.51808 9.37623C1.19853 9.20047 1 8.8647 1 8.50001C1 8.13532 1.19853 7.79955 1.51808 7.62379L9.59041 3.18401ZM6 11.8413V16.9788C6 17.5632 6.23208 18.0386 6.59095 18.2939C8.09354 19.363 9.96467 20 12 20C14.0353 20 15.9065 19.363 17.409 18.2939C17.7679 18.0386 18 17.5632 18 16.9788V11.8413L14.4096 13.816C12.9092 14.6412 11.0908 14.6412 9.59041 13.816L6 11.8413Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.01202 3.05545C8.38184 2.70886 7.61813 2.70886 6.98796 3.05545L2.45251 5.54995L6.98796 8.04444C7.61813 8.39104 8.38184 8.39104 9.01202 8.04444L13.5475 5.54995L9.01202 3.05545ZM6.31327 1.82875C7.36356 1.25109 8.63641 1.25109 9.6867 1.82875L15.3373 4.9366C15.561 5.05962 15.7 5.29466 15.7 5.54995C15.7 5.80523 15.561 6.04027 15.3373 6.1633L13.6001 7.11878V11.4852C13.6001 12.2418 13.2967 13.0494 12.598 13.5465C11.3127 14.461 9.72 15 8.00009 15C6.28017 15 4.68746 14.461 3.40212 13.5465C2.70348 13.0494 2.40009 12.2418 2.40009 11.4852V7.11889L0.662645 6.1633C0.438961 6.04027 0.299988 5.80523 0.299988 5.54995C0.299988 5.29466 0.438961 5.05962 0.662645 4.9366L6.31327 1.82875ZM3.80009 7.88889V11.4852C3.80009 11.8943 3.96254 12.227 4.21375 12.4058C5.26557 13.1541 6.57535 13.6 8.00009 13.6C9.42482 13.6 10.7346 13.1541 11.7864 12.4058C12.0376 12.227 12.2001 11.8943 12.2001 11.4852V7.88878L9.6867 9.27114C8.63642 9.8488 7.36356 9.8488 6.31327 9.27114L3.80009 7.88889Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
