import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'download';
    sizeManagement?: 'self' | 'parent';
}

export const withDownloadIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'download',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 3C11.4477 3 11 3.44772 11 4V10C11 10.6812 11.116 11.4784 11.269 12.2336L11.1258 12.3772C11.086 12.3316 11.0494 12.2819 11.0168 12.2281C10.5202 11.4097 9.89 10.4805 9.25784 9.84365L8.70711 9.29292C8.31658 8.90239 7.68342 8.90239 7.29289 9.29292C6.90449 9.68132 6.90238 10.3097 7.28656 10.7007L11.2929 14.7071C11.6323 15.0465 12.155 15.0909 12.5424 14.8404C12.6008 14.8026 12.656 14.7582 12.7071 14.7071L16.7071 10.7071C17.0976 10.3166 17.0976 9.68344 16.7071 9.29292C16.3175 8.90335 15.6865 8.90239 15.2958 9.29006L15.2929 9.29292L14.7574 9.82843C14.1202 10.4657 13.4839 11.4031 12.9834 12.228C12.9507 12.2819 12.9141 12.3316 12.8742 12.3773L12.731 12.2336C12.884 11.4784 13 10.6812 13 10V4C13 3.44772 12.5523 3 12 3Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M20 13C20.5523 13 21 13.4477 21 14V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17L3 14C3 13.4477 3.44772 13 4 13C4.55229 13 5 13.4477 5 14L5 17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V14C19 13.4477 19.4477 13 20 13Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00001 1.69998C7.61341 1.69998 7.30001 2.01338 7.30001 2.39998V6.59998C7.30001 7.07682 7.38121 7.63487 7.48831 8.16349L7.38812 8.26403C7.36021 8.23208 7.33462 8.19726 7.31175 8.15958C6.96419 7.58679 6.5231 6.93645 6.08064 6.49064L5.69498 6.10498C5.42162 5.83161 4.9784 5.83161 4.70503 6.10498C4.43318 6.37683 4.43167 6.81665 4.70051 7.09036L7.50506 9.89491C7.74478 10.1346 8.11513 10.1641 8.387 9.98342C8.42515 9.95806 8.46135 9.92856 8.49498 9.89493L11.295 7.09493C11.5683 6.82156 11.5683 6.37835 11.295 6.10498C11.0223 5.83226 10.5805 5.83162 10.307 6.10306L10.305 6.10498L9.93018 6.47984C9.48412 6.9259 9.03872 7.58213 8.68835 8.15957C8.66548 8.19727 8.63987 8.23211 8.61194 8.26408L8.51171 8.16349C8.6188 7.63487 8.70001 7.07682 8.70001 6.59998V2.39998C8.70001 2.01338 8.38661 1.69998 8.00001 1.69998Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13.6 8.7C13.9866 8.7 14.3 9.0134 14.3 9.4V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.50001C2.95361 14.3 1.70001 13.0464 1.70001 11.5L1.70001 9.4C1.70001 9.0134 2.01341 8.7 2.40001 8.7C2.78661 8.7 3.10001 9.0134 3.10001 9.4L3.10001 11.5C3.10001 12.2732 3.72681 12.9 4.50001 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5V9.4C12.9 9.0134 13.2134 8.7 13.6 8.7Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
