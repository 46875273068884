import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowShortUp';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowShortUpIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowShortUp',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.27935 8.52966C1.98646 8.82255 1.51159 8.82255 1.21869 8.52966C0.9258 8.23676 0.9258 7.76189 1.21869 7.469L5.46799 3.2197C5.58185 3.10584 5.72321 3.03624 5.87069 3.0109C6.10263 2.97101 6.34973 3.0406 6.5288 3.21967L10.7781 7.46897C11.071 7.76186 11.071 8.23674 10.7781 8.52963C10.4852 8.82252 10.0103 8.82252 9.71744 8.52963L5.99841 4.8106L2.27935 8.52966Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.29289 15.7071C4.68342 16.0976 5.31658 16.0976 5.70711 15.7071L12 9.41421L18.2929 15.7071C18.6834 16.0976 19.3166 16.0976 19.7071 15.7071C20.0976 15.3166 20.0976 14.6834 19.7071 14.2929L12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L4.29289 14.2929C3.90237 14.6834 3.90237 15.3166 4.29289 15.7071Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.60505 10.595C2.87842 10.8683 3.32163 10.8683 3.595 10.595L8.00002 6.18995L12.405 10.595C12.6784 10.8683 13.1216 10.8683 13.395 10.595C13.6684 10.3216 13.6684 9.87839 13.395 9.60502L8.495 4.70503C8.22163 4.43166 7.77842 4.43166 7.50505 4.70503L2.60505 9.60502C2.33168 9.87839 2.33168 10.3216 2.60505 10.595Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
