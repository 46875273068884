import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'baggageOff';
    sizeManagement?: 'self' | 'parent';
}

export const withBaggageOffIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'baggageOff',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 2V3.5H8.5V2C8.5 0.89543 9.39543 0 10.5 0H14.5C15.6046 0 16.5 0.895431 16.5 2V3.5H15V2C15 1.72386 14.7761 1.5 14.5 1.5H10.5C10.2239 1.5 10 1.72386 10 2Z" fill="currentColor" /><path d="M9 24C8.17157 24 7.5 23.3284 7.5 22.5H10.5C10.5 23.3284 9.82843 24 9 24Z" fill="currentColor" /><path d="M16 24C15.1716 24 14.5 23.3284 14.5 22.5H17.5C17.5 23.3284 16.8284 24 16 24Z" fill="currentColor" /><path d="M10.9477 10.387C10.6548 10.0941 10.1799 10.0941 9.88701 10.387C9.59411 10.6799 9.59411 11.1548 9.88701 11.4477L11.6046 13.1653L9.88701 14.8829C9.59411 15.1758 9.59411 15.6507 9.88701 15.9436C10.1799 16.2365 10.6548 16.2365 10.9477 15.9436L12.6653 14.226L14.3829 15.9436C14.6758 16.2365 15.1507 16.2365 15.4436 15.9436C15.7365 15.6507 15.7365 15.1758 15.4436 14.8829L13.726 13.1653L15.4436 11.4477C15.7365 11.1548 15.7365 10.6799 15.4436 10.387C15.1507 10.0941 14.6758 10.0941 14.3829 10.387L12.6653 12.1046L10.9477 10.387Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9 5C6.79086 5 5 6.79086 5 9V17C5 19.2091 6.79086 21 9 21H16C18.2091 21 20 19.2091 20 17V9C20 6.79086 18.2091 5 16 5H9ZM16 6.75H9C7.75736 6.75 6.75 7.75736 6.75 9V17C6.75 18.2426 7.75736 19.25 9 19.25H16C17.2426 19.25 18.25 18.2426 18.25 17V9C18.25 7.75736 17.2426 6.75 16 6.75Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.66666 1.33333V2.33333H5.66666V1.33333C5.66666 0.596954 6.26362 0 6.99999 0H9.66666C10.403 0 11 0.596954 11 1.33333V2.33333H10V1.33333C10 1.14924 9.85076 1 9.66666 1H6.99999C6.8159 1 6.66666 1.14924 6.66666 1.33333Z" fill="currentColor" /><path d="M5.99999 16C5.44771 16 4.99999 15.5523 4.99999 15H6.99999C6.99999 15.5523 6.55228 16 5.99999 16Z" fill="currentColor" /><path d="M10.6667 16C10.1144 16 9.66666 15.5523 9.66666 15H11.6667C11.6667 15.5523 11.2189 16 10.6667 16Z" fill="currentColor" /><path d="M7.29844 6.92467C7.10318 6.72941 6.78659 6.72941 6.59133 6.92467C6.39607 7.11993 6.39607 7.43652 6.59133 7.63178L7.73642 8.77687L6.59133 9.92195C6.39607 10.1172 6.39607 10.4338 6.59133 10.6291C6.78659 10.8243 7.10318 10.8243 7.29844 10.6291L8.44353 9.48397L9.58862 10.6291C9.78388 10.8243 10.1005 10.8243 10.2957 10.6291C10.491 10.4338 10.491 10.1172 10.2957 9.92196L9.15063 8.77687L10.2957 7.63178C10.491 7.43652 10.491 7.11993 10.2957 6.92467C10.1005 6.72941 9.78388 6.72941 9.58862 6.92467L8.44353 8.06976L7.29844 6.92467Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.99999 3.33333C4.52724 3.33333 3.33333 4.52724 3.33333 6V11.3333C3.33333 12.8061 4.52724 14 5.99999 14H10.6667C12.1394 14 13.3333 12.8061 13.3333 11.3333V6C13.3333 4.52724 12.1394 3.33333 10.6667 3.33333H5.99999ZM10.6667 4.5H5.99999C5.17157 4.5 4.49999 5.17157 4.49999 6V11.3333C4.49999 12.1618 5.17157 12.8333 5.99999 12.8333H10.6667C11.4951 12.8333 12.1667 12.1618 12.1667 11.3333V6C12.1667 5.17157 11.4951 4.5 10.6667 4.5Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
