import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'archive';
    sizeManagement?: 'self' | 'parent';
}

export const withArchiveIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'archive',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 15C8 14.4477 8.44772 14 9 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H9C8.44772 16 8 15.5523 8 15Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M2 7C2 4.79086 3.79086 3 6 3H18C20.2091 3 22 4.79086 22 7V8C22 8.8885 21.6137 9.68679 21 10.2361V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V10.2361C2.38625 9.68679 2 8.8885 2 8V7ZM5 9H19C19.5523 9 20 8.55228 20 8V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V8C4 8.55228 4.44772 9 5 9ZM5 11V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V11H5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 4.50001C1 2.95362 2.2536 1.70001 3.8 1.70001H12.2C13.7464 1.70001 15 2.95361 15 4.50001V5.20001C15 5.82196 14.7296 6.38076 14.3 6.76529V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.5C2.9536 14.3 1.7 13.0464 1.7 11.5V6.76529C1.27038 6.38076 1 5.82196 1 5.20001V4.50001ZM3.1 5.90001H12.9C13.2866 5.90001 13.6 5.58661 13.6 5.20001V4.50001C13.6 3.72681 12.9732 3.10001 12.2 3.10001H3.8C3.0268 3.10001 2.4 3.72681 2.4 4.50001V5.20001C2.4 5.58661 2.7134 5.90001 3.1 5.90001ZM3.1 7.30001L3.1 11.5C3.1 12.2732 3.7268 12.9 4.5 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5L12.9 7.30001H3.1ZM5.2 10.1C5.2 9.71341 5.5134 9.40001 5.9 9.40001H10.1C10.4866 9.40001 10.8 9.71341 10.8 10.1C10.8 10.4866 10.4866 10.8 10.1 10.8H5.9C5.5134 10.8 5.2 10.4866 5.2 10.1Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
