import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowLongRight';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowLongRightIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowLongRight',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2929 18.2929C11.9023 18.6834 11.9023 19.3166 12.2929 19.7071C12.6834 20.0977 13.3166 20.0977 13.7071 19.7071L20.7071 12.7071C21.0976 12.3166 21.0976 11.6834 20.7071 11.2929L13.7071 4.29292C13.3166 3.9024 12.6834 3.9024 12.2929 4.29292C11.9023 4.68345 11.9023 5.31661 12.2929 5.70714L15.8284 9.24267C16.4656 9.87991 17.4031 10.5162 18.228 11.0168C18.2818 11.0494 18.3316 11.086 18.3772 11.1258L18.2335 11.269C17.4784 11.116 16.6812 11 16 11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H16C16.6812 13 17.4784 12.884 18.2336 12.731L18.3773 12.8742C18.3317 12.9141 18.2819 12.9507 18.228 12.9833C17.4031 13.4839 16.4656 14.1202 15.8284 14.7574L12.2929 18.2929Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.20502 12.405C7.93165 12.6784 7.93165 13.1216 8.20502 13.395C8.47839 13.6684 8.9216 13.6684 9.19497 13.395L14.095 8.495C14.3683 8.22163 14.3683 7.77842 14.095 7.50505L9.19497 2.60505C8.9216 2.33168 8.47839 2.33168 8.20502 2.60505C7.93165 2.87842 7.93165 3.32163 8.20502 3.595L10.6799 6.06987C11.126 6.51594 11.7822 6.96137 12.3596 7.31175C12.3973 7.33461 12.4321 7.36019 12.4641 7.3881L12.3635 7.4883C11.8349 7.38121 11.2769 7.3 10.8 7.3H2.40001C2.01341 7.3 1.70001 7.6134 1.70001 8C1.70001 8.3866 2.01341 8.7 2.40001 8.7H10.8C11.2769 8.7 11.8349 8.61879 12.3635 8.5117L12.4641 8.61195C12.4322 8.63987 12.3973 8.66547 12.3596 8.68835C11.7822 9.03871 11.126 9.48412 10.6799 9.93018L8.20502 12.405Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
