import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'write';
    sizeManagement?: 'self' | 'parent';
}

export const withWriteIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'write',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 4.99997C5.89543 4.99997 5 5.8954 5 6.99997V17C5 18.1045 5.89543 19 7 19H17C18.1046 19 19 18.1045 19 17V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V6.99997C3 4.79083 4.79086 2.99997 7 2.99997H11C11.5523 2.99997 12 3.44769 12 3.99997C12 4.55226 11.5523 4.99997 11 4.99997H7Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M18.526 4.76766C18.3307 4.5724 18.0142 4.5724 17.8189 4.76766L16.4116 6.17497L17.825 7.58835L19.2323 6.18104C19.4275 5.98578 19.4275 5.6692 19.2323 5.47394L18.526 4.76766ZM16.4108 9.00256L14.9974 7.58918L10.9195 11.6671C10.6902 11.8964 10.52 12.1781 10.4238 12.4878L9.9331 14.0669L11.5122 13.5762C11.8219 13.4799 12.1035 13.3098 12.3329 13.0805L16.4108 9.00256ZM16.4047 3.35345C17.381 2.37714 18.9639 2.37714 19.9402 3.35345L20.6465 4.05972C21.6228 5.03603 21.6228 6.61894 20.6465 7.59525L13.7471 14.4947C13.2884 14.9534 12.7251 15.2936 12.1057 15.4861L10.5266 15.9768C8.98853 16.4547 7.54524 15.0114 8.02319 13.4734L8.51388 11.8943C8.70637 11.2749 9.04661 10.7116 9.50527 10.2529L16.4047 3.35345Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 3.1C3.72681 3.1 3.10001 3.7268 3.10001 4.5V11.5C3.10001 12.2732 3.72681 12.9 4.50001 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5V8.7C12.9 8.3134 13.2134 8 13.6 8C13.9866 8 14.3 8.3134 14.3 8.7V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.50001C2.95361 14.3 1.70001 13.0464 1.70001 11.5V4.5C1.70001 2.9536 2.95362 1.7 4.50001 1.7H7.30001C7.68661 1.7 8.00001 2.0134 8.00001 2.4C8.00001 2.7866 7.68661 3.1 7.30001 3.1H4.50001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12.5682 2.93739C12.4315 2.8007 12.2099 2.8007 12.0732 2.93739L11.0881 3.9225L12.0775 4.91186L13.0626 3.92675C13.1993 3.79007 13.1993 3.56846 13.0626 3.43178L12.5682 2.93739ZM11.0875 5.90182L10.0982 4.91245L7.24365 7.76699C7.08312 7.92753 6.96404 8.12469 6.89667 8.34149L6.55318 9.44683L7.65852 9.10335C7.87532 9.03598 8.07248 8.91689 8.23302 8.75636L11.0875 5.90182ZM11.0833 1.94744C11.7667 1.26402 12.8747 1.26402 13.5582 1.94744L14.0526 2.44183C14.736 3.12525 14.736 4.23328 14.0526 4.9167L9.22297 9.7463C8.9019 10.0674 8.50757 10.3055 8.07397 10.4403L6.96863 10.7838C5.89198 11.1183 4.88168 10.108 5.21625 9.03138L5.55973 7.92604C5.69447 7.49244 5.93264 7.09811 6.2537 6.77705L11.0833 1.94744Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
