import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'check';
    sizeManagement?: 'self' | 'parent';
}

export const withCheckIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'check',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.55585 9.41902C4.56019 9.42359 4.5646 9.42811 4.56908 9.43259C4.67268 9.53619 4.79905 9.60314 4.93207 9.63345C5.17526 9.68902 5.44077 9.6221 5.63017 9.43269C5.63583 9.42703 5.64138 9.4213 5.64683 9.41551L10.982 4.08038C11.2748 3.78749 11.2748 3.31261 10.982 3.01972C10.6891 2.72683 10.2142 2.72683 9.9213 3.01972L5.09958 7.84143L2.48053 5.22238C2.18763 4.92949 1.71276 4.92949 1.41987 5.22238C1.12697 5.51527 1.12697 5.99015 1.41987 6.28304L4.55585 9.41902Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M21.7071 5.29289C22.0976 5.68342 22.0976 6.31658 21.7071 6.70711L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L3.29289 11.7071C2.90237 11.3166 2.90237 10.6834 3.29289 10.2929C3.68342 9.90237 4.31658 9.90237 4.70711 10.2929L10 15.5858L20.2929 5.29289C20.6834 4.90237 21.3166 4.90237 21.7071 5.29289Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14.795 3.30503C15.0684 3.5784 15.0684 4.02161 14.795 4.29498L7.09499 11.995C6.82162 12.2683 6.3784 12.2683 6.10504 11.995L1.90504 7.79498C1.63167 7.52161 1.63167 7.0784 1.90504 6.80503C2.1784 6.53166 2.62162 6.53166 2.89499 6.80503L6.60001 10.5101L13.805 3.30503C14.0784 3.03166 14.5216 3.03166 14.795 3.30503Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
