import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'numbersEight';
    sizeManagement?: 'self' | 'parent';
}

export const withNumbersEightIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'numbersEight',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M21 4.00002C21.5523 4.00002 22 4.44774 22 5.00002V19C22 19.5523 21.5523 20 21 20C20.4477 20 20 19.5523 20 19V5.00002C20 4.44774 20.4477 4.00002 21 4.00002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M17 4.00002C17.5523 4.00002 18 4.44774 18 5.00002V19C18 19.5523 17.5523 20 17 20C16.4477 20 16 19.5523 16 19V5.00002C16 4.44774 16.4477 4.00002 17 4.00002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13 4.00002C13.5523 4.00002 14 4.44774 14 5.00002V19C14 19.5523 13.5523 20 13 20C12.4477 20 12 19.5523 12 19V5.00002C12 4.44774 12.4477 4.00002 13 4.00002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M2.79047 4.02222C3.3305 3.9065 3.86208 4.25047 3.9778 4.79049L6 14.2274L8.0222 4.79049C8.13792 4.25047 8.6695 3.9065 9.20953 4.02222C9.74956 4.13794 10.0935 4.66953 9.9778 5.20955L6.9778 19.2096C6.879 19.6706 6.47154 20 6 20C5.52846 20 5.121 19.6706 5.0222 19.2096L2.0222 5.20955C1.90648 4.66953 2.25045 4.13794 2.79047 4.02222Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14.3001 2.40011C14.6867 2.40011 15.0001 2.71351 15.0001 3.10011V12.9001C15.0001 13.2867 14.6867 13.6001 14.3001 13.6001C13.9135 13.6001 13.6001 13.2867 13.6001 12.9001V3.10011C13.6001 2.71351 13.9135 2.40011 14.3001 2.40011Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11.5 2.40011C11.8866 2.40011 12.2 2.71351 12.2 3.10011V12.9001C12.2 13.2867 11.8866 13.6001 11.5 13.6001C11.1134 13.6001 10.8 13.2867 10.8 12.9001V3.10011C10.8 2.71351 11.1134 2.40011 11.5 2.40011Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.69996 2.40011C9.08656 2.40011 9.39996 2.71351 9.39996 3.10011V12.9001C9.39996 13.2867 9.08656 13.6001 8.69996 13.6001C8.31336 13.6001 7.99996 13.2867 7.99996 12.9001V3.10011C7.99996 2.71351 8.31336 2.40011 8.69996 2.40011Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.55329 2.41565C1.93131 2.33464 2.30342 2.57542 2.38442 2.95344L3.79996 9.55928L5.2155 2.95344C5.2965 2.57542 5.66861 2.33464 6.04663 2.41565C6.42465 2.49665 6.66543 2.86876 6.58442 3.24678L4.48442 13.0468C4.41526 13.3695 4.13004 13.6001 3.79996 13.6001C3.46989 13.6001 3.18466 13.3695 3.1155 13.0468L1.0155 3.24678C0.934496 2.86876 1.17527 2.49665 1.55329 2.41565Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
