import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'fullscreen';
    sizeManagement?: 'self' | 'parent';
}

export const withFullscreenIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'fullscreen',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9289 11.0711C13.3194 11.4616 13.9526 11.4616 14.3431 11.0711L17.8786 7.53557C18.3603 7.05388 18.842 6.40814 19.2678 5.76597L19.4706 5.76562C19.4665 5.82607 19.4572 5.88708 19.4422 5.94822C19.2147 6.87803 19.0033 7.98067 19 8.87802V9.65687C19 10.2092 19.4477 10.6569 20 10.6569C20.5493 10.6569 20.9951 10.214 20.9999 9.66587L20.9999 4.00001C20.9999 3.44774 20.5522 3.00002 20 3.00002L14.3431 3.00002C13.7908 3.00002 13.3431 3.44773 13.3431 4.00002C13.3431 4.55095 13.7886 4.99783 14.3391 5.00001L14.3431 5.00002L15.1004 5.00001C16.0016 5.00001 17.1144 4.78704 18.0517 4.55766C18.1128 4.54269 18.1739 4.53337 18.2344 4.52929L18.234 4.73219C17.5918 5.15799 16.9461 5.63967 16.4644 6.12135L12.9289 9.65689C12.5384 10.0474 12.5384 10.6806 12.9289 11.0711Z" fill="currentColor" /><path d="M11.0711 12.9289C10.6806 12.5384 10.0474 12.5384 9.65689 12.9289L6.12136 16.4645C5.63968 16.9461 5.15799 17.5919 4.73219 18.234L4.52938 18.2344C4.53347 18.1739 4.54278 18.1129 4.55774 18.0518C4.78529 17.122 4.99668 16.0194 5.00002 15.122L5.00002 14.3431C5.00002 13.7909 4.55231 13.3431 4.00002 13.3431C3.45074 13.3431 3.00489 13.786 3.00006 14.3342L3.00006 20C3.00006 20.5523 3.44775 21 4.00002 21H9.65688C10.2092 21 10.6569 20.5523 10.6569 20C10.6569 19.4491 10.2113 19.0022 9.66093 19L9.65688 19L8.89956 19C7.99838 19 6.88556 19.213 5.94834 19.4424C5.88716 19.4573 5.82611 19.4667 5.76562 19.4707L5.76598 19.2678C6.40815 18.842 7.05389 18.3603 7.53557 17.8787L11.0711 14.3431C11.4616 13.9526 11.4616 13.3194 11.0711 12.9289Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.65037 7.34983C8.92374 7.6232 9.36695 7.6232 9.64032 7.34983L12.1152 4.87496C12.4524 4.53778 12.7895 4.08576 13.0876 3.63624L13.2296 3.63599C13.2267 3.67831 13.2202 3.72102 13.2097 3.76382C13.0504 4.41468 12.9025 5.18653 12.9001 5.81468L12.9001 6.35987C12.9001 6.74647 13.2135 7.05987 13.6001 7.05987C13.9846 7.05987 14.2967 6.74987 14.3001 6.36617L14.3001 2.40007C14.3001 2.01348 13.9867 1.70007 13.6001 1.70007L9.64033 1.70007C9.25373 1.70007 8.94033 2.01348 8.94033 2.40008C8.94033 2.78573 9.2522 3.09854 9.63749 3.10007L9.64033 3.10008L10.1705 3.10007C10.8013 3.10007 11.5803 2.95099 12.2363 2.79043C12.2791 2.77995 12.3219 2.77342 12.3642 2.77056L12.364 2.91259C11.9144 3.21065 11.4624 3.54783 11.1252 3.88501L8.65037 6.35988C8.377 6.63325 8.377 7.07647 8.65037 7.34983Z" fill="currentColor" /><path d="M7.34962 8.65018C7.07625 8.37681 6.63304 8.37682 6.35967 8.65018L3.8848 11.1251C3.54762 11.4622 3.21044 11.9143 2.91238 12.3638L2.77041 12.364C2.77327 12.3217 2.7798 12.279 2.79027 12.2362C2.94955 11.5853 3.09753 10.8135 3.09986 10.1853L3.09986 9.64014C3.09986 9.25354 2.78646 8.94014 2.39986 8.94014C2.01536 8.94014 1.70327 9.25015 1.69989 9.63385L1.69989 13.5999C1.69989 13.9865 2.01328 14.2999 2.39986 14.2999L6.35966 14.2999C6.74626 14.2999 7.05966 13.9865 7.05966 13.5999C7.05966 13.2143 6.74779 12.9015 6.3625 12.8999L6.35966 12.8999L5.82954 12.8999C5.19871 12.8999 4.41974 13.049 3.76368 13.2096C3.72086 13.2201 3.67813 13.2266 3.63578 13.2295L3.63603 13.0874C4.08555 12.7894 4.53757 12.4522 4.87475 12.115L7.34962 9.64013C7.62299 9.36676 7.62299 8.92355 7.34962 8.65018Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
