import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingItallic';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingItallicIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingItallic',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.9828 4.00001H9C8.44772 4.00001 8 4.44772 8 5.00001C8 5.55229 8.44772 6.00001 9 6.00001H11.847L10.1327 18H7C6.44772 18 6 18.4477 6 19C6 19.5523 6.44772 20 7 20H10.9853C10.996 20.0002 11.0066 20.0002 11.0172 20H15C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18H12.153L13.8673 6.00001H17C17.5523 6.00001 18 5.55229 18 5.00001C18 4.44772 17.5523 4.00001 17 4.00001H13.0147C13.004 3.99984 12.9934 3.99984 12.9828 4.00001Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.68795 2.40001H5.89999C5.51339 2.40001 5.19999 2.71341 5.19999 3.10001C5.19999 3.48661 5.51339 3.80001 5.89999 3.80001H7.89288L6.69288 12.2H4.49999C4.11339 12.2 3.79999 12.5134 3.79999 12.9C3.79999 13.2866 4.11339 13.6 4.49999 13.6H7.28963C7.29714 13.6001 7.30463 13.6001 7.31209 13.6H10.1C10.4866 13.6 10.8 13.2866 10.8 12.9C10.8 12.5134 10.4866 12.2 10.1 12.2H8.10709L9.30709 3.80001H11.5C11.8866 3.80001 12.2 3.48661 12.2 3.10001C12.2 2.71341 11.8866 2.40001 11.5 2.40001H8.71028C8.70281 2.39989 8.69537 2.39989 8.68795 2.40001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
