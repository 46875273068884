import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'attach';
    sizeManagement?: 'self' | 'parent';
}

export const withAttachIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'attach',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_922_2850)"><path fillRule="evenodd" clipRule="evenodd" d="M5.53553 20.0918C2.80186 17.3582 2.80186 12.926 5.53553 10.1923L12.6066 3.12127C14.5592 1.16865 17.725 1.16865 19.6777 3.12127C21.6303 5.0739 21.6303 8.23972 19.6777 10.1923L12.6066 17.2634C11.435 18.435 9.53553 18.435 8.36396 17.2634C7.19239 16.0918 7.19239 14.1923 8.36396 13.0208L15.435 5.9497C15.8256 5.55918 16.4587 5.55918 16.8492 5.9497C17.2398 6.34023 17.2398 6.97339 16.8492 7.36392L9.77817 14.435C9.38765 14.8255 9.38765 15.4587 9.77817 15.8492C10.1687 16.2397 10.8019 16.2397 11.1924 15.8492L18.2635 8.77813C19.435 7.60656 19.435 5.70706 18.2635 4.53549C17.0919 3.36392 15.1924 3.36392 14.0208 4.53549L6.94975 11.6066C4.99712 13.5592 4.99712 16.725 6.94975 18.6776C8.90237 20.6302 12.0682 20.6302 14.0208 18.6776L16.8492 15.8492C17.2398 15.4587 17.8729 15.4587 18.2635 15.8492C18.654 16.2397 18.654 16.8729 18.2635 17.2634L15.435 20.0918C12.7014 22.8255 8.2692 22.8255 5.53553 20.0918Z" fill="currentColor" /></g><defs><clipPath id="clip0_922_2850"><rect width={24} height={24} fill="white" /></clipPath></defs></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_921_2736)"><path fillRule="evenodd" clipRule="evenodd" d="M3.47489 13.6643C1.56133 11.7507 1.56132 8.64821 3.47489 6.73464L8.42464 1.78489C9.79148 0.418058 12.0076 0.418058 13.3744 1.78489C14.7412 3.15173 14.7412 5.36781 13.3744 6.73464L8.42464 11.6844C7.60454 12.5045 6.27489 12.5045 5.45479 11.6844C4.63469 10.8643 4.63469 9.53464 5.45479 8.71454L10.4045 3.76479C10.6779 3.49142 11.1211 3.49142 11.3945 3.76479C11.6679 4.03816 11.6679 4.48137 11.3945 4.75474L6.44474 9.70449C6.17137 9.97786 6.17137 10.4211 6.44474 10.6944C6.71811 10.9678 7.16132 10.9678 7.43469 10.6944L12.3844 5.74469C13.2045 4.92459 13.2045 3.59494 12.3844 2.77484C11.5643 1.95474 10.2347 1.95474 9.41459 2.77484L4.46484 7.72459C3.09801 9.09142 3.09801 11.3075 4.46484 12.6743C5.83168 14.0412 8.04776 14.0412 9.41459 12.6743L11.3945 10.6944C11.6679 10.4211 12.1111 10.4211 12.3844 10.6944C12.6578 10.9678 12.6578 11.411 12.3844 11.6844L10.4045 13.6643C8.49097 15.5779 5.38846 15.5779 3.47489 13.6643Z" fill="currentColor" /></g><defs><clipPath id="clip0_921_2736"><rect width={16} height={16} fill="white" /></clipPath></defs></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
