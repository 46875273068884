import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'chat';
    sizeManagement?: 'self' | 'parent';
}

export const withChatIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'chat',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58174 4 4.00001 7.58172 4.00001 12C4.00001 12.9626 4.16957 13.8833 4.4796 14.7354C4.74252 15.4581 4.89633 16.3023 4.73374 17.1712L4.25153 19.7485L6.82877 19.2663C7.69772 19.1037 8.54194 19.2575 9.26459 19.5204C10.1167 19.8304 11.0374 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2.00001 12C2.00001 6.47715 6.47717 2 12 2C17.5229 2 22 6.47715 22 12C22 17.5228 17.5229 22 12 22C10.8009 22 9.64888 21.7885 8.58079 21.3999C8.0783 21.2171 7.60913 21.155 7.1966 21.2322L3.18393 21.9829C2.8596 22.0436 2.52622 21.9404 2.29291 21.7071C2.0596 21.4738 1.95639 21.1404 2.01707 20.8161L2.76786 16.8034C2.84504 16.3909 2.78295 15.9217 2.60013 15.4192C2.21152 14.3511 2.00001 13.1992 2.00001 12Z" fill="currentColor" /><path d="M18 12C18 12.8284 17.3284 13.5 16.5 13.5C15.6716 13.5 15 12.8284 15 12C15 11.1716 15.6716 10.5 16.5 10.5C17.3284 10.5 18 11.1716 18 12Z" fill="currentColor" /><path d="M13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12Z" fill="currentColor" /><path d="M9.00001 12C9.00001 12.8284 8.32844 13.5 7.50001 13.5C6.67159 13.5 6.00001 12.8284 6.00001 12C6.00001 11.1716 6.67159 10.5 7.50001 10.5C8.32844 10.5 9.00001 11.1716 9.00001 12Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 2.4C4.90722 2.4 2.40001 4.90721 2.40001 8C2.40001 8.6738 2.5187 9.31832 2.73572 9.91479C2.91976 10.4207 3.02743 11.0116 2.91362 11.6199L2.57607 13.4239L4.38014 13.0864C4.98841 12.9726 5.57936 13.0802 6.08522 13.2643C6.6817 13.4813 7.32621 13.6 8.00001 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 8.00001 2.4ZM1.00001 8C1.00001 4.13401 4.13402 1 8.00001 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8.00001 15C7.1606 15 6.35422 14.8519 5.60655 14.5799C5.25481 14.4519 4.92639 14.4085 4.63762 14.4625L1.82875 14.9881C1.60172 15.0305 1.36835 14.9583 1.20504 14.795C1.04172 14.6317 0.969473 14.3983 1.01195 14.1713L1.5375 11.3624C1.59153 11.0736 1.54806 10.7452 1.42009 10.3935C1.14807 9.6458 1.00001 8.83941 1.00001 8Z" fill="currentColor" /><path d="M12.2 8C12.2 8.5799 11.7299 9.05 11.15 9.05C10.5701 9.05 10.1 8.5799 10.1 8C10.1 7.4201 10.5701 6.95 11.15 6.95C11.7299 6.95 12.2 7.4201 12.2 8Z" fill="currentColor" /><path d="M9.05001 8C9.05001 8.5799 8.57991 9.05 8.00001 9.05C7.42011 9.05 6.95001 8.5799 6.95001 8C6.95001 7.4201 7.42011 6.95 8.00001 6.95C8.57991 6.95 9.05001 7.4201 9.05001 8Z" fill="currentColor" /><path d="M5.90001 8C5.90001 8.5799 5.42991 9.05 4.85001 9.05C4.27011 9.05 3.80001 8.5799 3.80001 8C3.80001 7.4201 4.27011 6.95 4.85001 6.95C5.42991 6.95 5.90001 7.4201 5.90001 8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
