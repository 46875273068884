import React, { forwardRef, MouseEvent, useCallback } from 'react';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { cnPageHeader } from '../../PageHeader.cn';
import { Icon } from '../Icon/Icon';
import { MenuItemProps } from './MenuItem.types';

const IconArrow = (className: string) => (
    <Icon className={cnPageHeader('MenuIcon', [className])} size="m" glyph="arrowShortDown" sizeManagement="parent" />
);

const IconArrowRight = (className: string) => (
    <Icon className={cnPageHeader('MenuIcon', [className])} size="m" glyph="arrowShortRight" sizeManagement="parent" />
);

export const SimpleMenuItem = forwardRef<HTMLElement, MenuItemProps>((props, ref) => {
    const { item, onClick, open, disabled, className, width, size = 'm', icon = 'arrowShortDown' } = props;

    const { name, url, isActive, icon: iconLeft } = item;
    const type = url ? 'link' : undefined;
    let iconElem;

    if (open !== undefined) {
        if (icon === 'down') {
            iconElem = IconArrow;
        }
        if (icon === 'right') {
            iconElem = IconArrowRight;
        }
    }

    const handleClick = useCallback((e: MouseEvent) => onClick?.(e, item), [onClick, item]);

    return (
        <Button
            className={cnPageHeader('MenuItem', { open, isActive }, [className])}
            type={type}
            iconRight={iconElem}
            iconLeft={iconLeft}
            disabled={disabled}
            view="clear"
            size={size}
            onClick={handleClick}
            url={url}
            pin="clear-clear"
            innerRef={ref}
            width={width}
        >
            {name}
        </Button>
    );
});
