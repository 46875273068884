import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'inbox';
    sizeManagement?: 'self' | 'parent';
}

export const withInboxIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'inbox',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7ZM5 14V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V14H15.8641C15.7941 14.285 15.6864 14.6133 15.5194 14.9472C15.2726 15.4408 14.8867 15.9649 14.2891 16.3633C13.6852 16.7659 12.925 17 12 17C11.075 17 10.3148 16.7659 9.71092 16.3633C9.11331 15.9649 8.72739 15.4408 8.48057 14.9472C8.31361 14.6133 8.20589 14.285 8.13593 14H5ZM9.99998 12.993C9.99622 12.4439 9.54996 12 9 12H5V7C5 5.89543 5.89543 5 7 5H17C18.1046 5 19 5.89543 19 7V12H15C14.4501 12 14.0038 12.4439 14 12.993L13.9998 13.0004C13.9995 13.0106 13.9986 13.0301 13.9967 13.0577C13.9927 13.1131 13.9843 13.1988 13.9667 13.3044C13.9308 13.5201 13.8607 13.7925 13.7306 14.0528C13.6024 14.3092 13.4258 14.5351 13.1797 14.6992C12.9398 14.8591 12.575 15 12 15C11.425 15 11.0602 14.8591 10.8203 14.6992C10.5742 14.5351 10.3976 14.3092 10.2694 14.0528C10.1393 13.7925 10.0692 13.5201 10.0333 13.3044C10.0157 13.1988 10.0073 13.1131 10.0033 13.0577C10.0013 13.0301 10.0005 13.0106 10.0002 13.0004L9.99998 12.993Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 4.50001C1.70001 2.95361 2.95362 1.70001 4.50001 1.70001H11.5C13.0464 1.70001 14.3 2.95362 14.3 4.50001V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.50001C2.95361 14.3 1.70001 13.0464 1.70001 11.5V4.50001ZM3.10001 9.40001V11.5C3.10001 12.2732 3.72681 12.9 4.50001 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5V9.40001H10.7049C10.6559 9.59952 10.5805 9.82932 10.4636 10.0631C10.2908 10.4086 10.0207 10.7754 9.60236 11.0543C9.17966 11.3361 8.6475 11.5 8.00001 11.5C7.35252 11.5 6.82037 11.3361 6.39766 11.0543C5.97933 10.7754 5.70918 10.4086 5.53641 10.0631C5.41954 9.82932 5.34414 9.59952 5.29517 9.40001H3.10001ZM6.60001 8.69935C6.60001 8.69935 6.60001 8.69935 6.60001 8.69935L6.60001 8.69764L6.6 8.69598L6.60001 8.69935C6.60025 8.70648 6.60096 8.72109 6.60233 8.74037C6.60511 8.77919 6.61099 8.8392 6.6233 8.91306C6.64847 9.06409 6.69751 9.25476 6.78861 9.43696C6.87834 9.61642 7.00194 9.77459 7.17424 9.88945C7.34216 10.0014 7.5975 10.1 8.00001 10.1C8.40252 10.1 8.65787 10.0014 8.82578 9.88945C8.99808 9.77459 9.12168 9.61642 9.21141 9.43696C9.30251 9.25476 9.35155 9.06409 9.37672 8.91306C9.38903 8.8392 9.39492 8.77919 9.39769 8.74037C9.39907 8.72109 9.39965 8.7074 9.39989 8.70028L9.40003 8.69511C9.40267 8.31077 9.71505 8.00001 10.1 8.00001H12.9V4.50001C12.9 3.72681 12.2732 3.10001 11.5 3.10001H4.50001C3.72681 3.10001 3.10001 3.72681 3.10001 4.50001V8.00001H5.90001C6.28498 8.00001 6.59736 8.31164 6.6 8.69598C6.60001 8.69713 6.60001 8.6982 6.60001 8.69935Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
