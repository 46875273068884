import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'travelHotelFeatures';
    sizeManagement?: 'self' | 'parent';
}

export const withTravelHotelFeaturesIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'travelHotelFeatures',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4V14.5C12 15.3284 11.3284 16 10.5 16H5.5C4.67157 16 4 15.3284 4 14.5V9.1736C4 8.00352 5.0078 7.08091 6.17001 6.9454C7.88794 6.74509 10 6.07657 10 4C10 2.89543 9.10457 2 8 2C6.89543 2 6 2.89543 6 4C6 4.55228 5.55228 5 5 5C4.44772 5 4 4.55228 4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4ZM7.99459 13.3473L6.63341 14.2177C6.53815 14.2786 6.41335 14.2479 6.35466 14.149C6.32607 14.1008 6.31738 14.0428 6.33055 13.9879L6.72594 12.3405L5.57756 11.4056C5.48952 11.3339 5.47414 11.2017 5.5432 11.1104C5.57798 11.0644 5.62965 11.0355 5.68581 11.0306L7.18658 10.901L7.81073 9.37802C7.85452 9.27116 7.97349 9.22138 8.07646 9.26683C8.12486 9.28819 8.16336 9.32826 8.18382 9.37856L8.80296 10.901L10.3141 11.0307C10.4256 11.0403 10.5085 11.1419 10.4993 11.2576C10.4946 11.3161 10.4667 11.3699 10.4221 11.406L9.26906 12.3405L9.66374 13.989C9.69073 14.1017 9.62457 14.2158 9.51598 14.2438C9.46321 14.2574 9.40738 14.2484 9.36105 14.2189L7.99459 13.3473Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
