import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';

import { compose, composeU } from '@bem-react/core';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Drawer } from '@yandex-lego/components/Drawer/touch-phone/bundle';
import {
    Menu as MenuBase,
    withSizeM,
    withSizeS,
    withViewDefault,
    withWidthMax,
} from '@yandex-lego/components/Menu/touch-phone';

import { withSizeL } from '../../../Menu';
import { cnPageHeader } from '../../PageHeader.cn';
import { IMenuItem, MenuItemClickHandler } from '../../PageHeader.types';
import { Icon } from '../Icon/Icon';
import { useMenuItems } from '../Menu/Menu';

import './Burger.css';

export const Menu = compose(
    composeU(withSizeS, withSizeM, withSizeL),
    withWidthMax,
    withViewDefault,
)(MenuBase);

const IconGlyphMenu = (className: string) => (
    <Icon className={className} size="l" glyph="menu" />
);

// бургер должен уметь отображать в сайдбаре плашки с действиями и меню, если они есть

export interface IBurgerProps {
    items?: IMenuItem[];
    onItemClick?: MenuItemClickHandler;
    actionButton?: ReactNode;
}

export const Burger: FC<IBurgerProps> = (props) => {
    const { items = [], onItemClick, actionButton } = props;
    const scopeRef = useRef(null);

    const [visible, setVisible] = useState(false);
    const handleClick = useCallback(() => setVisible(!visible), [visible]);
    const handleClose = useCallback(() => setVisible(false), []);

    const menuItems = useMenuItems(items, 'l', onItemClick, true);
    const menu = menuItems.length ? (
        <div className={cnPageHeader('BurgerMenu')}>
            <Menu view="default" size="l" items={menuItems} width="max" />
        </div>
    ) : null;

    const button = (
        <div ref={scopeRef} className={cnPageHeader('Burger')}>
            <Button
                className={cnPageHeader('BurgerButton')}
                onClick={handleClick}
                icon={IconGlyphMenu}
                view="clear"
                size="m"
            />
            <Drawer
                visible={visible}
                onClose={handleClose}
                view="default"
                direction="left"
                scope={scopeRef}
            >
                <div className={cnPageHeader('BurgerContent')}>
                    {menu}
                    {actionButton && (
                        <div className={cnPageHeader('BurgerActionButton')}>
                            {actionButton}
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );

    return button;
};
