import { MouseEvent, ReactNode } from 'react';

import { IconProvider } from '@yandex-lego/components/Button/Icon/Button-Icon';

export interface ISimpleMenuItem {
    id: string;
    name: string;
    url?: string;
    isActive?: boolean;
    icon?: IconProvider;
}

export interface IMenuItem extends ISimpleMenuItem {
    children?: ISimpleMenuItem[];
}

export type MenuItemClickHandler = (e: MouseEvent, item: ISimpleMenuItem) => void;

export enum MenuType {
    BURGER = 'burger',
    TABS = 'tabs',
}

export interface ITabsMenuItem {
    id: string;
    isActive?: boolean;
    disabled?: boolean;
    content: string | ReactNode;
}

export type TabsMenuItemClickHandler = (e: MouseEvent, item: ITabsMenuItem) => void;
