import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'message';
    sizeManagement?: 'self' | 'parent';
}

export const withMessageIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'message',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.52145 3.71283C7.8551 3.30561 7 3.78518 7 4.56611V7.02019L17.5198 9.21181L8.52145 3.71283ZM17.5198 14.7882L6.90306 17H7V19.4339C7 20.2148 7.8551 20.6944 8.52145 20.2872L17.5198 14.7882ZM5 17.3965V19.4339C5 21.7767 7.56529 23.2154 9.56435 21.9937L21.7289 14.5598C23.6431 13.3901 23.6431 10.6099 21.7289 9.44015L9.56435 2.00626C7.56529 0.784616 5 2.22332 5 4.56611V6.60352L4.1813 6.43296C2.56076 6.09535 1.25501 7.76015 1.96901 9.25358L3.28204 12L1.96901 14.7464C1.25501 16.2398 2.56075 17.9046 4.1813 17.567L5 17.3965ZM21.0969 12L3.7734 8.39092L5.08643 11.1373C5.34724 11.6829 5.34724 12.3171 5.08642 12.8627L3.77339 15.6091L21.0969 12Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_921_2751)"><path fillRule="evenodd" clipRule="evenodd" d="M5.565 2.19899C5.09856 1.91394 4.49999 2.24964 4.49999 2.79629V4.51415L11.8638 6.04828L5.565 2.19899ZM11.8638 9.95173L4.43213 11.5H4.49999V13.2037C4.49999 13.7504 5.09856 14.0861 5.565 13.801L11.8638 9.95173ZM3.09999 11.7775V13.2037C3.09999 14.8437 4.89569 15.8508 6.29503 14.9956L14.8102 9.7919C16.1502 8.97305 16.1502 7.02697 14.8102 6.20812L6.29503 1.0044C4.89569 0.149243 3.09999 1.15634 3.09999 2.79629V4.22248L2.5269 4.10309C1.39252 3.86676 0.478493 5.03211 0.978292 6.07752L1.89741 8.00001L0.978292 9.9225C0.478494 10.9679 1.39252 12.1333 2.5269 11.8969L3.09999 11.7775ZM14.3678 8.00001L2.24136 5.47366L3.16049 7.39615C3.34306 7.77802 3.34306 8.22199 3.16049 8.60387L2.24136 10.5264L14.3678 8.00001Z" fill="currentColor" /></g><defs><clipPath id="clip0_921_2751"><rect width={16} height={16} fill="white" /></clipPath></defs></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
