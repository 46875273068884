import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingAlignRight';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingAlignRightIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingAlignRight',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 12C11 11.4477 11.4477 11 12 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H12C11.4477 13 11 12.5523 11 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7 18C7 17.4477 7.44772 17 8 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H8C7.44772 19 7 18.5523 7 18Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.30001 8.00001C7.30001 7.61341 7.61341 7.30001 8.00001 7.30001H13.6C13.9866 7.30001 14.3 7.61341 14.3 8.00001C14.3 8.38661 13.9866 8.70001 13.6 8.70001H8.00001C7.61341 8.70001 7.30001 8.38661 7.30001 8.00001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 12.2C4.50001 11.8134 4.81341 11.5 5.20001 11.5H13.6C13.9866 11.5 14.3 11.8134 14.3 12.2C14.3 12.5866 13.9866 12.9 13.6 12.9H5.20001C4.81341 12.9 4.50001 12.5866 4.50001 12.2Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 3.80001C1.70001 3.41341 2.01341 3.10001 2.40001 3.10001H13.6C13.9866 3.10001 14.3 3.41341 14.3 3.80001C14.3 4.18661 13.9866 4.50001 13.6 4.50001H2.40001C2.01341 4.50001 1.70001 4.18661 1.70001 3.80001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
