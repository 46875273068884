import React, { useMemo } from 'react';

import { withBemMod } from '@bem-react/core';
import { IMenuSizeSProps as MenuWithSizeSProps } from '@yandex-lego/components/Menu/_size/Menu_size_s';
import { cnSelect, ISelectProps } from '@yandex-lego/components/Select';

export type SelectWithSizeSProps = {
    size?: 's';
};

export const withSizeS = withBemMod<SelectWithSizeSProps, ISelectProps>(
    cnSelect(),
    { size: 's' },
    (Select) => ({ view, ...otherProps }) => {
        const renderMenu = useMemo(() => {
            return (menuProps: MenuWithSizeSProps, Menu: React.ComponentType<MenuWithSizeSProps>) => {
                return (
                    <Menu
                        {...menuProps}
                        size="s"
                    />
                );
            };
        }, []);
        return (
            <Select
                // @ts-ignore
                renderMenu={renderMenu}
                {...otherProps}
            />
        );
    },
);
