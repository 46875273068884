import React, { ReactNode } from 'react';

import { cnTabsMenuItem } from './TabsMenuItem.cn';

import './TabMenuItem.css';
interface TabsMenuItemProps {
    icon?: ReactNode,
    status?: ReactNode,
}

export const TabsMenuItem:React.FC<TabsMenuItemProps> = (props) => {
    const { icon, status, children } = props;
    const leftIcon = icon ? (
        <div className={cnTabsMenuItem('Icon')}>
            {icon}
        </div>
    ) : null;

    const rightIcon = status ? (
        <div className={cnTabsMenuItem('RightSlot')}>
            {status}
        </div>
    ) : null;

    return (
        <div className={cnTabsMenuItem()}>
            {leftIcon}
            <div>
                {children}
            </div>
            {rightIcon}
        </div>
    );
};
