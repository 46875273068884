import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'eye';
    sizeManagement?: 'self' | 'parent';
}

export const withEyeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'eye',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8.00001 12C8.00001 9.79086 9.79087 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79087 16 8.00001 14.2091 8.00001 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.05417 12C4.26753 12.588 4.85145 13.9763 5.98185 15.2689C7.23368 16.7004 9.13649 18 12 18C14.8635 18 16.7663 16.7004 18.0182 15.2689C19.1486 13.9763 19.7325 12.588 19.9458 12C19.7325 11.412 19.1486 10.0237 18.0182 8.73107C16.7663 7.29962 14.8635 6 12 6C9.13648 6 7.23368 7.29962 5.98185 8.73107C4.85144 10.0237 4.26753 11.412 4.05417 12ZM4.47633 7.41448C6.01698 5.65276 8.43443 4 12 4C15.5656 4 17.983 5.65276 19.5237 7.41448C21.0443 9.1533 21.7289 11.0118 21.9081 11.5544C22.0045 11.8467 22.0045 12.1533 21.9081 12.4456C21.7289 12.9882 21.0443 14.8467 19.5237 16.5855C17.983 18.3472 15.5656 20 12 20C8.43443 20 6.01698 18.3472 4.47634 16.5855C2.95571 14.8467 2.27108 12.9882 2.09195 12.4456C1.99547 12.1533 1.99547 11.8467 2.09195 11.5544C2.27108 11.0118 2.95571 9.1533 4.47633 7.41448Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.00002 6.59999C7.22683 6.59999 6.60002 7.2268 6.60002 7.99999C6.60002 8.77319 7.22683 9.39999 8.00002 9.39999C8.77322 9.39999 9.40002 8.77319 9.40002 7.99999C9.40002 7.2268 8.77322 6.59999 8.00002 6.59999ZM5.20002 7.99999C5.20002 6.4536 6.45363 5.19999 8.00002 5.19999C9.54642 5.19999 10.8 6.4536 10.8 7.99999C10.8 9.54639 9.54642 10.8 8.00002 10.8C6.45363 10.8 5.20002 9.54639 5.20002 7.99999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M2.43794 7.99999C2.58729 8.41162 2.99603 9.38341 3.78731 10.2882C4.66359 11.2903 5.99556 12.2 8.00002 12.2C10.0045 12.2 11.3365 11.2903 12.2127 10.2882C13.004 9.38342 13.4128 8.41162 13.5621 7.99999C13.4128 7.58836 13.004 6.61657 12.2127 5.71174C11.3365 4.70973 10.0045 3.79999 8.00002 3.79999C5.99556 3.79999 4.66359 4.70973 3.78731 5.71175C2.99603 6.61657 2.58729 7.58837 2.43794 7.99999ZM2.73345 4.79013C3.8119 3.55693 5.50412 2.39999 8.00002 2.39999C10.4959 2.39999 12.1881 3.55693 13.2666 4.79013C14.331 6.0073 14.8103 7.30826 14.9357 7.6881C15.0032 7.89267 15.0032 8.10732 14.9357 8.31189C14.8103 8.69173 14.331 9.99268 13.2666 11.2099C12.1881 12.4431 10.4959 13.6 8.00002 13.6C5.50412 13.6 3.8119 12.4431 2.73345 11.2099C1.66902 9.99268 1.18977 8.69173 1.06438 8.31189C0.99685 8.10732 0.99685 7.89267 1.06438 7.6881C1.18977 7.30826 1.66902 6.00731 2.73345 4.79013Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
