import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'location';
    sizeManagement?: 'self' | 'parent';
}

export const withLocationIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'location',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8ZM8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 3C8.13401 3 5 6.13401 5 10C5 10.896 5.3099 11.9812 5.87046 13.1504C6.42526 14.3075 7.19385 15.481 8.03229 16.5339C8.87151 17.5879 9.75944 18.4943 10.5371 19.1267C10.9268 19.4435 11.2703 19.6764 11.5507 19.8252C11.69 19.8991 11.8016 19.9458 11.8857 19.9727C11.9578 19.9958 11.994 19.9994 12 19.9999C12.006 19.9994 12.0422 19.9958 12.1143 19.9727C12.1984 19.9458 12.31 19.8991 12.4493 19.8252C12.7297 19.6764 13.0732 19.4435 13.4629 19.1267C14.2406 18.4943 15.1285 17.5879 15.9677 16.5339C16.8061 15.481 17.5747 14.3075 18.1295 13.1504C18.6901 11.9812 19 10.896 19 10C19 6.13401 15.866 3 12 3ZM3 10C3 5.02944 7.02944 1 12 1C16.9706 1 21 5.02944 21 10C21 11.3131 20.5599 12.7075 19.933 14.0151C19.3003 15.3346 18.4439 16.635 17.5323 17.7798C16.6215 18.9236 15.6344 19.9387 14.7246 20.6784C14.2706 21.0477 13.8171 21.3635 13.3867 21.5919C12.9778 21.8089 12.4922 22 12 22C11.5078 22 11.0222 21.8089 10.6133 21.5919C10.1829 21.3635 9.72944 21.0477 9.27537 20.6784C8.36556 19.9387 7.37849 18.9236 6.46771 17.7798C5.55615 16.635 4.69974 15.3346 4.06704 14.0151C3.4401 12.7075 3 11.3131 3 10Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 5.19999C7.22681 5.19999 6.60001 5.82679 6.60001 6.59999C6.60001 7.37319 7.22681 7.99999 8.00001 7.99999C8.77321 7.99999 9.40001 7.37319 9.40001 6.59999C9.40001 5.82679 8.77321 5.19999 8.00001 5.19999ZM5.20001 6.59999C5.20001 5.05359 6.45362 3.79999 8.00001 3.79999C9.54641 3.79999 10.8 5.05359 10.8 6.59999C10.8 8.14638 9.54641 9.39999 8.00001 9.39999C6.45362 9.39999 5.20001 8.14638 5.20001 6.59999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 1.69999C5.29382 1.69999 3.10001 3.89379 3.10001 6.59999C3.10001 7.2272 3.31695 7.98686 3.70933 8.80525C4.0977 9.61523 4.63571 10.4367 5.22261 11.1737C5.81007 11.9115 6.43162 12.546 6.97601 12.9887C7.24878 13.2105 7.4892 13.3735 7.68551 13.4776C7.78304 13.5294 7.86115 13.562 7.92001 13.5809C7.97046 13.597 7.99578 13.5995 8.00001 13.5999C8.00424 13.5995 8.02956 13.597 8.08001 13.5809C8.13887 13.562 8.21698 13.5294 8.31451 13.4776C8.51082 13.3735 8.75124 13.2105 9.02402 12.9887C9.5684 12.546 10.19 11.9115 10.7774 11.1737C11.3643 10.4367 11.9023 9.61523 12.2907 8.80525C12.6831 7.98686 12.9 7.2272 12.9 6.59999C12.9 3.89379 10.7062 1.69999 8.00001 1.69999ZM1.70001 6.59999C1.70001 3.12059 4.52062 0.299988 8.00001 0.299988C11.4794 0.299988 14.3 3.12059 14.3 6.59999C14.3 7.51917 13.9919 8.49521 13.5531 9.41052C13.1102 10.3342 12.5107 11.2445 11.8726 12.0458C11.2351 12.8465 10.5441 13.557 9.90726 14.0749C9.5894 14.3334 9.27201 14.5545 8.97067 14.7143C8.68447 14.8662 8.34455 15 8.00001 15C7.65547 15 7.31556 14.8662 7.02936 14.7143C6.72801 14.5545 6.41062 14.3334 6.09277 14.0749C5.4559 13.557 4.76495 12.8465 4.12741 12.0458C3.48931 11.2445 2.88983 10.3342 2.44694 9.41052C2.00808 8.49521 1.70001 7.51917 1.70001 6.59999Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
