import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'desktop';
    sizeManagement?: 'self' | 'parent';
}

export const withDesktopIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'desktop',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 7C2 4.79086 3.79086 3 6 3H18C20.2091 3 22 4.79086 22 7V14C22 16.2091 20.2091 18 18 18H6C3.79086 18 2 16.2091 2 14V7ZM6 5C4.89543 5 4 5.89543 4 7V14C4 15.1046 4.89543 16 6 16H18C19.1046 16 20 15.1046 20 14V7C20 5.89543 19.1046 5 18 5H6Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7 21C7 20.4477 7.44772 20 8 20H16C16.5523 20 17 20.4477 17 21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 4.50001C1 2.95361 2.2536 1.70001 3.8 1.70001H12.2C13.7464 1.70001 15 2.95361 15 4.50001V9.40001C15 10.9464 13.7464 12.2 12.2 12.2H3.8C2.2536 12.2 1 10.9464 1 9.40001V4.50001ZM3.8 3.10001C3.0268 3.10001 2.4 3.72681 2.4 4.50001V9.40001C2.4 10.1732 3.0268 10.8 3.8 10.8H12.2C12.9732 10.8 13.6 10.1732 13.6 9.40001V4.50001C13.6 3.72681 12.9732 3.10001 12.2 3.10001H3.8Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.5 14.3C4.5 13.9134 4.8134 13.6 5.2 13.6H10.8C11.1866 13.6 11.5 13.9134 11.5 14.3C11.5 14.6866 11.1866 15 10.8 15H5.2C4.8134 15 4.5 14.6866 4.5 14.3Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
