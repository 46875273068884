import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapShield';
    sizeManagement?: 'self' | 'parent';
}

export const withGapShieldIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapShield',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.71076 0.730969L3.04409 1.76801C2.43404 1.90357 2 2.44466 2 3.06959V9.19263C2 10.53 2.6684 11.779 3.7812 12.5208L7.8151 15.2101C7.92707 15.2847 8.07293 15.2847 8.1849 15.2101L12.2188 12.5208C13.3316 11.779 14 10.53 14 9.19263V3.06959C14 2.44466 13.566 1.90357 12.9559 1.76801L8.28924 0.730969C8.09874 0.688635 7.90126 0.688635 7.71076 0.730969ZM8 2.03255L8 13.7309L11.4792 11.4114C12.2211 10.9169 12.6667 10.0842 12.6667 9.19263V3.06959L8 2.03255Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
