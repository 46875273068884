import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'bulb';
    sizeManagement?: 'self' | 'parent';
}

export const withBulbIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'bulb',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 3C8.13401 3 5 6.13401 5 10C5 12.0662 5.8939 13.9222 7.31923 15.2049C7.71944 15.5651 8.09925 16.0008 8.39623 16.5H11V12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16.5H15.6038C15.9008 16.0008 16.2806 15.5651 16.6808 15.2049C18.1061 13.9222 19 12.0662 19 10C19 6.13401 15.866 3 12 3ZM15.0014 18.5H8.99865C8.99955 18.5337 9 18.5676 9 18.6015C9 19.65 9.84997 20.5 10.8985 20.5H13.1015C14.15 20.5 15 19.65 15 18.6015C15 18.5676 15.0005 18.5337 15.0014 18.5ZM3 10C3 5.02944 7.02944 1 12 1C16.9706 1 21 5.02944 21 10C21 12.6567 19.8476 15.0455 18.0187 16.6915C17.3715 17.274 17 17.9512 17 18.6015C17 20.7546 15.2546 22.5 13.1015 22.5H10.8985C8.7454 22.5 7 20.7546 7 18.6015C7 17.9512 6.62849 17.274 5.98133 16.6915C4.15236 15.0455 3 12.6567 3 10Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 1.69999C5.29382 1.69999 3.10001 3.89379 3.10001 6.59999C3.10001 8.0463 3.72574 9.34552 4.72347 10.2434C5.00362 10.4956 5.26948 10.8005 5.47737 11.15H7.30001V7.99999C7.30001 7.61339 7.61341 7.29999 8.00001 7.29999C8.38661 7.29999 8.70001 7.61339 8.70001 7.99999V11.15H10.5227C10.7305 10.8005 10.9964 10.4956 11.2766 10.2434C12.2743 9.34552 12.9 8.0463 12.9 6.59999C12.9 3.89379 10.7062 1.69999 8.00001 1.69999ZM10.101 12.55H5.89907C5.8997 12.5736 5.90001 12.5973 5.90001 12.6211C5.90001 13.355 6.49499 13.95 7.22893 13.95H8.7711C9.50504 13.95 10.1 13.355 10.1 12.6211C10.1 12.5973 10.1003 12.5736 10.101 12.55ZM1.70001 6.59999C1.70001 3.12059 4.52062 0.299988 8.00001 0.299988C11.4794 0.299988 14.3 3.12059 14.3 6.59999C14.3 8.45965 13.4934 10.1319 12.2131 11.2841C11.7601 11.6918 11.5 12.1658 11.5 12.6211C11.5 14.1282 10.2782 15.35 8.7711 15.35H7.22893C5.72179 15.35 4.50001 14.1282 4.50001 12.6211C4.50001 12.1658 4.23996 11.6918 3.78694 11.2841C2.50666 10.1319 1.70001 8.45965 1.70001 6.59999Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
