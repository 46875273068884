import React, { useMemo } from 'react';

import { withBemMod } from '@bem-react/core';
import { IButtonViewClearProps } from '@yandex-lego/components/Button/_view/Button_view_clear';
import { cnSelect, ISelectProps } from '@yandex-lego/components/Select/desktop';

export type SelectWithViewClear = {
    view?: 'clear';
};

export const withViewClear = withBemMod<SelectWithViewClear, ISelectProps>(
    cnSelect(),
    { view: 'clear' },
    (Select) => ({ view, ...otherProps }) => {
        const renderTrigger = useMemo(() => {
            return (buttonProps: IButtonViewClearProps, Button: React.ComponentType<IButtonViewClearProps>) => {
                return (
                    <Button {...buttonProps} view="clear" />
                );
            };
        }, []);
        return (
            <Select
                renderTrigger={renderTrigger}
                {...otherProps}
            />
        );
    },
);
