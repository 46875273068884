import { ComponentProps } from 'react';

import { compose, composeU } from '@bem-react/core';

import {
    UserAvatar as UserAvatarBase,
    withSizeL,
    withSizeM,
    withSizeS,
} from './index';

export const UserAvatar = compose(
    composeU(
        withSizeL,
        withSizeM,
        withSizeS,
    ),
)(UserAvatarBase);

export type IUserAvatarProps = ComponentProps<typeof UserAvatar>;
