import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'minus';
    sizeManagement?: 'self' | 'parent';
}

export const withMinusIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'minus',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.00146 6.00012C1.00146 5.58591 1.33725 5.25012 1.75146 5.25012H10.2523C10.6665 5.25012 11.0023 5.58591 11.0023 6.00012C11.0023 6.41434 10.6665 6.75012 10.2523 6.75012H1.75146C1.33725 6.75012 1.00146 6.41434 1.00146 6.00012Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 8C1.70001 7.6134 2.01341 7.3 2.40001 7.3H13.6C13.9866 7.3 14.3 7.6134 14.3 8C14.3 8.3866 13.9866 8.7 13.6 8.7H2.40001C2.01341 8.7 1.70001 8.3866 1.70001 8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
