import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'video';
    sizeManagement?: 'self' | 'parent';
}

export const withVideoIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'video',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 8C2 5.79086 3.79086 4 6 4H14C16.2091 4 18 5.79086 18 8L19.6 6.8C20.5889 6.05836 22 6.76393 22 8V16C22 17.2361 20.5889 17.9416 19.6 17.2L18 16C18 18.2091 16.2091 20 14 20H6C3.79086 20 2 18.2091 2 16V8ZM6 6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V14C16 13.6212 16.214 13.275 16.5528 13.1056C16.8916 12.9362 17.297 12.9727 17.6 13.2L20 15V9L17.6 10.8C17.297 11.0273 16.8916 11.0638 16.5528 10.8944C16.214 10.725 16 10.3788 16 10V8C16 6.89543 15.1046 6 14 6H6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 5.2C1 3.6536 2.2536 2.39999 3.8 2.39999H9.4C10.9464 2.39999 12.2 3.6536 12.2 5.19999L13.32 4.35999C14.0122 3.84085 15 4.33475 15 5.19999V10.8C15 11.6652 14.0122 12.1591 13.32 11.64L12.2 10.8C12.2 12.3464 10.9464 13.6 9.4 13.6H3.8C2.25361 13.6 1 12.3464 1 10.8V5.2ZM3.8 3.79999C3.0268 3.79999 2.4 4.4268 2.4 5.2V10.8C2.4 11.5732 3.0268 12.2 3.8 12.2H9.4C10.1732 12.2 10.8 11.5732 10.8 10.8V9.39999C10.8 9.13485 10.9498 8.89247 11.187 8.77389C11.4241 8.65532 11.7079 8.68091 11.92 8.83999L13.6 10.1V5.89999L11.92 7.15999C11.7079 7.31908 11.4241 7.34467 11.187 7.22609C10.9498 7.10752 10.8 6.86513 10.8 6.59999V5.19999C10.8 4.4268 10.1732 3.79999 9.4 3.79999H3.8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
