import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'handBaggageOn';
    sizeManagement?: 'self' | 'parent';
}

export const withHandBaggageOnIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'handBaggageOn',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.6666 4H9.66665C9.66665 3.44772 9.21893 3 8.66665 3H7.33331C6.78103 3 6.33331 3.44772 6.33331 4H5.33331C5.33331 2.89543 6.22874 2 7.33331 2H8.66665C9.77122 2 10.6666 2.89543 10.6666 4Z" fill="currentColor" /><path d="M10.1861 8.85355C10.3814 8.65829 10.3814 8.34171 10.1861 8.14645C9.99085 7.95118 9.67426 7.95118 9.479 8.14645L7.38862 10.2368L6.52022 9.36843C6.32496 9.17317 6.00838 9.17317 5.81312 9.36843C5.61785 9.56369 5.61785 9.88027 5.81312 10.0755L7.03108 11.2935L7.03505 11.2975C7.12315 11.3856 7.23595 11.434 7.35116 11.4426C7.49131 11.453 7.63503 11.4047 7.7422 11.2975L7.74647 11.2932L10.1861 8.85355Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.33334 5C3.1242 5 1.33334 6.79086 1.33334 9V10.3333C1.33334 12.5425 3.1242 14.3333 5.33334 14.3333H10.6667C12.8758 14.3333 14.6667 12.5425 14.6667 10.3333V9C14.6667 6.79086 12.8758 5 10.6667 5H5.33334ZM10.6667 6.16667H5.33334C3.76853 6.16667 2.5 7.43519 2.5 9V10.3333C2.5 11.8981 3.76853 13.1667 5.33334 13.1667H10.6667C12.2315 13.1667 13.5 11.8981 13.5 10.3333V9C13.5 7.43519 12.2315 6.16667 10.6667 6.16667Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
