import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapTrip';
    sizeManagement?: 'self' | 'parent';
}

export const withGapTripIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapTrip',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.33333 1.66663C10.0697 1.66663 10.6667 2.26358 10.6667 2.99996V3.99996H11.3333V14H4.66666V3.99996H5.33333V2.99996C5.33333 2.26358 5.93028 1.66663 6.66666 1.66663H9.33333ZM6.66666 2.99996H9.33333V3.99996H6.66666V2.99996Z" fill="currentColor" /><path d="M14.6667 12.6666C14.6667 13.403 14.0697 14 13.3333 14H12.6667V3.99996H13.3333C14.0697 3.99996 14.6667 4.59691 14.6667 5.33329V12.6666Z" fill="currentColor" /><path d="M1.33333 5.33329C1.33333 4.59691 1.93028 3.99996 2.66666 3.99996H3.33333V14H2.66666C1.93028 14 1.33333 13.403 1.33333 12.6666V5.33329Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
