import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'upload';
    sizeManagement?: 'self' | 'parent';
}

export const withUploadIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'upload',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 15C12.5523 15 13 14.5523 13 14V8C13 7.3188 12.884 6.52159 12.731 5.76642L12.8742 5.62275C12.914 5.6684 12.9506 5.71812 12.9832 5.77194C13.4798 6.5903 14.11 7.51947 14.7422 8.15635L15.2929 8.70708C15.6834 9.09761 16.3166 9.09761 16.7071 8.70708C17.0955 8.31868 17.0976 7.69027 16.7134 7.29926L12.7071 3.29289C12.3677 2.95348 11.845 2.90906 11.4576 3.15963C11.3992 3.19736 11.344 3.24177 11.2929 3.29287L7.29289 7.29287C6.90237 7.68339 6.90237 8.31656 7.29289 8.70708C7.68246 9.09665 8.31349 9.09761 8.70424 8.70994L8.70711 8.70708L9.24261 8.17157C9.87983 7.53435 10.5161 6.59687 11.0166 5.77196C11.0493 5.71811 11.0859 5.66836 11.1258 5.62269L11.269 5.76642C11.116 6.52159 11 7.3188 11 8V14C11 14.5523 11.4477 15 12 15Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M20 13C20.5523 13 21 13.4477 21 14V17C21 19.2091 19.2091 21 17 21L7 21C4.79086 21 3 19.2091 3 17L3 14C3 13.4477 3.44772 13 4 13C4.55229 13 5 13.4477 5 14L5 17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V14C19 13.4477 19.4477 13 20 13Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00001 10.1001C8.38661 10.1001 8.70001 9.78674 8.70001 9.40014V5.20014C8.70001 4.7233 8.6188 4.16525 8.5117 3.63663L8.61189 3.53609C8.6398 3.56805 8.6654 3.60287 8.68826 3.64055C9.03582 4.21334 9.47689 4.86366 9.91935 5.30947L10.305 5.69515C10.5784 5.96852 11.0216 5.96852 11.295 5.69515C11.5668 5.4233 11.5683 4.98348 11.2995 4.70977L8.49496 1.90522C8.25523 1.66549 7.88489 1.63599 7.61301 1.81672C7.57487 1.84208 7.53866 1.87157 7.50503 1.9052L4.70503 4.7052C4.43166 4.97857 4.43166 5.42179 4.70503 5.69515C4.97774 5.96787 5.4195 5.96852 5.69302 5.69711L5.69498 5.69515L6.06983 5.3203C6.51589 4.87424 6.9613 4.218 7.31166 3.64057C7.33454 3.60286 7.36015 3.56802 7.38808 3.53605L7.48831 3.63663C7.38121 4.16525 7.30001 4.7233 7.30001 5.20014L7.30001 9.40014C7.30001 9.78674 7.61341 10.1001 8.00001 10.1001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13.6 8.69999C13.9866 8.69999 14.3 9.01339 14.3 9.39999V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3L4.50001 14.3C2.95361 14.3 1.70001 13.0464 1.70001 11.5L1.70001 9.39999C1.70001 9.01339 2.01341 8.69999 2.40001 8.69999C2.78661 8.69999 3.10001 9.01339 3.10001 9.39999L3.10001 11.5C3.10001 12.2732 3.72681 12.9 4.50001 12.9L11.5 12.9C12.2732 12.9 12.9 12.2732 12.9 11.5V9.39999C12.9 9.01339 13.2134 8.69999 13.6 8.69999Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
