import React, { FC } from 'react';

import { cnUserAvatar } from './UserAvatar.cn';
import { IUserAvatarProps } from './UserAvatar.types';

import './UserAvatar.css';

export const UserAvatar: FC<IUserAvatarProps> = (props) => {
    const {
        className,
        icon = 'https://avatars.mds.yandex.net/get-yapic/0/0-0/islands-retina-middle',
        alt,
        gap,
        dismissed,
    } = props;

    return (
        <div className={cnUserAvatar({ dismissed, gap }, [className])}>
            <img
                className={cnUserAvatar('Image')}
                key="image"
                src={icon}
                alt={alt}
                loading="lazy"
            />
        </div>
    );
};
