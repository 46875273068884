import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowLongDown';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowLongDownIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowLongDown',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.3 7.50024L5.3 1.70005C5.3 1.31345 5.6134 1.00005 6 1.00005C6.3866 1.00005 6.7 1.31345 6.7 1.70005L6.7 7.50024C6.7 7.97709 6.6188 8.53513 6.5117 9.06375L6.61191 9.16432C6.63981 9.13237 6.6654 9.09756 6.68826 9.05989C7.03583 8.48708 7.47692 7.83673 7.9194 7.39092L9.00503 6.30529C9.2784 6.03192 9.72161 6.03192 9.99498 6.30529C10.2668 6.57714 10.2683 7.01697 9.99949 7.29068L6.49495 10.7952C6.4829 10.8073 6.47051 10.8188 6.45783 10.8298C6.34839 10.9244 6.21236 10.9827 6.06866 10.9969C6.02585 11.0011 5.98272 11.0013 5.93987 10.9977C5.77625 10.9836 5.62206 10.9123 5.50503 10.7952L2.00503 7.29523C1.73166 7.02187 1.73166 6.57865 2.00503 6.30528C2.27773 6.03259 2.71944 6.03192 2.99297 6.30329L2.99498 6.30528L4.06983 7.38014C4.51589 7.8262 4.96129 8.48244 5.31166 9.05987C5.33453 9.09756 5.36013 9.13239 5.38805 9.16436L5.4883 9.06375C5.38121 8.53513 5.3 7.97709 5.3 7.50024Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.70708 12.2929C5.31655 11.9023 4.68339 11.9023 4.29286 12.2929C3.90234 12.6834 3.90234 13.3166 4.29286 13.7071L11.2929 20.7071C11.6834 21.0976 12.3166 21.0976 12.7071 20.7071L19.7071 13.7071C20.0976 13.3166 20.0976 12.6834 19.7071 12.2929C19.3166 11.9023 18.6834 11.9023 18.2929 12.2929L14.7573 15.8284C14.1201 16.4656 13.4838 17.4031 12.9832 18.228C12.9506 18.2819 12.914 18.3316 12.8741 18.3773L12.731 18.2336C12.884 17.4784 13 16.6812 13 16V4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4L11 16C11 16.6812 11.116 17.4785 11.269 18.2337L11.1259 18.3774C11.0859 18.3317 11.0493 18.2819 11.0167 18.228C10.5161 17.4031 9.87984 16.4656 9.24261 15.8284L5.70708 12.2929Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.59494 8.20501C3.32157 7.93164 2.87836 7.93164 2.60499 8.20501C2.33162 8.47837 2.33162 8.92159 2.60499 9.19496L7.50499 14.095C7.77836 14.3683 8.22157 14.3683 8.49494 14.095L13.3949 9.19496C13.6683 8.92159 13.6683 8.47837 13.3949 8.20501C13.1216 7.93164 12.6784 7.93164 12.405 8.20501L9.93011 10.6799C9.48405 11.1259 9.03862 11.7822 8.68824 12.3596C8.66538 12.3973 8.63979 12.4321 8.61189 12.4641L8.51169 12.3635C8.61878 11.8349 8.69999 11.2768 8.69999 10.8V2.4C8.69999 2.0134 8.38659 1.7 7.99999 1.7C7.61339 1.7 7.29999 2.0134 7.29999 2.4L7.29999 10.8C7.29999 11.2768 7.38119 11.8349 7.48829 12.3635L7.38804 12.4641C7.36012 12.4321 7.33451 12.3973 7.31164 12.3596C6.96128 11.7822 6.51587 11.1259 6.06981 10.6799L3.59494 8.20501Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
