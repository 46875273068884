import React, { FC, ReactNode } from 'react';
import { createContext } from 'react';

import { GetHostFn, Hosts } from '../utils';

type HostsContextProviderProps = {
    hosts: Hosts;
    children: ReactNode;
};

type HostsContext = {
    getHost: GetHostFn;
};

const getHostError = () => { throw new Error('Using getHost outside HostsContextProvider is forbidden') };

export const hostsContext = createContext<HostsContext>({ getHost: getHostError });

export const HostsContextProvider: FC<HostsContextProviderProps> = ({ hosts, children }) => {
    const getHost: GetHostFn = (key) => {
        return hosts[key];
    };

    return <hostsContext.Provider value={{ getHost }}>{children}</hostsContext.Provider>;
};
