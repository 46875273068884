import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingList';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingListIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingList',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9 12C9 11.4477 9.44772 11 10 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H10C9.44772 13 9 12.5523 9 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9 18C9 17.4477 9.44772 17 10 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H10C9.44772 19 9 18.5523 9 18Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9 6C9 5.44772 9.44772 5 10 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H10C9.44772 7 9 6.55228 9 6Z" fill="currentColor" /><path d="M7 18C7 19.1046 6.10457 20 5 20C3.89543 20 3 19.1046 3 18C3 16.8954 3.89543 16 5 16C6.10457 16 7 16.8954 7 18Z" fill="currentColor" /><path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="currentColor" /><path d="M7 6C7 7.10457 6.10457 8 5 8C3.89543 8 3 7.10457 3 6C3 4.89543 3.89543 4 5 4C6.10457 4 7 4.89543 7 6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.90001 7.99999C5.90001 7.61339 6.21341 7.29999 6.60001 7.29999H13.6C13.9866 7.29999 14.3 7.61339 14.3 7.99999C14.3 8.38659 13.9866 8.69999 13.6 8.69999H6.60001C6.21341 8.69999 5.90001 8.38659 5.90001 7.99999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.90001 12.2C5.90001 11.8134 6.21341 11.5 6.60001 11.5H13.6C13.9866 11.5 14.3 11.8134 14.3 12.2C14.3 12.5866 13.9866 12.9 13.6 12.9H6.60001C6.21341 12.9 5.90001 12.5866 5.90001 12.2Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.90001 3.79999C5.90001 3.41339 6.21341 3.09999 6.60001 3.09999H13.6C13.9866 3.09999 14.3 3.41339 14.3 3.79999C14.3 4.18659 13.9866 4.49999 13.6 4.49999H6.60001C6.21341 4.49999 5.90001 4.18659 5.90001 3.79999Z" fill="currentColor" /><path d="M4.50001 12.2C4.50001 12.9732 3.87321 13.6 3.10001 13.6C2.32681 13.6 1.70001 12.9732 1.70001 12.2C1.70001 11.4268 2.32681 10.8 3.10001 10.8C3.87321 10.8 4.50001 11.4268 4.50001 12.2Z" fill="currentColor" /><path d="M4.50001 7.99999C4.50001 8.77319 3.87321 9.39999 3.10001 9.39999C2.32681 9.39999 1.70001 8.77319 1.70001 7.99999C1.70001 7.2268 2.32681 6.59999 3.10001 6.59999C3.87321 6.59999 4.50001 7.2268 4.50001 7.99999Z" fill="currentColor" /><path d="M4.50001 3.79999C4.50001 4.57319 3.87321 5.19999 3.10001 5.19999C2.32681 5.19999 1.70001 4.57319 1.70001 3.79999C1.70001 3.0268 2.32681 2.39999 3.10001 2.39999C3.87321 2.39999 4.50001 3.0268 4.50001 3.79999Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
