import React, { FC } from 'react';

import { cnErrorState } from '../ErrorState.cn';
import { IErrorStateDescriptionProps } from './ErrorState-Description.types';

import './ErrorState-Description.css';

export const ErrorStateDescription: FC<IErrorStateDescriptionProps> = (props) => {
    const { description } = props;

    if (!description) {
        return null;
    }

    return (
        <div className={cnErrorState('Description')}>
            {description}
        </div>
    );
};
