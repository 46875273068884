import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'addToList';
    sizeManagement?: 'self' | 'parent';
}

export const withAddToListIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'addToList',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H13.5C14.0523 11 14.5 11.4477 14.5 12C14.5 12.5523 14.0523 13 13.5 13H4C3.44772 13 3 12.5523 3 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 18C3 17.4477 3.44772 17 4 17H10C10.5523 17 11 17.4477 11 18C11 18.5523 10.5523 19 10 19H4C3.44772 19 3 18.5523 3 18Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M17.5 13.5C18.0523 13.5 18.5 13.9477 18.5 14.5V17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H18.5V21.5C18.5 22.0523 18.0523 22.5 17.5 22.5C16.9477 22.5 16.5 22.0523 16.5 21.5V19H14C13.4477 19 13 18.5523 13 18C13 17.4477 13.4477 17 14 17H16.5V14.5C16.5 13.9477 16.9477 13.5 17.5 13.5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 8.00001C1.70001 7.61341 2.01341 7.30001 2.40001 7.30001H9.05001C9.43661 7.30001 9.75001 7.61341 9.75001 8.00001C9.75001 8.38661 9.43661 8.70001 9.05001 8.70001H2.40001C2.01341 8.70001 1.70001 8.38661 1.70001 8.00001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 12.2C1.70001 11.8134 2.01341 11.5 2.40001 11.5H6.60001C6.98661 11.5 7.30001 11.8134 7.30001 12.2C7.30001 12.5866 6.98661 12.9 6.60001 12.9H2.40001C2.01341 12.9 1.70001 12.5866 1.70001 12.2Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 3.80001C1.70001 3.41341 2.01341 3.10001 2.40001 3.10001H13.6C13.9866 3.10001 14.3 3.41341 14.3 3.80001C14.3 4.18661 13.9866 4.50001 13.6 4.50001H2.40001C2.01341 4.50001 1.70001 4.18661 1.70001 3.80001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11.85 9.05001C12.2366 9.05001 12.55 9.36341 12.55 9.75001V11.5H14.3C14.6866 11.5 15 11.8134 15 12.2C15 12.5866 14.6866 12.9 14.3 12.9H12.55V14.65C12.55 15.0366 12.2366 15.35 11.85 15.35C11.4634 15.35 11.15 15.0366 11.15 14.65V12.9H9.40001C9.01341 12.9 8.70001 12.5866 8.70001 12.2C8.70001 11.8134 9.01341 11.5 9.40001 11.5H11.15V9.75001C11.15 9.36341 11.4634 9.05001 11.85 9.05001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
