import React, { FC, useCallback, useEffect, useState } from 'react';

import { composeU } from '@bem-react/core';
import { Dropdown } from '@yandex-lego/components/Dropdown/desktop';
import { Icon as IconPresenter } from '@yandex-lego/components/Icon/Icon';
import { Direction } from '@yandex-lego/components/Popup';

import { withNotificationsIcon } from '../Icon/_glyph/Notifications';
import { Button } from '../PageHeader/PageHeader.components/Button/Button';
import { Platform } from '../utils';
import { cnNotifications } from './Notifications.cn';
import { INotificationsProps } from './Notifications.types';

import './Notifications.css';

const IFRAME_URL = 'https://gnc.yandex-team.ru/frame?utm_source_service=internal_staff';

export const NotificationsFrame: FC<{ url?: string, className?: string }> = (props) => {
    const { className, url = IFRAME_URL } = props;

    return <iframe src={url} frameBorder="0" className={cnNotifications('Frame', [className])} />;
};

const Icon = composeU(withNotificationsIcon)(IconPresenter);

const notificationsIcon = (className: string) => (
    <Icon className={className} size="l" sizeManagement="parent" glyph="notifications" />
);

const getDefaultPopupDirection: (platform: Platform) => Direction[] = (platform) => {
    if (platform === Platform.DESKTOP) {
        return ['bottom-end', 'top'];
    }

    return ['top', 'bottom-end'];
};

export const Notifications: FC<INotificationsProps> = (props) => {
    const {
        iFrameUrl,
        platform,
        getUnviewed,
        onClick,
        direction = getDefaultPopupDirection(platform),
    } = props;
    const [hasBadge, setHasBadge] = useState(false);

    useEffect(() => {
        getUnviewed()
            .then((unviewed) => setHasBadge(unviewed !== 0))
            .catch(() => setHasBadge(false));
    }, [getUnviewed]);

    const content = <NotificationsFrame url={iFrameUrl} />;

    const onVisibleChange = useCallback((visible) => {
        visible && setHasBadge(false);
        onClick && onClick(visible);
    }, [onClick]);

    return (
        <Dropdown
            view="default"
            className={cnNotifications('Popup', { platform })}
            trigger="click"
            direction={direction}
            content={content}
            onVisibleChange={onVisibleChange}
        >
            <Button
                className={cnNotifications('Button')}
                icon={notificationsIcon}
                hasBadge={hasBadge}
                platform={platform}
            />
        </Dropdown>
    );
};
