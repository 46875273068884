import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'documentSend';
    sizeManagement?: 'self' | 'parent';
}

export const withDocumentSendIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'documentSend',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 6C6 4.89543 6.89543 4 8 4H11V10C11 10.5523 11.4477 11 12 11H18V12.5C18 13.0523 18.4477 13.5 19 13.5C19.5523 13.5 20 13.0523 20 12.5V10C20 9.73478 19.8946 9.48043 19.7071 9.29289L12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2H8C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22H11.5C12.0523 22 12.5 21.5523 12.5 21C12.5 20.4477 12.0523 20 11.5 20H8C6.89543 20 6 19.1046 6 18V6ZM13 5.41421V9H16.5858L13 5.41421Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M14.7929 16.3433C15.1834 15.9528 15.8166 15.9528 16.2071 16.3433L18.682 18.8182C19.0725 19.2087 19.0725 19.8419 18.682 20.2324L16.2071 22.7073C15.8166 23.0978 15.1834 23.0978 14.7929 22.7073C14.4023 22.3168 14.4023 21.6836 14.7929 21.2931L16.5606 19.5253L14.7929 17.7575C14.4023 17.367 14.4023 16.7339 14.7929 16.3433Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M18.818 16.3182C19.2085 15.9277 19.8417 15.9277 20.2322 16.3182L22.7071 18.7931C23.0976 19.1836 23.0976 19.8168 22.7071 20.2073L20.2322 22.6822C19.8417 23.0727 19.2085 23.0727 18.818 22.6822C18.4275 22.2916 18.4275 21.6585 18.818 21.268L20.5858 19.5002L18.818 17.7324C18.4275 17.3419 18.4275 16.7087 18.818 16.3182Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.80002 3.8C3.80002 3.0268 4.42683 2.4 5.20002 2.4H7.30002V6.6C7.30002 6.9866 7.61342 7.3 8.00002 7.3H12.2V8.35C12.2 8.7366 12.5134 9.05 12.9 9.05C13.2866 9.05 13.6 8.7366 13.6 8.35V6.6C13.6 6.41435 13.5263 6.2363 13.395 6.10503L8.495 1.20503C8.36372 1.07375 8.18568 1 8.00002 1H5.20002C3.65363 1 2.40002 2.2536 2.40002 3.8V12.2C2.40002 13.7464 3.65363 15 5.20002 15H7.65002C8.03662 15 8.35002 14.6866 8.35002 14.3C8.35002 13.9134 8.03662 13.6 7.65002 13.6H5.20002C4.42683 13.6 3.80002 12.9732 3.80002 12.2V3.8ZM8.70002 3.38995V5.9H11.2101L8.70002 3.38995Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9.95504 11.0403C10.2284 10.767 10.6716 10.767 10.945 11.0403L12.6774 12.7727C12.9508 13.0461 12.9508 13.4893 12.6774 13.7627L10.945 15.4951C10.6716 15.7685 10.2284 15.7685 9.95504 15.4951C9.68167 15.2217 9.68167 14.7785 9.95504 14.5052L11.1925 13.2677L9.95504 12.0303C9.68167 11.7569 9.68167 11.3137 9.95504 11.0403Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12.7726 11.0227C13.046 10.7494 13.4892 10.7494 13.7626 11.0227L15.495 12.7552C15.7684 13.0285 15.7684 13.4717 15.495 13.7451L13.7626 15.4775C13.4892 15.7509 13.046 15.7509 12.7726 15.4775C12.4993 15.2041 12.4993 14.7609 12.7726 14.4876L14.0101 13.2501L12.7726 12.0127C12.4993 11.7393 12.4993 11.2961 12.7726 11.0227Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
