import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowLongUp';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowLongUpIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowLongUp',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.3 4.5L5.3 10.3002C5.3 10.6868 5.6134 11.0002 6 11.0002C6.3866 11.0002 6.7 10.6868 6.7 10.3002L6.7 4.5C6.7 4.02316 6.6188 3.46511 6.5117 2.93649L6.61191 2.83593C6.63981 2.86788 6.6654 2.90269 6.68826 2.94036C7.03583 3.51316 7.47692 4.16351 7.9194 4.60933L9.00503 5.69496C9.2784 5.96833 9.72161 5.96833 9.99498 5.69496C10.2668 5.42311 10.2683 4.98328 9.99949 4.70956L6.49495 1.20503C6.4829 1.19297 6.47051 1.18145 6.45783 1.17046C6.34839 1.07583 6.21236 1.01752 6.06866 1.00336C6.02585 0.999161 5.98272 0.998899 5.93987 1.00257C5.77625 1.01667 5.62206 1.08798 5.50503 1.20501L2.00503 4.70501C1.73166 4.97838 1.73166 5.42159 2.00503 5.69496C2.27773 5.96766 2.71944 5.96832 2.99297 5.69696L2.99498 5.69496L4.06983 4.6201C4.51589 4.17404 4.96129 3.51781 5.31166 2.94037C5.33453 2.90268 5.36013 2.86785 5.38805 2.83588L5.4883 2.93649C5.38121 3.46511 5.3 4.02316 5.3 4.5Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.70708 11.7071C5.31655 12.0977 4.68339 12.0977 4.29286 11.7071C3.90234 11.3166 3.90234 10.6834 4.29286 10.2929L11.2929 3.29292C11.6834 2.90239 12.3166 2.90239 12.7071 3.29292L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0977 18.6834 12.0977 18.2929 11.7071L14.7573 8.17159C14.1201 7.53436 13.4838 6.59687 12.9832 5.77196C12.9506 5.71817 12.914 5.66846 12.8742 5.62283L12.731 5.76652C12.884 6.52166 13 7.31883 13 8V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20L11 8C11 7.31881 11.116 6.52162 11.269 5.76646L11.1258 5.62273C11.0859 5.66839 11.0493 5.71814 11.0167 5.77198C10.5161 6.59689 9.87984 7.53437 9.24261 8.1716L5.70708 11.7071Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.59494 7.79499C3.32157 8.06836 2.87836 8.06836 2.60499 7.79499C2.33162 7.52162 2.33162 7.07841 2.60499 6.80504L7.50499 1.90504C7.77836 1.63167 8.22157 1.63167 8.49494 1.90504L13.3949 6.80504C13.6683 7.0784 13.6683 7.52162 13.3949 7.79499C13.1216 8.06836 12.6784 8.06836 12.405 7.79499L9.93011 5.32011C9.48405 4.87405 9.03862 4.21781 8.68824 3.64037C8.66538 3.6027 8.63979 3.56788 8.61189 3.53593L8.51169 3.63649C8.61878 4.16511 8.69999 4.72316 8.69999 5.2V13.6C8.69999 13.9866 8.38659 14.3 7.99999 14.3C7.61339 14.3 7.29999 13.9866 7.29999 13.6L7.29999 5.2C7.29999 4.72316 7.38119 4.16511 7.48829 3.63649L7.38804 3.53589C7.36012 3.56786 7.33451 3.60269 7.31164 3.64039C6.96128 4.21782 6.51587 4.87405 6.06981 5.32011L3.59494 7.79499Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
