import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'calendar';
    sizeManagement?: 'self' | 'parent';
}

export const withCalendarIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'calendar',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 2C7.55228 2 8 2.44772 8 3V3.50002H16V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V3.62604C19.7252 4.07008 21 5.63618 21 7.50002V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7.50003C3 5.63619 4.27477 4.07008 6 3.62604V3C6 2.44772 6.44772 2 7 2ZM5 11V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V11H5ZM19 9H5V7.50003C5 6.39546 5.89543 5.50002 7 5.50002H17C18.1046 5.50002 19 6.39545 19 7.50002V9Z" fill="currentColor" /><path d="M17 15.5C17 16.3284 16.3284 17 15.5 17C14.6716 17 14 16.3284 14 15.5C14 14.6716 14.6716 14 15.5 14C16.3284 14 17 14.6716 17 15.5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 1C4.88661 1 5.20001 1.3134 5.20001 1.7V2.05H10.8L10.8 1.7C10.8 1.3134 11.1134 1 11.5 1C11.8866 0.999999 12.2 1.3134 12.2 1.7L12.2 2.13821C13.4077 2.44904 14.3 3.54531 14.3 4.85V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.50001C2.95361 14.3 1.70001 13.0464 1.70001 11.5V4.85C1.70001 3.54531 2.59235 2.44904 3.80001 2.13821V1.7C3.80001 1.3134 4.11341 1 4.50001 1ZM3.10001 7.3V11.5C3.10001 12.2732 3.72681 12.9 4.50001 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5V7.3H3.10001ZM12.9 5.9H3.10001V4.85C3.10001 4.0768 3.72681 3.45 4.50001 3.45H11.5C12.2732 3.45 12.9 4.0768 12.9 4.85V5.9Z" fill="currentColor" /><path d="M11.5 10.45C11.5 11.0299 11.0299 11.5 10.45 11.5C9.87011 11.5 9.40001 11.0299 9.40001 10.45C9.40001 9.8701 9.87011 9.4 10.45 9.4C11.0299 9.4 11.5 9.8701 11.5 10.45Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
