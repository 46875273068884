import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'ruble';
    sizeManagement?: 'self' | 'parent';
}

export const withRubleIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'ruble',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 5C8 4.44772 8.44772 4 9 4H14C16.2091 4 18 5.79086 18 8V9C18 11.2091 16.2091 13 14 13H10V15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H10V19C10 19.5523 9.55228 20 9 20C8.44772 20 8 19.5523 8 19V17H7C6.44772 17 6 16.5523 6 16C6 15.4477 6.44772 15 7 15H8V13H7C6.44771 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H8V5ZM10 11L14 11C15.1046 11 16 10.1046 16 9V8C16 6.89543 15.1046 6 14 6H10V11Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.19999 3.10002C5.19999 2.71343 5.51339 2.40002 5.89999 2.40002H9.39999C10.9464 2.40002 12.2 3.65363 12.2 5.20002V5.90002C12.2 7.44642 10.9464 8.70002 9.39999 8.70002L6.59999 8.70003V10.1H9.39999C9.78659 10.1 10.1 10.4134 10.1 10.8C10.1 11.1866 9.78659 11.5 9.39999 11.5H6.59999V12.9C6.59999 13.2866 6.28659 13.6 5.89999 13.6C5.51339 13.6 5.19999 13.2866 5.19999 12.9V11.5H4.49999C4.11339 11.5 3.79999 11.1866 3.79999 10.8C3.79999 10.4134 4.11339 10.1 4.49999 10.1H5.19999V8.70003L4.49999 8.70002C4.11339 8.70002 3.79999 8.38662 3.79999 8.00002C3.79999 7.61343 4.11339 7.30002 4.49999 7.30002H5.19999V3.10002ZM6.59999 7.30002L9.39999 7.30002C10.1732 7.30002 10.8 6.67322 10.8 5.90002V5.20002C10.8 4.42683 10.1732 3.80002 9.39999 3.80002H6.59999V7.30002Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
