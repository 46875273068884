import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'numbersThree';
    sizeManagement?: 'self' | 'parent';
}

export const withNumbersThreeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'numbersThree',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 4C8.55228 4 9 4.44772 9 5V19C9 19.5523 8.55228 20 8 20C7.44772 20 7 19.5523 7 19V5C7 4.44772 7.44772 4 8 4Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M16 4C16.5523 4 17 4.44772 17 5V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V5C15 4.44772 15.4477 4 16 4Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.2 2.40002C5.5866 2.40002 5.9 2.71343 5.9 3.10002V12.9C5.9 13.2866 5.5866 13.6 5.2 13.6C4.8134 13.6 4.5 13.2866 4.5 12.9V3.10002C4.5 2.71343 4.8134 2.40002 5.2 2.40002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 2.40002C8.3866 2.40002 8.7 2.71343 8.7 3.10002V12.9C8.7 13.2866 8.3866 13.6 8 13.6C7.6134 13.6 7.3 13.2866 7.3 12.9V3.10002C7.3 2.71343 7.6134 2.40002 8 2.40002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.8 2.40002C11.1866 2.40002 11.5 2.71343 11.5 3.10002V12.9C11.5 13.2866 11.1866 13.6 10.8 13.6C10.4134 13.6 10.1 13.2866 10.1 12.9V3.10002C10.1 2.71343 10.4134 2.40002 10.8 2.40002Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
