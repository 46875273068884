import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'logOut';
    sizeManagement?: 'self' | 'parent';
}

export const withLogOutIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'logOut',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 12C8 12.5523 8.44772 13 9 13H16C16.6812 13 17.4784 12.884 18.2336 12.731L18.3772 12.8742C18.3316 12.914 18.2819 12.9506 18.2281 12.9832C17.4097 13.4798 16.4805 14.11 15.8436 14.7422L15.2929 15.2929C14.9024 15.6834 14.9024 16.3166 15.2929 16.7071C15.6813 17.0955 16.3097 17.0976 16.7007 16.7134L20.7071 12.7071C21.0465 12.3677 21.0909 11.845 20.8404 11.4576C20.8026 11.3992 20.7582 11.344 20.7071 11.2929L16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289C14.9033 7.68246 14.9024 8.31349 15.2901 8.70424L15.2929 8.70711L15.8284 9.24261C16.4657 9.87983 17.4031 10.5161 18.228 11.0166C18.2819 11.0493 18.3316 11.0859 18.3773 11.1258L18.2336 11.269C17.4784 11.116 16.6812 11 16 11H9C8.44772 11 8 11.4477 8 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11 4C11 3.44772 10.5523 3 10 3H7C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H10C10.5523 21 11 20.5523 11 20C11 19.4477 10.5523 19 10 19H7C5.89543 19 5 18.1046 5 17V7C5 5.89543 5.89543 5 7 5H10C10.5523 5 11 4.55228 11 4Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.19997 7.99997C5.19997 8.38657 5.51337 8.69997 5.89997 8.69997H10.8C11.2768 8.69997 11.8349 8.61877 12.3635 8.51167L12.4641 8.61191C12.4321 8.6398 12.3973 8.66538 12.3597 8.68823C11.7868 9.03581 11.1365 9.47692 10.6907 9.91941L10.3051 10.305C10.0317 10.5784 10.0317 11.0216 10.3051 11.2949C10.5769 11.5668 11.0168 11.5683 11.2905 11.2995L14.095 8.49492C14.3337 8.25627 14.364 7.88815 14.1859 7.61662C14.16 7.57714 14.1297 7.5397 14.095 7.505L11.295 4.705C11.0216 4.43163 10.5784 4.43163 10.3051 4.705C10.0324 4.97769 10.0317 5.4194 10.3031 5.69293L10.3051 5.69495L10.6799 6.0698C11.126 6.51586 11.7822 6.96126 12.3596 7.31163C12.3973 7.33449 12.4322 7.36009 12.4641 7.388L12.3635 7.48828C11.8349 7.38118 11.2768 7.29997 10.8 7.29997H5.89997C5.51337 7.29997 5.19997 7.61337 5.19997 7.99997Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7.30001 2.40001C7.30001 2.01341 6.98661 1.70001 6.60001 1.70001H4.50001C2.95362 1.70001 1.70001 2.95361 1.70001 4.50001V11.5C1.70001 13.0464 2.95362 14.3 4.50001 14.3H6.60001C6.98661 14.3 7.30001 13.9866 7.30001 13.6C7.30001 13.2134 6.98661 12.9 6.60001 12.9H4.50001C3.72681 12.9 3.10001 12.2732 3.10001 11.5V4.50001C3.10001 3.72681 3.72681 3.10001 4.50001 3.10001H6.60001C6.98661 3.10001 7.30001 2.78661 7.30001 2.40001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
