import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'copyWithArrow';
    sizeManagement?: 'self' | 'parent';
}

export const withCopyWithArrowIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'copyWithArrow',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M22 10C22 7.79086 20.2091 6 18 6H10C7.79086 6 6 7.79086 6 10V18C6 20.2091 7.79086 22 10 22C10.5523 22 11 21.5523 11 21C11 20.4477 10.5523 20 10 20C8.89543 20 8 19.1046 8 18V10C8 8.89543 8.89543 8 10 8H18C19.1046 8 20 8.89543 20 10C20 10.5523 20.4477 11 21 11C21.5523 11 22 10.5523 22 10Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 16C2.44771 16 2 15.5523 2 15V9C2 5.13401 5.13401 2 9 2H15C15.5523 2 16 2.44771 16 3C16 3.55229 15.5523 4 15 4H9C6.23858 4 4 6.23858 4 9V15C4 15.5523 3.55229 16 3 16Z" fill="currentColor" /><path d="M13.9288 13.9289C14.3194 13.5384 14.9525 13.5384 15.343 13.9289L18.8786 17.4644C19.3603 17.9461 19.8419 18.5919 20.2677 19.234L20.4706 19.2344C20.4665 19.1739 20.4572 19.1129 20.4422 19.0518C20.2147 18.122 20.0033 17.0193 19.9999 16.122L20 15.009C20 14.4567 20.4478 14.009 21 14.009C21.5493 14.009 21.9952 14.4519 22 15L21.9999 21C21.9999 21.5523 21.5522 22 20.9999 22H15.0041C14.4518 22 14.0041 21.5523 14.0041 21C14.0041 20.4491 14.4496 20.0022 15 20L15.0041 20L16.1004 20C17.0016 20 18.1144 20.213 19.0516 20.4424C19.1128 20.4573 19.1738 20.4666 19.2343 20.4707L19.234 20.2678C18.5918 19.842 17.946 19.3603 17.4644 18.8787L13.9288 15.3431C13.5383 14.9526 13.5383 14.3194 13.9288 13.9289Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15 6.6C15 5.0536 13.7464 3.8 12.2 3.8H6.6C5.0536 3.8 3.8 5.0536 3.8 6.6V12.2C3.8 13.7464 5.0536 15 6.6 15C6.9866 15 7.3 14.6866 7.3 14.3C7.3 13.9134 6.9866 13.6 6.6 13.6C5.8268 13.6 5.2 12.9732 5.2 12.2V6.6C5.2 5.8268 5.8268 5.2 6.6 5.2H12.2C12.9732 5.2 13.6 5.8268 13.6 6.6C13.6 6.9866 13.9134 7.3 14.3 7.3C14.6866 7.3 15 6.9866 15 6.6Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.7 10.8C1.3134 10.8 1 10.4866 1 10.1V5.9C1 3.19381 3.1938 1 5.9 1H10.1C10.4866 1 10.8 1.3134 10.8 1.7C10.8 2.0866 10.4866 2.4 10.1 2.4H5.9C3.967 2.4 2.4 3.967 2.4 5.9V10.1C2.4 10.4866 2.0866 10.8 1.7 10.8Z" fill="currentColor" /><path d="M9.35018 9.35021C9.62355 9.07685 10.0668 9.07685 10.3401 9.35021L12.815 11.8251C13.1522 12.1623 13.4894 12.6143 13.7874 13.0638L13.9294 13.0641C13.9265 13.0217 13.92 12.979 13.9095 12.9362C13.7503 12.2854 13.6023 11.5135 13.5999 10.8854L13.6 10.1063C13.6 9.7197 13.9134 9.40629 14.3 9.40629C14.6845 9.40629 14.9966 9.7163 15 10.1L14.9999 14.3C14.9999 14.6866 14.6865 15 14.2999 15H10.1028C9.71624 15 9.40283 14.6866 9.40283 14.3C9.40283 13.9143 9.7147 13.6015 10.1 13.6L10.1028 13.6L10.8703 13.6C11.5011 13.6 12.2801 13.7491 12.9361 13.9096C12.9789 13.9201 13.0217 13.9266 13.064 13.9295L13.0638 13.7875C12.6143 13.4894 12.1622 13.1522 11.8251 12.815L9.35018 10.3402C9.07682 10.0668 9.07682 9.62358 9.35018 9.35021Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
