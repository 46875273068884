import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'error';
    sizeManagement?: 'self' | 'parent';
}

export const withErrorIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'error',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.545 7.58132C10.474 6.73007 11.1458 6 12 6C12.8542 6 13.526 6.73007 13.455 7.58132L12.9965 13.083C12.9534 13.6013 12.5201 14 12 14C11.4799 14 11.0466 13.6013 11.0035 13.083L10.545 7.58132Z" fill="currentColor" /><path d="M13.5 16.5C13.5 17.3284 12.8285 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6715 11.1716 15 12 15C12.8285 15 13.5 15.6715 13.5 16.5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 2.4C4.90721 2.4 2.4 4.90721 2.4 8C2.4 11.0928 4.90721 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 8 2.4ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6.98148 4.90692C6.93183 4.31105 7.40206 3.8 8 3.8C8.59794 3.8 9.06817 4.31105 9.01852 4.90692L8.69758 8.75813C8.66735 9.12094 8.36406 9.4 8 9.4C7.63594 9.4 7.33265 9.12094 7.30242 8.75813L6.98148 4.90692Z" fill="currentColor" /><path d="M9.05 11.15C9.05 11.7299 8.5799 12.2 8 12.2C7.4201 12.2 6.95 11.7299 6.95 11.15C6.95 10.5701 7.4201 10.1 8 10.1C8.5799 10.1 9.05 10.5701 9.05 11.15Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
