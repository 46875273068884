import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'travelHotel';
    sizeManagement?: 'self' | 'parent';
}

export const withTravelHotelIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'travelHotel',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.58786 13.475L2.66667 11H13.3333L14.4121 13.475C14.5112 13.6794 14.5593 13.7466 14.6242 13.812C14.6892 13.8774 14.7629 13.9244 14.8487 13.9551C14.9345 13.9857 15.0142 14 15.2358 14H16V8.3125C16 7.12232 15.1656 6.10659 14.0272 5.91093L10.7113 5.34101C9.18015 5.07784 7.70982 6.07198 7.33333 7.625L7 9H4H3.95089H3.90178C3.56717 9 3.3511 8.95673 3.15608 8.86995C2.96107 8.78317 2.79924 8.65288 2.67031 8.47882C2.54137 8.30476 2.44826 8.09908 2.36711 7.76432L1.50114 3.69219C1.43893 3.43559 1.39598 3.34762 1.33151 3.26059C1.26705 3.17356 1.18613 3.10841 1.08862 3.06502C0.991115 3.02163 0.897946 3 0.641461 3H0V14H0.764196C0.985794 14 1.06551 13.9857 1.15129 13.9551C1.23707 13.9244 1.31082 13.8774 1.37576 13.812C1.44071 13.7466 1.48876 13.6794 1.58786 13.475ZM6.66667 5.995C6.66667 6.91455 5.92047 7.66 5 7.66C4.07952 7.66 3.33333 6.91455 3.33333 5.995C3.33333 5.07545 4.07952 4.33 5 4.33C5.92047 4.33 6.66667 5.07545 6.66667 5.995Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
