import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'aa';
    sizeManagement?: 'self' | 'parent';
}

export const withAaIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'aa',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 8C2 5.79086 3.79086 4 6 4H7C9.20914 4 11 5.79086 11 8V19C11 19.5523 10.5523 20 10 20C9.44772 20 9 19.5523 9 19V14H4V19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19V8ZM4 12H9V8C9 6.89543 8.10457 6 7 6H6C4.89543 6 4 6.89543 4 8V12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13 8C13 5.79086 14.7909 4 17 4H18C20.2091 4 22 5.79086 22 8V19C22 19.5523 21.5523 20 21 20C20.4477 20 20 19.5523 20 19V14H15V19C15 19.5523 14.5523 20 14 20C13.4477 20 13 19.5523 13 19V8ZM15 12H20V8C20 6.89543 19.1046 6 18 6H17C15.8954 6 15 6.89543 15 8V12Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 5.20003C1 3.65363 2.2536 2.40002 3.8 2.40002H4.5C6.0464 2.40002 7.3 3.65363 7.3 5.20002V12.9C7.3 13.2866 6.9866 13.6 6.6 13.6C6.2134 13.6 5.9 13.2866 5.9 12.9V9.40002H2.4V12.9C2.4 13.2866 2.0866 13.6 1.7 13.6C1.3134 13.6 1 13.2866 1 12.9V5.20003ZM2.4 8.00002H5.9V5.20002C5.9 4.42683 5.2732 3.80002 4.5 3.80002H3.8C3.0268 3.80002 2.4 4.42683 2.4 5.20003V8.00002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.7 5.20003C8.7 3.65363 9.9536 2.40002 11.5 2.40002H12.2C13.7464 2.40002 15 3.65363 15 5.20002V12.9C15 13.2866 14.6866 13.6 14.3 13.6C13.9134 13.6 13.6 13.2866 13.6 12.9V9.40002H10.1V12.9C10.1 13.2866 9.7866 13.6 9.4 13.6C9.0134 13.6 8.7 13.2866 8.7 12.9V5.20003ZM10.1 8.00002H13.6V5.20002C13.6 4.42683 12.9732 3.80002 12.2 3.80002H11.5C10.7268 3.80002 10.1 4.42683 10.1 5.20003V8.00002Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
