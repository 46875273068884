import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'reviewExcellent';
    sizeManagement?: 'self' | 'parent';
}

export const withReviewExcellentIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'reviewExcellent',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 11C8.55228 11 9 11.4477 9 12V15H12C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17H9V20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20V17H4C3.44772 17 3 16.5523 3 16C3 15.4477 3.44772 15 4 15H7V12C7 11.4477 7.44772 11 8 11Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M16 3C16.5523 3 17 3.44772 17 4V7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H17V12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12V9H12C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7H15V4C15 3.44772 15.4477 3 16 3Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.20001 7.30001C5.58661 7.30001 5.90001 7.61341 5.90001 8.00001V10.1H8.00001C8.38661 10.1 8.70001 10.4134 8.70001 10.8C8.70001 11.1866 8.38661 11.5 8.00001 11.5H5.90001V13.6C5.90001 13.9866 5.58661 14.3 5.20001 14.3C4.81341 14.3 4.50001 13.9866 4.50001 13.6V11.5H2.40001C2.01341 11.5 1.70001 11.1866 1.70001 10.8C1.70001 10.4134 2.01341 10.1 2.40001 10.1H4.50001V8.00001C4.50001 7.61341 4.81341 7.30001 5.20001 7.30001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.8 1.70001C11.1866 1.70001 11.5 2.01341 11.5 2.40001V4.50001H13.6C13.9866 4.50001 14.3 4.81341 14.3 5.20001C14.3 5.58661 13.9866 5.90001 13.6 5.90001H11.5V8.00001C11.5 8.38661 11.1866 8.70001 10.8 8.70001C10.4134 8.70001 10.1 8.38661 10.1 8.00001V5.90001H8.00001C7.61341 5.90001 7.30001 5.58661 7.30001 5.20001C7.30001 4.81341 7.61341 4.50001 8.00001 4.50001H10.1V2.40001C10.1 2.01341 10.4134 1.70001 10.8 1.70001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
