import { withBemMod } from '@bem-react/core';
import { cnMenu } from '@yandex-lego/components/Menu';

import './Menu_size_l.css';

export type WithSizeLProps = {
    size?: 'l';
};

export const withSizeL = withBemMod<WithSizeLProps>(cnMenu(), {
    size: 'l',
});
