import React, {
    ComponentProps,
    CSSProperties,
    FC,
    RefObject,
    useCallback,
    useRef,
    useState,
} from 'react';

import { IconProvider } from '@yandex-lego/components/Button/Icon/Button-Icon';
import { Popup } from '@yandex-lego/components/Popup/desktop/bundle';
import { applyMaxHeight } from '@yandex-lego/components/usePopper';

import { Platform } from '../../../utils';
import { cnPageHeader } from '../../PageHeader.cn';
import { Button } from '../Button/Button';

import './Dropdown.css';

const popupModifiers = [applyMaxHeight];

export interface DropdownProps {
    icon: IconProvider;
    title?: string;
    buttonClassName?: string;
    popupClassName?: string;
    direction: ComponentProps<typeof Popup>['direction'];
    mainOffset?: ComponentProps<typeof Popup>['mainOffset'];
}

const POPUP_OFFSET = -1;
const POPUP_STYLE: CSSProperties = { marginTop: 1 };

interface DropdownPopupProps {
    className?: string;
    anchor: RefObject<HTMLElement>;
    onClose?: ComponentProps<typeof Popup>['onClose'];
    visible?: boolean;
    direction: ComponentProps<typeof Popup>['direction'];
    mainOffset?: ComponentProps<typeof Popup>['mainOffset'];
}

export const DropdownPopup: FC<DropdownPopupProps> = (props) => {
    const {
        className,
        anchor,
        visible,
        onClose,
        children,
        direction,
        mainOffset,
    } = props;

    return (
        <Popup
            mainOffset={mainOffset ?? POPUP_OFFSET}
            style={POPUP_STYLE}
            className={cnPageHeader('DropdownPopup', [className])}
            view="default"
            target="anchor"
            anchor={anchor}
            visible={visible}
            onClose={onClose}
            scope="inplace" // https://st.yandex-team.ru/LEGOSUPPORT-410
            direction={direction}
            modifiers={popupModifiers}
        >
            <div className={cnPageHeader('DropdownPopupContent')}>
                {children}
            </div>
        </Popup>
    );
};

export const Dropdown: FC<DropdownProps & { platform: Platform }> = (props) => {
    const {
        icon,
        title,
        buttonClassName,
        popupClassName,
        children,
        platform,
        direction,
        mainOffset,
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);
    const onClick = useCallback(() => {
        setVisible(!visible);
    }, [visible]);
    const onClose = useCallback(() => {
        setVisible(false);
    }, []);

    return (
        <>
            <Button
                className={buttonClassName}
                innerRef={ref}
                icon={icon}
                title={title}
                onClick={onClick}
                platform={platform}
            />
            <DropdownPopup
                className={popupClassName}
                anchor={ref}
                visible={visible}
                onClose={onClose}
                direction={direction}
                mainOffset={mainOffset}
            >
                {children}
            </DropdownPopup>
        </>
    );
};
