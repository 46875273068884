import { withBemMod } from '@bem-react/core';
import { cnTextinput } from '@yandex-lego/components/Textinput';

import './Textinput_size_l.css';

export type TextinputSizeLProps = {
    size?: 'l';
};

export const withSizeL = withBemMod<TextinputSizeLProps>(cnTextinput(), { size: 'l' });
