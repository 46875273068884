import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'documentAdd';
    sizeManagement?: 'self' | 'parent';
}

export const withDocumentAddIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'documentAdd',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 6C6 4.89543 6.89543 4 8 4H11V10C11 10.5523 11.4477 11 12 11H18V12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12V10C20 9.73478 19.8946 9.48043 19.7071 9.29289L12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2H8C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22H12.5C13.0523 22 13.5 21.5523 13.5 21C13.5 20.4477 13.0523 20 12.5 20H8C6.89543 20 6 19.1046 6 18V6ZM13 5.41421V9H16.5858L13 5.41421Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M19 15C19.5523 15 20 15.4477 20 16V18H22C22.5523 18 23 18.4477 23 19C23 19.5523 22.5523 20 22 20H20V22C20 22.5523 19.5523 23 19 23C18.4477 23 18 22.5523 18 22V20H16C15.4477 20 15 19.5523 15 19C15 18.4477 15.4477 18 16 18H18V16C18 15.4477 18.4477 15 19 15Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.80002 3.8C3.80002 3.0268 4.42683 2.4 5.20002 2.4H7.30002V6.6C7.30002 6.9866 7.61342 7.3 8.00002 7.3H12.2V8C12.2 8.3866 12.5134 8.7 12.9 8.7C13.2866 8.7 13.6 8.3866 13.6 8V6.6C13.6 6.41435 13.5263 6.2363 13.395 6.10503L8.495 1.20503C8.36372 1.07375 8.18568 1 8.00002 1H5.20002C3.65363 1 2.40002 2.2536 2.40002 3.8V12.2C2.40002 13.7464 3.65363 15 5.20002 15H8.35002C8.73662 15 9.05002 14.6866 9.05002 14.3C9.05002 13.9134 8.73662 13.6 8.35002 13.6H5.20002C4.42683 13.6 3.80002 12.9732 3.80002 12.2V3.8ZM8.70002 3.38995V5.9H11.2101L8.70002 3.38995Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12.9 10.1C13.2866 10.1 13.6 10.4134 13.6 10.8V12.2H15C15.3866 12.2 15.7 12.5134 15.7 12.9C15.7 13.2866 15.3866 13.6 15 13.6H13.6V15C13.6 15.3866 13.2866 15.7 12.9 15.7C12.5134 15.7 12.2 15.3866 12.2 15V13.6H10.8C10.4134 13.6 10.1 13.2866 10.1 12.9C10.1 12.5134 10.4134 12.2 10.8 12.2H12.2V10.8C12.2 10.4134 12.5134 10.1 12.9 10.1Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
