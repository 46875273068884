import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'geolocationCircle';
    sizeManagement?: 'self' | 'parent';
}

export const withGeolocationCircleIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'geolocationCircle',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="currentColor" /><path d="M14.9681 8.34392C15.4372 8.18757 15.8834 8.63382 15.7271 9.10287L13.2351 16.5788C13.0224 17.217 12.1197 17.217 11.9069 16.5788L10.8981 13.5523C10.8384 13.3731 10.6978 13.2325 10.5186 13.1728L7.49226 12.164C6.85406 11.9513 6.85405 11.0486 7.49225 10.8359L14.9681 8.34392Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 2.4C4.90721 2.4 2.4 4.90721 2.4 8C2.4 11.0928 4.90721 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 8 2.4ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z" fill="currentColor" /><path d="M10.0776 5.44076C10.406 5.33131 10.7184 5.64368 10.6089 5.97202L8.86452 11.2052C8.71561 11.6519 8.08372 11.6519 7.93481 11.2052L7.22864 9.08659C7.18684 8.96117 7.08842 8.86276 6.963 8.82095L4.84453 8.11484C4.3978 7.96594 4.39779 7.33404 4.84452 7.18513L10.0776 5.44076Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
