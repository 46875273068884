import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'play';
    sizeManagement?: 'self' | 'parent';
}

export const withPlayIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'play',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.5 9.40193C21.5 10.5566 21.5 13.4434 19.5 14.5981L10.5 19.7942C8.5 20.9489 6 19.5056 6 17.1962L6 6.80385C6 4.49446 8.5 3.05107 10.5 4.20578L19.5 9.40193ZM18.5 12.866C19.1667 12.4811 19.1667 11.5189 18.5 11.134L9.5 5.93783C8.83334 5.55293 8 6.03405 8 6.80385L8 17.1962C8 17.966 8.83333 18.4471 9.5 18.0622L18.5 12.866Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.25 6.18137C14.65 6.98966 14.65 9.01039 13.25 9.81868L6.94999 13.456C5.54998 14.2643 3.79999 13.2539 3.79999 11.6373L3.79999 4.36271C3.79999 2.74613 5.54999 1.73577 6.94999 2.54406L13.25 6.18137ZM12.55 8.60624C13.0167 8.33681 13.0167 7.66323 12.55 7.3938L6.24999 3.7565C5.78332 3.48707 5.19999 3.82385 5.19999 4.36271L5.19999 11.6373C5.19999 12.1762 5.78332 12.513 6.24999 12.2435L12.55 8.60624Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
