import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'numbersSix';
    sizeManagement?: 'self' | 'parent';
}

export const withNumbersSixIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'numbersSix',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17 4.00002C17.5523 4.00002 18 4.44774 18 5.00002V19C18 19.5523 17.5523 20 17 20C16.4477 20 16 19.5523 16 19V5.00002C16 4.44774 16.4477 4.00002 17 4.00002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6.79046 4.02222C7.33049 3.9065 7.86208 4.25047 7.9778 4.79049L9.99999 14.2274L12.0222 4.79049C12.1379 4.25047 12.6695 3.9065 13.2095 4.02222C13.7495 4.13794 14.0935 4.66953 13.9778 5.20955L10.9778 19.2096C10.879 19.6706 10.4715 20 9.99999 20C9.52846 20 9.12099 19.6706 9.02219 19.2096L6.02219 5.20955C5.90647 4.66953 6.25044 4.13794 6.79046 4.02222Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.5 2.39999C11.8866 2.39999 12.2 2.71339 12.2 3.09999V12.9C12.2 13.2866 11.8866 13.6 11.5 13.6C11.1134 13.6 10.8 13.2866 10.8 12.9V3.09999C10.8 2.71339 11.1134 2.39999 11.5 2.39999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.35334 2.41552C4.73136 2.33452 5.10347 2.5753 5.18447 2.95332L6.60001 9.55916L8.01555 2.95332C8.09655 2.5753 8.46866 2.33452 8.84668 2.41552C9.2247 2.49653 9.46548 2.86864 9.38447 3.24666L7.28447 13.0467C7.21531 13.3694 6.93009 13.6 6.60001 13.6C6.26993 13.6 5.98471 13.3694 5.91555 13.0467L3.81555 3.24666C3.73455 2.86864 3.97532 2.49653 4.35334 2.41552Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
