import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingAlignCenter';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingAlignCenterIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingAlignCenter',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8C7.44772 13 7 12.5523 7 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5 18C5 17.4477 5.44772 17 6 17H18C18.5523 17 19 17.4477 19 18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 8.00001C4.50001 7.61341 4.81341 7.30001 5.20001 7.30001H10.8C11.1866 7.30001 11.5 7.61341 11.5 8.00001C11.5 8.38661 11.1866 8.70001 10.8 8.70001H5.20001C4.81341 8.70001 4.50001 8.38661 4.50001 8.00001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3.10001 12.2C3.10001 11.8134 3.41341 11.5 3.80001 11.5H12.2C12.5866 11.5 12.9 11.8134 12.9 12.2C12.9 12.5866 12.5866 12.9 12.2 12.9H3.80001C3.41341 12.9 3.10001 12.5866 3.10001 12.2Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 3.80001C1.70001 3.41341 2.01341 3.10001 2.40001 3.10001H13.6C13.9866 3.10001 14.3 3.41341 14.3 3.80001C14.3 4.18661 13.9866 4.50001 13.6 4.50001H2.40001C2.01341 4.50001 1.70001 4.18661 1.70001 3.80001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
