import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'playOff';
    sizeManagement?: 'self' | 'parent';
}

export const withPlayOffIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'playOff',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9893 13.1609L18.5 12.866C19.1667 12.4811 19.1667 11.5189 18.5 11.134L12.496 7.66755L8.64928 3.82086C9.25732 3.74718 9.90108 3.85998 10.5 4.20577L19.5 9.40192C21.5 10.5566 21.5 13.4434 19.5 14.5981L19.4534 14.625L17.9893 13.1609Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6 17.1962C6 19.5056 8.5 20.9489 10.5 19.7942L15.4957 16.9099L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L6 7.41421V17.1962ZM8 17.1962C8 17.966 8.83333 18.4471 9.5 18.0622L14.0316 15.4458L8 9.41421V17.1962Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.1925 8.81257L12.55 8.60618C13.0167 8.33675 13.0167 7.66317 12.55 7.39374L8.34718 4.96725L5.6545 2.27456C6.08012 2.22299 6.53076 2.30195 6.95 2.544L13.25 6.18131C14.65 6.9896 14.65 9.01032 13.25 9.81861L13.2174 9.83745L12.1925 8.81257Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6.95 13.4559C5.55 14.2642 3.8 13.2538 3.8 11.6373L3.8 4.78995L1.20503 2.19497C0.931658 1.92161 0.931658 1.47839 1.20503 1.20503C1.47839 0.931658 1.92161 0.931658 2.19497 1.20503L14.795 13.805C15.0683 14.0784 15.0683 14.5216 14.795 14.795C14.5216 15.0683 14.0784 15.0683 13.805 14.795L10.447 11.4369L6.95 13.4559ZM6.25 12.2435C5.78333 12.5129 5.2 12.1761 5.2 11.6373V6.18995L9.42212 10.4121L6.25 12.2435Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
