import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'home';
    sizeManagement?: 'self' | 'parent';
}

export const withHomeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'home',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.5858 4.8284C11.3668 4.04735 12.6332 4.04735 13.4142 4.8284L18.4142 9.8284C18.7893 10.2035 19 10.7122 19 11.2426V17C19 18.1045 18.1046 19 17 19H16V15C16 12.7908 14.2091 11 12 11C9.79086 11 8 12.7908 8 15V19H7C5.89543 19 5 18.1045 5 17V11.2426C5 10.7122 5.21071 10.2035 5.58579 9.8284L10.5858 4.8284ZM10 19V15C10 13.8954 10.8954 13 12 13C13.1046 13 14 13.8954 14 15V19H10ZM14.8284 3.41419C13.2663 1.85209 10.7337 1.85209 9.17157 3.41419L4.17157 8.41419C3.42143 9.16434 3 10.1818 3 11.2426V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V11.2426C21 10.1818 20.5786 9.16433 19.8284 8.41419L14.8284 3.41419Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.01004 2.97991C7.55677 2.43318 8.4432 2.43318 8.98994 2.97991L12.4899 6.47991C12.7525 6.74246 12.9 7.09856 12.9 7.46986V11.5C12.9 12.2732 12.2732 12.9 11.5 12.9H10.8V10.1C10.8 8.55362 9.54638 7.30001 7.99999 7.30001C6.45359 7.30001 5.19999 8.55361 5.19999 10.1V12.9H4.49999C3.72679 12.9 3.09999 12.2732 3.09999 11.5V7.46986C3.09999 7.09856 3.24749 6.74246 3.51004 6.47991L7.01004 2.97991ZM6.59999 12.9V10.1C6.59999 9.32681 7.22679 8.70001 7.99999 8.70001C8.77319 8.70001 9.39999 9.32681 9.39999 10.1V12.9H6.59999ZM9.97989 1.98996C8.88642 0.896493 7.11356 0.896495 6.02009 1.98996L2.52009 5.48996C1.99499 6.01507 1.69999 6.72726 1.69999 7.46986V11.5C1.69999 13.0464 2.95359 14.3 4.49999 14.3H11.5C13.0464 14.3 14.3 13.0464 14.3 11.5V7.46986C14.3 6.72726 14.005 6.01506 13.4799 5.48996L9.97989 1.98996Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
