import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'videoOff';
    sizeManagement?: 'self' | 'parent';
}

export const withVideoOffIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'videoOff',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 9L19.2 9.6C17.8815 10.5889 16 9.64809 16 8C16 6.89543 15.1046 6 14 6H10.1213L8.12132 4H14C16.2091 4 18 5.79086 18 8L19.6 6.8C20.5889 6.05836 22 6.76393 22 8V16C22 16.5355 21.7352 16.9714 21.3546 17.2332L20 15.8787V9Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M2 8V16C2 18.2091 3.79086 20 6 20H14C15.2538 20 16.3728 19.4232 17.1062 18.5204L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L3.47958 4.89379C2.57683 5.6272 2 6.74624 2 8ZM6 18H14C14.7021 18 15.3198 17.6382 15.6766 17.0908L4.90917 6.32339C4.36182 6.68023 4 7.29786 4 8V16C4 17.1046 4.89543 18 6 18Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.6 5.9L13.04 6.32C12.1171 7.0122 10.8 6.35366 10.8 5.2C10.8 4.4268 10.1732 3.8 9.4 3.8H6.68492L5.28492 2.4H9.4C10.9464 2.4 12.2 3.6536 12.2 5.2L13.32 4.36C14.0122 3.84085 15 4.33475 15 5.2V10.8C15 11.1748 14.8146 11.48 14.5482 11.6633L13.6 10.7151V5.9Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1 10.8V5.2C1 4.32236 1.40378 3.53904 2.03571 3.02566L1.20503 2.19497C0.931658 1.92161 0.931658 1.47839 1.20503 1.20503C1.47839 0.931658 1.92161 0.931658 2.19497 1.20503L14.795 13.805C15.0683 14.0784 15.0683 14.5216 14.795 14.795C14.5216 15.0683 14.0784 15.0683 13.805 14.795L11.5743 12.5643C11.061 13.1962 10.2776 13.6 9.4 13.6H3.8C2.2536 13.6 1 12.3464 1 10.8ZM9.4 12.2H3.8C3.0268 12.2 2.4 11.5732 2.4 10.8V5.2C2.4 4.7085 2.65327 4.27616 3.03642 4.02637L10.5736 11.5636C10.3238 11.9467 9.89149 12.2 9.4 12.2Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
