import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingCrossedText';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingCrossedTextIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingCrossedText',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 8.5C9 6.84315 10.3431 5.5 12 5.5C13.1092 5.5 14.079 6.10141 14.5993 7.00073C14.8758 7.47879 15.4875 7.64215 15.9656 7.36561C16.4437 7.08906 16.607 6.47733 16.3305 5.99927C15.4675 4.50738 13.852 3.5 12 3.5C9.23858 3.5 7 5.73858 7 8.5C7 8.84243 7.03449 9.17686 7.10014 9.5H9.17047C9.05999 9.18724 9 8.85072 9 8.5Z" fill="currentColor" /><path d="M14.8295 14.5H16.8999C16.9655 14.8231 17 15.1576 17 15.5C17 18.2614 14.7614 20.5 12 20.5C10.148 20.5 8.53254 19.4926 7.66952 18.0007C7.39298 17.5227 7.55634 16.9109 8.0344 16.6344C8.51246 16.3579 9.12419 16.5212 9.40073 16.9993C9.92096 17.8986 10.8908 18.5 12 18.5C13.6569 18.5 15 17.1569 15 15.5C15 15.1493 14.94 14.8128 14.8295 14.5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.90001 5.55C5.90001 4.39021 6.84021 3.45 8.00001 3.45C8.77643 3.45 9.45534 3.87099 9.8195 4.50051C10.0131 4.83515 10.4413 4.94951 10.7759 4.75593C11.1106 4.56235 11.2249 4.13414 11.0313 3.79949C10.4272 2.75517 9.29639 2.05 8.00001 2.05C6.06702 2.05 4.50001 3.61701 4.50001 5.55C4.50001 5.7897 4.52415 6.02381 4.57011 6.25H6.01934C5.94201 6.03107 5.90001 5.79551 5.90001 5.55Z" fill="currentColor" /><path d="M9.98068 9.75H11.4299C11.4759 9.9762 11.5 10.2103 11.5 10.45C11.5 12.383 9.93301 13.95 8.00001 13.95C6.70364 13.95 5.57279 13.2448 4.96868 12.2005C4.7751 11.8659 4.88945 11.4377 5.22409 11.2441C5.55874 11.0505 5.98695 11.1649 6.18053 11.4995C6.54468 12.129 7.2236 12.55 8.00001 12.55C9.15981 12.55 10.1 11.6098 10.1 10.45C10.1 10.2045 10.058 9.96893 9.98068 9.75Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 8C1.70001 7.6134 2.01341 7.3 2.40001 7.3H13.6C13.9866 7.3 14.3 7.6134 14.3 8C14.3 8.3866 13.9866 8.7 13.6 8.7H2.40001C2.01341 8.7 1.70001 8.3866 1.70001 8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
