import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'copyAlternative';
    sizeManagement?: 'self' | 'parent';
}

export const withCopyAlternativeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'copyAlternative',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 6C2 3.79086 3.79086 2 6 2H14C16.2091 2 18 3.79086 18 6V7C20.2091 7 22 8.79086 22 11V18C22 20.2091 20.2091 22 18 22H11C8.79086 22 7 20.2091 7 18H6C3.79086 18 2 16.2091 2 14V6ZM9 18C9 19.1046 9.89543 20 11 20H18C19.1046 20 20 19.1046 20 18V11C20 9.89543 19.1046 9 18 9V14C18 16.2091 16.2091 18 14 18H9ZM6 4C4.89543 4 4 4.89543 4 6V14C4 15.1046 4.89543 16 6 16H14C15.1046 16 16 15.1046 16 14V6C16 4.89543 15.1046 4 14 4H6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 3.8C1 2.25361 2.2536 1 3.8 1H9.4C10.9464 1 12.2 2.2536 12.2 3.8L12.2 4.5C13.7464 4.5 15 5.7536 15 7.3V12.2C15 13.7464 13.7464 15 12.2 15H7.3C5.7536 15 4.5 13.7464 4.5 12.2H3.8C2.25361 12.2 1 10.9464 1 9.4V3.8ZM5.9 12.2C5.9 12.9732 6.5268 13.6 7.3 13.6H12.2C12.9732 13.6 13.6 12.9732 13.6 12.2V7.3C13.6 6.5268 12.9732 5.9 12.2 5.9L12.2 9.4C12.2 10.9464 10.9464 12.2 9.4 12.2H5.9ZM3.8 2.4C3.0268 2.4 2.4 3.0268 2.4 3.8V9.4C2.4 10.1732 3.0268 10.8 3.8 10.8H9.4C10.1732 10.8 10.8 10.1732 10.8 9.4V3.8C10.8 3.0268 10.1732 2.4 9.4 2.4H3.8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
