import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowShortDown';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowShortDownIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowShortDown',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.27935 3.46967C1.98646 3.17678 1.51159 3.17678 1.21869 3.46967C0.9258 3.76256 0.9258 4.23744 1.21869 4.53033L5.46799 8.77963C5.58185 8.89349 5.72321 8.96309 5.87069 8.98842C6.10263 9.02832 6.34973 8.95873 6.5288 8.77966L10.7781 4.53036C11.071 4.23746 11.071 3.76259 10.7781 3.4697C10.4852 3.1768 10.0103 3.1768 9.71744 3.4697L5.99841 7.18873L2.27935 3.46967Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.7071 8.29289C19.3166 7.90237 18.6834 7.90237 18.2929 8.29289L12 14.5858L5.70711 8.29289C5.31658 7.90237 4.68342 7.90237 4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711L11.2929 16.7071C11.6834 17.0976 12.3166 17.0976 12.7071 16.7071L19.7071 9.70711C20.0976 9.31658 20.0976 8.68342 19.7071 8.29289Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.395 5.40503C13.1216 5.13166 12.6784 5.13166 12.405 5.40503L7.99998 9.81005L3.59495 5.40503C3.32158 5.13166 2.87837 5.13166 2.605 5.40503C2.33163 5.67839 2.33163 6.12161 2.605 6.39498L7.505 11.295C7.77837 11.5683 8.22158 11.5683 8.49495 11.295L13.395 6.39498C13.6683 6.12161 13.6683 5.67839 13.395 5.40503Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
