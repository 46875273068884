import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'hr';
    sizeManagement?: 'self' | 'parent';
}

export const withHrIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'hr',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 4C3.55228 4 4 4.44772 4 5V11H9V5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5V19C11 19.5523 10.5523 20 10 20C9.44772 20 9 19.5523 9 19V13H4V19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19V5C2 4.44772 2.44772 4 3 4Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13 4H18C20.2091 4 22 5.79086 22 8V9C22 10.1947 21.4762 11.2671 20.6458 12C21.4762 12.7329 22 13.8053 22 15V19C22 19.5523 21.5523 20 21 20C20.4477 20 20 19.5523 20 19V15C20 13.8954 19.1046 13 18 13H15V19C15 19.5523 14.5523 20 14 20C13.4477 20 13 19.5523 13 19V4ZM15 11L18 11C19.1046 11 20 10.1046 20 9V8C20 6.89543 19.1046 6 18 6H15V11Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.7 2.39999C2.0866 2.39999 2.4 2.71339 2.4 3.09999V7.29999H5.9V3.09999C5.9 2.71339 6.2134 2.39999 6.6 2.39999C6.9866 2.39999 7.3 2.71339 7.3 3.09999V12.9C7.3 13.2866 6.9866 13.6 6.6 13.6C6.2134 13.6 5.9 13.2866 5.9 12.9V8.69999H2.4V12.9C2.4 13.2866 2.0866 13.6 1.7 13.6C1.3134 13.6 1 13.2866 1 12.9V3.09999C1 2.71339 1.3134 2.39999 1.7 2.39999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.70002 2.39996L12.2 2.39999C13.7464 2.4 15 3.6536 15 5.19999V5.89999C15 6.73628 14.6334 7.48693 14.0521 7.99999C14.6334 8.51306 15 9.26371 15 10.1V12.9C15 13.2866 14.6866 13.6 14.3 13.6C13.9134 13.6 13.6 13.2866 13.6 12.9V10.1C13.6 9.32679 12.9732 8.69999 12.2 8.69999L10.1 8.69998V12.9C10.1 13.2866 9.78662 13.6 9.40002 13.6C9.01342 13.6 8.70002 13.2866 8.70002 12.9V2.39996ZM10.1 7.29998L12.2 7.29999C12.9732 7.29999 13.6 6.67319 13.6 5.89999V5.19999C13.6 4.4268 12.9732 3.79999 12.2 3.79999L10.1 3.79998V7.29998Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
