import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'edit';
    sizeManagement?: 'self' | 'parent';
}

export const withEditIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'edit',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M18.3493 4.2365C17.9588 3.84597 17.3256 3.84597 16.9351 4.2365L15.7929 5.3787L18.6213 8.20711L19.7635 7.0649C20.154 6.67438 20.154 6.04121 19.7635 5.65069L18.3493 4.2365ZM17.2071 9.62133L14.3787 6.79292L6.14363 15.028C5.9143 15.2573 5.74418 15.539 5.64794 15.8487L4.51928 19.4808L8.15133 18.3521C8.46104 18.2559 8.74271 18.0857 8.97204 17.8564L17.2071 9.62133ZM15.5209 2.82229C16.6924 1.65071 18.5919 1.65071 19.7635 2.82228L21.1777 4.23648C22.3493 5.40805 22.3493 7.30754 21.1777 8.47911L10.3863 19.2706C9.92759 19.7293 9.36427 20.0695 8.74483 20.262L5.11279 21.3907C3.57472 21.8686 2.13142 20.4253 2.60937 18.8873L3.73803 15.2552C3.93052 14.6358 4.27075 14.0725 4.72942 13.6138L15.5209 2.82229Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.4445 2.5656C12.1711 2.29223 11.7279 2.29223 11.4545 2.5656L10.6551 3.36505L12.635 5.34494L13.4344 4.54548C13.7078 4.27211 13.7078 3.8289 13.4344 3.55553L12.4445 2.5656ZM11.645 6.33489L9.66512 4.35501L3.9005 10.1196C3.73997 10.2802 3.62089 10.4773 3.55352 10.6941L2.76346 13.2366L5.30589 12.4465C5.52269 12.3792 5.71986 12.2601 5.88039 12.0995L11.645 6.33489ZM10.4646 1.57565C11.2847 0.755547 12.6143 0.755544 13.4344 1.57565L14.4244 2.56558C15.2445 3.38568 15.2445 4.71533 14.4244 5.53543L6.87034 13.0895C6.54928 13.4105 6.15495 13.6487 5.72134 13.7835L3.17891 14.5735C2.10226 14.9081 1.09195 13.8978 1.42652 12.8211L2.21658 10.2787C2.35132 9.84509 2.58949 9.45076 2.91055 9.1297L10.4646 1.57565Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
