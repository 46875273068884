import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'baggageOn';
    sizeManagement?: 'self' | 'parent';
}

export const withBaggageOnIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'baggageOn',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 2V3.5H8.5V2C8.5 0.89543 9.39543 0 10.5 0H14.5C15.6046 0 16.5 0.895431 16.5 2V3.5H15V2C15 1.72386 14.7761 1.5 14.5 1.5H10.5C10.2239 1.5 10 1.72386 10 2Z" fill="currentColor" /><path d="M9 24C8.17157 24 7.5 23.3284 7.5 22.5H10.5C10.5 23.3284 9.82843 24 9 24Z" fill="currentColor" /><path d="M16 24C15.1716 24 14.5 23.3284 14.5 22.5H17.5C17.5 23.3284 16.8284 24 16 24Z" fill="currentColor" /><path d="M15.7792 11.7803C16.0721 11.4874 16.0721 11.0126 15.7792 10.7197C15.4863 10.4268 15.0114 10.4268 14.7185 10.7197L11.5829 13.8552L10.2803 12.5526C9.98744 12.2597 9.51256 12.2597 9.21967 12.5526C8.92678 12.8455 8.92678 13.3204 9.21967 13.6133L11.0466 15.4403L11.0526 15.4463C11.1847 15.5784 11.3539 15.6509 11.5267 15.6638C11.737 15.6796 11.9525 15.607 12.1133 15.4463L12.1197 15.4398L15.7792 11.7803Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9 5C6.79086 5 5 6.79086 5 9V17C5 19.2091 6.79086 21 9 21H16C18.2091 21 20 19.2091 20 17V9C20 6.79086 18.2091 5 16 5H9ZM16 6.75H9C7.75736 6.75 6.75 7.75736 6.75 9V17C6.75 18.2426 7.75736 19.25 9 19.25H16C17.2426 19.25 18.25 18.2426 18.25 17V9C18.25 7.75736 17.2426 6.75 16 6.75Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 2V3.5H8.5V2C8.5 0.89543 9.39543 0 10.5 0H14.5C15.6046 0 16.5 0.895431 16.5 2V3.5H15V2C15 1.72386 14.7761 1.5 14.5 1.5H10.5C10.2239 1.5 10 1.72386 10 2Z" fill="currentColor" /><path d="M9 24C8.17157 24 7.5 23.3284 7.5 22.5H10.5C10.5 23.3284 9.82843 24 9 24Z" fill="currentColor" /><path d="M16 24C15.1716 24 14.5 23.3284 14.5 22.5H17.5C17.5 23.3284 16.8284 24 16 24Z" fill="currentColor" /><path d="M15.7792 11.7803C16.0721 11.4874 16.0721 11.0126 15.7792 10.7197C15.4863 10.4268 15.0114 10.4268 14.7185 10.7197L11.5829 13.8552L10.2803 12.5526C9.98744 12.2597 9.51256 12.2597 9.21967 12.5526C8.92678 12.8455 8.92678 13.3204 9.21967 13.6133L11.0466 15.4403L11.0526 15.4463C11.1847 15.5784 11.3539 15.6509 11.5267 15.6638C11.737 15.6796 11.9525 15.607 12.1133 15.4463L12.1197 15.4398L15.7792 11.7803Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9 5C6.79086 5 5 6.79086 5 9V17C5 19.2091 6.79086 21 9 21H16C18.2091 21 20 19.2091 20 17V9C20 6.79086 18.2091 5 16 5H9ZM16 6.75H9C7.75736 6.75 6.75 7.75736 6.75 9V17C6.75 18.2426 7.75736 19.25 9 19.25H16C17.2426 19.25 18.25 18.2426 18.25 17V9C18.25 7.75736 17.2426 6.75 16 6.75Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
