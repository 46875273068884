import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'inNewTab';
    sizeManagement?: 'self' | 'parent';
}

export const withInNewTabIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'inNewTab',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 5C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V15C19 14.4477 19.4477 14 20 14C20.5523 14 21 14.4477 21 15V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7C3 4.79086 4.79086 3 7 3H9C9.55228 3 10 3.44772 10 4C10 4.55229 9.55228 5 9 5H7Z" fill="currentColor" /><path d="M10.9288 13.0711C11.3194 13.4616 11.9525 13.4616 12.3431 13.0711L17.8786 7.53555C18.3603 7.05387 18.8419 6.40812 19.2677 5.76596L19.4706 5.7656C19.4665 5.82606 19.4572 5.88707 19.4422 5.94821C19.2147 6.87801 19.0033 7.98065 18.9999 8.878L19 9.99097C19 10.5433 19.4478 10.991 20 10.991C20.5493 10.991 20.9952 10.5481 21 9.99996L20.9999 3.99999C20.9999 3.44772 20.5522 3 19.9999 3L14.0041 3C13.4518 3 13.0041 3.44772 13.0041 4C13.0041 4.55094 13.4496 4.99781 14 4.99999L14.0041 5L15.1004 4.99999C16.0016 4.99999 17.1144 4.78703 18.0516 4.55765C18.1128 4.54268 18.1738 4.53336 18.2343 4.52927L18.234 4.73217C17.5918 5.15797 16.946 5.63966 16.4644 6.12134L10.9288 11.6569C10.5383 12.0474 10.5383 12.6806 10.9288 13.0711Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 3.10001C3.72681 3.10001 3.10001 3.72681 3.10001 4.50001V11.5C3.10001 12.2732 3.72681 12.9 4.50001 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5V10.1C12.9 9.71341 13.2134 9.40001 13.6 9.40001C13.9866 9.40001 14.3 9.71341 14.3 10.1V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.50001C2.95361 14.3 1.70001 13.0464 1.70001 11.5V4.50001C1.70001 2.95361 2.95362 1.70001 4.50001 1.70001H5.90001C6.28661 1.70001 6.60001 2.01341 6.60001 2.40001C6.60001 2.78661 6.28661 3.10001 5.90001 3.10001H4.50001Z" fill="currentColor" /><path d="M7.2502 8.7498C7.52356 9.02316 7.96678 9.02316 8.24015 8.7498L12.115 4.87492C12.4522 4.53774 12.7894 4.08572 13.0874 3.63621L13.2294 3.63596C13.2265 3.67828 13.22 3.72098 13.2095 3.76378C13.0503 4.41464 12.9023 5.18649 12.9 5.81464L12.9 6.59372C12.9 6.98031 13.2134 7.29372 13.6 7.29372C13.9845 7.29372 14.2966 6.98371 14.3 6.60001L14.2999 2.40003C14.2999 2.01344 13.9865 1.70004 13.6 1.70004L9.40285 1.70004C9.01625 1.70004 8.70285 2.01344 8.70285 2.40004C8.70285 2.78569 9.01472 3.0985 9.40001 3.10003L9.40285 3.10004L10.1703 3.10003C10.8011 3.10003 11.5801 2.95095 12.2361 2.79039C12.279 2.77991 12.3217 2.77339 12.364 2.77052L12.3638 2.91255C11.9143 3.21062 11.4622 3.5478 11.1251 3.88497L7.2502 7.75985C6.97683 8.03321 6.97683 8.47643 7.2502 8.7498Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
