import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'plus';
    sizeManagement?: 'self' | 'parent';
}

export const withPlusIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'plus',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.74671 1.75037C6.74671 1.33615 6.41092 1.00037 5.99671 1.00037C5.58249 1.00037 5.24671 1.33615 5.24671 1.75037V5.25012H1.75049C1.33627 5.25012 1.00049 5.58591 1.00049 6.00012C1.00049 6.41434 1.33627 6.75012 1.75049 6.75012H5.24671V10.2512C5.24671 10.6654 5.58249 11.0012 5.99671 11.0012C6.41092 11.0012 6.74671 10.6654 6.74671 10.2512V6.75012H10.2513C10.6655 6.75012 11.0013 6.41434 11.0013 6.00012C11.0013 5.58591 10.6655 5.25012 10.2513 5.25012H6.74671V1.75037Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 3C12.5523 3 13 3.44772 13 4V11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H13V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H11V4C11 3.44772 11.4477 3 12 3Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.70001 2.4C8.70001 2.0134 8.38661 1.7 8.00001 1.7C7.61341 1.7 7.30001 2.0134 7.30001 2.4V7.3H2.40001C2.01341 7.3 1.70001 7.6134 1.70001 8C1.70001 8.3866 2.01341 8.7 2.40001 8.7H7.30001V13.6C7.30001 13.9866 7.61341 14.3 8.00001 14.3C8.38661 14.3 8.70001 13.9866 8.70001 13.6V8.7H13.6C13.9866 8.7 14.3 8.3866 14.3 8C14.3 7.6134 13.9866 7.3 13.6 7.3H8.70001V2.4Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
