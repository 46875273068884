import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'notificationsOff';
    sizeManagement?: 'self' | 'parent';
}

export const withNotificationsOffIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'notificationsOff',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 21C14 22.1046 13.1046 23 12 23C10.8954 23 10 22.1046 10 21C10 19.8954 10.8954 20 12 20C13.1046 20 14 19.8954 14 21Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5 10V17H4C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H17.5858L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L5.64558 7.05979C5.23124 7.95376 5 8.94987 5 10ZM7 10V17H15.5858L7.19576 8.60997C7.0683 9.05126 7 9.51766 7 10Z" fill="currentColor" /><path d="M17 12.1716V10C17 7.23858 14.7614 5 12 5C11.3551 5 10.7388 5.12208 10.1728 5.34438L8.66981 3.84138C9.38122 3.45588 10.167 3.19005 11 3.07089V2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2V3.07089C16.3923 3.55612 19 6.47353 19 10V14.1716L17 12.1716Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.4 14.3C9.4 15.0732 8.7732 15.7 8 15.7C7.2268 15.7 6.6 15.0732 6.6 14.3C6.6 13.5268 7.2268 13.6 8 13.6C8.7732 13.6 9.4 13.5268 9.4 14.3Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3.1 11.5V6.59999C3.1 5.8649 3.26187 5.16762 3.5519 4.54184L1.20503 2.19496C0.931658 1.9216 0.931658 1.47838 1.20503 1.20501C1.47839 0.931646 1.92161 0.931646 2.19497 1.20501L14.795 13.805C15.0683 14.0784 15.0683 14.5216 14.795 14.795C14.5216 15.0683 14.0784 15.0683 13.805 14.795L11.9101 12.9H2.4C2.0134 12.9 1.7 12.5866 1.7 12.2C1.7 11.8134 2.0134 11.5 2.4 11.5H3.1ZM4.5 11.5V6.59999C4.5 6.26235 4.54781 5.93587 4.63703 5.62697L10.51 11.5H4.5Z" fill="currentColor" /><path d="M11.5 8.12009V6.59999C11.5 4.66699 9.933 3.09999 8 3.09999C7.5486 3.09999 7.11716 3.18544 6.72097 3.34106L5.66886 2.28895C6.16686 2.0191 6.71687 1.83302 7.3 1.74961V0.999988C7.3 0.613388 7.6134 0.299988 8 0.299988C8.3866 0.299988 8.7 0.613388 8.7 0.999988V1.74961C11.0746 2.08927 12.9 4.13146 12.9 6.59999V9.52009L11.5 8.12009Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
