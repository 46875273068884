import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'mail';
    sizeManagement?: 'self' | 'parent';
}

export const withMailIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'mail',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 8C2 5.79086 3.79086 4 6 4H18C20.2091 4 22 5.79086 22 8V16C22 18.2091 20.2091 20 18 20H6C3.79086 20 2 18.2091 2 16V8ZM4.07556 7.45357L11.0287 11.3164C11.6328 11.652 12.3672 11.652 12.9713 11.3164L19.9244 7.45357C19.6867 6.61461 18.9151 6 18 6H6C5.08488 6 4.31331 6.61461 4.07556 7.45357ZM20 9.69951L13.9426 13.0648C12.7345 13.7359 11.2655 13.7359 10.0574 13.0648L4 9.69951V16C4 17.1046 4.89543 18 6 18H18C19.1046 18 20 17.1046 20 16V9.69951Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 5.20003C1 3.65363 2.2536 2.40002 3.8 2.40002H12.2C13.7464 2.40002 15 3.65363 15 5.20002V10.8C15 12.3464 13.7464 13.6 12.2 13.6H3.8C2.2536 13.6 1 12.3464 1 10.8V5.20003ZM2.45289 4.81753L7.3201 7.52153C7.74293 7.75644 8.25707 7.75644 8.6799 7.52153L13.5471 4.81753C13.3807 4.23025 12.8406 3.80002 12.2 3.80002H3.8C3.15942 3.80002 2.61932 4.23026 2.45289 4.81753ZM13.6 6.38968L9.3598 8.74535C8.51414 9.21516 7.48586 9.21516 6.6402 8.74535L2.4 6.38969V10.8C2.4 11.5732 3.0268 12.2 3.8 12.2H12.2C12.9732 12.2 13.6 11.5732 13.6 10.8V6.38968Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
