import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'documentDelete';
    sizeManagement?: 'self' | 'parent';
}

export const withDocumentDeleteIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'documentDelete',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 6C6 4.89543 6.89543 4 8 4H11V10C11 10.5523 11.4477 11 12 11H18V12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12V10C20 9.73478 19.8946 9.48043 19.7071 9.29289L12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2H8C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22H13C13.5523 22 14 21.5523 14 21C14 20.4477 13.5523 20 13 20H8C6.89543 20 6 19.1046 6 18V6ZM13 5.41421V9H16.5858L13 5.41421Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L19.4749 18.0607L21.2426 16.2929C21.6332 15.9024 22.2663 15.9024 22.6569 16.2929C23.0474 16.6834 23.0474 17.3166 22.6569 17.7071L20.8891 19.4749L22.6569 21.2426C23.0474 21.6332 23.0474 22.2663 22.6569 22.6569C22.2663 23.0474 21.6332 23.0474 21.2426 22.6569L19.4749 20.8891L17.7071 22.6569C17.3166 23.0474 16.6834 23.0474 16.2929 22.6569C15.9024 22.2663 15.9024 21.6332 16.2929 21.2426L18.0607 19.4749L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.80002 3.8C3.80002 3.0268 4.42683 2.4 5.20002 2.4H7.30002V6.6C7.30002 6.9866 7.61342 7.3 8.00002 7.3H12.2V8C12.2 8.3866 12.5134 8.7 12.9 8.7C13.2866 8.7 13.6 8.3866 13.6 8V6.6C13.6 6.41435 13.5263 6.2363 13.395 6.10503L8.495 1.20503C8.36372 1.07375 8.18568 1 8.00002 1H5.20002C3.65363 1 2.40002 2.2536 2.40002 3.8V12.2C2.40002 13.7464 3.65363 15 5.20002 15H8.70002C9.08662 15 9.40002 14.6866 9.40002 14.3C9.40002 13.9134 9.08662 13.6 8.70002 13.6H5.20002C4.42683 13.6 3.80002 12.9732 3.80002 12.2V3.8ZM8.70002 3.38995V5.9H11.2101L8.70002 3.38995Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11.005 11.005C11.2784 10.7317 11.7216 10.7317 11.995 11.005L13.2324 12.2425L14.4699 11.005C14.7432 10.7317 15.1865 10.7317 15.4598 11.005C15.7332 11.2784 15.7332 11.7216 15.4598 11.995L14.2224 13.2324L15.4598 14.4698C15.7332 14.7432 15.7332 15.1864 15.4598 15.4598C15.1865 15.7332 14.7432 15.7332 14.4699 15.4598L13.2324 14.2224L11.995 15.4598C11.7216 15.7332 11.2784 15.7332 11.005 15.4598C10.7317 15.1864 10.7317 14.7432 11.005 14.4698L12.2425 13.2324L11.005 11.995C10.7317 11.7216 10.7317 11.2784 11.005 11.005Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
