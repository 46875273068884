import { withBemMod } from '@bem-react/core';

import { cnTabsMenu } from '../TabsMenu.cn';

import './TabsMenu_size_l.css';

export interface WithSizeLProps {
    size?: 'l';
}

export const withSizeL = withBemMod<WithSizeLProps>(cnTabsMenu(), { size: 'l' });
