import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'code';
    sizeManagement?: 'self' | 'parent';
}

export const withCodeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'code',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14.5882 2.3406C15.1217 2.48354 15.4382 3.03188 15.2953 3.56534L10.6366 20.952C10.4936 21.4855 9.94529 21.8021 9.41182 21.6591C8.87835 21.5162 8.56177 20.9678 8.70471 20.4344L13.3635 3.0477C13.5064 2.51424 14.0547 2.19765 14.5882 2.3406Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M16.2929 6.29289C15.9024 6.68341 15.9024 7.31658 16.2929 7.7071L20.5858 12L16.2929 16.2929C15.9024 16.6834 15.9024 17.3166 16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071L22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929L17.7071 6.29289C17.3166 5.90236 16.6834 5.90236 16.2929 6.29289Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7.70711 6.29289C8.09763 6.68341 8.09763 7.31658 7.70711 7.7071L3.41421 12L7.70711 16.2929C8.09763 16.6834 8.09763 17.3166 7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071L1.29289 12.7071C0.902369 12.3166 0.902369 11.6834 1.29289 11.2929L6.29289 6.29289C6.68342 5.90236 7.31658 5.90236 7.70711 6.29289Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.8117 1.2385C10.1851 1.33856 10.4067 1.7224 10.3067 2.09582L7.04556 14.2665C6.9455 14.6399 6.56166 14.8615 6.18824 14.7615C5.81481 14.6614 5.5932 14.2776 5.69326 13.9041L8.95438 1.73348C9.05444 1.36005 9.43828 1.13844 9.8117 1.2385Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11.005 4.00503C10.7316 4.27839 10.7316 4.72161 11.005 4.99498L14.01 8L11.005 11.005C10.7316 11.2784 10.7316 11.7216 11.005 11.995C11.2784 12.2683 11.7216 12.2683 11.995 11.995L15.495 8.49498C15.7683 8.22161 15.7683 7.77839 15.495 7.50503L11.995 4.00503C11.7216 3.73166 11.2784 3.73166 11.005 4.00503Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.99496 4.00503C5.26833 4.27839 5.26833 4.72161 4.99496 4.99498L1.98994 8L4.99496 11.005C5.26833 11.2784 5.26833 11.7216 4.99496 11.995C4.7216 12.2683 4.27838 12.2683 4.00501 11.995L0.505013 8.49498C0.231646 8.22161 0.231646 7.77839 0.505013 7.50503L4.00501 4.00503C4.27838 3.73166 4.7216 3.73166 4.99496 4.00503Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
