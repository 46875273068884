import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'bug';
    sizeManagement?: 'self' | 'parent';
}

export const withBugIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'bug',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 6C7 3.79086 8.79086 2 11 2H13C15.2091 2 17 3.79086 17 6V7.12602C17.367 7.22048 17.7137 7.36573 18.032 7.55382L19.7929 5.79289C20.1834 5.40237 20.8166 5.40237 21.2071 5.79289C21.5976 6.18342 21.5976 6.81658 21.2071 7.20711L19.4462 8.96803C19.7981 9.56355 20 10.2582 20 11V12H22C22.5523 12 23 12.4477 23 13C23 13.5523 22.5523 14 22 14H20C20 15.0621 19.793 16.0758 19.4173 17.003L21.2071 18.7929C21.5976 19.1834 21.5976 19.8166 21.2071 20.2071C20.8166 20.5976 20.1834 20.5976 19.7929 20.2071L18.3942 18.8084C16.9343 20.7468 14.6135 22 12 22C9.38649 22 7.06568 20.7468 5.60577 18.8084L4.20711 20.2071C3.81658 20.5976 3.18342 20.5976 2.79289 20.2071C2.40237 19.8166 2.40237 19.1834 2.79289 18.7929L4.58275 17.003C4.20696 16.0758 4 15.0621 4 14H2C1.44772 14 1 13.5523 1 13C1 12.4477 1.44772 12 2 12H4V11C4 10.2582 4.20193 9.56355 4.55382 8.96803L2.79289 7.20711C2.40237 6.81658 2.40237 6.18342 2.79289 5.79289C3.18342 5.40237 3.81658 5.40237 4.20711 5.79289L5.96803 7.55382C6.28634 7.36573 6.63298 7.22048 7 7.12602V6ZM9 7H15V6C15 4.89543 14.1046 4 13 4H11C9.89543 4 9 4.89543 9 6V7ZM6 14C6 16.973 8.16229 19.441 11 19.917V14C11 13.4477 11.4477 13 12 13C12.5523 13 13 13.4477 13 14V19.917C15.8377 19.441 18 16.973 18 14V11C18 9.89543 17.1046 9 16 9H8C6.89543 9 6 9.89543 6 11V14Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.49999 3.8C4.49999 2.2536 5.75359 1 7.29999 1H8.69999C10.2464 1 11.5 2.2536 11.5 3.8L11.5 4.58821C11.7569 4.65434 11.9995 4.75601 12.2224 4.88767L13.455 3.65503C13.7284 3.38166 14.1716 3.38166 14.445 3.65503C14.7183 3.92839 14.7183 4.37161 14.445 4.64497L13.2123 5.87762C13.4586 6.29448 13.6 6.78073 13.6 7.3V8H15C15.3866 8 15.7 8.3134 15.7 8.7C15.7 9.0866 15.3866 9.4 15 9.4L13.6 9.4C13.6 10.1434 13.4551 10.853 13.1921 11.5021L14.445 12.755C14.7183 13.0284 14.7183 13.4716 14.445 13.745C14.1716 14.0183 13.7284 14.0183 13.455 13.745L12.476 12.7659C11.454 14.1227 9.82945 15 7.99999 15C6.17053 15 4.54597 14.1227 3.52402 12.7659L2.54496 13.745C2.2716 14.0183 1.82838 14.0183 1.55501 13.745C1.28165 13.4716 1.28165 13.0284 1.55501 12.755L2.80791 11.5021C2.54486 10.853 2.39999 10.1434 2.39999 9.4L0.999988 9.4C0.613388 9.4 0.299988 9.0866 0.299988 8.7C0.299988 8.3134 0.613388 8 0.999988 8H2.39999V7.3C2.39999 6.78073 2.54134 6.29448 2.78766 5.87762L1.55501 4.64497C1.28165 4.37161 1.28165 3.92839 1.55501 3.65503C1.82838 3.38166 2.2716 3.38166 2.54496 3.65503L3.77761 4.88767C4.00043 4.75601 4.24307 4.65434 4.49999 4.58821V3.8ZM5.89999 4.5H10.1L10.1 3.8C10.1 3.0268 9.47319 2.4 8.69999 2.4H7.29999C6.52679 2.4 5.89999 3.0268 5.89999 3.8V4.5ZM3.79999 9.4C3.79999 11.4811 5.31359 13.2087 7.29999 13.5419V9.4C7.29999 9.0134 7.61339 8.7 7.99999 8.7C8.38659 8.7 8.69999 9.0134 8.69999 9.4V13.5419C10.6864 13.2087 12.2 11.4811 12.2 9.4V7.3C12.2 6.5268 11.5732 5.9 10.8 5.9H5.19999C4.42679 5.9 3.79999 6.5268 3.79999 7.3V9.4Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
