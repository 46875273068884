import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'send';
    sizeManagement?: 'self' | 'parent';
}

export const withSendIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'send',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M18.1733 2.15253C20.404 1.55481 22.4453 3.59605 21.8475 5.82676L18.1636 19.5753C17.4724 22.155 14.0084 22.6135 12.6699 20.3024L9.37915 14.6209L3.69763 11.3302C1.38656 9.99167 1.84504 6.52767 4.42476 5.83644L18.1733 2.15253ZM11.3614 14.0529L14.4005 19.3001C14.8467 20.0704 16.0014 19.9176 16.2318 19.0577L19.9157 5.30912C20.1149 4.56555 19.4345 3.88514 18.6909 4.08438L4.94239 7.76829C4.08249 7.9987 3.92966 9.15337 4.70002 9.59955L9.94718 12.6387L13.2929 9.29294C13.6834 8.90242 14.3166 8.90242 14.7071 9.29294C15.0976 9.68347 15.0976 10.3166 14.7071 10.7072L11.3614 14.0529Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.3213 1.10674C13.8828 0.688343 15.3117 2.11721 14.8933 3.67871L12.3146 13.3027C11.8307 15.1085 9.4059 15.4294 8.46891 13.8117L6.16542 9.83462L2.18835 7.53113C0.570613 6.59414 0.891541 4.16935 2.69734 3.68548L12.3213 1.10674ZM7.55299 9.437L9.68038 13.11C9.99271 13.6493 10.801 13.5423 10.9623 12.9403L13.541 3.31636C13.6805 2.79586 13.2042 2.31957 12.6837 2.45904L3.05969 5.03778C2.45776 5.19907 2.35078 6.00733 2.89003 6.31966L6.56304 8.44705L8.90505 6.10504C9.17842 5.83167 9.62163 5.83167 9.895 6.10504C10.1684 6.3784 10.1684 6.82162 9.895 7.09499L7.55299 9.437Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
