import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapBaby';
    sizeManagement?: 'self' | 'parent';
}

export const withGapBabyIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapBaby',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.83719 4.8C9.1076 4.8 10.1375 3.72548 10.1375 2.4C10.1375 1.07452 9.1076 0 7.83719 0C6.56677 0 5.53689 1.07452 5.53689 2.4C5.53689 3.72548 6.56677 4.8 7.83719 4.8Z" fill="currentColor" /><path d="M10.9042 8.01665C10.9042 7.59231 11.0658 7.18534 11.3534 6.88528L14.3859 3.72132C14.7602 3.3308 14.7602 2.69763 14.3859 2.30711C14.0116 1.91658 13.4048 1.91658 13.0305 2.30711L10.3235 5.13137C10.0359 5.43143 9.64588 5.6 9.23916 5.6H6.7608C6.35408 5.6 5.96402 5.43143 5.67643 5.13137L2.9695 2.30711C2.5952 1.91658 1.98834 1.91658 1.61404 2.30711C1.23974 2.69763 1.23974 3.33079 1.61404 3.72132L4.32097 6.54558C4.60856 6.84564 4.77013 7.25261 4.77013 7.67696V14.6C4.77013 15.3732 5.37089 16 6.11197 16C6.85304 16 7.45381 15.3732 7.45381 14.6V12.4C7.45381 12.1791 7.62545 12 7.83719 12C8.04892 12 8.22057 12.1791 8.22057 12.4V14.6C8.22057 15.3732 8.82133 16 9.56241 16C10.3035 16 10.9042 15.3732 10.9042 14.6V8.01665Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
