import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'travelRestaurant';
    sizeManagement?: 'self' | 'parent';
}

export const withTravelRestaurantIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'travelRestaurant',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14 6.66667C13.2936 8 12.2782 8.66667 10.9538 8.66667C9.6293 8.66667 8.61389 8 7.90751 6.66667L8.15652 0.399681C8.16539 0.176405 8.34777 0 8.56974 0H8.84108C8.91166 0 8.96984 0.0557137 8.97336 0.126675L9.19885 4.66667H10.3246L10.5501 0.126675C10.5537 0.0557137 10.6118 0 10.6824 0H11.2251C11.2957 0 11.3539 0.0557137 11.3574 0.126675L11.5829 4.66667H12.7087L12.9341 0.126675C12.9377 0.0557137 12.9958 0 13.0664 0H13.3378C13.5597 0 13.7421 0.176405 13.751 0.399681L14 6.66667ZM11.6886 9.23627L11.8611 15.3143C11.8715 15.6823 11.5836 15.9892 11.218 15.9997C11.2117 15.9999 11.2054 16 11.1991 16H10.7077C10.342 16 10.0455 15.7015 10.0455 15.3333C10.0455 15.327 10.0456 15.3206 10.0457 15.3143L10.2182 9.23627C10.4633 9.30098 10.7083 9.33333 10.9534 9.33333C11.1985 9.33333 11.4435 9.30098 11.6886 9.23627ZM6.38472 0.703641V16H5.8984C5.15067 16 4.54451 15.4031 4.54451 14.6667C4.54451 14.6489 4.54487 14.6311 4.5456 14.6134L4.76005 9.33334C4.48928 8.64053 3.90259 8.19609 3 8C3 4.45212 3.73461 1.87162 5.20383 0.258497L5.20383 0.258489C5.45347 -0.0156018 5.88146 -0.0384954 6.15978 0.207355C6.30293 0.33381 6.38472 0.514257 6.38472 0.703641Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
