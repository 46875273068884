export enum Platform {
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

export interface Host {
    protocol?: 'https';
    host: string;
    port?: string;
}

export interface RequiredHosts {
    staff: Host,
    center: Host,
}
export type OptionalHosts = Record<string, Host>
export type Hosts = RequiredHosts & OptionalHosts
export type GetHostFn = (key: keyof RequiredHosts) => Host;
export type FormatUrlFn = (host: Host, pathname: string, query?: Record<string, string>) => string;
