import React, { FC } from 'react';

import { cnErrorState } from '../ErrorState.cn';
import { IErrorStateCodeProps } from './ErrorState-Code.types';

import './ErrorState-Code.css';

export const ErrorStateCode: FC<IErrorStateCodeProps> = (props) => {
    const { code } = props;

    if (!code) {
        return null;
    }

    return (
        <div className={cnErrorState('Code')}>
            {code}
        </div>
    );
};
