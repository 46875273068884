import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'compass';
    sizeManagement?: 'self' | 'parent';
}

export const withCompassIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'compass',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M16.2071 7.79289C16.4609 8.04664 16.5592 8.41691 16.4648 8.76312L14.9648 14.2631C14.8716 14.6047 14.6047 14.8716 14.2631 14.9648L8.76312 16.4648C8.41691 16.5592 8.04664 16.4609 7.79289 16.2071C7.53914 15.9534 7.44081 15.5831 7.53524 15.2369L9.03524 9.73688C9.1284 9.39527 9.39527 9.1284 9.73688 9.03524L15.2369 7.53524C15.5831 7.44081 15.9534 7.53914 16.2071 7.79289ZM10.8144 10.8144L9.92522 14.0748L13.1856 13.1856L14.0748 9.92522L10.8144 10.8144Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99994 2.39994C4.90714 2.39994 2.39994 4.90714 2.39994 7.99994C2.39994 11.0927 4.90714 13.5999 7.99994 13.5999C11.0927 13.5999 13.5999 11.0927 13.5999 7.99994C13.5999 4.90714 11.0927 2.39994 7.99994 2.39994ZM0.999939 7.99994C0.999939 4.13395 4.13395 0.999939 7.99994 0.999939C11.8659 0.999939 14.9999 4.13395 14.9999 7.99994C14.9999 11.8659 11.8659 14.9999 7.99994 14.9999C4.13395 14.9999 0.999939 11.8659 0.999939 7.99994Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.945 5.05506C11.1226 5.23269 11.1915 5.49187 11.1254 5.73422L10.0754 9.58422C10.0102 9.82335 9.82335 10.0102 9.58422 10.0754L5.73422 11.1254C5.49187 11.1915 5.23269 11.1226 5.05506 10.945C4.87744 10.7674 4.80861 10.5082 4.8747 10.2659L5.9247 6.41585C5.98992 6.17673 6.17673 5.98992 6.41585 5.9247L10.2659 4.8747C10.5082 4.80861 10.7674 4.87744 10.945 5.05506ZM7.17012 7.17012L6.54769 9.45238L8.82995 8.82995L9.45238 6.54769L7.17012 7.17012Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
