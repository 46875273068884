import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapHome';
    sizeManagement?: 'self' | 'parent';
}

export const withGapHomeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapHome',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.51475 5.5997C2.18996 5.85231 2 6.24072 2 6.65217V12.6667C2 13.4031 2.59695 14.0001 3.33333 14.0001H6.41667C6.55474 14.0001 6.66667 13.8881 6.66667 13.7501V11.5834C6.66667 11.4453 6.7786 11.3334 6.91667 11.3334H9.08333C9.2214 11.3334 9.33333 11.4453 9.33333 11.5834V13.7501C9.33333 13.8881 9.44526 14.0001 9.58333 14.0001H12.6667C13.403 14.0001 14 13.4031 14 12.6667V6.65217C14 6.24072 13.81 5.85231 13.4853 5.5997L8.81859 1.97007C8.33711 1.59559 7.66289 1.59559 7.18141 1.97007L2.51475 5.5997Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
