import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'moreHorizontal';
    sizeManagement?: 'self' | 'parent';
}

export const withMoreHorizontalIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'moreHorizontal',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="currentColor" /><path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="currentColor" /><path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.50001 8.00001C4.50001 8.7732 3.87321 9.40001 3.10001 9.40001C2.32681 9.40001 1.70001 8.7732 1.70001 8.00001C1.70001 7.22681 2.32681 6.60001 3.10001 6.60001C3.87321 6.60001 4.50001 7.22681 4.50001 8.00001Z" fill="currentColor" /><path d="M9.40001 8.00001C9.40001 8.7732 8.77321 9.40001 8.00001 9.40001C7.22681 9.40001 6.60001 8.7732 6.60001 8.00001C6.60001 7.22681 7.22681 6.60001 8.00001 6.60001C8.77321 6.60001 9.40001 7.22681 9.40001 8.00001Z" fill="currentColor" /><path d="M14.3 8.00001C14.3 8.7732 13.6732 9.40001 12.9 9.40001C12.1268 9.40001 11.5 8.7732 11.5 8.00001C11.5 7.22681 12.1268 6.60001 12.9 6.60001C13.6732 6.60001 14.3 7.22681 14.3 8.00001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
