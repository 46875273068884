import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowLongLeft';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowLongLeftIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowLongLeft',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.7071 18.2929C12.0977 18.6834 12.0977 19.3166 11.7071 19.7071C11.3166 20.0977 10.6834 20.0977 10.2929 19.7071L3.29292 12.7071C2.90239 12.3166 2.90239 11.6834 3.29292 11.2929L10.2929 4.29292C10.6834 3.9024 11.3166 3.9024 11.7071 4.29292C12.0977 4.68345 12.0977 5.31661 11.7071 5.70714L8.1716 9.24267C7.53436 9.87991 6.59687 10.5162 5.77196 11.0168C5.71815 11.0494 5.66843 11.086 5.6228 11.1258L5.76647 11.269C6.52162 11.116 7.31881 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.31878 13 6.52155 12.884 5.76637 12.731L5.62267 12.8742C5.66835 12.9141 5.71812 12.9507 5.77198 12.9833C6.59689 13.4839 7.53437 14.1202 8.1716 14.7574L11.7071 18.2929Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.795 12.405C8.06837 12.6784 8.06837 13.1216 7.795 13.395C7.52164 13.6684 7.07842 13.6684 6.80505 13.395L1.90505 8.495C1.63169 8.22163 1.63169 7.77842 1.90505 7.50505L6.80505 2.60505C7.07842 2.33168 7.52164 2.33168 7.795 2.60505C8.06837 2.87842 8.06837 3.32163 7.795 3.595L5.32013 6.06987C4.87406 6.51594 4.21782 6.96137 3.64038 7.31175C3.60271 7.33461 3.5679 7.36019 3.53595 7.3881L3.63651 7.4883C4.16512 7.38121 4.72317 7.3 5.20001 7.3H13.6C13.9866 7.3 14.3 7.6134 14.3 8C14.3 8.3866 13.9866 8.7 13.6 8.7H5.20001C4.72317 8.7 4.16512 8.61879 3.6365 8.5117L3.5359 8.61195C3.56787 8.63987 3.6027 8.66547 3.6404 8.68835C4.21784 9.03871 4.87407 9.48412 5.32013 9.93018L7.795 12.405Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
