import React, { FC } from 'react';

import { cnErrorState } from '../ErrorState.cn';
import { IErrorStateImageProps } from './ErrorState-Image.types';

import './ErrorState-Image.css';

export const ErrorStateImage: FC<IErrorStateImageProps> = (props) => {
    const { image } = props;

    if (!image) {
        return null;
    }

    if (typeof image === 'string') {
        return (
            <div className={cnErrorState('Image')}>
                <div
                    className={cnErrorState('ImageContainer')}
                    style={{ backgroundImage: `url("${image}")` }}
                />
            </div>
        );
    }

    return (
        <div className={cnErrorState('Image')}>
            <div className={cnErrorState('ImageContainer')}>
                {image}
            </div>
        </div>
    );
};
