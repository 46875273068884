import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'documentEdit';
    sizeManagement?: 'self' | 'parent';
}

export const withDocumentEditIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'documentEdit',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 6C6 4.89543 6.89543 4 8 4H11V10C11 10.5523 11.4477 11 12 11H18V12.5C18 13.0523 18.4477 13.5 19 13.5C19.5523 13.5 20 13.0523 20 12.5V10C20 9.73478 19.8946 9.48043 19.7071 9.29289L12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2H8C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22H12C12.5523 22 13 21.5523 13 21C13 20.4477 12.5523 20 12 20H8C6.89543 20 6 19.1046 6 18V6ZM13 5.41421V9H16.5858L13 5.41421Z" fill="currentColor" /><path d="M15.9528 20.2293C15.8054 20.3767 15.6961 20.5577 15.6343 20.7568L15.1507 22.3129C15.0483 22.6424 15.3575 22.9516 15.687 22.8492L17.2431 22.3657C17.4422 22.3038 17.6232 22.1945 17.7706 22.0471L21.252 18.5657C21.2326 18.5512 21.2139 18.5352 21.1963 18.5176L19.4825 16.8037C19.4648 16.7861 19.4487 16.7674 19.4343 16.7479L15.9528 20.2293Z" fill="currentColor" /><path d="M20.1337 16.0484C20.1532 16.0629 20.1719 16.079 20.1896 16.0966L21.9034 17.8105C21.9211 17.8281 21.9371 17.8467 21.9515 17.8661L22.3942 17.4234C22.7289 17.0888 22.7289 16.5462 22.3942 16.2116L21.7883 15.6057C21.4537 15.271 20.9111 15.271 20.5765 15.6057L20.1337 16.0484Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.80002 3.8C3.80002 3.0268 4.42683 2.4 5.20002 2.4H7.30002V6.6C7.30002 6.9866 7.61342 7.3 8.00002 7.3H12.2V8.35C12.2 8.7366 12.5134 9.05 12.9 9.05C13.2866 9.05 13.6 8.7366 13.6 8.35V6.6C13.6 6.41435 13.5263 6.2363 13.395 6.10503L8.495 1.20503C8.36372 1.07375 8.18568 1 8.00002 1H5.20002C3.65363 1 2.40002 2.2536 2.40002 3.8V12.2C2.40002 13.7464 3.65363 15 5.20002 15H8.00002C8.38662 15 8.70002 14.6866 8.70002 14.3C8.70002 13.9134 8.38662 13.6 8.00002 13.6H5.20002C4.42683 13.6 3.80002 12.9732 3.80002 12.2V3.8ZM8.70002 3.38995V5.9H11.2101L8.70002 3.38995Z" fill="currentColor" /><path d="M10.767 13.7605C10.6638 13.8637 10.5873 13.9904 10.544 14.1297L10.2055 15.2191C10.1338 15.4497 10.3503 15.6661 10.5809 15.5945L11.6702 15.256C11.8096 15.2127 11.9363 15.1361 12.0394 15.033L14.4764 12.596C14.4628 12.5859 14.4498 12.5746 14.4374 12.5623L13.2378 11.3626C13.2254 11.3502 13.2141 11.3372 13.204 11.3235L10.767 13.7605Z" fill="currentColor" /><path d="M13.6936 10.8339C13.7073 10.844 13.7204 10.8553 13.7328 10.8677L14.9324 12.0673C14.9448 12.0797 14.956 12.0927 14.9661 12.1063L15.276 11.7964C15.5102 11.5621 15.5102 11.1824 15.276 10.9481L14.8518 10.524C14.6176 10.2897 14.2378 10.2897 14.0036 10.524L13.6936 10.8339Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
