import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'calendarGrid';
    sizeManagement?: 'self' | 'parent';
}

export const withCalendarGridIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'calendarGrid',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.7 3C12.5895 3 12.5 3.08954 12.5 3.2V5.8C12.5 5.91046 12.5895 6 12.7 6H15.3C15.4105 6 15.5 5.91046 15.5 5.8V3.2C15.5 3.08954 15.4105 3 15.3 3H12.7Z" fill="currentColor" /><path d="M8.7 11C8.58954 11 8.5 11.0895 8.5 11.2V13.8C8.5 13.9105 8.58954 14 8.7 14H11.3C11.4105 14 11.5 13.9105 11.5 13.8V11.2C11.5 11.0895 11.4105 11 11.3 11H8.7Z" fill="currentColor" /><path d="M8.5 7.2C8.5 7.08954 8.58954 7 8.7 7H11.3C11.4105 7 11.5 7.08954 11.5 7.2V9.8C11.5 9.91046 11.4105 10 11.3 10H8.7C8.58954 10 8.5 9.91046 8.5 9.8V7.2Z" fill="currentColor" /><path d="M12.7 7C12.5895 7 12.5 7.08954 12.5 7.2V9.8C12.5 9.91046 12.5895 10 12.7 10H15.3C15.4105 10 15.5 9.91046 15.5 9.8V7.2C15.5 7.08954 15.4105 7 15.3 7H12.7Z" fill="currentColor" /><path d="M8.5 3.2C8.5 3.08954 8.58954 3 8.7 3H11.3C11.4105 3 11.5 3.08954 11.5 3.2V5.8C11.5 5.91046 11.4105 6 11.3 6H8.7C8.58954 6 8.5 5.91046 8.5 5.8V3.2Z" fill="currentColor" /><path d="M4.7 11C4.58954 11 4.5 11.0895 4.5 11.2V13.8C4.5 13.9105 4.58954 14 4.7 14H7.3C7.41046 14 7.5 13.9105 7.5 13.8V11.2C7.5 11.0895 7.41046 11 7.3 11H4.7Z" fill="currentColor" /><path d="M4.5 7.2C4.5 7.08954 4.58954 7 4.7 7H7.3C7.41046 7 7.5 7.08954 7.5 7.2V9.8C7.5 9.91046 7.41046 10 7.3 10H4.7C4.58954 10 4.5 9.91046 4.5 9.8V7.2Z" fill="currentColor" /><path d="M4.7 3C4.58954 3 4.5 3.08954 4.5 3.2V5.8C4.5 5.91046 4.58954 6 4.7 6H7.3C7.41046 6 7.5 5.91046 7.5 5.8V3.2C7.5 3.08954 7.41046 3 7.3 3H4.7Z" fill="currentColor" /><path d="M0.5 11.2C0.5 11.0895 0.589543 11 0.7 11H3.3C3.41046 11 3.5 11.0895 3.5 11.2V13.8C3.5 13.9105 3.41046 14 3.3 14H0.7C0.589543 14 0.5 13.9105 0.5 13.8V11.2Z" fill="currentColor" /><path d="M0.7 7C0.589543 7 0.5 7.08954 0.5 7.2V9.8C0.5 9.91046 0.589543 10 0.7 10H3.3C3.41046 10 3.5 9.91046 3.5 9.8V7.2C3.5 7.08954 3.41046 7 3.3 7H0.7Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
