import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'reviewAverage';
    sizeManagement?: 'self' | 'parent';
}

export const withReviewAverageIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'reviewAverage',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 5C12.5523 5 13 5.44772 13 6V9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H13V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V11H8C7.44772 11 7 10.5523 7 10C7 9.44772 7.44772 9 8 9H11V6C11 5.44772 11.4477 5 12 5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7 18C7 17.4477 7.44772 17 8 17H16C16.5523 17 17 17.4477 17 18C17 18.5523 16.5523 19 16 19H8C7.44772 19 7 18.5523 7 18Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 3.10001C8.3866 3.10001 8.7 3.41341 8.7 3.80001V5.90001H10.8C11.1866 5.90001 11.5 6.21341 11.5 6.60001C11.5 6.98661 11.1866 7.30001 10.8 7.30001H8.7V9.40001C8.7 9.78661 8.3866 10.1 8 10.1C7.6134 10.1 7.3 9.78661 7.3 9.40001V7.30001H5.2C4.8134 7.30001 4.5 6.98661 4.5 6.60001C4.5 6.21341 4.8134 5.90001 5.2 5.90001H7.3V3.80001C7.3 3.41341 7.6134 3.10001 8 3.10001ZM4.5 12.2C4.5 11.8134 4.8134 11.5 5.2 11.5H10.8C11.1866 11.5 11.5 11.8134 11.5 12.2C11.5 12.5866 11.1866 12.9 10.8 12.9H5.2C4.8134 12.9 4.5 12.5866 4.5 12.2Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
