import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'refresh';
    sizeManagement?: 'self' | 'parent';
}

export const withRefreshIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'refresh',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 12C5 8.13401 8.13401 5 12 5C12.9111 5 13.6422 5.11318 14.3561 5.41915C15.078 5.72852 15.85 6.26424 16.7929 7.20711L17.4645 7.87863C17.9462 8.36031 18.5919 8.842 19.2341 9.2678L19.2344 9.4707C19.1739 9.46661 19.1129 9.45729 19.0517 9.44232C18.1145 9.21294 17.0017 8.99998 16.1005 8.99998L15.3432 8.99997L15.3391 8.99998C14.7887 9.00216 14.3432 9.44903 14.3432 9.99997C14.3432 10.5523 14.7909 11 15.3432 11L21 11C21.5523 11 22 10.5522 22 9.99998L22 4.33412C21.9952 3.78598 21.5493 3.34311 21 3.34311C20.4478 3.34311 20 3.79083 20 4.34311L20 5.12197C20.0034 6.01932 20.2148 7.12196 20.4423 8.05176C20.4573 8.1129 20.4666 8.17391 20.4707 8.23437L20.2679 8.23401C19.8421 7.59185 19.3604 6.9461 18.8787 6.46442L18.2071 5.79289C17.15 4.73576 16.172 4.02148 15.1439 3.58086C14.1078 3.13682 13.0889 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C14.4772 21 16.7223 19.9978 18.3487 18.3792C18.7402 17.9896 18.7417 17.3564 18.3521 16.965C17.9625 16.5735 17.3293 16.572 16.9379 16.9616C15.671 18.2225 13.9273 19 12 19C8.13401 19 5 15.866 5 12Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.10007 8.00004C3.10007 5.29385 5.29388 3.10004 8.00007 3.10004C8.63782 3.10004 9.14958 3.17927 9.64933 3.39344C10.1546 3.61001 10.6951 3.98501 11.3551 4.64502L11.8252 5.11506C12.1623 5.45223 12.6144 5.78941 13.0639 6.08748L13.0641 6.22951C13.0218 6.22664 12.9791 6.22012 12.9362 6.20964C12.2802 6.04908 11.5012 5.9 10.8704 5.9L10.3403 5.89999L10.3374 5.9C9.95212 5.90153 9.64025 6.21434 9.64025 6.59999C9.64025 6.98659 9.95365 7.29999 10.3403 7.29999L14.3 7.3C14.6866 7.29999 15 6.98659 15 6.6L15 2.6339C14.9966 2.2502 14.6845 1.94019 14.3 1.94019C13.9134 1.94019 13.6 2.2536 13.6 2.64019V3.18539C13.6024 3.81354 13.7504 4.58539 13.9096 5.23625C13.9201 5.27905 13.9266 5.32175 13.9295 5.36407L13.7875 5.36382C13.4895 4.91431 13.1523 4.46229 12.8151 4.12511L12.345 3.65507C11.6051 2.91507 10.9205 2.41508 10.2008 2.10664C9.47556 1.79582 8.76233 1.70004 8.00007 1.70004C4.52068 1.70004 1.70007 4.52065 1.70007 8.00004C1.70007 11.4794 4.52068 14.3 8.00007 14.3C9.73414 14.3 11.3057 13.5985 12.4442 12.4655C12.7182 12.1927 12.7192 11.7495 12.4465 11.4755C12.1738 11.2015 11.7306 11.2004 11.4566 11.4732C10.5698 12.3558 9.34916 12.9 8.00007 12.9C5.29388 12.9 3.10007 10.7062 3.10007 8.00004Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
