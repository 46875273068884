import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'book';
    sizeManagement?: 'self' | 'parent';
}

export const withBookIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'book',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4.99173C11.9655 4.94788 11.9299 4.90388 11.8932 4.85985C11.1398 3.95571 9.8811 3.00003 8.00001 3.00001L5.99933 3C3.78996 3 2 4.79111 2 6.99995V14.9999C2 17.2086 3.78925 19 5.99896 19L7.99999 19C9.84585 19 10.5466 19.4608 10.7929 19.7071C11.1616 20.2285 11.1496 21 12 21C12.8504 21 12.8384 20.2285 13.2071 19.7071C13.4534 19.4608 14.1541 19 16 19L18.0013 19C20.2109 19.0001 22 17.2087 22 15.0001V6.99995C22 4.79111 20.21 3 18.0007 3L16 3.00001C14.1189 3.00003 12.8602 3.95571 12.1068 4.85985C12.0701 4.90388 12.0345 4.94788 12 4.99173ZM5.99933 5C4.89506 5 4 5.89515 4 6.99995V14.9999C4 16.105 4.89484 17 5.99896 17L8.00001 17C9.28036 17 10.2587 17.1905 11 17.5033V7.18823C10.976 7.13217 10.9437 7.0609 10.9024 6.9785C10.787 6.74756 10.6081 6.44185 10.3568 6.14022C9.86023 5.54435 9.1189 5.00002 7.99999 5.00001L5.99933 5ZM13 17.5033C13.7413 17.1905 14.7197 17 16 17L18.0013 17C19.1053 17.0001 20 16.1052 20 15.0001V6.99995C20 5.89515 19.1049 5 18.0007 5L16 5.00001C14.8811 5.00002 14.1398 5.54435 13.6432 6.14022C13.3919 6.44185 13.213 6.74756 13.0976 6.9785C13.0563 7.0609 13.024 7.13217 13 7.18823V17.5033Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.2 12.9C6.49209 12.9 6.9826 13.2226 7.15502 13.395C7.37073 13.7 7.43359 14.3 8 14.3C8.56641 14.3 8.62927 13.7 8.84498 13.395C9.0174 13.2226 9.5079 12.9 10.8 12.9L12.2009 12.9C13.7477 12.9001 15 11.6461 15 10.1001V4.49998C15 2.95379 13.747 1.70002 12.2005 1.70001L10.8 1.70002C9.48323 1.70003 8.60216 2.36901 8.07474 3.00191C8.04906 3.03273 8.02415 3.06353 8 3.09422C7.97585 3.06353 7.95094 3.03273 7.92526 3.00191C7.39784 2.36901 6.51677 1.70003 5.20001 1.70002L3.79953 1.70001C2.25297 1.70002 1 2.95379 1 4.49998V10.0999C1 11.646 2.25247 12.9 3.79927 12.9L5.2 12.9ZM3.79953 3.10001C3.02655 3.10001 2.4 3.72662 2.4 4.49998V10.0999C2.4 10.8735 3.02638 11.5 3.79927 11.5L5.20001 11.5C6.09625 11.5 6.7811 11.6334 7.3 11.8523V4.63177C7.23606 4.48256 7.08848 4.18465 6.84974 3.89816C6.50216 3.48106 5.98323 3.10002 5.19999 3.10002L3.79953 3.10001ZM8.7 4.63177V11.8523C9.21891 11.6334 9.90376 11.5 10.8 11.5L12.2009 11.5C12.9737 11.5 13.6 10.8736 13.6 10.1001V4.49998C13.6 3.72662 12.9735 3.10001 12.2005 3.10001L10.8 3.10002C10.0168 3.10002 9.49784 3.48106 9.15026 3.89816C8.91152 4.18465 8.76394 4.48256 8.7 4.63177Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
