import React, { ComponentProps, FC, useCallback, useMemo, useRef } from 'react';

import { IMenuItem, MenuItemClickHandler } from '../../PageHeader.types';
import { DropdownPopup } from '../Dropdown/Dropdown';
import { Menu } from '../Menu/Menu';
import { SimpleMenuItem } from './SimpleMenuItem';

export const PopupMenuItemWithChildren: FC<{
    items: IMenuItem[],
    label: string,
    onItemClick?: MenuItemClickHandler,
    className?: string,
    disabled?: boolean,
    id: string,
    direction: ComponentProps<typeof DropdownPopup>['direction'];
    width?: 'max';
    isActive?: boolean;
    icon: 'right' | 'down'
    isPopupVisible?: boolean;
    setPopupVisible: Function;
}> = (props) => {
    const {
        items, label, onItemClick, className,
        disabled, id, direction, width, icon,
        isActive,
        isPopupVisible,
        setPopupVisible,
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    const menuItem = useMemo((): IMenuItem => ({ id, name: label, isActive }), [id, isActive, label]);
    const onClick = useCallback(
        () => { setPopupVisible(!isPopupVisible, id) },
        [id, isPopupVisible, setPopupVisible],
    );
    const onClose = useCallback(
        () => { setPopupVisible(false, id) },
        [id, setPopupVisible],
    );

    const handleItemClick: MenuItemClickHandler = useCallback((e, item) => {
        if (onItemClick) {
            onItemClick(e, item);
        }
        onClose();
    }, [onClose, onItemClick]);

    return (
        <>
            <div ref={ref} className={className}>
                <SimpleMenuItem
                    item={menuItem}
                    onClick={onClick}
                    open={isPopupVisible}
                    disabled={disabled}
                    width={width}
                    icon={icon}
                />
            </div>
            <DropdownPopup anchor={ref} visible={isPopupVisible} onClose={onClose} direction={direction}>
                <Menu items={items} onItemClick={handleItemClick} />
            </DropdownPopup>
        </>
    );
};
