import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'pause';
    sizeManagement?: 'self' | 'parent';
}

export const withPauseIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'pause',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 7.5C4 5.567 5.567 4 7.5 4C9.433 4 11 5.567 11 7.5V16.5C11 18.433 9.433 20 7.5 20C5.567 20 4 18.433 4 16.5V7.5ZM7.5 6C6.67157 6 6 6.67157 6 7.5V16.5C6 17.3284 6.67157 18 7.5 18C8.32843 18 9 17.3284 9 16.5V7.5C9 6.67157 8.32843 6 7.5 6Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13 7.5C13 5.567 14.567 4 16.5 4C18.433 4 20 5.567 20 7.5V16.5C20 18.433 18.433 20 16.5 20C14.567 20 13 18.433 13 16.5V7.5ZM16.5 6C15.6716 6 15 6.67157 15 7.5V16.5C15 17.3284 15.6716 18 16.5 18C17.3284 18 18 17.3284 18 16.5V7.5C18 6.67157 17.3284 6 16.5 6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.40002 4.85003C2.40002 3.49693 3.49693 2.40002 4.85002 2.40002C6.20312 2.40002 7.30002 3.49693 7.30002 4.85002V11.15C7.30002 12.5031 6.20312 13.6 4.85002 13.6C3.49693 13.6 2.40002 12.5031 2.40002 11.15V4.85003ZM4.85002 3.80002C4.27013 3.80002 3.80002 4.27013 3.80002 4.85003V11.15C3.80002 11.7299 4.27013 12.2 4.85002 12.2C5.42992 12.2 5.90002 11.7299 5.90002 11.15V4.85002C5.90002 4.27013 5.42992 3.80002 4.85002 3.80002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.70002 4.85003C8.70002 3.49693 9.79693 2.40002 11.15 2.40002C12.5031 2.40002 13.6 3.49693 13.6 4.85002V11.15C13.6 12.5031 12.5031 13.6 11.15 13.6C9.79693 13.6 8.70002 12.5031 8.70002 11.15V4.85003ZM11.15 3.80002C10.5701 3.80002 10.1 4.27013 10.1 4.85003V11.15C10.1 11.7299 10.5701 12.2 11.15 12.2C11.7299 12.2 12.2 11.7299 12.2 11.15V4.85002C12.2 4.27013 11.7299 3.80002 11.15 3.80002Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
