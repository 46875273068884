import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'print';
    sizeManagement?: 'self' | 'parent';
}

export const withPrintIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'print',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 5C6 3.34315 7.34315 2 9 2H15C16.6569 2 18 3.34315 18 5V6C20.2091 6 22 7.79086 22 10V15C22 17.2091 20.2091 19 18 19V20C18 21.6569 16.6569 23 15 23H9C7.34315 23 6 21.6569 6 20V19C3.79086 19 2 17.2091 2 15V10C2 7.79086 3.79086 6 6 6V5ZM8 6H16V5C16 4.44772 15.5523 4 15 4H9C8.44772 4 8 4.44772 8 5V6ZM6 8C4.89543 8 4 8.89543 4 10V15C4 16.1046 4.89543 17 6 17C6 15.3431 7.34315 14 9 14H15C16.6569 14 18 15.3431 18 17C19.1046 17 20 16.1046 20 15V10C20 8.89543 19.1046 8 18 8H6ZM9 16C8.44772 16 8 16.4477 8 17V20C8 20.5523 8.44772 21 9 21H15C15.5523 21 16 20.5523 16 20V17C16 16.4477 15.5523 16 15 16H9Z" fill="currentColor" /><path d="M9 11C9 11.8284 8.32843 12.5 7.5 12.5C6.67157 12.5 6 11.8284 6 11C6 10.1716 6.67157 9.5 7.5 9.5C8.32843 9.5 9 10.1716 9 11Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.8 3.1C3.8 1.9402 4.7402 1 5.9 1H10.1C11.2598 1 12.2 1.9402 12.2 3.1L12.2 3.8C13.7464 3.8 15 5.0536 15 6.6V10.1C15 11.6464 13.7464 12.9 12.2 12.9L12.2 13.6C12.2 14.7598 11.2598 15.7 10.1 15.7H5.9C4.7402 15.7 3.8 14.7598 3.8 13.6L3.8 12.9C2.2536 12.9 1 11.6464 1 10.1V6.6C1 5.0536 2.2536 3.8 3.8 3.8V3.1ZM5.2 3.8H10.8V3.1C10.8 2.7134 10.4866 2.4 10.1 2.4H5.9C5.5134 2.4 5.2 2.7134 5.2 3.1V3.8ZM3.8 5.2C3.0268 5.2 2.4 5.8268 2.4 6.6V10.1C2.4 10.8732 3.0268 11.5 3.8 11.5C3.8 10.3402 4.7402 9.4 5.9 9.4H10.1C11.2598 9.4 12.2 10.3402 12.2 11.5C12.9732 11.5 13.6 10.8732 13.6 10.1V6.6C13.6 5.8268 12.9732 5.2 12.2 5.2H3.8ZM5.9 10.8C5.5134 10.8 5.2 11.1134 5.2 11.5V13.6C5.2 13.9866 5.5134 14.3 5.9 14.3H10.1C10.4866 14.3 10.8 13.9866 10.8 13.6V11.5C10.8 11.1134 10.4866 10.8 10.1 10.8H5.9Z" fill="currentColor" /><path d="M5.9 7.3C5.9 7.8799 5.4299 8.35 4.85 8.35C4.2701 8.35 3.8 7.8799 3.8 7.3C3.8 6.7201 4.2701 6.25 4.85 6.25C5.4299 6.25 5.9 6.7201 5.9 7.3Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
