import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowUpAndDown';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowUpAndDownIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowUpAndDown',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 5C17.5523 5 18 5.44772 18 6V15C18 15.6812 17.884 16.4784 17.731 17.2336L17.8742 17.3772C17.914 17.3316 17.9506 17.2819 17.9832 17.2281C18.4798 16.4097 19.11 15.4805 19.7422 14.8436L20.2929 14.2929C20.6834 13.9024 21.3166 13.9024 21.7071 14.2929C22.0955 14.6813 22.0976 15.3097 21.7134 15.7007L17.7071 19.7071C17.3677 20.0465 16.845 20.0909 16.4576 19.8404C16.3992 19.8026 16.344 19.7582 16.2929 19.7071L12.2929 15.7071C11.9024 15.3166 11.9024 14.6834 12.2929 14.2929C12.6825 13.9033 13.3135 13.9024 13.7042 14.2901L13.7071 14.2929L14.2426 14.8284C14.8798 15.4657 15.5161 16.4031 16.0166 17.228C16.0493 17.2819 16.0859 17.3316 16.1258 17.3773L16.269 17.2336C16.116 16.4784 16 15.6812 16 15L16 6C16 5.44772 16.4477 5 17 5Z" fill="currentColor" /><path d="M7 19C7.55229 19 8 18.5523 8 18L8 9C8 8.3188 7.88399 7.52159 7.731 6.76642L7.87416 6.62276C7.91402 6.6684 7.95057 6.71812 7.98322 6.77194C8.47979 7.5903 9.11 8.51947 9.74216 9.15635L10.2929 9.70708C10.6834 10.0976 11.3166 10.0976 11.7071 9.70708C12.0955 9.31868 12.0976 8.69027 11.7134 8.29926L7.70707 4.29289C7.36766 3.95348 6.84495 3.90906 6.45756 4.15963C6.39924 4.19736 6.34399 4.24177 6.29289 4.29287L2.29289 8.29287C1.90237 8.6834 1.90237 9.31656 2.29289 9.70708C2.68246 10.0967 3.31349 10.0976 3.70424 9.70994L3.70711 9.70708L4.24261 9.17157C4.87983 8.53435 5.51613 7.59687 6.01665 6.77196C6.04932 6.71811 6.0859 6.66836 6.12578 6.62269L6.269 6.76642C6.11601 7.52159 6 8.3188 6 9L6 18C6 18.5523 6.44772 19 7 19Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 3.09985C11.8866 3.09985 12.2 3.41326 12.2 3.79986V10.0999C12.2 10.5767 12.1187 11.1347 12.0117 11.6633L12.112 11.764C12.1399 11.7321 12.1654 11.6974 12.1883 11.6597C12.5358 11.0869 12.977 10.4365 13.4195 9.9907L13.805 9.60514C14.0784 9.33177 14.5216 9.33177 14.795 9.60514C15.0668 9.877 15.0683 10.3169 14.7995 10.5906L11.995 13.3951C11.7584 13.6316 11.3948 13.6635 11.124 13.4907C11.0818 13.4638 11.0419 13.4319 11.005 13.3951L8.20503 10.5951C7.93166 10.3217 7.93166 9.87851 8.20503 9.60514C8.47771 9.33246 8.91939 9.33177 9.19292 9.60309L9.19497 9.60514L9.56982 9.98C10.0159 10.4261 10.4613 11.0823 10.8117 11.6597C10.8345 11.6974 10.8601 11.7322 10.888 11.7641L10.9883 11.6635C10.8812 11.1348 10.8 10.5767 10.8 10.0999V3.79986C10.8 3.41326 11.1134 3.09985 11.5 3.09985Z" fill="currentColor" /><path d="M4.49995 12.9001C4.88655 12.9001 5.19995 12.5867 5.19995 12.2001L5.19995 5.90015C5.19995 5.42332 5.11875 4.86529 5.01166 4.33668L5.11202 4.23596C5.13988 4.26787 5.16543 4.30264 5.18825 4.34026C5.53584 4.91309 5.97697 5.56348 6.41946 6.0093L6.80503 6.39486C7.07839 6.66823 7.52161 6.66823 7.79497 6.39486C8.06684 6.123 8.06833 5.68314 7.79946 5.40943L4.99495 2.60493C4.75843 2.3684 4.39475 2.33653 4.12396 2.50929C4.08178 2.5362 4.04186 2.56808 4.00502 2.60491L1.20503 5.40491C0.931658 5.67828 0.931658 6.12149 1.20503 6.39486C1.47771 6.66755 1.91939 6.66823 2.19292 6.39691L2.19497 6.39486L2.56982 6.02C3.01588 5.57394 3.46129 4.91771 3.81165 4.34028C3.8345 4.30261 3.86008 4.26781 3.88798 4.23586L3.98828 4.33652C3.88117 4.86517 3.79995 5.42327 3.79995 5.90015L3.79995 12.2001C3.79995 12.5867 4.11335 12.9001 4.49995 12.9001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
