import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'travelWiFi';
    sizeManagement?: 'self' | 'parent';
}

export const withTravelWiFiIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'travelWiFi',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.3197 4.79704C15.7169 5.14734 15.7185 5.75304 15.358 6.14105L15.2868 6.21763C14.8934 6.64116 14.2265 6.63913 13.7827 6.26865C13.1186 5.71427 12.3766 5.25731 11.5788 4.91328C10.4534 4.42796 9.24063 4.17762 8.015 4.17762C6.78937 4.17762 5.57664 4.42796 4.45119 4.91328C3.65421 5.25696 2.91289 5.71335 2.24931 6.26699C1.80468 6.63795 1.13639 6.63915 0.743292 6.21397L0.640767 6.10307C0.281055 5.714 0.284311 5.10793 0.682963 4.75886C1.56684 3.98492 2.56912 3.35433 3.65351 2.89211C5.02786 2.30629 6.50601 2.0029 8 2C9.49641 2.01092 10.9754 2.32187 12.3495 2.91446C13.4344 3.38231 14.4365 4.01821 15.3197 4.79704ZM12.6237 9.0672C12.2317 9.49119 11.5662 9.48706 11.0935 9.1555C10.7913 8.94356 10.4672 8.76279 10.1263 8.61658C9.45757 8.32985 8.73758 8.182 8.01 8.182C7.28242 8.182 6.56243 8.32985 5.89373 8.61658C5.55387 8.76231 5.23087 8.94237 4.92952 9.15341C4.45544 9.48543 3.78794 9.48838 3.39648 9.06206L3.29115 8.94735C2.93574 8.56029 2.93638 7.95788 3.34316 7.62523C3.87514 7.1902 4.46648 6.83063 5.10063 6.55804C6.02129 6.16231 7.0129 5.95823 8.015 5.95823C9.01711 5.95823 10.0087 6.16231 10.9294 6.55804C11.5625 6.8302 12.153 7.18909 12.6844 7.62321C13.0923 7.95651 13.092 8.56073 12.7344 8.94754L12.6237 9.0672ZM9.93266 10.6109C10.3627 10.899 10.3485 11.4991 9.99417 11.8764L8.73093 13.2216C8.33523 13.643 7.66573 13.6421 7.27116 13.2197L5.95841 11.8142C5.59504 11.4252 5.59506 10.8048 6.0506 10.5294C6.19672 10.441 6.34915 10.3628 6.50687 10.2954C6.95681 10.103 7.44068 10.0026 7.93 10C8.43829 10.0009 8.94066 10.1092 9.40422 10.3177C9.58874 10.4007 9.76546 10.4989 9.93266 10.6109Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
