import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'eyeOff';
    sizeManagement?: 'self' | 'parent';
}

export const withEyeOffIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'eyeOff',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.6231 14.5018C19.3639 13.4611 19.7745 12.4724 19.9458 12C19.7325 11.412 19.1486 10.0237 18.0182 8.73107C16.7663 7.29962 14.8635 6 12 6C11.3865 6 10.8171 6.05965 10.2888 6.16747L8.66353 4.54221C9.64254 4.20287 10.7508 4 12 4C15.5656 4 17.983 5.65276 19.5237 7.41448C21.0443 9.1533 21.7289 11.0118 21.9081 11.5544C22.0045 11.8467 22.0045 12.1533 21.9081 12.4456C21.7513 12.9206 21.2071 14.4039 20.0539 15.9326L18.6231 14.5018Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.47633 7.41448C2.9557 9.1533 2.27107 11.0118 2.09194 11.5544C1.99547 11.8467 1.99547 12.1533 2.09194 12.4456C2.27107 12.9882 2.9557 14.8467 4.47633 16.5855C6.01697 18.3472 8.43442 20 12 20C14.1066 20 15.8124 19.4231 17.1734 18.5876L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L5.22473 6.63894C4.95518 6.89162 4.70601 7.15184 4.47633 7.41448ZM5.98185 15.2689C7.23367 16.7004 9.13648 18 12 18C13.4777 18 14.6995 17.6539 15.7081 17.1223L13.2934 14.7076C12.9018 14.895 12.4631 15 12 15C10.3431 15 9 13.6569 9 12C9 11.5369 9.10495 11.0982 9.29237 10.7066L6.63953 8.05375C6.40251 8.27283 6.18371 8.50024 5.98184 8.73107C4.85144 10.0237 4.26752 11.412 4.05416 12C4.26752 12.588 4.85144 13.9763 5.98185 15.2689Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.6362 9.75128C13.1547 9.02277 13.4421 8.33065 13.562 8C13.4127 7.58837 13.004 6.61658 12.2127 5.71175C11.3364 4.70973 10.0044 3.8 7.99996 3.8C7.57052 3.8 7.17195 3.84176 6.80212 3.91723L5.66443 2.77955C6.34974 2.54201 7.12553 2.4 7.99996 2.4C10.4959 2.4 12.1881 3.55693 13.2665 4.79013C14.331 6.00731 14.8102 7.30826 14.9356 7.68811C15.0031 7.89268 15.0031 8.10732 14.9356 8.31189C14.8258 8.64439 14.4449 9.68272 13.6377 10.7528L12.6362 9.75128Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M2.73339 4.79014C1.66895 6.00731 1.18971 7.30827 1.06432 7.68811C0.996789 7.89268 0.996789 8.10732 1.06432 8.31189C1.18971 8.69173 1.66895 9.99269 2.73339 11.2099C3.81184 12.4431 5.50406 13.6 7.99996 13.6C9.47459 13.6 10.6687 13.1962 11.6214 12.6113L13.805 14.795C14.0784 15.0683 14.5216 15.0683 14.795 14.795C15.0683 14.5216 15.0683 14.0784 14.795 13.805L2.19497 1.20503C1.92161 0.931658 1.47839 0.931658 1.20503 1.20503C0.931658 1.47839 0.931658 1.92161 1.20503 2.19497L3.25729 4.24724C3.0686 4.42412 2.89417 4.60628 2.73339 4.79014ZM7.99996 12.2C5.9955 12.2 4.66353 11.2903 3.78725 10.2882C2.99597 9.38342 2.58722 8.41162 2.43787 8C2.58722 7.58838 2.99597 6.61658 3.78725 5.71175C3.92857 5.55016 4.08173 5.39097 4.24766 5.23761L6.10466 7.09461C5.97347 7.36875 5.9 7.6758 5.9 8C5.9 9.1598 6.8402 10.1 8 10.1C8.3242 10.1 8.63125 10.0265 8.90539 9.89534L10.5956 11.5856C9.88962 11.9577 9.03433 12.2 7.99996 12.2Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
