import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'linkAlternative';
    sizeManagement?: 'self' | 'parent';
}

export const withLinkAlternativeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'linkAlternative',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.7071 8.29287C16.0976 8.68339 16.0976 9.31655 15.7071 9.70708L9.70708 15.7071C9.31656 16.0976 8.68339 16.0976 8.29287 15.7071C7.90234 15.3166 7.90234 14.6834 8.29287 14.2929L14.2929 8.29287C14.6834 7.90234 15.3166 7.90234 15.7071 8.29287Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.63604 11.2929C3.68341 13.2455 3.68341 16.4113 5.63604 18.364C7.58866 20.3166 10.7545 20.3166 12.7071 18.364L13.4142 17.6569C13.8047 17.2663 14.4379 17.2663 14.8284 17.6569C15.2189 18.0474 15.2189 18.6805 14.8284 19.0711L14.1213 19.7782C11.3876 22.5118 6.95549 22.5118 4.22182 19.7782C1.48815 17.0445 1.48815 12.6123 4.22182 9.87868L4.92893 9.17157C5.31945 8.78105 5.95262 8.78105 6.34314 9.17157C6.73367 9.56209 6.73367 10.1953 6.34314 10.5858L5.63604 11.2929Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9.17157 6.34314C8.78105 5.95262 8.78105 5.31945 9.17157 4.92893L9.87868 4.22182C12.6123 1.48815 17.0445 1.48815 19.7782 4.22182C22.5118 6.95549 22.5118 11.3876 19.7782 14.1213L19.0711 14.8284C18.6805 15.2189 18.0474 15.2189 17.6569 14.8284C17.2663 14.4379 17.2663 13.8047 17.6569 13.4142L18.364 12.7071C20.3166 10.7545 20.3166 7.58866 18.364 5.63604C16.4113 3.68341 13.2455 3.68341 11.2929 5.63604L10.5858 6.34314C10.1953 6.73367 9.56209 6.73367 9.17157 6.34314Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.595 5.40502C10.8684 5.67838 10.8684 6.1216 10.595 6.39497L6.395 10.595C6.12163 10.8683 5.67842 10.8683 5.40505 10.595C5.13168 10.3216 5.13168 9.87838 5.40505 9.60502L9.60505 5.40502C9.87842 5.13165 10.3216 5.13165 10.595 5.40502Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3.54524 7.50498C2.17841 8.87181 2.17841 11.0879 3.54524 12.4547C4.91208 13.8216 7.12816 13.8216 8.49499 12.4547L8.98997 11.9597C9.26333 11.6864 9.70655 11.6864 9.97991 11.9597C10.2533 12.2331 10.2533 12.6763 9.97991 12.9497L9.48494 13.4447C7.57137 15.3582 4.46886 15.3582 2.55529 13.4447C0.641726 11.5311 0.641724 8.4286 2.55529 6.51503L3.05027 6.02005C3.32364 5.74669 3.76685 5.74669 4.04022 6.02005C4.31359 6.29342 4.31359 6.73664 4.04022 7.01L3.54524 7.50498Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6.02011 4.04016C5.74675 3.76679 5.74675 3.32357 6.02011 3.05021L6.51509 2.55523C8.42866 0.641664 11.5312 0.641664 13.4447 2.55523C15.3583 4.4688 15.3583 7.57131 13.4447 9.48488L12.9498 9.97985C12.6764 10.2532 12.2332 10.2532 11.9598 9.97985C11.6864 9.70649 11.6864 9.26327 11.9598 8.9899L12.4548 8.49493C13.8216 7.12809 13.8216 4.91202 12.4548 3.54518C11.088 2.17835 8.87187 2.17835 7.50504 3.54518L7.01006 4.04016C6.7367 4.31352 6.29348 4.31352 6.02011 4.04016Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
