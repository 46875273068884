import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingAlignLeft';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingAlignLeftIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingAlignLeft',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 18C3 17.4477 3.44772 17 4 17H16C16.5523 17 17 17.4477 17 18C17 18.5523 16.5523 19 16 19H4C3.44772 19 3 18.5523 3 18Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 8.00001C1.70001 7.61341 2.01341 7.30001 2.40001 7.30001H8.00001C8.38661 7.30001 8.70001 7.61341 8.70001 8.00001C8.70001 8.38661 8.38661 8.70001 8.00001 8.70001H2.40001C2.01341 8.70001 1.70001 8.38661 1.70001 8.00001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 12.2C1.70001 11.8134 2.01341 11.5 2.40001 11.5H10.8C11.1866 11.5 11.5 11.8134 11.5 12.2C11.5 12.5866 11.1866 12.9 10.8 12.9H2.40001C2.01341 12.9 1.70001 12.5866 1.70001 12.2Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 3.80001C1.70001 3.41341 2.01341 3.10001 2.40001 3.10001H13.6C13.9866 3.10001 14.3 3.41341 14.3 3.80001C14.3 4.18661 13.9866 4.50001 13.6 4.50001H2.40001C2.01341 4.50001 1.70001 4.18661 1.70001 3.80001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
