import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'lock';
    sizeManagement?: 'self' | 'parent';
}

export const withLockIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'lock',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V9C19.2091 9 21 10.7909 21 13V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V13C3 10.7909 4.79086 9 7 9V7ZM7 11C5.89543 11 5 11.8954 5 13V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V13C19 11.8954 18.1046 11 17 11H7ZM15 9H9V7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7V9Z" fill="currentColor" /><path d="M13.5 15.5C13.5 16.3284 12.8284 17 12 17C11.1716 17 10.5 16.3284 10.5 15.5C10.5 14.6716 11.1716 14 12 14C12.8284 14 13.5 14.6716 13.5 15.5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 4.5C4.50001 2.567 6.06701 1 8.00001 1C9.93301 1 11.5 2.567 11.5 4.5V5.9C13.0464 5.9 14.3 7.1536 14.3 8.7V12.2C14.3 13.7464 13.0464 15 11.5 15H4.50001C2.95361 15 1.70001 13.7464 1.70001 12.2V8.7C1.70001 7.1536 2.95361 5.9 4.50001 5.9L4.50001 4.5ZM5.20001 7.3L4.50001 7.3C3.72681 7.3 3.10001 7.9268 3.10001 8.7V12.2C3.10001 12.9732 3.72681 13.6 4.50001 13.6H11.5C12.2732 13.6 12.9 12.9732 12.9 12.2V8.7C12.9 7.9268 12.2732 7.3 11.5 7.3H10.8008C10.8006 7.3 10.8011 7.3 10.8008 7.3C10.8006 7.3 10.7995 7.3 10.7992 7.3L5.20001 7.3ZM10.1 5.9H5.90001V4.5C5.90001 3.3402 6.84021 2.4 8.00001 2.4C9.15981 2.4 10.1 3.3402 10.1 4.5V5.9Z" fill="currentColor" /><path d="M9.05001 10.45C9.05001 11.0299 8.57991 11.5 8.00001 11.5C7.42011 11.5 6.95001 11.0299 6.95001 10.45C6.95001 9.8701 7.42011 9.4 8.00001 9.4C8.57991 9.4 9.05001 9.8701 9.05001 10.45Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
