import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'moreVertical';
    sizeManagement?: 'self' | 'parent';
}

export const withMoreVerticalIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'moreVertical',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19Z" fill="currentColor" /><path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="currentColor" /><path d="M14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.39998 12.9C9.39998 13.6732 8.77317 14.3 7.99998 14.3C7.22678 14.3 6.59998 13.6732 6.59998 12.9C6.59998 12.1268 7.22678 11.5 7.99998 11.5C8.77317 11.5 9.39998 12.1268 9.39998 12.9Z" fill="currentColor" /><path d="M9.39998 8.00001C9.39998 8.77321 8.77317 9.40001 7.99998 9.40001C7.22678 9.40001 6.59998 8.77321 6.59998 8.00001C6.59998 7.22681 7.22678 6.60001 7.99998 6.60001C8.77317 6.60001 9.39998 7.22681 9.39998 8.00001Z" fill="currentColor" /><path d="M9.39998 3.10001C9.39998 3.87321 8.77317 4.50001 7.99998 4.50001C7.22678 4.50001 6.59998 3.87321 6.59998 3.10001C6.59998 2.32681 7.22678 1.70001 7.99998 1.70001C8.77317 1.70001 9.39998 2.32681 9.39998 3.10001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
