import React, { FC, Fragment, useCallback, useMemo, useRef, useState } from 'react';

import useResizeObserver from 'use-resize-observer';

import { Platform } from '../../../utils';
import { cnPageHeader } from '../../PageHeader.cn';
import { IMenuItem, MenuItemClickHandler } from '../../PageHeader.types';
import { SimpleMenuItem } from '../MenuItem';
import { PopupMenuItemWithChildren } from '../MenuItem/PopupMenuItemWithChildren';

import './MainMenu.css';

export interface MainMenuProps {
    platform: Platform;
    items: IMenuItem[];
    moreLabel: string;
    onItemClick?: MenuItemClickHandler;
}

const MENU_ITEM_SELECTOR = '.PageHeader-MainMenuItem';
const MORE_ITEM_ID = '%more%';

export const MainMenu: FC<MainMenuProps> = (props) => {
    const { items, platform, moreLabel, onItemClick } = props;
    const isDesktop = platform === Platform.DESKTOP;
    const [openedPopupId, setOpenedPopup] = useState<string | null>(null);

    const handleShowChildren = useCallback((isVisible, id: string) => {
        if (isVisible) {
            setOpenedPopup(id);
        }
        if (!isVisible && openedPopupId === id) {
            setOpenedPopup(null);
        }
    }, [openedPopupId]);

    const ref = useRef<HTMLDivElement>(null);
    const [primaryCount, setPrimaryCount] = useState<number>(items.length);

    useResizeObserver({ ref, onResize: () => {
        if (ref.current && isDesktop) {
            const list = Array.from(ref.current.querySelectorAll<HTMLDivElement>(`${MENU_ITEM_SELECTOR}`));

            // при изменении размера сохраняем индекс последнего видимого элемента
            const filtered = list.filter(({ offsetTop, offsetHeight }) => offsetTop < offsetHeight);
            setPrimaryCount(filtered.length);
        }
    } });

    const moreItems = useMemo(() => items.slice(primaryCount), [items, primaryCount]);

    const menuItems = useMemo(() => items.map((item, index) => {
        const { id, children } = item;
        const isActive = (children || []).some((item) => item.isActive);

        const thisItem = children ? (
            <PopupMenuItemWithChildren
                direction="bottom-start"
                icon="down"
                isPopupVisible={openedPopupId === item.id}
                setPopupVisible={handleShowChildren}
                items={children}
                isActive={isActive}
                label={item.name}
                onItemClick={onItemClick}
                disabled={index >= primaryCount}
                id={item.id}
                className={cnPageHeader('MainMenuItem')}
            />
        ) : (
            <SimpleMenuItem
                className={cnPageHeader('MainMenuItem')}
                item={item}
                onClick={onItemClick}
                disabled={index >= primaryCount}
            />
        );

        // если не все элементы поместились в шапку, то добавляем
        // пункт "Ещё" после последнего видимого элемента пункт
        const moreItem = (moreItems.length && index === primaryCount - 1) ? (
            <PopupMenuItemWithChildren
                direction="bottom-start"
                icon="down"
                id={MORE_ITEM_ID}
                isPopupVisible={openedPopupId === MORE_ITEM_ID}
                setPopupVisible={handleShowChildren}
                className={cnPageHeader('More')}
                items={moreItems}
                label={moreLabel}
                onItemClick={onItemClick}
            />
        ) : null;

        return <Fragment key={id}>{thisItem}{moreItem}</Fragment>;
    }), [items, openedPopupId, handleShowChildren, onItemClick, primaryCount, moreItems, moreLabel]);

    return (
        <div className={cnPageHeader('MainMenu')}>
            <div className={cnPageHeader('MainMenuBody')} ref={ref}>
                {menuItems}
            </div>
        </div>
    );
};
