import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'bookmark';
    sizeManagement?: 'self' | 'parent';
}

export const withBookmarkIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'bookmark',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V18.9425C20 20.6114 18.0766 21.5462 16.7644 20.5152L12 16.7717L7.23564 20.5152C5.92338 21.5462 4 20.6114 4 18.9425V6ZM8 4C6.89543 4 6 4.89543 6 6V18.9425L10.7644 15.1991C11.4896 14.6293 12.5104 14.6293 13.2356 15.1991L18 18.9425V6C18 4.89543 17.1046 4 16 4H8Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.40002 3.8C2.40002 2.2536 3.65363 1 5.20002 1H10.8C12.3464 1 13.6 2.2536 13.6 3.8V12.8598C13.6 14.028 12.2537 14.6824 11.3351 13.9606L8.00002 11.3402L4.66498 13.9606C3.7464 14.6824 2.40002 14.028 2.40002 12.8598V3.8ZM5.20002 2.4C4.42683 2.4 3.80002 3.0268 3.80002 3.8V12.8598L7.13507 10.2394C7.64273 9.84051 8.35732 9.84051 8.86497 10.2394L12.2 12.8598V3.8C12.2 3.0268 11.5732 2.4 10.8 2.4H5.20002Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
