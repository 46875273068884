import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'travelCar';
    sizeManagement?: 'self' | 'parent';
}

export const withTravelCarIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'travelCar',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C10.5279 4 9.21242 4.15984 8.19457 4.34129C7.42866 4.47783 6.78265 5.24037 6.62008 6.33289C6.46579 7.36981 6.16985 8.65496 5.60511 10H18.3949C17.8301 8.65496 17.5342 7.36981 17.3799 6.33289C17.2173 5.24037 16.5713 4.47783 15.8054 4.34129C14.7876 4.15984 13.472 4 12 4ZM19 12H18V14C18 14.5523 17.5523 15 17 15C16.4477 15 16 14.5523 16 14V12H7.99999V14C7.99999 14.5523 7.55227 15 6.99999 15C6.4477 15 5.99999 14.5523 5.99999 14V12H4.99999C4.4477 12 3.99999 12.4477 3.99999 13V17H20V13C20 12.4477 19.5523 12 19 12ZM18.1529 19L18.5136 21.1644C18.594 21.6466 19.0111 22 19.5 22H21C21.5523 22 22 21.5523 22 21V13C22 11.9142 21.3519 11.3503 20.8682 10.5039C20.5814 10.0019 20.3437 9.49627 20.1468 8.99997H21C21.5523 8.99997 22 8.55225 22 7.99997C22 7.44769 21.5523 6.99997 21 6.99997H19.5359C19.4609 6.66158 19.4028 6.33918 19.3581 6.03853C19.1205 4.44165 18.0673 2.71299 16.1564 2.37233C15.0486 2.17485 13.6134 2 12 2C10.3866 2 8.95136 2.17485 7.84357 2.37233C5.93268 2.71299 4.87948 4.44165 4.64187 6.03853C4.59713 6.3392 4.53908 6.66161 4.46409 7.00003H2.99997C2.44768 7.00003 1.99997 7.44774 1.99997 8.00003C1.99997 8.55231 2.44768 9.00003 2.99997 9.00003H3.85319C3.6563 9.49631 3.41857 10.0019 3.13174 10.5039C2.64808 11.3503 1.99999 11.9142 1.99999 13V21C1.99999 21.5523 2.4477 22 2.99999 22H4.49999C4.98883 22 5.40602 21.6466 5.48638 21.1644L5.84711 19H18.1529Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 2.4C6.96957 2.4 6.0487 2.51189 5.33621 2.6389C4.80007 2.73448 4.34787 3.26826 4.23407 4.03302C4.12606 4.75886 3.9189 5.65847 3.52359 6.6H12.4764C12.0811 5.65847 11.8739 4.75886 11.7659 4.03302C11.6521 3.26826 11.1999 2.73448 10.6638 2.6389C9.9513 2.51189 9.03043 2.4 8 2.4ZM12.9 8H12.2V9.4C12.2 9.7866 11.8866 10.1 11.5 10.1C11.1134 10.1 10.8 9.7866 10.8 9.4V8H5.2V9.4C5.2 9.7866 4.8866 10.1 4.5 10.1C4.1134 10.1 3.8 9.7866 3.8 9.4V8H3.1C2.7134 8 2.4 8.3134 2.4 8.7V11.5H13.6V8.7C13.6 8.3134 13.2866 8 12.9 8ZM12.307 12.9L12.5595 14.4151C12.6158 14.7526 12.9078 15 13.25 15H14.3C14.6866 15 15 14.6866 15 14.3L15 8.7C15 7.93994 14.5463 7.54519 14.2078 6.9527C14.007 6.60134 13.8406 6.2474 13.7027 5.9H14.3C14.6866 5.9 15 5.5866 15 5.2C15 4.8134 14.6866 4.5 14.3 4.5H13.2751C13.2226 4.26312 13.182 4.03743 13.1507 3.82697C12.9844 2.70916 12.2471 1.49909 10.9095 1.26063C10.134 1.12239 9.12938 1 8 1C6.87062 1 5.86596 1.12239 5.09051 1.26063C3.75289 1.49909 3.01565 2.70916 2.84932 3.82697C2.818 4.03743 2.77737 4.26312 2.72488 4.5H1.7C1.3134 4.5 1 4.8134 1 5.2C1 5.5866 1.3134 5.9 1.7 5.9H2.29725C2.15942 6.2474 1.99301 6.60134 1.79223 6.9527C1.45367 7.54519 1 7.93994 1 8.7V14.3C1 14.6866 1.3134 15 1.7 15H2.75C3.09219 15 3.38422 14.7526 3.44048 14.4151L3.69299 12.9H12.307Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
