import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'dislike';
    sizeManagement?: 'self' | 'parent';
}

export const withDislikeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'dislike',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.6786 21.4675C13.5054 21.7951 13.1652 22 12.7946 22C10.7918 22 9.05308 20.3668 9.05101 18.3037L9.03197 15.5H4.85409C3.19378 15.5148 1.76445 14.0143 2.03164 12.2779L2.033 12.2691L3.32033 4.37291C3.53752 2.98077 4.77657 1.98821 6.14184 2.0001H19.1962C19.1962 2.0001 19.1962 2.0001 19.1962 2.0001C20.7084 2.00013 22.0252 3.23163 22.0252 4.8001V11.2001C22.0252 12.7686 20.7084 14.0001 19.1962 14.0001L17.6276 14L13.6786 21.4675ZM18.0252 12L19.1962 12.0001C19.1962 12.0001 19.1962 12.0001 19.1962 12.0001C19.6517 12.0001 20.0252 11.6167 20.0252 11.2001V4.8001C20.0252 4.3835 19.6517 4.0001 19.1962 4.0001L18.0252 4.00005V12ZM16.0252 4.00001L6.12613 4.0001C5.71389 3.99562 5.35422 4.30649 5.29622 4.68254L5.29491 4.69101L4.00785 12.5856C3.94128 13.0339 4.34052 13.5056 4.83794 13.5001L4.8491 13.4999L10.0252 13.5C10.5748 13.5 11.0215 13.9436 11.0252 14.4932L11.051 18.3C11.051 19.0272 11.5563 19.6747 12.2409 19.9079L16.0252 12.7519V4.00001Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.17496 14.6272C9.05369 14.8565 8.81556 15 8.55616 15C7.1542 15 5.93707 13.8567 5.93564 12.4126L5.92231 10.45H2.99777C1.83557 10.4603 0.835048 9.41001 1.02208 8.19454L1.02303 8.18836L1.92417 2.66097C2.07623 1.68653 2.94351 0.991776 3.89916 1.00007H13.0373C13.0373 1.00007 13.0373 1.00007 13.0373 1.00007C14.0958 1.00009 15.0176 1.86214 15.0176 2.96007V7.44007C15.0176 8.53804 14.0958 9.40007 13.0373 9.40007L11.9392 9.40002L9.17496 14.6272ZM12.2176 8.00003L13.0373 8.00007C13.0373 8.00007 13.0373 8.00007 13.0373 8.00007C13.3561 8.00005 13.6176 7.73169 13.6176 7.44007V2.96007C13.6176 2.66845 13.3561 2.40007 13.0373 2.40007L12.2176 2.40003V8.00003ZM10.8176 2.40001L3.88822 2.40007C3.59965 2.39693 3.34789 2.61454 3.30729 2.87778L3.30637 2.88371L2.40543 8.40988C2.35883 8.72374 2.63829 9.05393 2.98649 9.05004L2.99431 9.04996L6.61758 9.05C7.00232 9.05 7.31495 9.36051 7.31756 9.74525L7.33562 12.4053L7.33564 12.41C7.33564 12.9191 7.68936 13.3723 8.16856 13.5356L10.8176 8.52631V2.40001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
