import React, { FC, ReactNode } from 'react';

import { IClassNameProps } from '@bem-react/core';
import { Header as LegoHeader } from '@yandex-lego/components/Header/desktop';

import { MenuProps } from '../Menu/desktop/bundle';
import { Platform } from '../utils';
import { cnPageHeader } from './PageHeader.cn';
import { Burger } from './PageHeader.components/Burger/Burger';
import { Dropdown } from './PageHeader.components/Dropdown/Dropdown';
import { Icon } from './PageHeader.components/Icon/Icon';
import { MainMenu } from './PageHeader.components/MainMenu/MainMenu';
import { TabsMenu } from './PageHeader.components/TabsMenu/TabsMenu';
import { User } from './PageHeader.components/User/User';
import { HEADER_MAIN_OFFSET } from './PageHeader.constants';
import {
    IMenuItem,
    ITabsMenuItem,
    MenuItemClickHandler,
    MenuType,
    TabsMenuItemClickHandler,
} from './PageHeader.types';

import './PageHeader.css';

export { Dropdown } from './PageHeader.components/Dropdown/Dropdown';

export interface IPageHeaderProps extends IClassNameProps {
    login?: string;
    desktopLogo: ReactNode;
    mobileLogo: ReactNode;
    platform: Platform;

    menuType?: MenuType;
    labelServices?: string;
    labelMore?: string;
    items?: IMenuItem[];
    tabs?: ITabsMenuItem[];
    services?: ReactNode;
    actions?: ReactNode[];
    popupActions?: MenuProps['items'];
    actionButton?: ReactNode;

    onMenuItemClick?: MenuItemClickHandler;
    onTabsMenuItemClick?: TabsMenuItemClickHandler;
}

export const IconGrid = (className: string) => (
    <Icon
        className={className}
        size="l"
        glyph="gridDots"
        sizeManagement="parent"
    />
);

export const PageHeader: FC<IPageHeaderProps> = (props) => {
    const {
        platform,
        desktopLogo,
        mobileLogo,
        login,
        tabs,
        items,
        menuType = MenuType.BURGER,
        services,
        actions,
        actionButton,
        popupActions,
        labelMore = 'MORE',
        labelServices = 'SERVICES',
        onMenuItemClick,
        onTabsMenuItemClick,
        className,
    } = props;

    const burger =
        menuType === MenuType.BURGER && (items?.length || actionButton) && platform === Platform.MOBILE ? (
            <Burger
                items={items}
                onItemClick={onMenuItemClick}
                actionButton={actionButton}
            />
        ) : null;

    const headerLogo = (
        <>
            {burger}
            {platform === Platform.DESKTOP ? desktopLogo : mobileLogo}
        </>
    );

    const headerMenu =
        items?.length && platform === Platform.DESKTOP ? (
            <MainMenu
                platform={platform}
                items={items}
                moreLabel={labelMore}
                onItemClick={onMenuItemClick}
            />
        ) : null;

    const tabsMenu =
        menuType === MenuType.TABS && tabs?.length && platform === Platform.MOBILE ? (
            <TabsMenu
                items={tabs}
                onItemClick={onTabsMenuItemClick}
            />
        ) : null;

    const user = login ? (
        <User items={popupActions} key="User" login={login} />
    ) : null;
    const buttonServices = services ? (
        <Dropdown
            direction="bottom-end"
            icon={IconGrid}
            title={labelServices}
            buttonClassName={cnPageHeader('ServicesButton')}
            popupClassName={cnPageHeader('ServicesPopup')}
            platform={platform}
            mainOffset={HEADER_MAIN_OFFSET}
        >
            {services}
        </Dropdown>
    ) : null;

    const headerButton =
        platform === Platform.DESKTOP ? (
            <div className={cnPageHeader('HeaderButton')}>{actionButton}</div>
        ) : null;

    const additional = (
        <>
            {headerButton}
            {actions}
            {buttonServices}
            {user}
        </>
    );

    if (menuType === MenuType.TABS && platform === Platform.MOBILE) {
        return (
            <div className={cnPageHeader({ platform }, [className])}>
                <LegoHeader
                    className={cnPageHeader('Lego')}
                    logo={headerLogo}
                    actions={additional}
                />
                {tabsMenu}
            </div>
        );
    }

    return (
        <LegoHeader
            className={cnPageHeader({ platform }, [className])}
            logo={headerLogo}
            actions={additional}
        >
            {headerMenu}
        </LegoHeader>
    );
};
