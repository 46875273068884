import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'dragAndDrop';
    sizeManagement?: 'self' | 'parent';
}

export const withDragAndDropIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'dragAndDrop',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 5C10.5 6.10457 9.60457 7 8.5 7C7.39543 7 6.5 6.10457 6.5 5C6.5 3.89543 7.39543 3 8.5 3C9.60457 3 10.5 3.89543 10.5 5Z" fill="currentColor" /><path d="M17.5 5C17.5 6.10457 16.6046 7 15.5 7C14.3954 7 13.5 6.10457 13.5 5C13.5 3.89543 14.3954 3 15.5 3C16.6046 3 17.5 3.89543 17.5 5Z" fill="currentColor" /><path d="M10.5 12C10.5 13.1046 9.60457 14 8.5 14C7.39543 14 6.5 13.1046 6.5 12C6.5 10.8954 7.39543 10 8.5 10C9.60457 10 10.5 10.8954 10.5 12Z" fill="currentColor" /><path d="M17.5 12C17.5 13.1046 16.6046 14 15.5 14C14.3954 14 13.5 13.1046 13.5 12C13.5 10.8954 14.3954 10 15.5 10C16.6046 10 17.5 10.8954 17.5 12Z" fill="currentColor" /><path d="M10.5 19C10.5 20.1046 9.60457 21 8.5 21C7.39543 21 6.5 20.1046 6.5 19C6.5 17.8954 7.39543 17 8.5 17C9.60457 17 10.5 17.8954 10.5 19Z" fill="currentColor" /><path d="M17.5 19C17.5 20.1046 16.6046 21 15.5 21C14.3954 21 13.5 20.1046 13.5 19C13.5 17.8954 14.3954 17 15.5 17C16.6046 17 17.5 17.8954 17.5 19Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.95002 3.10001C6.95002 3.87321 6.32322 4.50001 5.55002 4.50001C4.77683 4.50001 4.15002 3.87321 4.15002 3.10001C4.15002 2.32681 4.77683 1.70001 5.55002 1.70001C6.32322 1.70001 6.95002 2.32681 6.95002 3.10001Z" fill="currentColor" /><path d="M11.85 3.10001C11.85 3.87321 11.2232 4.50001 10.45 4.50001C9.67683 4.50001 9.05002 3.87321 9.05002 3.10001C9.05002 2.32681 9.67683 1.70001 10.45 1.70001C11.2232 1.70001 11.85 2.32681 11.85 3.10001Z" fill="currentColor" /><path d="M6.95002 8.00001C6.95002 8.77321 6.32322 9.40001 5.55002 9.40001C4.77683 9.40001 4.15002 8.77321 4.15002 8.00001C4.15002 7.22681 4.77683 6.60001 5.55002 6.60001C6.32322 6.60001 6.95002 7.22681 6.95002 8.00001Z" fill="currentColor" /><path d="M11.85 8.00001C11.85 8.77321 11.2232 9.40001 10.45 9.40001C9.67683 9.40001 9.05002 8.77321 9.05002 8.00001C9.05002 7.22681 9.67683 6.60001 10.45 6.60001C11.2232 6.60001 11.85 7.22681 11.85 8.00001Z" fill="currentColor" /><path d="M6.95002 12.9C6.95002 13.6732 6.32322 14.3 5.55002 14.3C4.77683 14.3 4.15002 13.6732 4.15002 12.9C4.15002 12.1268 4.77683 11.5 5.55002 11.5C6.32322 11.5 6.95002 12.1268 6.95002 12.9Z" fill="currentColor" /><path d="M11.85 12.9C11.85 13.6732 11.2232 14.3 10.45 14.3C9.67683 14.3 9.05002 13.6732 9.05002 12.9C9.05002 12.1268 9.67683 11.5 10.45 11.5C11.2232 11.5 11.85 12.1268 11.85 12.9Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
