import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapPlane';
    sizeManagement?: 'self' | 'parent';
}

export const withGapPlaneIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapPlane',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0531 6.40377L12.7446 12.234C12.779 12.3527 12.7461 12.4807 12.6588 12.568L11.8399 13.3869C11.678 13.5488 11.4053 13.5068 11.2997 13.3036L8.85469 8.60217L6.51926 10.9376L6.8742 13.302C6.89016 13.4083 6.85473 13.5159 6.7787 13.5919L6.46986 13.9007C6.31657 14.054 6.06074 14.0259 5.94435 13.843L4.47148 11.5285L2.15696 10.0557C1.97406 9.93926 1.94598 9.68343 2.09927 9.53014L2.40815 9.22126C2.48415 9.14526 2.59171 9.10982 2.698 9.12576L5.06288 9.48026L7.39831 7.14482L2.69646 4.70033C2.49324 4.59467 2.4512 4.32201 2.61316 4.16004L3.43197 3.34123C3.51934 3.25386 3.64735 3.22097 3.76601 3.2554L9.59623 4.9469L12.2414 2.30173C12.4346 2.10853 12.6966 2 12.9698 2C13.2431 2 13.5051 2.10853 13.6983 2.30173C13.8915 2.49492 14 2.75694 14 3.03016C14 3.30337 13.8915 3.5654 13.6983 3.75859L11.0531 6.40377Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
