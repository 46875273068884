import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingImage';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingImageIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingImage',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7ZM19 8.98787V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 17.8823 5.57134 18.6312 6.36423 18.8968L7.27833 16.6116C7.9226 15.0009 9.80587 14.2849 11.3575 15.0607C11.8934 15.3286 12.5414 15.0487 12.7135 14.4748L13.5989 11.5235C14.3142 9.13912 16.9101 8.09336 19 8.98787ZM8.47703 19L9.13529 17.3544C9.345 16.8301 9.958 16.597 10.463 16.8496C12.1094 17.6728 14.1003 16.8126 14.6292 15.0495L15.5146 12.0982C15.9494 10.6486 17.7743 10.1886 18.8444 11.2587L19 11.4142V17C19 18.1046 18.1046 19 17 19H8.47703Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.30001 5.9C7.30001 6.6732 6.67321 7.3 5.90001 7.3C5.12681 7.3 4.50001 6.6732 4.50001 5.9C4.50001 5.1268 5.12681 4.5 5.90001 4.5C6.67321 4.5 7.30001 5.1268 7.30001 5.9Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 4.49999C1.70001 2.9536 2.95362 1.7 4.50001 1.7H11.5C13.0464 1.7 14.3 2.9536 14.3 4.5V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.50001C2.95361 14.3 1.70001 13.0464 1.70001 11.5V4.49999ZM12.9 5.89151V4.5C12.9 3.7268 12.2732 3.1 11.5 3.1H4.50001C3.72681 3.1 3.10001 3.7268 3.10001 4.49999V11.5C3.10001 12.1176 3.49995 12.6418 4.05497 12.8278L4.69485 11.2281C5.14583 10.1006 6.46412 9.59943 7.55024 10.1425C7.92537 10.3301 8.37897 10.1341 8.49949 9.73234L9.11926 7.66643C9.61997 5.99738 11.4371 5.26535 12.9 5.89151ZM5.53393 12.9L5.99471 11.748C6.14151 11.3811 6.57061 11.2179 6.92414 11.3947C8.07663 11.9709 9.47019 11.3688 9.84044 10.1346L10.4602 8.06872C10.7646 7.05405 12.0421 6.73199 12.7911 7.48106L12.9 7.58995V11.5C12.9 12.2732 12.2732 12.9 11.5 12.9H5.53393Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
