import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowShortLeft';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowShortLeftIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowShortLeft',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.52966 9.71842C8.82255 10.0113 8.82255 10.4862 8.52966 10.7791C8.23676 11.072 7.76189 11.072 7.469 10.7791L3.2197 6.52978C3.10584 6.41592 3.03624 6.27456 3.0109 6.12708C2.97101 5.89514 3.0406 5.64804 3.21967 5.46897L7.46897 1.21967C7.76186 0.926777 8.23674 0.926777 8.52963 1.21967C8.82252 1.51256 8.82252 1.98744 8.52963 2.28033L4.8106 5.99936L8.52966 9.71842Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.595 2.60502C10.8683 2.87839 10.8683 3.3216 10.595 3.59497L6.18995 7.99999L10.595 12.405C10.8683 12.6784 10.8683 13.1216 10.595 13.395C10.3216 13.6683 9.87839 13.6683 9.60502 13.395L4.70503 8.49497C4.43166 8.2216 4.43166 7.77839 4.70503 7.50502L9.60502 2.60502C9.87839 2.33165 10.3216 2.33165 10.595 2.60502Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
