import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapEducation';
    sizeManagement?: 'self' | 'parent';
}

export const withGapEducationIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapEducation',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.21465 6.50794C0.484033 6.1098 0.484032 5.4643 1.21465 5.06616L6.67712 2.0895C7.40774 1.69136 8.5923 1.69136 9.32292 2.08949L14.7854 5.06616C15.516 5.4643 15.516 6.1098 14.7854 6.50794L9.32292 9.4846C8.5923 9.88274 7.40774 9.88274 6.67712 9.4846L1.21465 6.50794Z" fill="currentColor" /><path d="M2.89502 8.51312C2.87635 8.65321 2.86669 8.79648 2.86669 8.94221V11.0721C2.86669 11.8789 3.26335 12.6239 3.9061 13.0241C6.4429 14.604 9.55714 14.604 12.0939 13.0241C12.7367 12.6239 13.1334 11.8789 13.1334 11.0721V8.94221C13.1334 8.79648 13.1237 8.65321 13.105 8.51312L9.65088 10.3954C9.15711 10.6644 8.55859 10.7784 8.00002 10.7784C7.44145 10.7784 6.84293 10.6644 6.34916 10.3954L2.89502 8.51312Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
