import { useContext } from 'react';

import { hostsContext } from '../Context/HostsContext';
import { FormatUrlFn, Host } from './types';

interface getProfileUrlProps {
    login: string;
    host: Host;
}

interface getCenterAvatarUrlProps {
    login: string;
    host: Host;
}

export const getCenterAvatarUrl = (props: getCenterAvatarUrlProps) => {
    const { login, host } = props;

    return {
        url: formatUrl(host, `/api/v1/user/${login}/avatar/85.jpg`),
    };
};

export const getProfileUrl = (props: getProfileUrlProps) => {
    const { login, host } = props;

    return {
        url: formatUrl(host, `/${login}`),
    };
};

interface useProfileUrlProps {
    login: string;
}

interface useCenterAvatarUrlProps {
    login: string;
}

export const useProfileUrl = (props: useProfileUrlProps) => {
    const { login } = props;
    const { getHost } = useContext(hostsContext);

    return getProfileUrl({ login, host: getHost('staff') });
};

export const useCenterAvatarUrl = (props: useCenterAvatarUrlProps) => {
    const { login } = props;
    const { getHost } = useContext(hostsContext);

    return getCenterAvatarUrl({ login, host: getHost('center') });
};

export const formatUrl: FormatUrlFn = (host, pathname = '/', query) => {
    const { protocol = 'https', host: hostname, port } = host;

    let baseUrl = `${protocol}://${hostname}`;

    if (port) {
        baseUrl += `:${port}`;
    }

    if (query) {
        const searchParams = new URLSearchParams(query);

        return new URL(`${pathname}?${searchParams}`, baseUrl).toString();
    }

    return new URL(pathname, baseUrl).toString();
};
