import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'crown';
    sizeManagement?: 'self' | 'parent';
}

export const withCrownIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'crown',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 2C12.3952 2 12.7533 2.23273 12.9138 2.59386L14.9551 7.18682C15.5569 8.54081 17.3706 8.79318 18.3192 7.65492L20.2318 5.35982C20.5097 5.02631 20.971 4.91022 21.3737 5.07246C21.7764 5.23471 22.0284 5.63822 21.9974 6.07125L21.1965 17.285C21.0469 19.3782 19.3052 21 17.2066 21H6.79334C4.69479 21 2.95302 19.3782 2.80351 17.285L2.00253 6.07125C1.9716 5.63822 2.22359 5.23471 2.62626 5.07246C3.02893 4.91022 3.49029 5.02631 3.76821 5.35982L5.68079 7.65492C6.62935 8.79318 8.44309 8.54081 9.04486 7.18682L11.0862 2.59386C11.2467 2.23273 11.6048 2 12 2ZM12 5.46221L10.8725 7.9991C9.68441 10.6723 6.13433 11.1985 4.21837 9.02171L4.79843 17.1425C4.87318 18.1891 5.74407 19 6.79334 19H17.2066C18.2559 19 19.1268 18.1891 19.2015 17.1425L19.7816 9.02172C17.8656 11.1985 14.3156 10.6722 13.1275 7.9991L12 5.46221Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 1C8.27664 1 8.52733 1.16291 8.63968 1.4157L10.0686 4.63078C10.4898 5.57857 11.7595 5.75523 12.4234 4.95844L13.7623 3.35187C13.9568 3.11842 14.2798 3.03715 14.5616 3.15072C14.8435 3.26429 15.0199 3.54676 14.9982 3.84987L14.4375 11.6995C14.3329 13.1647 13.1137 14.3 11.6447 14.3H4.35536C2.88637 14.3 1.66714 13.1647 1.56248 11.6995L1.00179 3.84987C0.980139 3.54676 1.15653 3.26429 1.4384 3.15072C1.72027 3.03715 2.04322 3.11842 2.23777 3.35187L3.57658 4.95844C4.24056 5.75523 5.51018 5.57857 5.93142 4.63078L7.36034 1.4157C7.47269 1.16291 7.72338 1 8.00001 1ZM8.00001 3.42355L7.21076 5.19937C6.37911 7.07058 3.89405 7.43893 2.55288 5.9152L2.95892 11.5997C3.01125 12.3324 3.62087 12.9 4.35536 12.9H11.6447C12.3792 12.9 12.9888 12.3324 13.0411 11.5997L13.4471 5.9152C12.106 7.43893 9.62091 7.07057 8.78927 5.19937L8.00001 3.42355Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
