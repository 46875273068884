import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'anchor';
    sizeManagement?: 'self' | 'parent';
}

export const withAnchorIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'anchor',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8C13.1046 8 14 7.10457 14 6C14 4.89543 13.1046 4 12 4ZM8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6C16 7.86384 14.7252 9.42994 13 9.87398V19.9381C16.6187 19.4869 19.4869 16.6187 19.9381 13H18C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11H21C21.5523 11 22 11.4477 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 11.4477 2.44772 11 3 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H4.06189C4.51314 16.6187 7.38128 19.4869 11 19.9381V9.87398C9.27477 9.42994 8 7.86384 8 6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 2.4C7.2268 2.4 6.6 3.0268 6.6 3.8C6.6 4.5732 7.2268 5.2 8 5.2C8.7732 5.2 9.4 4.5732 9.4 3.8C9.4 3.0268 8.7732 2.4 8 2.4ZM5.2 3.8C5.2 2.2536 6.4536 1 8 1C9.5464 1 10.8 2.2536 10.8 3.8C10.8 5.10469 9.90766 6.20096 8.7 6.51179V13.5567C11.2331 13.2408 13.2408 11.2331 13.5567 8.7L12.2 8.7C11.8134 8.7 11.5 8.3866 11.5 8C11.5 7.6134 11.8134 7.3 12.2 7.3L14.3 7.3C14.6866 7.3 15 7.6134 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 7.6134 1.3134 7.3 1.7 7.3L3.8 7.3C4.1866 7.3 4.5 7.6134 4.5 8C4.5 8.3866 4.1866 8.7 3.8 8.7L2.44333 8.7C2.7592 11.2331 4.7669 13.2408 7.3 13.5567V6.51179C6.09234 6.20096 5.2 5.10469 5.2 3.8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
