import React, { FC } from 'react';

import { cnErrorState } from '../ErrorState.cn';
import { IErrorStateTitleProps } from './ErrorState-Title.types';

import './ErrorState-Title.css';

export const ErrorStateTitle: FC<IErrorStateTitleProps> = (props) => {
    const { title } = props;

    if (!title) {
        return null;
    }

    return (
        <div className={cnErrorState('Title')}>
            {title}
        </div>
    );
};
