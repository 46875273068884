import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'stop';
    sizeManagement?: 'self' | 'parent';
}

export const withStopIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'stop',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 8C4 5.79086 5.79086 4 8 4H16C18.2091 4 20 5.79086 20 8V16C20 18.2091 18.2091 20 16 20H8C5.79086 20 4 18.2091 4 16V8ZM8 6C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V8C18 6.89543 17.1046 6 16 6H8Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.40002 5.20003C2.40002 3.65363 3.65363 2.40002 5.20002 2.40002H10.8C12.3464 2.40002 13.6 3.65363 13.6 5.20002V10.8C13.6 12.3464 12.3464 13.6 10.8 13.6H5.20003C3.65363 13.6 2.40002 12.3464 2.40002 10.8V5.20003ZM5.20002 3.80002C4.42683 3.80002 3.80002 4.42683 3.80002 5.20003V10.8C3.80002 11.5732 4.42683 12.2 5.20003 12.2H10.8C11.5732 12.2 12.2 11.5732 12.2 10.8V5.20002C12.2 4.42683 11.5732 3.80002 10.8 3.80002H5.20002Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
