import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapOffice';
    sizeManagement?: 'self' | 'parent';
}

export const withGapOfficeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapOffice',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.66667 14.6667C1.48257 14.6667 1.33334 14.5175 1.33334 14.3334V13.6667C1.33334 13.4826 1.48257 13.3334 1.66667 13.3334H2.66667V2.66671C2.66667 1.93033 3.26362 1.33337 4 1.33337H12C12.7364 1.33337 13.3333 1.93033 13.3333 2.66671V13.3334H14.3333C14.5174 13.3334 14.6667 13.4826 14.6667 13.6667V14.3334C14.6667 14.5175 14.5174 14.6667 14.3333 14.6667H1.66667ZM5.66667 4.66671C5.48257 4.66671 5.33334 4.81595 5.33334 5.00004V5.66671C5.33334 5.8508 5.48257 6.00004 5.66667 6.00004H7C7.1841 6.00004 7.33334 5.8508 7.33334 5.66671V5.00004C7.33334 4.81595 7.1841 4.66671 7 4.66671H5.66667ZM5.66667 7.33337C5.48257 7.33337 5.33334 7.48261 5.33334 7.66671V8.33337C5.33334 8.51747 5.48257 8.66671 5.66667 8.66671H7C7.1841 8.66671 7.33334 8.51747 7.33334 8.33337V7.66671C7.33334 7.48261 7.1841 7.33337 7 7.33337H5.66667ZM5.33334 10.3334C5.33334 10.1493 5.48257 10 5.66667 10H7C7.1841 10 7.33334 10.1493 7.33334 10.3334V11C7.33334 11.1841 7.1841 11.3334 7 11.3334H5.66667C5.48257 11.3334 5.33334 11.1841 5.33334 11V10.3334ZM9 4.66671C8.81591 4.66671 8.66667 4.81595 8.66667 5.00004V5.66671C8.66667 5.8508 8.81591 6.00004 9 6.00004H10.3333C10.5174 6.00004 10.6667 5.8508 10.6667 5.66671V5.00004C10.6667 4.81595 10.5174 4.66671 10.3333 4.66671H9ZM8.66667 7.66671C8.66667 7.48261 8.81591 7.33337 9 7.33337H10.3333C10.5174 7.33337 10.6667 7.48261 10.6667 7.66671V8.33337C10.6667 8.51747 10.5174 8.66671 10.3333 8.66671H9C8.81591 8.66671 8.66667 8.51747 8.66667 8.33337V7.66671ZM9 10C8.81591 10 8.66667 10.1493 8.66667 10.3334V11C8.66667 11.1841 8.81591 11.3334 9 11.3334H10.3333C10.5174 11.3334 10.6667 11.1841 10.6667 11V10.3334C10.6667 10.1493 10.5174 10 10.3333 10H9Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
