import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingBold';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingBoldIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingBold',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 7.07692C6 5.37759 7.37759 4 9.07692 4H12.5C14.9853 4 17 6.01472 17 8.5C17 9.70432 16.5269 10.7981 15.7564 11.6058C17.0979 12.3847 18 13.837 18 15.5C18 17.9853 15.9853 20 13.5 20H9.30769C7.4809 20 6 18.5191 6 16.6923V7.07692ZM8 13V16.6923C8 17.4145 8.58547 18 9.30769 18H13.5C14.8807 18 16 16.8807 16 15.5C16 14.1193 14.8807 13 13.5 13H8ZM8 11H12.5C13.8807 11 15 9.88071 15 8.5C15 7.11929 13.8807 6 12.5 6H9.07692C8.48216 6 8 6.48216 8 7.07692V11Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.79999 4.55384C3.79999 3.36431 4.7643 2.39999 5.95383 2.39999H8.34999C10.0897 2.39999 11.5 3.8103 11.5 5.54999C11.5 6.39301 11.1688 7.15869 10.6295 7.72402C11.5685 8.26931 12.2 9.28591 12.2 10.45C12.2 12.1897 10.7897 13.6 9.04999 13.6H6.11537C4.83662 13.6 3.79999 12.5634 3.79999 11.2846V4.55384ZM5.19999 8.69999V11.2846C5.19999 11.7902 5.60982 12.2 6.11537 12.2H9.04999C10.0165 12.2 10.8 11.4165 10.8 10.45C10.8 9.4835 10.0165 8.69999 9.04999 8.69999H5.19999ZM5.19999 7.29999H8.34999C9.31649 7.29999 10.1 6.51649 10.1 5.54999C10.1 4.5835 9.31649 3.79999 8.34999 3.79999H5.95383C5.5375 3.79999 5.19999 4.1375 5.19999 4.55384V7.29999Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
