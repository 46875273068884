import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'filterAlternative';
    sizeManagement?: 'self' | 'parent';
}

export const withFilterAlternativeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'filterAlternative',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V6.89023C22 7.84507 21.6584 8.76842 21.037 9.49339L16.4815 14.8082C16.1708 15.1707 16 15.6323 16 16.1098V19C16 20.6569 14.6569 22 13 22H11C9.34315 22 8 20.6569 8 19V16.1098C8 15.6323 7.82922 15.1707 7.51851 14.8082L2.96297 9.49339C2.34157 8.76842 2 7.84507 2 6.89023V6ZM6 4C4.89543 4 4 4.89543 4 6V6.89023C4 7.36765 4.17078 7.82932 4.48149 8.19181L9.03703 13.5066C9.65843 14.2316 10 15.1549 10 16.1098V19C10 19.5523 10.4477 20 11 20H13C13.5523 20 14 19.5523 14 19V16.1098C14 15.1549 14.3416 14.2316 14.963 13.5066L19.5185 8.19181C19.8292 7.82932 20 7.36765 20 6.89023V6C20 4.89543 19.1046 4 18 4H6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 3.8C1 2.2536 2.2536 1 3.8 1H12.2C13.7464 1 15 2.2536 15 3.8V4.42316C15 5.09155 14.7609 5.73789 14.3259 6.24538L11.137 9.96573C10.9195 10.2195 10.8 10.5426 10.8 10.8768V12.9C10.8 14.0598 9.8598 15 8.7 15H7.3C6.1402 15 5.2 14.0598 5.2 12.9V10.8768C5.2 10.5426 5.08045 10.2195 4.86296 9.96573L1.67408 6.24538C1.2391 5.7379 1 5.09155 1 4.42316V3.8ZM3.8 2.4C3.0268 2.4 2.4 3.0268 2.4 3.8V4.42316C2.4 4.75736 2.51955 5.08053 2.73704 5.33427L5.92592 9.05463C6.3609 9.56211 6.6 10.2085 6.6 10.8768V12.9C6.6 13.2866 6.9134 13.6 7.3 13.6H8.7C9.0866 13.6 9.4 13.2866 9.4 12.9V10.8768C9.4 10.2084 9.6391 9.56211 10.0741 9.05462L13.263 5.33427C13.4805 5.08053 13.6 4.75736 13.6 4.42316V3.8C13.6 3.0268 12.9732 2.4 12.2 2.4H3.8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
