import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'officesMapCoffeePoint';
    sizeManagement?: 'self' | 'parent';
}

export const withOfficesMapCoffeePointIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'officesMapCoffeePoint',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 1C7.55228 1 8 1.44772 8 2V4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4V2C6 1.44772 6.44772 1 7 1Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11 1C11.5523 1 12 1.44772 12 2V4C12 4.55228 11.5523 5 11 5C10.4477 5 10 4.55228 10 4V2C10 1.44772 10.4477 1 11 1Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M15 1C15.5523 1 16 1.44772 16 2V4C16 4.55228 15.5523 5 15 5C14.4477 5 14 4.55228 14 4V2C14 1.44772 14.4477 1 15 1Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 7C3 6.44772 3.44772 6 4 6H19C21.2091 6 23 7.79086 23 10V12C23 14.2091 21.2091 16 19 16V18C19 20.2091 17.2091 22 15 22H7C4.79086 22 3 20.2091 3 18V7ZM19 14C20.1046 14 21 13.1046 21 12V10C21 8.89543 20.1046 8 19 8V14ZM17 8H5V18C5 19.1046 5.89543 20 7 20H15C16.1046 20 17 19.1046 17 18V8Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 0.299988C4.88661 0.299988 5.20001 0.613388 5.20001 0.999988V2.39999C5.20001 2.78659 4.88661 3.09999 4.50001 3.09999C4.11341 3.09999 3.80001 2.78659 3.80001 2.39999V0.999988C3.80001 0.613388 4.11341 0.299988 4.50001 0.299988ZM7.30001 0.299988C7.68661 0.299988 8.00001 0.613388 8.00001 0.999988V2.39999C8.00001 2.78659 7.68661 3.09999 7.30001 3.09999C6.91341 3.09999 6.60001 2.78659 6.60001 2.39999V0.999988C6.60001 0.613388 6.91341 0.299988 7.30001 0.299988ZM10.1 0.299988C10.4866 0.299988 10.8 0.613388 10.8 0.999988V2.39999C10.8 2.78659 10.4866 3.09999 10.1 3.09999C9.71341 3.09999 9.40001 2.78659 9.40001 2.39999V0.999988C9.40001 0.613388 9.71341 0.299988 10.1 0.299988ZM1.70001 4.49999C1.70001 4.11339 2.01341 3.79999 2.40001 3.79999H12.9C14.4464 3.79999 15.7 5.05359 15.7 6.59999V7.99999C15.7 9.54639 14.4464 10.8 12.9 10.8V12.2C12.9 13.7464 11.6464 15 10.1 15H4.50001C2.95362 15 1.70001 13.7464 1.70001 12.2V4.49999ZM11.5 10.1011C11.5 10.1008 11.5 10.1004 11.5 10.1L11.5 5.19999H3.10001V12.2C3.10001 12.9732 3.72681 13.6 4.50001 13.6H10.1C10.8732 13.6 11.5 12.9732 11.5 12.2V10.1011ZM12.9 5.19999V9.39999C13.6732 9.39999 14.3 8.77319 14.3 7.99999V6.59999C14.3 5.82679 13.6732 5.19999 12.9 5.19999Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
