import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'document';
    sizeManagement?: 'self' | 'parent';
}

export const withDocumentIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'document',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 4C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V11H12C11.4477 11 11 10.5523 11 10V4H8ZM13 5.41421L16.5858 9H13V5.41421ZM4 6C4 3.79086 5.79086 2 8 2H12C12.2652 2 12.5196 2.10536 12.7071 2.29289L19.7071 9.29289C19.8946 9.48043 20 9.73478 20 10V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.20002 2.4C4.42683 2.4 3.80002 3.0268 3.80002 3.8V12.2C3.80002 12.9732 4.42683 13.6 5.20003 13.6H10.8C11.5732 13.6 12.2 12.9732 12.2 12.2V7.3L8.00002 7.3C7.61342 7.3 7.30002 6.9866 7.30002 6.6V2.4H5.20002ZM8.70002 3.38995L11.2101 5.9L8.70002 5.9V3.38995ZM2.40002 3.8C2.40002 2.2536 3.65363 1 5.20002 1H8.00002C8.18568 1 8.36372 1.07375 8.495 1.20503L13.395 6.10503C13.5263 6.2363 13.6 6.41435 13.6 6.6V12.2C13.6 13.7464 12.3464 15 10.8 15H5.20003C3.65363 15 2.40002 13.7464 2.40002 12.2V3.8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
