import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'chartAdd';
    sizeManagement?: 'self' | 'parent';
}

export const withChartAddIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'chartAdd',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M18 1.49994C18.5523 1.49994 19 1.94765 19 2.49994V4.99997H21.5C22.0523 4.99997 22.5 5.44768 22.5 5.99997C22.5 6.55225 22.0523 6.99997 21.5 6.99997H19V9.49994C19 10.0522 18.5523 10.4999 18 10.4999C17.4477 10.4999 17 10.0522 17 9.49994V6.99997H14.5C13.9477 6.99997 13.5 6.55225 13.5 5.99997C13.5 5.44768 13.9477 4.99997 14.5 4.99997H17V2.49994C17 1.94765 17.4477 1.49994 18 1.49994Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7 4.99999C5.89543 4.99999 5 5.89543 5 6.99999V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V6.99999C3 4.79086 4.79086 2.99999 7 2.99999H11C11.5523 2.99999 12 3.44771 12 3.99999C12 4.55228 11.5523 4.99999 11 4.99999H7Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 9.99999C8.55228 9.99999 9 10.4477 9 11V16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16V11C7 10.4477 7.44772 9.99999 8 9.99999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 7.99999C12.5523 7.99999 13 8.44771 13 8.99999V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V8.99999C11 8.44771 11.4477 7.99999 12 7.99999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M16 13C16.5523 13 17 13.4477 17 14V16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16V14C15 13.4477 15.4477 13 16 13Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.2 0.650085C12.5866 0.650085 12.9 0.963486 12.9 1.35009V3.10011H14.65C15.0366 3.10011 15.35 3.41351 15.35 3.80011C15.35 4.1867 15.0366 4.5001 14.65 4.5001H12.9V6.25009C12.9 6.63668 12.5866 6.95009 12.2 6.95009C11.8134 6.95009 11.5 6.63668 11.5 6.25009V4.5001H9.75001C9.36341 4.5001 9.05001 4.1867 9.05001 3.80011C9.05001 3.41351 9.36341 3.10011 9.75001 3.10011H11.5V1.35009C11.5 0.963486 11.8134 0.650085 12.2 0.650085Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 3.10001C3.72681 3.10001 3.10001 3.72681 3.10001 4.5V11.5C3.10001 12.2732 3.72681 12.9 4.50001 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5V8.70001C12.9 8.31341 13.2134 8.00001 13.6 8.00001C13.9866 8.00001 14.3 8.31341 14.3 8.70001V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.50001C2.95361 14.3 1.70001 13.0464 1.70001 11.5V4.5C1.70001 2.95361 2.95362 1.70001 4.50001 1.70001H7.30001C7.68661 1.70001 8.00001 2.01341 8.00001 2.40001C8.00001 2.78661 7.68661 3.10001 7.30001 3.10001H4.50001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.20001 6.60001C5.58661 6.60001 5.90001 6.91341 5.90001 7.30001V10.8C5.90001 11.1866 5.58661 11.5 5.20001 11.5C4.81341 11.5 4.50001 11.1866 4.50001 10.8V7.30001C4.50001 6.91341 4.81341 6.60001 5.20001 6.60001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 5.20001C8.38661 5.20001 8.70001 5.51341 8.70001 5.90001V10.8C8.70001 11.1866 8.38661 11.5 8.00001 11.5C7.61341 11.5 7.30001 11.1866 7.30001 10.8V5.90001C7.30001 5.51341 7.61341 5.20001 8.00001 5.20001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.8 8.70001C11.1866 8.70001 11.5 9.01341 11.5 9.40001V10.8C11.5 11.1866 11.1866 11.5 10.8 11.5C10.4134 11.5 10.1 11.1866 10.1 10.8V9.40001C10.1 9.01341 10.4134 8.70001 10.8 8.70001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
