import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'numbersFive';
    sizeManagement?: 'self' | 'parent';
}

export const withNumbersFiveIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'numbersFive',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.79046 4.02222C9.33049 3.9065 9.86208 4.25047 9.9778 4.79049L12 14.2274L14.0222 4.79049C14.1379 4.25047 14.6695 3.9065 15.2095 4.02222C15.7495 4.13794 16.0935 4.66953 15.9778 5.20955L12.9778 19.2096C12.879 19.6706 12.4715 20 12 20C11.5285 20 11.121 19.6706 11.0222 19.2096L8.02219 5.20955C7.90647 4.66953 8.25044 4.13794 8.79046 4.02222Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.7533 2.41552C6.13132 2.33452 6.50343 2.5753 6.58444 2.95332L7.99997 9.55916L9.41551 2.95332C9.49652 2.5753 9.86863 2.33452 10.2466 2.41552C10.6247 2.49653 10.8654 2.86864 10.7844 3.24666L8.68444 13.0467C8.61527 13.3694 8.33005 13.6 7.99997 13.6C7.6699 13.6 7.38467 13.3694 7.31551 13.0467L5.21551 3.24666C5.13451 2.86864 5.37529 2.49653 5.7533 2.41552Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
