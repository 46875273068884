import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'speed';
    sizeManagement?: 'self' | 'parent';
}

export const withSpeedIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'speed',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 15.0103 5.66244 17.6339 8.12361 19H15.8764C18.3376 17.6339 20 15.0103 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 15.8713 19.7999 19.2271 16.5864 20.8883C16.4445 20.9617 16.287 21 16.1272 21H7.8728C7.713 21 7.55553 20.9617 7.41358 20.8883C4.20013 19.2271 2 15.8713 2 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.7537 11.4451C10.0887 11.9892 10.0388 12.9887 10.6464 13.5963C11.2541 14.2039 12.2535 14.1541 12.7977 13.489L15.663 9.9869C15.9884 9.58928 15.9595 9.00983 15.5962 8.64656C15.2329 8.28328 14.6535 8.25438 14.2558 8.57971L10.7537 11.4451Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99994 2.39994C4.90714 2.39994 2.39994 4.90714 2.39994 7.99994C2.39994 10.1072 3.56365 11.9437 5.28646 12.8999H10.7134C12.4362 11.9437 13.5999 10.1072 13.5999 7.99994C13.5999 4.90714 11.0927 2.39994 7.99994 2.39994ZM0.999939 7.99994C0.999939 4.13395 4.13395 0.999939 7.99994 0.999939C11.8659 0.999939 14.9999 4.13395 14.9999 7.99994C14.9999 10.7099 13.4599 13.0589 11.2104 14.2218C11.1111 14.2731 11.0008 14.2999 10.889 14.2999H5.1109C4.99904 14.2999 4.88881 14.2731 4.78945 14.2218C2.54003 13.0589 0.999939 10.7099 0.999939 7.99994Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7.12766 7.61158C6.6621 7.9925 6.6272 8.6921 7.05255 9.11745C7.47789 9.5428 8.1775 9.5079 8.55841 9.04234L10.5642 6.59087C10.7919 6.31253 10.7717 5.90692 10.5174 5.65263C10.2631 5.39833 9.85747 5.3781 9.57913 5.60583L7.12766 7.61158Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
