import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapAbsence';
    sizeManagement?: 'self' | 'parent';
}

export const withGapAbsenceIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapAbsence',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_964_2584)"><path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM12.7329 11.0613C13.3043 10.18 13.636 9.12921 13.636 8C13.636 4.88733 11.1127 2.36401 8 2.36401C6.87079 2.36401 5.82004 2.69569 4.93866 3.26706L12.7329 11.0613ZM3.26706 4.93866C2.69569 5.82004 2.36401 6.87079 2.36401 8C2.36401 11.1127 4.88733 13.636 8 13.636C9.12921 13.636 10.18 13.3043 11.0613 12.7329L3.26706 4.93866Z" fill="currentColor" /></g><defs><clipPath id="clip0_964_2584"><rect width={16} height={16} fill="white" /></clipPath></defs></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
