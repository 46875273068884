import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'blank';
    sizeManagement?: 'self' | 'parent';
}

export const withBlankIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'blank',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 7C2 4.79086 3.79086 3 6 3H18C20.2091 3 22 4.79086 22 7V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V7ZM6 5C4.89543 5 4 5.89543 4 7V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V7C20 5.89543 19.1046 5 18 5H6Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 8C12 7.44772 12.4477 7 13 7L17 7C17.5523 7 18 7.44772 18 8C18 8.55229 17.5523 9 17 9L13 9C12.4477 9 12 8.55228 12 8Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 12C12 11.4477 12.4477 11 13 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13C12.4477 13 12 12.5523 12 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6 16C6 15.4477 6.44772 15 7 15H17C17.5523 15 18 15.4477 18 16C18 16.5523 17.5523 17 17 17H7C6.44772 17 6 16.5523 6 16Z" fill="currentColor" /><path d="M10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 4.50001C1 2.95361 2.2536 1.70001 3.8 1.70001H12.2C13.7464 1.70001 15 2.95362 15 4.50001V11.5C15 13.0464 13.7464 14.3 12.2 14.3H3.8C2.2536 14.3 1 13.0464 1 11.5V4.50001ZM3.8 3.10001C3.0268 3.10001 2.4 3.72681 2.4 4.50001V11.5C2.4 12.2732 3.0268 12.9 3.8 12.9H12.2C12.9732 12.9 13.6 12.2732 13.6 11.5V4.50001C13.6 3.72681 12.9732 3.10001 12.2 3.10001H3.8Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 5.20001C8 4.81341 8.3134 4.50001 8.7 4.50001L11.5 4.50001C11.8866 4.50001 12.2 4.81341 12.2 5.20001C12.2 5.58661 11.8866 5.90001 11.5 5.90001L8.7 5.90001C8.3134 5.90001 8 5.58661 8 5.20001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 8.00001C8 7.61341 8.3134 7.30001 8.7 7.30001H11.5C11.8866 7.30001 12.2 7.61341 12.2 8.00001C12.2 8.38661 11.8866 8.70001 11.5 8.70001L8.7 8.70001C8.3134 8.70001 8 8.38661 8 8.00001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3.8 10.8C3.8 10.4134 4.1134 10.1 4.5 10.1H11.5C11.8866 10.1 12.2 10.4134 12.2 10.8C12.2 11.1866 11.8866 11.5 11.5 11.5H4.5C4.1134 11.5 3.8 11.1866 3.8 10.8Z" fill="currentColor" /><path d="M6.6 5.90001C6.6 6.67321 5.9732 7.30001 5.2 7.30001C4.4268 7.30001 3.8 6.67321 3.8 5.90001C3.8 5.12681 4.4268 4.50001 5.2 4.50001C5.9732 4.50001 6.6 5.12681 6.6 5.90001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
