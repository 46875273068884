import { composeU } from '@bem-react/core';
import { Icon as BaseIcon } from '@yandex-lego/components/Icon';

import { withAaIcon } from '../_glyph/Aa';
import { withAddToListIcon } from '../_glyph/AddToList';
import { withAnchorIcon } from '../_glyph/Anchor';
import { withArchiveIcon } from '../_glyph/Archive';
import { withArrowLongDownIcon } from '../_glyph/ArrowLongDown';
import { withArrowLongLeftIcon } from '../_glyph/ArrowLongLeft';
import { withArrowLongRightIcon } from '../_glyph/ArrowLongRight';
import { withArrowLongUpIcon } from '../_glyph/ArrowLongUp';
import { withArrowRelationshipIcon } from '../_glyph/ArrowRelationship';
import { withArrowShortDownIcon } from '../_glyph/ArrowShortDown';
import { withArrowShortLeftIcon } from '../_glyph/ArrowShortLeft';
import { withArrowShortRightIcon } from '../_glyph/ArrowShortRight';
import { withArrowShortUpIcon } from '../_glyph/ArrowShortUp';
import { withArrowUpAndDownIcon } from '../_glyph/ArrowUpAndDown';
import { withAttachIcon } from '../_glyph/Attach';
import { withBaggageOffIcon } from '../_glyph/BaggageOff';
import { withBaggageOnIcon } from '../_glyph/BaggageOn';
import { withBanIcon } from '../_glyph/Ban';
import { withBlankIcon } from '../_glyph/Blank';
import { withBookIcon } from '../_glyph/Book';
import { withBookmarkIcon } from '../_glyph/Bookmark';
import { withBookmarkCheckedIcon } from '../_glyph/BookmarkChecked';
import { withBugIcon } from '../_glyph/Bug';
import { withBulbIcon } from '../_glyph/Bulb';
import { withCalendarIcon } from '../_glyph/Calendar';
import { withCalendarGridIcon } from '../_glyph/CalendarGrid';
import { withCallIcon } from '../_glyph/Call';
import { withCameraIcon } from '../_glyph/Camera';
import { withCardIcon } from '../_glyph/Card';
import { withCategoryIcon } from '../_glyph/Category';
import { withChartIcon } from '../_glyph/Chart';
import { withChartAddIcon } from '../_glyph/ChartAdd';
import { withChatIcon } from '../_glyph/Chat';
import { withCheckIcon } from '../_glyph/Check';
import { withCheckCircleIcon } from '../_glyph/CheckCircle';
import { withClockIcon } from '../_glyph/Clock';
import { withCloseIcon } from '../_glyph/Close';
import { withCloseCircleIcon } from '../_glyph/CloseCircle';
import { withCodeIcon } from '../_glyph/Code';
import { withCommentIcon } from '../_glyph/Comment';
import { withCompassIcon } from '../_glyph/Compass';
import { withCopyIcon } from '../_glyph/Copy';
import { withCopyAlternativeIcon } from '../_glyph/CopyAlternative';
import { withCopyWithArrowIcon } from '../_glyph/CopyWithArrow';
import { withCrownIcon } from '../_glyph/Crown';
import { withCrownFilledIcon } from '../_glyph/CrownFilled';
import { withDatabaseIcon } from '../_glyph/Database';
import { withDayIcon } from '../_glyph/Day';
import { withDayCheckedIcon } from '../_glyph/DayChecked';
import { withDesktopIcon } from '../_glyph/Desktop';
import { withDislikeIcon } from '../_glyph/Dislike';
import { withDivideIcon } from '../_glyph/Divide';
import { withDocumentIcon } from '../_glyph/Document';
import { withDocumentAddIcon } from '../_glyph/DocumentAdd';
import { withDocumentDeleteIcon } from '../_glyph/DocumentDelete';
import { withDocumentEditIcon } from '../_glyph/DocumentEdit';
import { withDocumentSendIcon } from '../_glyph/DocumentSend';
import { withDollarIcon } from '../_glyph/Dollar';
import { withDownloadIcon } from '../_glyph/Download';
import { withDragAndDropIcon } from '../_glyph/DragAndDrop';
import { withEditIcon } from '../_glyph/Edit';
import { withEqualIcon } from '../_glyph/Equal';
import { withErrorIcon } from '../_glyph/Error';
import { withErrorTriangleIcon } from '../_glyph/ErrorTriangle';
import { withEyeIcon } from '../_glyph/Eye';
import { withEyeOffIcon } from '../_glyph/EyeOff';
import { withFilterIcon } from '../_glyph/Filter';
import { withFilterAlternativeIcon } from '../_glyph/FilterAlternative';
import { withFlagIcon } from '../_glyph/Flag';
import { withFolderIcon } from '../_glyph/Folder';
import { withFullscreenIcon } from '../_glyph/Fullscreen';
import { withFullscreenExitIcon } from '../_glyph/FullscreenExit';
import { withGapAbsenceIcon } from '../_glyph/GapAbsence';
import { withGapBabyIcon } from '../_glyph/GapBaby';
import { withGapDayOffIcon } from '../_glyph/GapDayOff';
import { withGapEducationIcon } from '../_glyph/GapEducation';
import { withGapHomeIcon } from '../_glyph/GapHome';
import { withGapOfficeIcon } from '../_glyph/GapOffice';
import { withGapPlaneIcon } from '../_glyph/GapPlane';
import { withGapShieldIcon } from '../_glyph/GapShield';
import { withGapSickIcon } from '../_glyph/GapSick';
import { withGapTripIcon } from '../_glyph/GapTrip';
import { withGeolocationCircleIcon } from '../_glyph/GeolocationCircle';
import { withGraduationCapIcon } from '../_glyph/GraduationCap';
import { withGridDotsIcon } from '../_glyph/GridDots';
import { withGroupIcon } from '../_glyph/Group';
import { withHandBaggageOffIcon } from '../_glyph/HandBaggageOff';
import { withHandBaggageOnIcon } from '../_glyph/HandBaggageOn';
import { withHeartIcon } from '../_glyph/Heart';
import { withHeartCheckedIcon } from '../_glyph/HeartChecked';
import { withHelpIcon } from '../_glyph/Help';
import { withHomeIcon } from '../_glyph/Home';
import { withHrIcon } from '../_glyph/Hr';
import { withInboxIcon } from '../_glyph/Inbox';
import { withInfoIcon } from '../_glyph/Info';
import { withInNewTabIcon } from '../_glyph/InNewTab';
import { withLikeIcon } from '../_glyph/Like';
import { withLinkIcon } from '../_glyph/Link';
import { withLinkAlternativeIcon } from '../_glyph/LinkAlternative';
import { withLocationIcon } from '../_glyph/Location';
import { withLockIcon } from '../_glyph/Lock';
import { withLogInIcon } from '../_glyph/LogIn';
import { withLogOutIcon } from '../_glyph/LogOut';
import { withMailIcon } from '../_glyph/Mail';
import { withMailReadIcon } from '../_glyph/MailRead';
import { withMenuIcon } from '../_glyph/Menu';
import { withMessageIcon } from '../_glyph/Message';
import { withMinusIcon } from '../_glyph/Minus';
import { withMinusCircleIcon } from '../_glyph/MinusCircle';
import { withMobileIcon } from '../_glyph/Mobile';
import { withMoreHorizontalIcon } from '../_glyph/MoreHorizontal';
import { withMoreVerticalIcon } from '../_glyph/MoreVertical';
import { withNightIcon } from '../_glyph/Night';
import { withNotificationsIcon } from '../_glyph/Notifications';
import { withNotificationsOffIcon } from '../_glyph/NotificationsOff';
import { withNotificationsUnreadIcon } from '../_glyph/NotificationsUnread';
import { withNumbersEightIcon } from '../_glyph/NumbersEight';
import { withNumbersFiveIcon } from '../_glyph/NumbersFive';
import { withNumbersFourIcon } from '../_glyph/NumbersFour';
import { withNumbersOneIcon } from '../_glyph/NumbersOne';
import { withNumbersSevenIcon } from '../_glyph/NumbersSeven';
import { withNumbersSixIcon } from '../_glyph/NumbersSix';
import { withNumbersThreeIcon } from '../_glyph/NumbersThree';
import { withNumbersTwoIcon } from '../_glyph/NumbersTwo';
import { withOfficesMapCoffeePointIcon } from '../_glyph/OfficesMapCoffeePoint';
import { withOfficesMapWardrobeIcon } from '../_glyph/OfficesMapWardrobe';
import { withPauseIcon } from '../_glyph/Pause';
import { withPinIcon } from '../_glyph/Pin';
import { withPlayIcon } from '../_glyph/Play';
import { withPlayOffIcon } from '../_glyph/PlayOff';
import { withPlusIcon } from '../_glyph/Plus';
import { withPlusCircleIcon } from '../_glyph/PlusCircle';
import { withPrintIcon } from '../_glyph/Print';
import { withProfileIcon } from '../_glyph/Profile';
import { withQrCodeIcon } from '../_glyph/QrCode';
import { withQuestionIcon } from '../_glyph/Question';
import { withRefreshIcon } from '../_glyph/Refresh';
import { withRepeatIcon } from '../_glyph/Repeat';
import { withReviewAverageIcon } from '../_glyph/ReviewAverage';
import { withReviewExcellentIcon } from '../_glyph/ReviewExcellent';
import { withReviewFantasticIcon } from '../_glyph/ReviewFantastic';
import { withReviewGoodIcon } from '../_glyph/ReviewGood';
import { withReviewLowIcon } from '../_glyph/ReviewLow';
import { withReviewOutstandingIcon } from '../_glyph/ReviewOutstanding';
import { withRubleIcon } from '../_glyph/Ruble';
import { withRulerIcon } from '../_glyph/Ruler';
import { withSearchIcon } from '../_glyph/Search';
import { withSendIcon } from '../_glyph/Send';
import { withSettingsIcon } from '../_glyph/Settings';
import { withShareIcon } from '../_glyph/Share';
import { withShieldIcon } from '../_glyph/Shield';
import { withSortIcon } from '../_glyph/Sort';
import { withSortAscendingIcon } from '../_glyph/SortAscending';
import { withSortDescendingIcon } from '../_glyph/SortDescending';
import { withSpeedIcon } from '../_glyph/Speed';
import { withStarIcon } from '../_glyph/Star';
import { withStarCheckedIcon } from '../_glyph/StarChecked';
import { withStopIcon } from '../_glyph/Stop';
import { withSwapIcon } from '../_glyph/Swap';
import { withTextFormattingAlignCenterIcon } from '../_glyph/TextFormattingAlignCenter';
import { withTextFormattingAlignLeftIcon } from '../_glyph/TextFormattingAlignLeft';
import { withTextFormattingAlignRightIcon } from '../_glyph/TextFormattingAlignRight';
import { withTextFormattingBoldIcon } from '../_glyph/TextFormattingBold';
import { withTextFormattingCrossedTextIcon } from '../_glyph/TextFormattingCrossedText';
import { withTextFormattingImageIcon } from '../_glyph/TextFormattingImage';
import { withTextFormattingItallicIcon } from '../_glyph/TextFormattingItallic';
import { withTextFormattingListIcon } from '../_glyph/TextFormattingList';
import { withTextFormattingUnderlineIcon } from '../_glyph/TextFormattingUnderline';
import { withTrashIcon } from '../_glyph/Trash';
import { withTrashAlternativeIcon } from '../_glyph/TrashAlternative';
import { withTravelAirConditionerIcon } from '../_glyph/TravelAirConditioner';
import { withTravelCarIcon } from '../_glyph/TravelCar';
import { withTravelConnectionIcon } from '../_glyph/TravelConnection';
import { withTravelEntertaimentIcon } from '../_glyph/TravelEntertaiment';
import { withTravelHotelIcon } from '../_glyph/TravelHotel';
import { withTravelHotelFeaturesIcon } from '../_glyph/TravelHotelFeatures';
import { withTravelPlaneIcon } from '../_glyph/TravelPlane';
import { withTravelRestaurantIcon } from '../_glyph/TravelRestaurant';
import { withTravelTrainIcon } from '../_glyph/TravelTrain';
import { withTravelWiFiIcon } from '../_glyph/TravelWiFi';
import { withTripIcon } from '../_glyph/Trip';
import { withUnlockIcon } from '../_glyph/Unlock';
import { withUploadIcon } from '../_glyph/Upload';
import { withVideoIcon } from '../_glyph/Video';
import { withVideoOffIcon } from '../_glyph/VideoOff';
import { withWriteIcon } from '../_glyph/Write';

export const Icon = composeU(
    withAaIcon,
    withAddToListIcon,
    withAnchorIcon,
    withArchiveIcon,
    withArrowLongDownIcon,
    withArrowLongLeftIcon,
    withArrowLongRightIcon,
    withArrowLongUpIcon,
    withArrowRelationshipIcon,
    withArrowShortDownIcon,
    withArrowShortLeftIcon,
    withArrowShortRightIcon,
    withArrowShortUpIcon,
    withArrowUpAndDownIcon,
    withAttachIcon,
    withBaggageOffIcon,
    withBaggageOnIcon,
    withBanIcon,
    withBlankIcon,
    withBookIcon,
    withBookmarkIcon,
    withBookmarkCheckedIcon,
    withBugIcon,
    withBulbIcon,
    withCalendarIcon,
    withCalendarGridIcon,
    withCallIcon,
    withCameraIcon,
    withCardIcon,
    withCategoryIcon,
    withChartIcon,
    withChartAddIcon,
    withChatIcon,
    withCheckIcon,
    withCheckCircleIcon,
    withClockIcon,
    withCloseIcon,
    withCloseCircleIcon,
    withCodeIcon,
    withCommentIcon,
    withCompassIcon,
    withCopyIcon,
    withCopyAlternativeIcon,
    withCopyWithArrowIcon,
    withCrownIcon,
    withCrownFilledIcon,
    withDatabaseIcon,
    withDayIcon,
    withDayCheckedIcon,
    withDesktopIcon,
    withDislikeIcon,
    withDivideIcon,
    withDocumentIcon,
    withDocumentAddIcon,
    withDocumentDeleteIcon,
    withDocumentEditIcon,
    withDocumentSendIcon,
    withDollarIcon,
    withDownloadIcon,
    withDragAndDropIcon,
    withEditIcon,
    withEqualIcon,
    withErrorIcon,
    withErrorTriangleIcon,
    withEyeIcon,
    withEyeOffIcon,
    withFilterIcon,
    withFilterAlternativeIcon,
    withFlagIcon,
    withFolderIcon,
    withFullscreenIcon,
    withFullscreenExitIcon,
    withGapAbsenceIcon,
    withGapBabyIcon,
    withGapDayOffIcon,
    withGapEducationIcon,
    withGapHomeIcon,
    withGapOfficeIcon,
    withGapPlaneIcon,
    withGapShieldIcon,
    withGapSickIcon,
    withGapTripIcon,
    withGeolocationCircleIcon,
    withGraduationCapIcon,
    withGridDotsIcon,
    withGroupIcon,
    withHandBaggageOffIcon,
    withHandBaggageOnIcon,
    withHeartIcon,
    withHeartCheckedIcon,
    withHelpIcon,
    withHomeIcon,
    withHrIcon,
    withInNewTabIcon,
    withInboxIcon,
    withInfoIcon,
    withLikeIcon,
    withLinkIcon,
    withLinkAlternativeIcon,
    withLocationIcon,
    withLockIcon,
    withLogInIcon,
    withLogOutIcon,
    withMailIcon,
    withMailReadIcon,
    withMenuIcon,
    withMessageIcon,
    withMinusIcon,
    withMinusCircleIcon,
    withMobileIcon,
    withMoreHorizontalIcon,
    withMoreVerticalIcon,
    withNightIcon,
    withNotificationsIcon,
    withNotificationsOffIcon,
    withNotificationsUnreadIcon,
    withNumbersEightIcon,
    withNumbersFiveIcon,
    withNumbersFourIcon,
    withNumbersOneIcon,
    withNumbersSevenIcon,
    withNumbersSixIcon,
    withNumbersThreeIcon,
    withNumbersTwoIcon,
    withOfficesMapCoffeePointIcon,
    withOfficesMapWardrobeIcon,
    withPauseIcon,
    withPinIcon,
    withPlayIcon,
    withPlayOffIcon,
    withPlusIcon,
    withPlusCircleIcon,
    withPrintIcon,
    withProfileIcon,
    withQrCodeIcon,
    withQuestionIcon,
    withRefreshIcon,
    withRepeatIcon,
    withReviewAverageIcon,
    withReviewExcellentIcon,
    withReviewFantasticIcon,
    withReviewGoodIcon,
    withReviewLowIcon,
    withReviewOutstandingIcon,
    withRubleIcon,
    withRulerIcon,
    withSearchIcon,
    withSendIcon,
    withSettingsIcon,
    withShareIcon,
    withShieldIcon,
    withSortIcon,
    withSortAscendingIcon,
    withSortDescendingIcon,
    withSpeedIcon,
    withStarIcon,
    withStarCheckedIcon,
    withStopIcon,
    withSwapIcon,
    withTextFormattingAlignCenterIcon,
    withTextFormattingAlignLeftIcon,
    withTextFormattingAlignRightIcon,
    withTextFormattingBoldIcon,
    withTextFormattingCrossedTextIcon,
    withTextFormattingImageIcon,
    withTextFormattingItallicIcon,
    withTextFormattingListIcon,
    withTextFormattingUnderlineIcon,
    withTrashIcon,
    withTrashAlternativeIcon,
    withTravelAirConditionerIcon,
    withTravelCarIcon,
    withTravelConnectionIcon,
    withTravelEntertaimentIcon,
    withTravelHotelIcon,
    withTravelHotelFeaturesIcon,
    withTravelPlaneIcon,
    withTravelRestaurantIcon,
    withTravelTrainIcon,
    withTravelWiFiIcon,
    withTripIcon,
    withUnlockIcon,
    withUploadIcon,
    withVideoIcon,
    withVideoOffIcon,
    withWriteIcon,
)(BaseIcon);

export const allGlyphs: React.ComponentProps<typeof Icon>['glyph'][] = [
    'aa',
    'addToList',
    'anchor',
    'archive',
    'arrowLongDown',
    'arrowLongLeft',
    'arrowLongRight',
    'arrowLongUp',
    'arrowRelationship',
    'arrowShortDown',
    'arrowShortLeft',
    'arrowShortRight',
    'arrowShortUp',
    'arrowUpAndDown',
    'attach',
    'baggageOff',
    'baggageOn',
    'ban',
    'blank',
    'book',
    'bookmark',
    'bookmarkChecked',
    'bug',
    'bulb',
    'calendar',
    'calendarGrid',
    'call',
    'camera',
    'card',
    'category',
    'chart',
    'chartAdd',
    'chat',
    'check',
    'checkCircle',
    'clock',
    'close',
    'closeCircle',
    'code',
    'comment',
    'compass',
    'copy',
    'copyAlternative',
    'copyWithArrow',
    'crown',
    'crownFilled',
    'database',
    'day',
    'dayChecked',
    'desktop',
    'dislike',
    'divide',
    'document',
    'documentAdd',
    'documentDelete',
    'documentEdit',
    'documentSend',
    'dollar',
    'download',
    'dragAndDrop',
    'edit',
    'equal',
    'error',
    'errorTriangle',
    'eye',
    'eyeOff',
    'filter',
    'filterAlternative',
    'flag',
    'folder',
    'fullscreen',
    'fullscreenExit',
    'gapAbsence',
    'gapBaby',
    'gapDayOff',
    'gapEducation',
    'gapHome',
    'gapOffice',
    'gapPlane',
    'gapShield',
    'gapSick',
    'gapTrip',
    'geolocationCircle',
    'graduationCap',
    'gridDots',
    'group',
    'handBaggageOff',
    'handBaggageOn',
    'heart',
    'heartChecked',
    'help',
    'home',
    'hr',
    'inNewTab',
    'inbox',
    'info',
    'like',
    'link',
    'linkAlternative',
    'location',
    'lock',
    'logIn',
    'logOut',
    'mail',
    'mailRead',
    'menu',
    'message',
    'minus',
    'minusCircle',
    'mobile',
    'moreHorizontal',
    'moreVertical',
    'night',
    'notifications',
    'notificationsOff',
    'notificationsUnread',
    'numbersEight',
    'numbersFive',
    'numbersFour',
    'numbersOne',
    'numbersSeven',
    'numbersSix',
    'numbersThree',
    'numbersTwo',
    'officesMapCoffeePoint',
    'officesMapWardrobe',
    'pause',
    'pin',
    'play',
    'playOff',
    'plus',
    'plusCircle',
    'print',
    'profile',
    'qrCode',
    'question',
    'refresh',
    'repeat',
    'reviewAverage',
    'reviewExcellent',
    'reviewFantastic',
    'reviewGood',
    'reviewLow',
    'reviewOutstanding',
    'ruble',
    'ruler',
    'search',
    'send',
    'settings',
    'share',
    'shield',
    'sort',
    'sortAscending',
    'sortDescending',
    'speed',
    'star',
    'starChecked',
    'stop',
    'swap',
    'textFormattingAlignCenter',
    'textFormattingAlignLeft',
    'textFormattingAlignRight',
    'textFormattingBold',
    'textFormattingCrossedText',
    'textFormattingImage',
    'textFormattingItallic',
    'textFormattingList',
    'textFormattingUnderline',
    'trash',
    'trashAlternative',
    'travelAirConditioner',
    'travelCar',
    'travelConnection',
    'travelEntertaiment',
    'travelHotel',
    'travelHotelFeatures',
    'travelPlane',
    'travelRestaurant',
    'travelTrain',
    'travelWiFi',
    'trip',
    'unlock',
    'upload',
    'video',
    'videoOff',
    'write',
];

export type IconProps = React.ComponentProps<typeof Icon>;
