import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'reviewOutstanding';
    sizeManagement?: 'self' | 'parent';
}

export const withReviewOutstandingIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'reviewOutstanding',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 11C6.55228 11 7 11.4477 7 12V15H10C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H7V20C7 20.5523 6.55228 21 6 21C5.44772 21 5 20.5523 5 20V17H2C1.44772 17 1 16.5523 1 16C1 15.4477 1.44772 15 2 15H5V12C5 11.4477 5.44772 11 6 11Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 3C12.5523 3 13 3.44772 13 4V7H16C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9H13V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V9H8C7.44772 9 7 8.55228 7 8C7 7.44772 7.44772 7 8 7H11V4C11 3.44772 11.4477 3 12 3Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M18 11C18.5523 11 19 11.4477 19 12V15H22C22.5523 15 23 15.4477 23 16C23 16.5523 22.5523 17 22 17H19V20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20V17H14C13.4477 17 13 16.5523 13 16C13 15.4477 13.4477 15 14 15H17V12C17 11.4477 17.4477 11 18 11Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.79999 7.30001C4.18659 7.30001 4.49999 7.61341 4.49999 8.00001V10.1H6.59999C6.98659 10.1 7.29999 10.4134 7.29999 10.8C7.29999 11.1866 6.98659 11.5 6.59999 11.5H4.49999V13.6C4.49999 13.9866 4.18659 14.3 3.79999 14.3C3.41339 14.3 3.09999 13.9866 3.09999 13.6V11.5H0.999988C0.613388 11.5 0.299988 11.1866 0.299988 10.8C0.299988 10.4134 0.613388 10.1 0.999988 10.1H3.09999V8.00001C3.09999 7.61341 3.41339 7.30001 3.79999 7.30001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7.99999 1.70001C8.38659 1.70001 8.69999 2.01341 8.69999 2.40001V4.50001H10.8C11.1866 4.50001 11.5 4.81341 11.5 5.20001C11.5 5.58661 11.1866 5.90001 10.8 5.90001H8.69999V8.00001C8.69999 8.38661 8.38659 8.70001 7.99999 8.70001C7.61339 8.70001 7.29999 8.38661 7.29999 8.00001V5.90001H5.19999C4.81339 5.90001 4.49999 5.58661 4.49999 5.20001C4.49999 4.81341 4.81339 4.50001 5.19999 4.50001H7.29999V2.40001C7.29999 2.01341 7.61339 1.70001 7.99999 1.70001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12.2 7.30001C12.5866 7.30001 12.9 7.61341 12.9 8.00001V10.1H15C15.3866 10.1 15.7 10.4134 15.7 10.8C15.7 11.1866 15.3866 11.5 15 11.5H12.9V13.6C12.9 13.9866 12.5866 14.3 12.2 14.3C11.8134 14.3 11.5 13.9866 11.5 13.6V11.5H9.39999C9.01339 11.5 8.69999 11.1866 8.69999 10.8C8.69999 10.4134 9.01339 10.1 9.39999 10.1H11.5V8.00001C11.5 7.61341 11.8134 7.30001 12.2 7.30001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
