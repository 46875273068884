import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'comment';
    sizeManagement?: 'self' | 'parent';
}

export const withCommentIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'comment',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.02 21.58C13.7962 21.852 14.6308 21.9999 15.5 21.9999C16.2857 21.9999 17.0451 21.8787 17.7593 21.6534C18.3084 21.4801 18.7821 21.4152 19.1923 21.4919L21.8161 21.9828C22.1404 22.0435 22.4738 21.9403 22.7071 21.707C22.9404 21.4737 23.0436 21.1403 22.983 20.816L22.492 18.1922C22.4153 17.7819 22.4802 17.3083 22.6535 16.7592C22.8789 16.045 23 15.2856 23 14.4999C23 12.4592 22.185 10.6088 20.8626 9.25656C20.953 9.82433 21 10.4066 21 10.9998C21 11.7022 20.9341 12.3893 20.8083 13.0551C20.9333 13.5155 21 13.9999 21 14.4999C21 15.0791 20.9108 15.6355 20.7462 16.1573C20.5271 16.8514 20.3632 17.6888 20.5262 18.56L20.7485 19.7484L19.5602 19.526C18.6889 19.363 17.8515 19.527 17.1574 19.7461C16.9239 19.8197 16.6835 19.8783 16.4372 19.9205C15.416 20.6588 14.2629 21.226 13.02 21.58Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10 4C6.13402 4 3.00001 7.13401 3.00001 11C3.00001 11.808 3.13652 12.5821 3.38691 13.3016C3.63532 14.0154 3.79257 14.8569 3.6298 15.7268L3.25153 17.7485L5.27322 17.3702C6.14315 17.2074 6.98457 17.3647 7.69842 17.6131C8.41795 17.8635 9.19198 18 10 18C13.866 18 17 14.866 17 11C17 7.13401 13.866 4 10 4ZM1.00001 11C1.00001 6.02944 5.02945 2 10 2C14.9706 2 19 6.02944 19 11C19 15.9706 14.9706 20 10 20C8.96518 20 7.9691 19.8249 7.0411 19.502C6.52366 19.3219 6.05259 19.2591 5.64105 19.3361L2.18393 19.9829C1.8596 20.0436 1.52622 19.9404 1.29291 19.7071C1.0596 19.4738 0.95639 19.1404 1.01707 18.8161L1.66391 15.359C1.74091 14.9474 1.67808 14.4764 1.49801 13.9589C1.17507 13.0309 1.00001 12.0348 1.00001 11Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71401 14.7059C9.25739 14.8963 9.84165 14.9999 10.4501 14.9999C11.0002 14.9999 11.5317 14.9151 12.0316 14.7573C12.416 14.636 12.7476 14.5906 13.0348 14.6443L14.8714 14.988C15.0984 15.0304 15.3318 14.9582 15.4951 14.7949C15.6584 14.6316 15.7307 14.3982 15.6882 14.1712L15.3446 12.3345C15.2908 12.0473 15.3363 11.7158 15.4576 11.3314C15.6153 10.8314 15.7001 10.2999 15.7001 9.7499C15.7001 8.32141 15.1296 7.02617 14.204 6.07961C14.2673 6.477 14.3001 6.88453 14.3001 7.29972C14.3001 7.79147 14.254 8.27244 14.1659 8.73857C14.2534 9.06084 14.3001 9.39992 14.3001 9.7499C14.3001 10.1553 14.2377 10.5448 14.1225 10.9101C13.9691 11.396 13.8543 11.9821 13.9684 12.592L14.1241 13.4238L13.2922 13.2682C12.6824 13.1541 12.0962 13.2689 11.6103 13.4222C11.4468 13.4738 11.2785 13.5148 11.1061 13.5444C10.3912 14.0611 9.58401 14.4581 8.71401 14.7059Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6.6 2.4C3.8938 2.4 1.7 4.5938 1.7 7.3C1.7 7.86562 1.79555 8.40745 1.97082 8.91112C2.14471 9.41081 2.25478 9.9998 2.14085 10.6088L1.87606 12.0239L3.29124 11.7592C3.90019 11.6452 4.48919 11.7553 4.98888 11.9292C5.49255 12.1044 6.03437 12.2 6.6 12.2C9.30619 12.2 11.5 10.0062 11.5 7.3C11.5 4.5938 9.30619 2.4 6.6 2.4ZM0.299998 7.3C0.299998 3.82061 3.1206 1 6.6 1C10.0794 1 12.9 3.82061 12.9 7.3C12.9 10.7794 10.0794 13.6 6.6 13.6C5.87561 13.6 5.17836 13.4775 4.52876 13.2514C4.16655 13.1254 3.8368 13.0814 3.54872 13.1353L1.12874 13.5881C0.90171 13.6305 0.668342 13.5583 0.505023 13.395C0.341705 13.2317 0.269461 12.9983 0.311938 12.7713L0.764726 10.3513C0.818627 10.0632 0.774641 9.73345 0.648596 9.37124C0.42254 8.72164 0.299998 8.02438 0.299998 7.3Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
