import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'star';
    sizeManagement?: 'self' | 'parent';
}

export const withStarIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'star',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.4398 3.91489C12.2507 3.5654 11.7493 3.5654 11.5602 3.91489L9.72802 7.30212C9.36153 7.97968 8.70313 8.45044 7.94345 8.57812L4.52072 9.15337C4.1209 9.22057 3.96309 9.71066 4.24856 9.99852L6.9202 12.6926C7.48527 13.2624 7.74245 14.0689 7.61159 14.8606L7.03006 18.3791C6.96474 18.7744 7.36753 19.0821 7.73171 18.9152L10.9583 17.4362C11.6197 17.1331 12.3803 17.1331 13.0417 17.4362L16.2683 18.9152C16.6325 19.0821 17.0353 18.7744 16.9699 18.3791L16.3884 14.8606C16.2575 14.0689 16.5147 13.2624 17.0798 12.6926L19.7514 9.99853C20.0369 9.71066 19.8791 9.22057 19.4793 9.15337L16.0565 8.57812C15.2969 8.45044 14.6385 7.97968 14.272 7.30212L12.4398 3.91489ZM9.80106 2.96335C10.7463 1.21591 13.2537 1.21591 14.1989 2.96335L16.0311 6.35058C16.1044 6.48609 16.2361 6.58025 16.388 6.60578L19.8108 7.18103C21.8098 7.51701 22.5989 9.96745 21.1715 11.4068L18.4999 14.1009C18.3869 14.2149 18.3355 14.3762 18.3616 14.5345L18.9432 18.053C19.2698 20.0293 17.2558 21.5679 15.4349 20.7333L12.2083 19.2543C12.0761 19.1937 11.9239 19.1937 11.7917 19.2543L8.56506 20.7333C6.74417 21.5679 4.7302 20.0292 5.05683 18.053L5.63836 14.5345C5.66453 14.3762 5.6131 14.2149 5.50008 14.1009L2.82844 11.4068C1.40108 9.96745 2.19018 7.51701 4.18923 7.18103L7.61197 6.60578C7.7639 6.58025 7.89558 6.48609 7.96888 6.35058L9.80106 2.96335Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.30787 2.34044C8.17554 2.0958 7.8245 2.0958 7.69217 2.34044L6.40964 4.7115C6.15309 5.18579 5.69222 5.51533 5.16044 5.6047L2.76453 6.00738C2.48466 6.05441 2.37419 6.39748 2.57402 6.59899L4.44417 8.48485C4.83972 8.88372 5.01974 9.44824 4.92814 10.0025L4.52107 12.4654C4.47534 12.7421 4.7573 12.9575 5.01222 12.8406L7.27083 11.8054C7.7338 11.5932 8.26624 11.5932 8.72921 11.8054L10.9878 12.8406C11.2427 12.9575 11.5247 12.7421 11.479 12.4654L11.0719 10.0025C10.9803 9.44824 11.1603 8.88372 11.5559 8.48485L13.426 6.59899C13.6259 6.39748 13.5154 6.05442 13.2355 6.00738L10.8396 5.6047C10.3078 5.51533 9.84695 5.18579 9.5904 4.7115L8.30787 2.34044ZM6.46077 1.67436C7.12242 0.451158 8.87763 0.451154 9.53927 1.67436L10.8218 4.04543C10.8731 4.14028 10.9653 4.20619 11.0716 4.22407L13.4676 4.62674C14.8669 4.86192 15.4193 6.57723 14.4201 7.58479L12.55 9.47065C12.4709 9.55042 12.4348 9.66333 12.4532 9.77417L12.8602 12.2371C13.0889 13.6205 11.6791 14.6976 10.4045 14.1133L8.14586 13.0781C8.05327 13.0356 7.94678 13.0356 7.85418 13.0781L5.59557 14.1133C4.32094 14.6976 2.91117 13.6205 3.13981 12.2371L3.54688 9.77417C3.5652 9.66333 3.52919 9.55042 3.45008 9.47065L1.57994 7.58479C0.580783 6.57724 1.13315 4.86193 2.53249 4.62674L4.9284 4.22407C5.03476 4.20619 5.12693 4.14028 5.17824 4.04543L6.46077 1.67436Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
