import { ComponentProps } from 'react';

import { compose } from '@bem-react/core';
import { Badge as BadgeBase } from '@yandex-lego/components/Badge/desktop';

import { withClearButton } from '..';

export const Badge = compose(
    withClearButton,
)(BadgeBase);

export type BadgeProps = ComponentProps<typeof Badge>;
