import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'chart';
    sizeManagement?: 'self' | 'parent';
}

export const withChartIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'chart',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7ZM7 5C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 10C8.55228 10 9 10.4477 9 11V16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16V11C7 10.4477 7.44772 10 8 10Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 7C12.5523 7 13 7.44772 13 8V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V8C11 7.44772 11.4477 7 12 7Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M16 13C16.5523 13 17 13.4477 17 14V16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16V14C15 13.4477 15.4477 13 16 13Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 4.50001C1.70001 2.95361 2.95362 1.70001 4.50001 1.70001H11.5C13.0464 1.70001 14.3 2.95362 14.3 4.50001V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H4.50001C2.95361 14.3 1.70001 13.0464 1.70001 11.5V4.50001ZM4.50001 3.10001C3.72681 3.10001 3.10001 3.72681 3.10001 4.50001V11.5C3.10001 12.2732 3.72681 12.9 4.50001 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5V4.50001C12.9 3.72681 12.2732 3.10001 11.5 3.10001H4.50001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.20001 6.60001C5.58661 6.60001 5.90001 6.91341 5.90001 7.30001V10.8C5.90001 11.1866 5.58661 11.5 5.20001 11.5C4.81341 11.5 4.50001 11.1866 4.50001 10.8V7.30001C4.50001 6.91341 4.81341 6.60001 5.20001 6.60001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 4.50001C8.38661 4.50001 8.70001 4.81341 8.70001 5.20001V10.8C8.70001 11.1866 8.38661 11.5 8.00001 11.5C7.61341 11.5 7.30001 11.1866 7.30001 10.8V5.20001C7.30001 4.81341 7.61341 4.50001 8.00001 4.50001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.8 8.70001C11.1866 8.70001 11.5 9.01341 11.5 9.40001V10.8C11.5 11.1866 11.1866 11.5 10.8 11.5C10.4134 11.5 10.1 11.1866 10.1 10.8V9.40001C10.1 9.01341 10.4134 8.70001 10.8 8.70001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
