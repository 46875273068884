import { withBemMod } from '@bem-react/core';
import { cnRadioButton } from '@yandex-lego/components/RadioButton/desktop';

import './RadioButton_fullWidth.css';

export type WithFullWidthProps = {
    fullWidth?: boolean;
};

export const withFullWidth = withBemMod<WithFullWidthProps>(cnRadioButton(), {
    fullWidth: true,
});
