import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'sortAscending';
    sizeManagement?: 'self' | 'parent';
}

export const withSortAscendingIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'sortAscending',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H4C3.44772 13 3 12.5523 3 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 18C3 17.4477 3.44772 17 4 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H4C3.44772 7 3 6.55228 3 6Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 8.00001C1.70001 7.61341 2.01341 7.30001 2.40001 7.30001H10.8C11.1866 7.30001 11.5 7.61341 11.5 8.00001C11.5 8.38661 11.1866 8.70001 10.8 8.70001H2.40001C2.01341 8.70001 1.70001 8.38661 1.70001 8.00001Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 12.2C1.70001 11.8134 2.01341 11.5 2.40001 11.5H13.6C13.9866 11.5 14.3 11.8134 14.3 12.2C14.3 12.5866 13.9866 12.9 13.6 12.9H2.40001C2.01341 12.9 1.70001 12.5866 1.70001 12.2Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 3.80001C1.70001 3.41341 2.01341 3.10001 2.40001 3.10001H7.30001C7.68661 3.10001 8.00001 3.41341 8.00001 3.80001C8.00001 4.18661 7.68661 4.50001 7.30001 4.50001H2.40001C2.01341 4.50001 1.70001 4.18661 1.70001 3.80001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
