import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'travelTrain';
    sizeManagement?: 'self' | 'parent';
}

export const withTravelTrainIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'travelTrain',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.3255 0.748283C5.45874 0.304151 5.86753 0 6.33121 0H9.66875C10.1324 0 10.5412 0.304154 10.6745 0.74829L10.816 1.21992C11.7649 1.33444 12.7138 1.50684 13.6627 1.73713C14.4475 1.92754 15 2.63023 15 3.43776V11.1985C15 12.5764 14.0047 13.7218 12.6938 13.9551L11.7739 13.2191C11.5966 13.0773 11.3763 13 11.1492 13H4.85078C4.62371 13 4.4034 13.0773 4.22609 13.2191L3.30616 13.9551C1.99529 13.7218 1 12.5764 1 11.1985V3.43777C1.00001 2.63025 1.55254 1.92758 2.33728 1.73714C3.28619 1.50685 4.23509 1.33444 5.184 1.21992L5.3255 0.748283ZM12 8C12.574 8 13.0593 7.68165 13.1351 7.16L13.5899 4.51603C13.6686 3.97464 13.2815 3.46833 12.6996 3.35155C11.133 3.03719 9.56653 2.88002 8 2.88002C6.43349 2.88002 4.86697 3.0372 3.30043 3.35155C2.71852 3.46835 2.33142 3.97466 2.41015 4.51605L2.86485 7.16C2.94071 7.68164 3.42603 8 4 8H12ZM13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10C11 9.44771 11.4477 9 12 9C12.5523 9 13 9.44771 13 10ZM5 10C5 10.5523 4.55228 11 4 11C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9C4.55228 9 5 9.44771 5 10ZM6.5 1.5C6.5 1.22386 6.72386 1 7 1H9C9.27614 1 9.5 1.22386 9.5 1.5C9.5 1.77614 9.27614 2 9 2H7C6.72386 2 6.5 1.77614 6.5 1.5Z" fill="currentColor" /><path d="M12.2702 16H3.73051C3.55998 16 3.46873 15.8003 3.57922 15.6704L4.7008 14.352C4.8908 14.1287 5.16924 14 5.46247 14H10.5381C10.8313 14 11.1098 14.1287 11.2998 14.352L12.4213 15.6704C12.5318 15.8003 12.4407 16 12.2702 16Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
