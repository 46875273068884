import React, { FC } from 'react';

import {
    Button as LegoButton,
    IButtonProps,
} from '@yandex-lego/components/Button/desktop/bundle';
import { IconProvider } from '@yandex-lego/components/Button/Icon/Button-Icon';

import { Badge } from '../../../Badge/desktop/bundle';
import { Platform } from '../../../utils';
import { cnPageHeader } from '../../PageHeader.cn';

const withBadgeIconProvider = (
    iconProvider: IconProvider,
    hasBadge?: boolean,
) => {
    return (className: string) =>
        hasBadge ? (
            <Badge withClearButton>{iconProvider && iconProvider(className)}</Badge>
        ) : (
            iconProvider && iconProvider(className)
        );
};

interface PageHeaderButtonProps {
    className?: string;
    platform: Platform;
    icon: IconProvider;
    hasBadge?: boolean;
    innerRef?: IButtonProps['innerRef'];
    onClick?: () => void;
    title?: string;
    overrideButtonProps?: IButtonProps;
}

export const Button: FC<PageHeaderButtonProps> = (props) => {
    const {
        className,
        icon: iconBase,
        hasBadge,
        innerRef,
        onClick,
        title,
        overrideButtonProps,
    } = props;

    const size = 'l';
    const icon = withBadgeIconProvider(iconBase, hasBadge);

    return (
        <LegoButton
            className={cnPageHeader('Button', [className])}
            view="clear"
            size={size}
            icon={icon}
            title={title}
            onClick={onClick}
            innerRef={innerRef}
            {...overrideButtonProps}
        />
    );
};
