import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapDayOff';
    sizeManagement?: 'self' | 'parent';
}

export const withGapDayOffIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapDayOff',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 0.199951C4.44183 0.199951 4.8 0.558123 4.8 0.999951V1.49995H11.2V0.999951C11.2 0.558123 11.5582 0.199951 12 0.199951C12.4418 0.199951 12.8 0.558123 12.8 0.999951V1.49995H13.2C14.1941 1.49995 15 2.30584 15 3.29995V13.2C15 14.1941 14.1941 15 13.2 15H2.8C1.80589 15 1 14.1941 1 13.2V3.29995C1 2.30584 1.80589 1.49995 2.8 1.49995H3.2V0.999951C3.2 0.558123 3.55817 0.199951 4 0.199951ZM5.56572 4.7676C5.2533 4.45518 4.74677 4.45518 4.43435 4.7676C4.12193 5.08002 4.12193 5.58655 4.43435 5.89897L6.86866 8.33329L4.43435 10.7676C4.12193 11.08 4.12193 11.5866 4.43435 11.899C4.74677 12.2114 5.2533 12.2114 5.56572 11.899L8.00003 9.46466L10.4343 11.899C10.7468 12.2114 11.2533 12.2114 11.5657 11.899C11.8781 11.5866 11.8781 11.08 11.5657 10.7676L9.1314 8.33329L11.5657 5.89897C11.8781 5.58655 11.8781 5.08002 11.5657 4.7676C11.2533 4.45518 10.7468 4.45518 10.4343 4.7676L8.00003 7.20191L5.56572 4.7676Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
