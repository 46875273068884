import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'call';
    sizeManagement?: 'self' | 'parent';
}

export const withCallIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'call',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_922_2861)"><path fillRule="evenodd" clipRule="evenodd" d="M2.82944 15.0018C1.91735 15.6859 1.62499 16.9243 2.13486 17.9441C3.67422 21.0228 7.29041 23.0211 10.6942 21.4313C15.4997 19.1869 19.3688 15.3179 21.6132 10.5124C23.2029 7.10859 21.2046 3.4924 18.1259 1.95304C17.1061 1.44316 15.8677 1.73553 15.1836 2.64761L12.9521 5.62301C11.8847 7.04617 12.2831 9.08022 13.8086 9.99549L14.4716 10.3933C14.6214 10.4832 14.7015 10.5893 14.732 10.66C14.746 10.6925 14.7486 10.7143 14.7487 10.7268C14.7487 10.7376 14.7472 10.7553 14.734 10.7843C14.5093 11.2761 14.0534 12.0221 13.1286 12.9468C12.2039 13.8716 11.4579 14.3274 10.9662 14.5521C10.9371 14.5654 10.9194 14.5669 10.9086 14.5669C10.8961 14.5668 10.8743 14.5642 10.8418 14.5502C10.7711 14.5197 10.665 14.4395 10.5751 14.2897L10.1773 13.6268C9.26205 12.1013 7.22799 11.7029 5.80483 12.7702L2.82944 15.0018ZM3.92372 17.0496C3.84611 16.8944 3.89061 16.7059 4.02944 16.6018L7.00483 14.3702C7.47922 14.0145 8.15724 14.1473 8.46232 14.6557L8.86011 15.3187C9.37988 16.185 10.5328 16.9491 11.7974 16.3712C12.5289 16.0369 13.4682 15.4357 14.5428 14.361C15.6175 13.2864 16.2188 12.3471 16.553 11.6156C17.1309 10.351 16.3668 9.19805 15.5005 8.67829L14.8376 8.2805C14.3291 7.97541 14.1963 7.2974 14.5521 6.82301L16.7836 3.84761C16.8877 3.70878 17.0762 3.66428 17.2315 3.74189C19.6345 4.9434 20.8049 7.51686 19.8011 9.66604C17.7553 14.0463 14.2281 17.5735 9.84787 19.6193C7.69869 20.623 5.12523 19.4527 3.92372 17.0496Z" fill="currentColor" /></g><defs><clipPath id="clip0_922_2861"><rect width={24} height={24} fill="white" /></clipPath></defs></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_921_2703)"><path fillRule="evenodd" clipRule="evenodd" d="M1.58062 10.1013C0.942159 10.5801 0.737509 11.4471 1.09442 12.1609C2.17197 14.316 4.70331 15.7148 7.08596 14.602C10.4498 13.0309 13.1581 10.3226 14.7292 6.9587C15.8421 4.57604 14.4432 2.04471 12.2881 0.967156C11.5743 0.610245 10.7074 0.814898 10.2285 1.45336L8.66646 3.53614C7.9193 4.53235 8.19822 5.95619 9.26602 6.59687L9.73011 6.87532C9.83498 6.93824 9.89107 7.01254 9.91241 7.06201C9.92224 7.08478 9.92404 7.10005 9.92409 7.10879C9.92414 7.11635 9.92308 7.12873 9.91379 7.14906C9.7565 7.49327 9.43739 8.01547 8.79006 8.6628C8.14273 9.31013 7.62053 9.62924 7.27632 9.78652C7.25599 9.79582 7.24361 9.79687 7.23605 9.79683C7.22731 9.79678 7.21204 9.79498 7.18927 9.78515C7.13981 9.76381 7.0655 9.70771 7.00258 9.60284L6.72413 9.13876C6.08345 8.07095 4.65961 7.79204 3.6634 8.5392L1.58062 10.1013ZM2.34662 11.5348C2.29229 11.4261 2.32344 11.2942 2.42062 11.2213L4.5034 9.6592C4.83547 9.41015 5.31008 9.50312 5.52364 9.85905L5.80209 10.3231C6.16593 10.9295 6.97297 11.4644 7.85819 11.0599C8.37024 10.8259 9.02776 10.405 9.78001 9.65275C10.5323 8.9005 10.9532 8.24298 11.1871 7.73093C11.5916 6.84571 11.0568 6.03867 10.4504 5.67483L9.98632 5.39638C9.63038 5.18282 9.53741 4.70821 9.78646 4.37614L11.3485 2.29336C11.4214 2.19618 11.5534 2.16503 11.662 2.21935C13.3442 3.06041 14.1634 4.86184 13.4608 6.36626C12.0287 9.43242 9.55968 11.9014 6.49352 13.3335C4.9891 14.0362 3.18767 13.2169 2.34662 11.5348Z" fill="currentColor" /></g><defs><clipPath id="clip0_921_2703"><rect width={16} height={16} fill="white" /></clipPath></defs></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
