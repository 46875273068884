import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'trash';
    sizeManagement?: 'self' | 'parent';
}

export const withTrashIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'trash',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 6C7 3.79086 8.79086 2 11 2H13C15.2091 2 17 3.79086 17 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H20V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H7ZM6 8V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V8H6ZM15 6C15 4.89543 14.1046 4 13 4H11C9.89543 4 9 4.89543 9 6H15ZM9.5 10C10.0523 10 10.5 10.4477 10.5 11V17C10.5 17.5523 10.0523 18 9.5 18C8.94772 18 8.5 17.5523 8.5 17V11C8.5 10.4477 8.94772 10 9.5 10ZM14.5 10C15.0523 10 15.5 10.4477 15.5 11V17C15.5 17.5523 15.0523 18 14.5 18C13.9477 18 13.5 17.5523 13.5 17V11C13.5 10.4477 13.9477 10 14.5 10Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.5 3.8C4.5 2.2536 5.7536 1 7.3 1H8.7C10.2464 1 11.5 2.2536 11.5 3.8L14.3 3.8C14.6866 3.8 15 4.1134 15 4.5C15 4.8866 14.6866 5.2 14.3 5.2H13.6V12.2C13.6 13.7464 12.3464 15 10.8 15H5.2C3.65361 15 2.4 13.7464 2.4 12.2V5.2H1.7C1.3134 5.2 1 4.8866 1 4.5C1 4.1134 1.3134 3.8 1.7 3.8L4.5 3.8ZM5.9 3.8L10.1 3.8C10.1 3.0268 9.4732 2.4 8.7 2.4H7.3C6.5268 2.4 5.9 3.0268 5.9 3.8ZM3.8 5.2V12.2C3.8 12.9732 4.4268 13.6 5.2 13.6H10.8C11.5732 13.6 12.2 12.9732 12.2 12.2V5.2H3.8ZM6.25 6.6C6.6366 6.6 6.95 6.9134 6.95 7.3V11.5C6.95 11.8866 6.6366 12.2 6.25 12.2C5.8634 12.2 5.55 11.8866 5.55 11.5V7.3C5.55 6.9134 5.8634 6.6 6.25 6.6ZM9.75 6.6C10.1366 6.6 10.45 6.9134 10.45 7.3V11.5C10.45 11.8866 10.1366 12.2 9.75 12.2C9.3634 12.2 9.05 11.8866 9.05 11.5V7.3C9.05 6.9134 9.3634 6.6 9.75 6.6Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
