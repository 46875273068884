import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'numbersSeven';
    sizeManagement?: 'self' | 'parent';
}

export const withNumbersSevenIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'numbersSeven',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19 4.00002C19.5523 4.00002 20 4.44774 20 5.00002V19C20 19.5523 19.5523 20 19 20C18.4477 20 18 19.5523 18 19V5.00002C18 4.44774 18.4477 4.00002 19 4.00002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M15 4.00002C15.5523 4.00002 16 4.44774 16 5.00002V19C16 19.5523 15.5523 20 15 20C14.4477 20 14 19.5523 14 19V5.00002C14 4.44774 14.4477 4.00002 15 4.00002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.79047 4.02222C5.3305 3.9065 5.86208 4.25047 5.9778 4.79049L8 14.2274L10.0222 4.79049C10.1379 4.25047 10.6695 3.9065 11.2095 4.02222C11.7496 4.13794 12.0935 4.66953 11.9778 5.20955L8.9778 19.2096C8.879 19.6706 8.47154 20 8 20C7.52846 20 7.121 19.6706 7.0222 19.2096L4.0222 5.20955C3.90648 4.66953 4.25045 4.13794 4.79047 4.02222Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.9 2.39999C13.2866 2.39999 13.6 2.71339 13.6 3.09999V12.9C13.6 13.2866 13.2866 13.6 12.9 13.6C12.5134 13.6 12.2 13.2866 12.2 12.9V3.09999C12.2 2.71339 12.5134 2.39999 12.9 2.39999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.1 2.39999C10.4866 2.39999 10.8 2.71339 10.8 3.09999V12.9C10.8 13.2866 10.4866 13.6 10.1 13.6C9.71339 13.6 9.39999 13.2866 9.39999 12.9V3.09999C9.39999 2.71339 9.71339 2.39999 10.1 2.39999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M2.95332 2.41552C3.33133 2.33452 3.70344 2.5753 3.78445 2.95332L5.19999 9.55916L6.61552 2.95332C6.69653 2.5753 7.06864 2.33452 7.44666 2.41552C7.82467 2.49653 8.06545 2.86864 7.98445 3.24666L5.88445 13.0467C5.81529 13.3694 5.53006 13.6 5.19999 13.6C4.86991 13.6 4.58468 13.3694 4.51552 13.0467L2.41552 3.24666C2.33452 2.86864 2.5753 2.49653 2.95332 2.41552Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
