import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'mailRead';
    sizeManagement?: 'self' | 'parent';
}

export const withMailReadIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'mailRead',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.9713 4.68357C12.3672 4.34799 11.6328 4.34799 11.0287 4.68357L5.02871 8.0169C4.7604 8.16597 4.53514 8.37156 4.36448 8.61409L11.0287 12.3164C11.6328 12.652 12.3672 12.652 12.9713 12.3164L19.6355 8.61409C19.4649 8.37156 19.2396 8.16597 18.9713 8.0169L12.9713 4.68357ZM20 10.6995L13.9426 14.0648C12.7345 14.7359 11.2655 14.7359 10.0574 14.0648L4 10.6995V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V10.6995ZM10.0574 2.93526C11.2655 2.2641 12.7345 2.26409 13.9426 2.93526L19.9426 6.26859C21.2124 6.97407 22 8.31255 22 9.76522V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V9.76522C2 8.31255 2.78757 6.97407 4.05743 6.26859L10.0574 2.93526Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.6799 2.87849C8.25707 2.64358 7.74293 2.64358 7.3201 2.87849L3.1201 5.21182C2.93228 5.31617 2.7746 5.46008 2.65513 5.62985L7.3201 8.2215C7.74293 8.45641 8.25707 8.45641 8.6799 8.2215L13.3449 5.62985C13.2254 5.46008 13.0677 5.31617 12.8799 5.21182L8.6799 2.87849ZM13.6 7.08965L9.3598 9.44532C8.51414 9.91513 7.48586 9.91513 6.6402 9.44532L2.4 7.08965V11.5C2.4 12.2732 3.0268 12.9 3.8 12.9H12.2C12.9732 12.9 13.6 12.2732 13.6 11.5V7.08965ZM6.6402 1.65467C7.48586 1.18485 8.51414 1.18485 9.3598 1.65467L13.5598 3.988C14.4487 4.48183 15 5.41877 15 6.43564V11.5C15 13.0464 13.7464 14.3 12.2 14.3H3.8C2.2536 14.3 1 13.0464 1 11.5V6.43564C1 5.41877 1.5513 4.48183 2.4402 3.988L6.6402 1.65467Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
