import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'fullscreenExit';
    sizeManagement?: 'self' | 'parent';
}

export const withFullscreenExitIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'fullscreenExit',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.7071 3.29295C20.3166 2.90243 19.6834 2.90243 19.2929 3.29295L15.7573 6.82849C15.2757 7.31017 14.794 7.95591 14.3682 8.59808L14.1654 8.59844C14.1694 8.53798 14.1788 8.47697 14.1937 8.41583C14.4213 7.48603 14.6327 6.38339 14.636 5.48604L14.636 4.70718C14.636 4.1549 14.1883 3.70718 13.636 3.70718C13.0867 3.70718 12.6409 4.15005 12.636 4.69819L12.636 10.364C12.636 10.9163 13.0837 11.364 13.636 11.364L19.2929 11.364C19.8451 11.364 20.2929 10.9163 20.2929 10.364C20.2929 9.8131 19.8473 9.36623 19.2969 9.36404L19.2929 9.36404L18.5355 9.36404C17.6344 9.36405 16.5215 9.57701 15.5843 9.80639C15.5231 9.82136 15.4621 9.83068 15.4016 9.83477L15.402 9.63187C16.0441 9.20607 16.6899 8.72438 17.1715 8.2427L20.7071 4.70717C21.0976 4.31664 21.0976 3.68348 20.7071 3.29295Z" fill="currentColor" /><path d="M3.29291 20.7071C3.68343 21.0976 4.3166 21.0976 4.70712 20.7071L8.24266 17.1716C8.72434 16.6899 9.20602 16.0441 9.63182 15.402L9.83464 15.4016C9.83055 15.4621 9.82123 15.5231 9.80627 15.5842C9.57873 16.514 9.36733 17.6167 9.36399 18.514L9.36399 19.2929C9.36399 19.8451 9.81171 20.2929 10.364 20.2929C10.9133 20.2929 11.3591 19.85 11.364 19.3019L11.364 13.636C11.364 13.0837 10.9163 12.636 10.364 12.636L4.70714 12.636C4.15485 12.636 3.70714 13.0837 3.70714 13.636C3.70714 14.1869 4.15267 14.6338 4.70309 14.636L4.70714 14.636L5.46446 14.636C6.36563 14.636 7.47846 14.423 8.41567 14.1937C8.47685 14.1787 8.5379 14.1694 8.59839 14.1653L8.59803 14.3682C7.95587 14.794 7.31012 15.2757 6.82844 15.7573L3.29291 19.2929C2.90238 19.6834 2.90238 20.3166 3.29291 20.7071Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.0949 1.90505C13.8216 1.63169 13.3784 1.63169 13.105 1.90505L10.6301 4.37993C10.2929 4.7171 9.95576 5.16912 9.6577 5.61864L9.51573 5.61889C9.51859 5.57657 9.52512 5.53386 9.53559 5.49107C9.69487 4.84021 9.84285 4.06836 9.84518 3.44021V2.89501C9.84518 2.50841 9.53178 2.19501 9.14518 2.19501C8.76068 2.19501 8.44859 2.50502 8.44521 2.88872L8.44521 6.85482C8.44521 7.24141 8.7586 7.55481 9.14518 7.55481L13.105 7.55481C13.4916 7.55481 13.805 7.24141 13.805 6.85481C13.805 6.46916 13.4931 6.15634 13.1078 6.15482L13.105 6.15481L12.5749 6.15482C11.944 6.15482 11.1651 6.30389 10.509 6.46446C10.4662 6.47494 10.4234 6.48146 10.3811 6.48432L10.3814 6.34229C10.8309 6.04423 11.2829 5.70705 11.6201 5.36988L14.0949 2.895C14.3683 2.62164 14.3683 2.17842 14.0949 1.90505Z" fill="currentColor" /><path d="M1.9051 14.095C2.17847 14.3683 2.62168 14.3683 2.89505 14.095L5.36992 11.6201C5.7071 11.2829 6.04428 10.8309 6.34234 10.3814L6.48431 10.3811C6.48145 10.4234 6.47492 10.4661 6.46445 10.5089C6.30517 11.1598 6.15719 11.9317 6.15486 12.5598L6.15486 13.105C6.15486 13.4916 6.46826 13.805 6.85486 13.805C7.23936 13.805 7.55145 13.495 7.55483 13.1113L7.55483 9.14519C7.55483 8.7586 7.24144 8.44519 6.85486 8.44519L2.89506 8.44519C2.50846 8.44519 2.19506 8.7586 2.19506 9.1452C2.19506 9.53085 2.50693 9.84366 2.89222 9.84519L2.89506 9.8452L3.42518 9.84519C4.05601 9.84519 4.83498 9.69611 5.49104 9.53555C5.53386 9.52507 5.57659 9.51854 5.61894 9.51568L5.61869 9.65771C5.16917 9.95578 4.71715 10.293 4.37997 10.6301L1.9051 13.105C1.63173 13.3784 1.63173 13.8216 1.9051 14.095Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
