import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'heartChecked';
    sizeManagement?: 'self' | 'parent';
}

export const withHeartCheckedIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'heartChecked',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.7352 4.50994C11.83 4.59614 11.9182 4.68246 12 4.76784C12.0818 4.68246 12.17 4.59614 12.2648 4.50994C13.1239 3.72892 14.4849 2.99988 16.5 2.99988C18.031 2.99988 19.4349 3.61907 20.4432 4.78245C21.4446 5.93791 22 7.56188 22 9.49988C22 11.874 20.6034 14.1815 19.0429 16.0505C17.4539 17.9537 15.5441 19.5791 14.2238 20.6082C12.9071 21.6344 11.0929 21.6344 9.7762 20.6082C8.4559 19.5791 6.54606 17.9537 4.95708 16.0505C3.3966 14.1815 2 11.874 2 9.49988C2 7.56188 2.55541 5.93791 3.55681 4.78245C4.56507 3.61907 5.96899 2.99988 7.5 2.99988C9.5151 2.99988 10.8761 3.72892 11.7352 4.50994Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 2.93758C7.94272 2.87782 7.881 2.8174 7.81462 2.75705C7.21324 2.21034 6.26057 1.70001 4.85 1.70001C3.77829 1.70001 2.79555 2.13345 2.08977 2.94781C1.38879 3.75664 1 4.89341 1 6.25001C1 7.91192 1.97762 9.52716 3.06996 10.8355C4.18224 12.1677 5.51913 13.3055 6.44334 14.0258C7.365 14.7442 8.635 14.7442 9.55666 14.0258C10.4809 13.3055 11.8178 12.1677 12.93 10.8355C14.0224 9.52716 15 7.91192 15 6.25001C15 4.89341 14.6112 3.75664 13.9102 2.94781C13.2045 2.13345 12.2217 1.70001 11.15 1.70001C9.73943 1.70001 8.78676 2.21034 8.18538 2.75705C8.119 2.8174 8.05729 2.87782 8 2.93758Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
