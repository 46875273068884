import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'card';
    sizeManagement?: 'self' | 'parent';
}

export const withCardIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'card',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 8C2 5.79086 3.79086 4 6 4H18C20.2091 4 22 5.79086 22 8V16C22 18.2091 20.2091 20 18 20H6C3.79086 20 2 18.2091 2 16V8ZM4 12V16C4 17.1046 4.89543 18 6 18H18C19.1046 18 20 17.1046 20 16V12H4ZM20 10H4V8C4 6.89543 4.89543 6 6 6H18C19.1046 6 20 6.89543 20 8V10Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6 15C6 14.4477 6.44772 14 7 14H9C9.55228 14 10 14.4477 10 15C10 15.5523 9.55228 16 9 16H7C6.44772 16 6 15.5523 6 15Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 5.20003C1 3.65363 2.2536 2.40002 3.8 2.40002H12.2C13.7464 2.40002 15 3.65363 15 5.20002V10.8C15 12.3464 13.7464 13.6 12.2 13.6H3.8C2.2536 13.6 1 12.3464 1 10.8V5.20003ZM2.4 8.00002V10.8C2.4 11.5732 3.0268 12.2 3.8 12.2H12.2C12.9732 12.2 13.6 11.5732 13.6 10.8V8.00002H2.4ZM13.6 6.60002H2.4V5.20003C2.4 4.42683 3.0268 3.80002 3.8 3.80002H12.2C12.9732 3.80002 13.6 4.42683 13.6 5.20002V6.60002ZM3.8 10.1C3.8 9.71343 4.1134 9.40002 4.5 9.40002H5.9C6.2866 9.40002 6.6 9.71343 6.6 10.1C6.6 10.4866 6.2866 10.8 5.9 10.8H4.5C4.1134 10.8 3.8 10.4866 3.8 10.1Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
