import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'share';
    sizeManagement?: 'self' | 'parent';
}

export const withShareIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'share',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17.5 4C16.6716 4 16 4.67157 16 5.5C16 6.32843 16.6716 7 17.5 7C18.3284 7 19 6.32843 19 5.5C19 4.67157 18.3284 4 17.5 4ZM14 5.5C14 3.567 15.567 2 17.5 2C19.433 2 21 3.567 21 5.5C21 7.433 19.433 9 17.5 9C16.5814 9 15.7455 8.64614 15.1211 8.06731L9.89712 11.1542C9.96433 11.4251 10 11.7084 10 12C10 12.2917 9.96432 12.575 9.89709 12.8459L15.121 15.9328C15.7454 15.3539 16.5814 15 17.5 15C19.433 15 21 16.567 21 18.5C21 20.433 19.433 22 17.5 22C15.567 22 14 20.433 14 18.5C14 18.2084 14.0357 17.9251 14.1029 17.6542L8.87887 14.5673C8.25448 15.1461 7.41857 15.5 6.5 15.5C4.567 15.5 3 13.933 3 12C3 10.067 4.567 8.5 6.5 8.5C7.41861 8.5 8.25457 8.85389 8.87897 9.43278L14.1029 6.3459C14.0357 6.07502 14 5.79168 14 5.5ZM6.5 10.5C5.67157 10.5 5 11.1716 5 12C5 12.8284 5.67157 13.5 6.5 13.5C7.32843 13.5 8 12.8284 8 12C8 11.1716 7.32843 10.5 6.5 10.5ZM17.5 17C16.6716 17 16 17.6716 16 18.5C16 19.3284 16.6716 20 17.5 20C18.3284 20 19 19.3284 19 18.5C19 17.6716 18.3284 17 17.5 17Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.85 2.4C11.2701 2.4 10.8 2.8701 10.8 3.45C10.8 4.0299 11.2701 4.5 11.85 4.5C12.4299 4.5 12.9 4.0299 12.9 3.45C12.9 2.8701 12.4299 2.4 11.85 2.4ZM9.40001 3.45C9.40001 2.0969 10.4969 1 11.85 1C13.2031 1 14.3 2.0969 14.3 3.45C14.3 4.8031 13.2031 5.9 11.85 5.9C11.207 5.9 10.6218 5.65228 10.1848 5.24707L6.52798 7.4079C6.57504 7.59751 6.60001 7.79584 6.60001 8C6.60001 8.20415 6.57504 8.40247 6.52799 8.59207L10.1848 10.7529C10.6219 10.3477 11.207 10.1 11.85 10.1C13.2031 10.1 14.3 11.1969 14.3 12.55C14.3 13.9031 13.2031 15 11.85 15C10.4969 15 9.40001 13.9031 9.40001 12.55C9.40001 12.3458 9.42498 12.1475 9.47204 11.9579L5.81527 9.79708C5.37819 10.2023 4.79303 10.45 4.15001 10.45C2.79691 10.45 1.70001 9.3531 1.70001 8C1.70001 6.6469 2.79691 5.55 4.15001 5.55C4.79302 5.55 5.37817 5.79771 5.81525 6.2029L9.47203 4.04207C9.42498 3.85247 9.40001 3.65415 9.40001 3.45ZM4.15001 6.95C3.57011 6.95 3.10001 7.4201 3.10001 8C3.10001 8.5799 3.57011 9.05 4.15001 9.05C4.72991 9.05 5.20001 8.5799 5.20001 8C5.20001 7.4201 4.72991 6.95 4.15001 6.95ZM11.85 11.5C11.2701 11.5 10.8 11.9701 10.8 12.55C10.8 13.1299 11.2701 13.6 11.85 13.6C12.4299 13.6 12.9 13.1299 12.9 12.55C12.9 11.9701 12.4299 11.5 11.85 11.5Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
