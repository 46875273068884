import { withBemMod } from '@bem-react/core';
import { cnBadge } from '@yandex-lego/components/Badge/desktop';

import './Badge_clearButton.css';

export type WithFullWidthProps = {
    withClearButton?: boolean;
};

export const withClearButton = withBemMod<WithFullWidthProps>(cnBadge(), { withClearButton: true });
