import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowShortRight';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowShortRightIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowShortRight',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 's':
                svg = <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.46869 9.71842C3.1758 10.0113 3.1758 10.4862 3.46869 10.7791C3.76159 11.072 4.23646 11.072 4.52935 10.7791L8.77865 6.52978C8.89251 6.41592 8.96211 6.27456 8.98745 6.12708C9.02734 5.89514 8.95775 5.64804 8.77868 5.46897L4.52938 1.21967C4.23649 0.926777 3.76161 0.926777 3.46872 1.21967C3.17583 1.51256 3.17583 1.98744 3.46872 2.28033L7.18775 5.99936L3.46869 9.71842Z" fill="currentColor" /></svg>;
                break;
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L14.5858 12L8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.40503 2.60502C5.13166 2.87839 5.13166 3.3216 5.40503 3.59497L9.81005 7.99999L5.40503 12.405C5.13166 12.6784 5.13166 13.1216 5.40503 13.395C5.67839 13.6683 6.12161 13.6683 6.39498 13.395L11.295 8.49497C11.5683 8.2216 11.5683 7.77839 11.295 7.50502L6.39498 2.60502C6.12161 2.33165 5.67839 2.33165 5.40503 2.60502Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
