import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'help';
    sizeManagement?: 'self' | 'parent';
}

export const withHelpIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'help',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="currentColor" /><path d="M13.5 16.5C13.5 17.3284 12.8285 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6715 11.1716 15 12 15C12.8285 15 13.5 15.6715 13.5 16.5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 8C11.1716 8 10.5 8.67157 10.5 9.5C10.5 10.0523 10.0523 10.5 9.5 10.5C8.94772 10.5 8.5 10.0523 8.5 9.5C8.5 7.567 10.067 6 12 6C13.933 6 15.5 7.567 15.5 9.5C15.5 10.9907 14.5367 11.8763 13.8747 12.4059C13.7202 12.5294 13.5939 12.6257 13.4851 12.7086C13.3192 12.8351 13.1942 12.9304 13.0729 13.0436C13.0352 13.0788 13.0094 13.1059 12.9922 13.1253C12.9306 13.6184 12.5099 14 12 14C11.4477 14 11 13.5523 11 13C11 12.3096 11.4339 11.8376 11.7083 11.5814C11.8977 11.4047 12.16 11.2034 12.3805 11.0342C12.472 10.964 12.5564 10.8993 12.6253 10.8441C13.2133 10.3737 13.5 10.0093 13.5 9.5C13.5 8.67157 12.8284 8 12 8Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 2.4C4.90721 2.4 2.4 4.90721 2.4 8C2.4 11.0928 4.90721 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 8 2.4ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z" fill="currentColor" /><path d="M9.05 11.15C9.05 11.7299 8.5799 12.2 8 12.2C7.4201 12.2 6.95 11.7299 6.95 11.15C6.95 10.5701 7.4201 10.1 8 10.1C8.5799 10.1 9.05 10.5701 9.05 11.15Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 5.2C7.4201 5.2 6.95 5.6701 6.95 6.25C6.95 6.6366 6.6366 6.95 6.25 6.95C5.8634 6.95 5.55 6.6366 5.55 6.25C5.55 4.8969 6.6469 3.8 8 3.8C9.3531 3.8 10.45 4.8969 10.45 6.25C10.45 7.29348 9.7757 7.91338 9.31229 8.28411C9.20417 8.3706 9.11571 8.43802 9.03959 8.49605C8.92341 8.5846 8.83594 8.65127 8.75106 8.73049C8.72463 8.75516 8.70654 8.77412 8.69456 8.78769C8.65142 9.1329 8.3569 9.4 8 9.4C7.6134 9.4 7.3 9.0866 7.3 8.7C7.3 8.21669 7.6037 7.88632 7.79581 7.70701C7.92839 7.58328 8.112 7.44239 8.26633 7.32397C8.33043 7.27479 8.38948 7.22948 8.43771 7.19089C8.8493 6.86162 9.05 6.60652 9.05 6.25C9.05 5.6701 8.5799 5.2 8 5.2Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
