import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'logIn';
    sizeManagement?: 'self' | 'parent';
}

export const withLogInIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'logIn',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 12C3 12.5523 3.44772 13 4 13H10C10.6812 13 11.4784 12.884 12.2336 12.731L12.3772 12.8742C12.3316 12.914 12.2819 12.9506 12.2281 12.9832C11.4097 13.4798 10.4805 14.11 9.84365 14.7422L9.29292 15.2929C8.90239 15.6834 8.90239 16.3166 9.29292 16.7071C9.68132 17.0955 10.3097 17.0976 10.7007 16.7134L14.7071 12.7071C15.0465 12.3677 15.0909 11.845 14.8404 11.4576C14.8026 11.3992 14.7582 11.344 14.7071 11.2929L10.7071 7.29289C10.3166 6.90237 9.68344 6.90237 9.29292 7.29289C8.90335 7.68246 8.90239 8.31349 9.29006 8.70424L9.29292 8.70711L9.82843 9.24261C10.4657 9.87983 11.4031 10.5161 12.228 11.0166C12.2819 11.0493 12.3316 11.0859 12.3773 11.1258L12.2336 11.269C11.4784 11.116 10.6812 11 10 11H4C3.44772 11 3 11.4477 3 12Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13 4C13 3.44772 13.4477 3 14 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H14C13.4477 21 13 20.5523 13 20C13 19.4477 13.4477 19 14 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H14C13.4477 5 13 4.55228 13 4Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.70007 7.99998C1.70007 8.38658 2.01348 8.69998 2.40007 8.69998H6.60007C7.07691 8.69998 7.63496 8.61877 8.16358 8.51167L8.26413 8.61187C8.23218 8.63978 8.19737 8.66537 8.15969 8.68823C7.58688 9.0358 6.93652 9.4769 6.49071 9.91938L6.10509 10.305C5.83172 10.5784 5.83172 11.0216 6.10509 11.295C6.37694 11.5668 6.81677 11.5683 7.09049 11.2995L9.89502 8.49493C10.1337 8.25627 10.164 7.88815 9.98593 7.61662C9.96004 7.57714 9.92974 7.5397 9.89504 7.505L7.09504 4.705C6.82167 4.43163 6.37846 4.43163 6.10509 4.705C5.83239 4.9777 5.83172 5.41941 6.10308 5.69294L6.10509 5.69495L6.47995 6.0698C6.92601 6.51586 7.58224 6.96127 8.15968 7.31163C8.19737 7.3345 8.23221 7.36011 8.26418 7.38803L8.16358 7.48828C7.63496 7.38118 7.07691 7.29998 6.60007 7.29998H2.40007C2.01348 7.29998 1.70007 7.61338 1.70007 7.99998Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.70003 2.40001C8.70003 2.01341 9.01343 1.70001 9.40003 1.70001H11.5C13.0464 1.70001 14.3 2.95361 14.3 4.50001V11.5C14.3 13.0464 13.0464 14.3 11.5 14.3H9.40003C9.01343 14.3 8.70003 13.9866 8.70003 13.6C8.70003 13.2134 9.01343 12.9 9.40003 12.9H11.5C12.2732 12.9 12.9 12.2732 12.9 11.5V4.50001C12.9 3.72681 12.2732 3.10001 11.5 3.10001H9.40003C9.01343 3.10001 8.70003 2.78661 8.70003 2.40001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
