import { ComponentProps } from 'react';

import { compose, composeU } from '@bem-react/core';
import {
    withThemeGhost,
    withThemeNormal,
} from '@yandex-lego/components/Link';

import { Link as LinkBase } from '../Link';

export const Link = compose(
    composeU(
        withThemeGhost,
        withThemeNormal,
    ),
)(LinkBase);

export type ILinkProps = ComponentProps<typeof Link>;
