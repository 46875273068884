import { withBemMod } from '@bem-react/core';

import { cnUserAvatar } from '../UserAvatar.cn';

import './UserAvatar_size_s.css';

export type WithSizeSProps = {
    size?: 's';
};

export const withSizeS = withBemMod<WithSizeSProps>(cnUserAvatar(), {
    size: 's',
});
