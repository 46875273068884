import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'numbersTwo';
    sizeManagement?: 'self' | 'parent';
}

export const withNumbersTwoIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'numbersTwo',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10 4C10.5523 4 11 4.44772 11 5V19C11 19.5523 10.5523 20 10 20C9.44772 20 9 19.5523 9 19V5C9 4.44772 9.44772 4 10 4Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M14 4C14.5523 4 15 4.44772 15 5V19C15 19.5523 14.5523 20 14 20C13.4477 20 13 19.5523 13 19V5C13 4.44772 13.4477 4 14 4Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.60002 2.40002C6.98662 2.40002 7.30002 2.71343 7.30002 3.10002V12.9C7.30002 13.2866 6.98662 13.6 6.60002 13.6C6.21343 13.6 5.90002 13.2866 5.90002 12.9V3.10002C5.90002 2.71343 6.21343 2.40002 6.60002 2.40002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9.40002 2.40002C9.78662 2.40002 10.1 2.71343 10.1 3.10002V12.9C10.1 13.2866 9.78662 13.6 9.40002 13.6C9.01342 13.6 8.70002 13.2866 8.70002 12.9V3.10002C8.70002 2.71343 9.01342 2.40002 9.40002 2.40002Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
