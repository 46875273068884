import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'category';
    sizeManagement?: 'self' | 'parent';
}

export const withCategoryIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'category',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 8C8 7.44772 8.44772 7 9 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H9C8.44772 9 8 8.55228 8 8Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 2C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22L16 22C18.2091 22 20 20.2091 20 18V6C20 3.79086 18.2091 2 16 2H8ZM18 6C18 4.89543 17.1046 4 16 4H8C6.89543 4 6 4.89543 6 6V16.7639C6.53076 16.2889 7.23165 16 8 16L16 16C17.1046 16 18 15.1046 18 14V6ZM18 17.4649C17.4117 17.8052 16.7286 18 16 18L8 18C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20L16 20C17.1046 20 18 19.1046 18 18V17.4649Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.19989 5.2C5.19989 4.8134 5.5133 4.5 5.89989 4.5H10.0999C10.4865 4.5 10.7999 4.8134 10.7999 5.2C10.7999 5.5866 10.4865 5.9 10.0999 5.9H5.89989C5.5133 5.9 5.19989 5.5866 5.19989 5.2Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.20002 1C3.65363 1 2.40002 2.2536 2.40002 3.8V12.2C2.40002 13.7464 3.65363 15 5.20002 15L10.8001 15C12.3464 15 13.6001 13.7464 13.6001 12.2V8.7C13.6001 8.69787 13.6 8.69573 13.6 8.6936V3.8C13.6 2.2536 12.3464 1 10.8 1H5.20002ZM12.2001 11.8254C11.7882 12.0636 11.31 12.2 10.8 12.2H6.60002L6.59396 12.2H5.19995C4.81335 12.2 4.49995 12.5134 4.49995 12.9C4.49995 13.2866 4.81335 13.6 5.19995 13.6H6.93038L6.93667 13.6L10.8001 13.6C11.5733 13.6 12.2001 12.9732 12.2001 12.2V11.8254ZM9.40601 10.8H10.8C11.5732 10.8 12.2 10.1732 12.2 9.4V3.8C12.2 3.0268 11.5732 2.4 10.8 2.4H5.20002C4.42683 2.4 3.80002 3.0268 3.80002 3.8V11.3346C4.17154 11.0021 4.66214 10.8 5.19995 10.8H9.39995L9.40601 10.8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
