import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'folder';
    sizeManagement?: 'self' | 'parent';
}

export const withFolderIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'folder',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 5C4.89543 5 4 5.89543 4 7V16C4 17.1046 4.89543 18 6 18H18C19.1046 18 20 17.1046 20 16V10C20 8.89543 19.1046 8 18 8H13C12.7348 8 12.4804 7.89464 12.2929 7.70711L9.58579 5H6ZM2 7C2 4.79086 3.79086 3 6 3H10C10.2652 3 10.5196 3.10536 10.7071 3.29289L13.4142 6H18C20.2091 6 22 7.79086 22 10V16C22 18.2091 20.2091 20 18 20H6C3.79086 20 2 18.2091 2 16V7Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.8 3.10001C3.0268 3.10001 2.4 3.72681 2.4 4.50001V10.8C2.4 11.5732 3.0268 12.2 3.8 12.2H12.2C12.9732 12.2 13.6 11.5732 13.6 10.8V6.60001C13.6 5.82681 12.9732 5.20001 12.2 5.20001H8.7C8.51435 5.20001 8.3363 5.12626 8.20503 4.99499L6.31005 3.10001H3.8ZM1 4.50001C1 2.95362 2.2536 1.70001 3.8 1.70001H6.6C6.78565 1.70001 6.9637 1.77376 7.09497 1.90504L8.98995 3.80001H12.2C13.7464 3.80001 15 5.05362 15 6.60001V10.8C15 12.3464 13.7464 13.6 12.2 13.6H3.8C2.2536 13.6 1 12.3464 1 10.8V4.50001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
