import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'like';
    sizeManagement?: 'self' | 'parent';
}

export const withLikeIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'like',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.3466 2.53252C10.5198 2.20493 10.86 2 11.2306 2C13.2334 2 14.9721 3.63321 14.9742 5.69628L14.9932 8.5H19.1711C20.8314 8.48522 22.2608 9.98569 21.9936 11.7221L21.9922 11.7309L20.7049 19.6271C20.4877 21.0192 19.2486 22.0118 17.8834 21.9999H4.82901C4.82899 21.9999 4.82903 21.9999 4.82901 21.9999C3.31684 21.9999 2 20.7684 2 19.1999V12.7999C2 11.2314 3.31682 9.9999 4.82901 9.9999L6.39762 9.99997L10.3466 2.53252ZM6 12L4.82901 11.9999C4.829 11.9999 4.82903 11.9999 4.82901 11.9999C4.37347 11.9999 4 12.3833 4 12.7999V19.1999C4 19.6165 4.37347 19.9999 4.82901 19.9999L6 20V12ZM8 20L17.8991 19.9999C18.3113 20.0044 18.671 19.6935 18.729 19.3175L18.7303 19.309L20.0174 11.4144C20.0839 10.9661 19.6847 10.4944 19.1873 10.4999L19.1761 10.5001L14 10.5C13.4504 10.5 13.0038 10.0564 13 9.50679L12.9742 5.69997C12.9742 4.97277 12.4689 4.32528 11.7843 4.09206L8 11.2481V20Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.84262 1.37276C6.96388 1.14345 7.20202 1 7.46142 1C8.86338 1 10.0805 2.14327 10.0819 3.58743L10.0953 5.55H13.0198C14.182 5.53967 15.1825 6.58999 14.9955 7.80546L14.9945 7.81164L14.0934 13.339C13.9413 14.3135 13.0741 15.0082 12.1184 14.9999H2.98031C2.9803 14.9999 2.98032 14.9999 2.98031 14.9999C1.92179 14.9999 1 14.1379 1 13.0399V8.55993C1 7.46196 1.92177 6.59993 2.98031 6.59993L4.07833 6.59998L6.84262 1.37276ZM3.8 7.99997L2.98031 7.99993C2.9803 7.99993 2.98032 7.99993 2.98031 7.99993C2.66143 7.99995 2.4 8.26831 2.4 8.55993V13.0399C2.4 13.3315 2.66143 13.5999 2.98031 13.5999L3.8 13.6V7.99997ZM5.2 13.6L12.1294 13.5999C12.4179 13.6031 12.6697 13.3855 12.7103 13.1222L12.7112 13.1163L13.6122 7.59012C13.6588 7.27626 13.3793 6.94607 13.0311 6.94996L13.0233 6.95004L9.4 6.95C9.01526 6.95 8.70263 6.63949 8.70002 6.25475L8.68196 3.59473L8.68194 3.58998C8.68194 3.08094 8.32822 2.6277 7.84902 2.46444L5.2 7.47369V13.6Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
