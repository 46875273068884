import { cnGrid } from './Grid.cn';

import './Grid.css';

const normalizeClassNames = (className?: string | string[]) => {
    return new Array<string>().concat(className || []);
};

export type ContainerSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'fluid';
export type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | 'fill';
export type ColumnOffset = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
export type ColumnOrder = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'first' | 'last';

const guttersMap = {
    xs: 1,
    s: 2,
    m: 3,
    l: 4,
    xl: 5,
};

export type RowGutters = keyof typeof guttersMap | false;

// Container

const CLASS_CONTAINER = 'container';

export interface ContainerParams {
    size?: ContainerSize;
    className?: string | string[];
}

export const cnContainer = ({ size, className }: ContainerParams = {}) => {
    const containerClass = size ? `${CLASS_CONTAINER}-${size}` : CLASS_CONTAINER;
    const others = normalizeClassNames(className);

    return cnGrid(null, [containerClass, ...others]);
};

// Row

const CLASS_ROW = 'row';
const CLASS_GUTTERS = 'g';

export interface RowParams {
    className?: string | string[];
    xsGutters?: RowGutters;
    smGutters?: RowGutters;
    mdGutters?: RowGutters;
    lgGutters?: RowGutters;
    xlGutters?: RowGutters;
}

function addGuttersClass(classNames: string[], name: string, value?: RowGutters) {
    if (value !== undefined) {
        const num = value === false ? 0 : guttersMap[value];
        classNames.push(`${name}-${num}`);
    }
}

export const cnRow = (props: RowParams = {}) => {
    const classNames = [CLASS_ROW, ...normalizeClassNames(props.className)];

    addGuttersClass(classNames, CLASS_GUTTERS, props.xsGutters);
    addGuttersClass(classNames, `${CLASS_GUTTERS}-sm`, props.smGutters);
    addGuttersClass(classNames, `${CLASS_GUTTERS}-md`, props.mdGutters);
    addGuttersClass(classNames, `${CLASS_GUTTERS}-lg`, props.lgGutters);
    addGuttersClass(classNames, `${CLASS_GUTTERS}-xl`, props.xlGutters);

    return cnGrid('Row', classNames);
};

// Column

const CLASS_COL = 'col';
const CLASS_OFFSET = 'offset';
const CLASS_ORDER = 'order';

export interface ColParams {
    className?: string | string[];

    xs?: ColumnSize;
    sm?: ColumnSize;
    md?: ColumnSize;
    lg?: ColumnSize;
    xl?: ColumnSize;

    xsOffset?: ColumnOffset;
    smOffset?: ColumnOffset;
    mdOffset?: ColumnOffset;
    lgOffset?: ColumnOffset;
    xlOffset?: ColumnOffset;

    xsOrder?: ColumnOrder;
    smOrder?: ColumnOrder;
    mdOrder?: ColumnOrder;
    lgOrder?: ColumnOrder;
    xlOrder?: ColumnOrder;
}

function addColClass(
    classNames: string[],
    name: string,
    value: ColumnSize | ColumnOffset | ColumnOrder | undefined,
) {
    if (value !== undefined) {
        const className = value === 'fill' ? name : `${name}-${value}`;
        classNames.push(className);
    }
}

export const cnCol = (props: ColParams = { xs: 'fill' }) => {
    const names = normalizeClassNames(props.className);

    addColClass(names, CLASS_COL, props.xs);
    addColClass(names, `${CLASS_COL}-sm`, props.sm);
    addColClass(names, `${CLASS_COL}-md`, props.md);
    addColClass(names, `${CLASS_COL}-lg`, props.lg);
    addColClass(names, `${CLASS_COL}-xl`, props.xl);

    addColClass(names, CLASS_OFFSET, props.xsOffset);
    addColClass(names, `${CLASS_OFFSET}-sm`, props.smOffset);
    addColClass(names, `${CLASS_OFFSET}-md`, props.mdOffset);
    addColClass(names, `${CLASS_OFFSET}-lg`, props.lgOffset);
    addColClass(names, `${CLASS_OFFSET}-xl`, props.xlOffset);

    addColClass(names, CLASS_ORDER, props.xsOrder);
    addColClass(names, `${CLASS_ORDER}-sm`, props.smOrder);
    addColClass(names, `${CLASS_ORDER}-md`, props.mdOrder);
    addColClass(names, `${CLASS_ORDER}-lg`, props.lgOrder);
    addColClass(names, `${CLASS_ORDER}-xl`, props.xlOrder);

    return cnGrid('Col', names);
};
