import React, { FC } from 'react';

import { ErrorStateActions } from './Actions';
import { ErrorStateCode } from './Code';
import { ErrorStateDescription } from './Description';
import { cnErrorState } from './ErrorState.cn';
import { IErrorStateProps } from './ErrorState.types';
import { ErrorStateImage } from './Image';
import { ErrorStateTitle } from './Title';

import './ErrorState.css';

export const ErrorState: FC<IErrorStateProps> = (props) => {
    const { className, image, title, description, code, actions, size } = props;

    return (
        <div className={cnErrorState({ size }, [className])}>
            <ErrorStateImage
                image={image}
            />
            <div className={cnErrorState('Content')}>
                <ErrorStateTitle
                    title={title}
                />
                <ErrorStateDescription
                    description={description}
                />
                <ErrorStateCode
                    code={code}
                />
                <ErrorStateActions
                    actions={actions}
                />
            </div>
        </div>
    );
};
