import { Hosts } from '.';

export const hostsPreset: Hosts = {
    center: {
        protocol: 'https',
        host: 'center.yandex-team.ru',
    },
    staff: {
        protocol: 'https',
        host: 'staff.yandex-team.ru',
    },
};
