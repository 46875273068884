import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'filter';
    sizeManagement?: 'self' | 'parent';
}

export const withFilterIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'filter',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15 14.5C13.8954 14.5 13 15.3954 13 16.5C13 17.6046 13.8954 18.5 15 18.5C16.1046 18.5 17 17.6046 17 16.5C17 15.3954 16.1046 14.5 15 14.5ZM11.126 15.5C11.5701 13.7748 13.1362 12.5 15 12.5C16.8638 12.5 18.4299 13.7748 18.874 15.5H21C21.5523 15.5 22 15.9477 22 16.5C22 17.0523 21.5523 17.5 21 17.5H18.874C18.4299 19.2252 16.8638 20.5 15 20.5C13.1362 20.5 11.5701 19.2252 11.126 17.5H3C2.44772 17.5 2 17.0523 2 16.5C2 15.9477 2.44772 15.5 3 15.5H11.126Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9 5.5C7.89543 5.5 7 6.39543 7 7.5C7 8.60457 7.89543 9.5 9 9.5C10.1046 9.5 11 8.60457 11 7.5C11 6.39543 10.1046 5.5 9 5.5ZM5.12602 6.5C5.57006 4.77477 7.13616 3.5 9 3.5C10.8638 3.5 12.4299 4.77477 12.874 6.5H21C21.5523 6.5 22 6.94772 22 7.5C22 8.05228 21.5523 8.5 21 8.5H12.874C12.4299 10.2252 10.8638 11.5 9 11.5C7.13616 11.5 5.57006 10.2252 5.12602 8.5H3C2.44772 8.5 2 8.05228 2 7.5C2 6.94772 2.44772 6.5 3 6.5H5.12602Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.1 9.74999C9.3268 9.74999 8.7 10.3768 8.7 11.15C8.7 11.9232 9.3268 12.55 10.1 12.55C10.8732 12.55 11.5 11.9232 11.5 11.15C11.5 10.3768 10.8732 9.74999 10.1 9.74999ZM7.38821 10.45C7.69904 9.24233 8.79531 8.34999 10.1 8.34999C11.4047 8.34999 12.501 9.24233 12.8118 10.45H14.3C14.6866 10.45 15 10.7634 15 11.15C15 11.5366 14.6866 11.85 14.3 11.85H12.8118C12.501 13.0576 11.4047 13.95 10.1 13.95C8.79531 13.95 7.69904 13.0576 7.38821 11.85H1.7C1.3134 11.85 1 11.5366 1 11.15C1 10.7634 1.3134 10.45 1.7 10.45H7.38821Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.9 3.44999C5.1268 3.44999 4.5 4.07679 4.5 4.84999C4.5 5.62319 5.1268 6.24999 5.9 6.24999C6.6732 6.24999 7.3 5.62319 7.3 4.84999C7.3 4.07679 6.6732 3.44999 5.9 3.44999ZM3.18821 4.14999C3.49904 2.94233 4.59531 2.04999 5.9 2.04999C7.20469 2.04999 8.30096 2.94233 8.61179 4.14999H14.3C14.6866 4.14999 15 4.46339 15 4.84999C15 5.23659 14.6866 5.54999 14.3 5.54999H8.61179C8.30096 6.75765 7.20469 7.64999 5.9 7.64999C4.59531 7.64999 3.49904 6.75765 3.18821 5.54999H1.7C1.3134 5.54999 1 5.23659 1 4.84999C1 4.46339 1.3134 4.14999 1.7 4.14999H3.18821Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
