import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'gapSick';
    sizeManagement?: 'self' | 'parent';
}

export const withGapSickIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'gapSick',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.06668 1.33337C6.66167 1.33337 6.33334 1.6617 6.33334 2.06671V6.33337H2.06668C1.66167 6.33337 1.33334 6.6617 1.33334 7.06671V8.93337C1.33334 9.33838 1.66167 9.66671 2.06668 9.66671H6.33334V13.9334C6.33334 14.3384 6.66167 14.6667 7.06668 14.6667H8.93334C9.33835 14.6667 9.66668 14.3384 9.66668 13.9334V9.66671H13.9333C14.3384 9.66671 14.6667 9.33838 14.6667 8.93337V7.06671C14.6667 6.6617 14.3384 6.33337 13.9333 6.33337H9.66668V2.06671C9.66668 1.6617 9.33835 1.33337 8.93334 1.33337H7.06668Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
