import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'arrowRelationship';
    sizeManagement?: 'self' | 'parent';
}

export const withArrowRelationshipIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'arrowRelationship',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.99999 5C4.55228 5 4.99999 5.44772 4.99999 6V10C4.99999 11.1046 5.89542 12 6.99999 12H16C16.6812 12 17.4784 12.116 18.2336 12.269L18.3773 12.1258C18.3316 12.0859 18.2819 12.0493 18.228 12.0166C17.4031 11.5161 16.4656 10.8798 15.8284 10.2426L14.2929 8.70711L14.29 8.70419C13.9024 8.31344 13.9034 7.68245 14.2929 7.29289C14.6834 6.90237 15.3166 6.90237 15.7071 7.29289L20.7071 12.2929C20.8746 12.4604 20.9766 12.6812 20.9964 12.9154C21.0015 12.9758 21.0012 13.0366 20.9953 13.097C20.9737 13.3197 20.8777 13.5365 20.7071 13.7071L15.7007 18.7134C15.3097 19.0976 14.6813 19.0955 14.2929 18.7071C13.9024 18.3166 13.9024 17.6834 14.2929 17.2929L15.8437 15.7421C16.4806 15.11 17.4097 14.4798 18.2281 13.9832C18.2819 13.9506 18.3316 13.914 18.3772 13.8742L18.2336 13.731C17.4784 13.884 16.6812 14 16 14H6.99999C4.79085 14 2.99999 12.2091 2.99999 10V6C2.99999 5.44772 3.44771 5 3.99999 5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.09995 3.79991C3.09995 3.41332 2.78655 3.09991 2.39995 3.09991C2.01335 3.09991 1.69995 3.41332 1.69995 3.79991V6.59991C1.69995 8.14631 2.95355 9.39991 4.49995 9.39991H10.8C11.2768 9.39991 11.8348 9.31871 12.3635 9.21161L12.464 9.31182C12.4321 9.33973 12.3973 9.36531 12.3596 9.38817C11.7868 9.73574 11.1364 10.1768 10.6906 10.6193L9.60499 11.7049C9.33163 11.9783 9.33163 12.4215 9.60499 12.6949C9.87685 12.9667 10.3167 12.9682 10.5904 12.6994L14.0949 9.19487C14.107 9.18281 14.1185 9.17043 14.1295 9.15774C14.2241 9.0483 14.2824 8.91227 14.2966 8.76857C14.3008 8.72576 14.3011 8.68263 14.2974 8.63978C14.2833 8.47616 14.212 8.32197 14.0949 8.20494L10.5949 4.70494C10.3216 4.43157 9.87836 4.43157 9.60499 4.70494C9.33229 4.97764 9.33163 5.41936 9.60299 5.69288L9.60499 5.69489L10.6798 6.76974C11.1259 7.2158 11.7821 7.6612 12.3596 8.01157C12.3973 8.03444 12.4321 8.06004 12.4641 8.08796L12.3635 8.18822C11.8348 8.08112 11.2768 7.99992 10.8 7.99992H4.49995C3.72675 7.99992 3.09995 7.37311 3.09995 6.59991V3.79991Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
