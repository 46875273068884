import { ComponentProps } from 'react';

import { compose, composeU } from '@bem-react/core';
import {
    Textinput as TextinputDesktop,
    withHasClear,
    withPinBrickBrick,
    withPinBrickClear,
    withPinBrickRound,
    withPinClearBrick,
    withPinClearClear,
    withPinClearRound,
    withPinRoundBrick,
    withPinRoundClear,
    withPinRoundRound,
    withSizeM,
    withSizeS,
    withViewDefault,
} from '@yandex-lego/components/Textinput/desktop';

import { withSizeL } from '..';

export const Textinput = compose(
    composeU(
        withViewDefault,
    ),
    composeU(
        withSizeL,
        withSizeM,
        withSizeS,
    ),
    composeU(
        composeU(
            withPinBrickBrick,
            withPinBrickClear,
            withPinBrickRound,
            withPinClearBrick,
            withPinClearClear,
            withPinClearRound,
        ),
        withPinRoundBrick,
        withPinRoundClear,
        withPinRoundRound,
    ),
    withHasClear,
)(TextinputDesktop);

export type TextinputProps = ComponentProps<typeof Textinput>;
