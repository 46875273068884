import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'trip';
    sizeManagement?: 'self' | 'parent';
}

export const withTripIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'trip',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 6C7 3.79086 8.79086 2 11 2H13C15.2091 2 17 3.79086 17 6H18C20.2091 6 22 7.79086 22 10V16C22 18.2091 20.2091 20 18 20H6C3.79086 20 2 18.2091 2 16V10C2 7.79086 3.79086 6 6 6H7ZM7 8H6C4.89543 8 4 8.89543 4 10V16C4 17.1046 4.89543 18 6 18H7V8ZM9 18H15V8H9V18ZM15 6H9C9 4.89543 9.89543 4 11 4H13C14.1046 4 15 4.89543 15 6ZM17 8V18H18C19.1046 18 20 17.1046 20 16V10C20 8.89543 19.1046 8 18 8H17Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.5 3.8C4.5 2.2536 5.7536 1 7.3 1H8.7C10.2464 1 11.5 2.2536 11.5 3.8H12.2C13.7464 3.8 15 5.0536 15 6.6V10.8C15 12.3464 13.7464 13.6 12.2 13.6H3.8C2.2536 13.6 1 12.3464 1 10.8V6.6C1 5.0536 2.2536 3.8 3.8 3.8L4.5 3.8ZM4.5 5.2H3.8C3.0268 5.2 2.4 5.8268 2.4 6.6V10.8C2.4 11.5732 3.0268 12.2 3.8 12.2H4.5V5.2ZM5.9 12.2H10.1V5.2H5.9V12.2ZM10.1 3.8L5.9 3.8C5.9 3.0268 6.5268 2.4 7.3 2.4H8.7C9.4732 2.4 10.1 3.0268 10.1 3.8ZM11.5 5.2V12.2H12.2C12.9732 12.2 13.6 11.5732 13.6 10.8V6.6C13.6 5.8268 12.9732 5.2 12.2 5.2H11.5Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
