import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'notifications';
    sizeManagement?: 'self' | 'parent';
}

export const withNotificationsIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'notifications',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 21C14 22.1046 13.1046 23 12 23C10.8954 23 10 22.1046 10 21C10 19.8954 10.8954 20.0001 12 20.0001C13.1046 20.0001 14 19.8954 14 21Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 1C12.5523 1 13 1.44772 13 2V3.07089C16.3923 3.55612 19 6.47353 19 10V17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17H5V10C5 6.47353 7.60771 3.55612 11 3.07089V2C11 1.44772 11.4477 1 12 1ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.40001 14.2999C9.40001 15.0731 8.77321 15.6999 8.00001 15.6999C7.22681 15.6999 6.60001 15.0731 6.60001 14.2999C6.60001 13.5267 7.22681 13.6 8.00001 13.6C8.77321 13.6 9.40001 13.5267 9.40001 14.2999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 0.299988C8.38661 0.299988 8.70001 0.613388 8.70001 0.999988V1.74961C11.0746 2.08927 12.9 4.13146 12.9 6.59999V11.5H13.6C13.9866 11.5 14.3 11.8134 14.3 12.2C14.3 12.5866 13.9866 12.9 13.6 12.9H2.40001C2.01341 12.9 1.70001 12.5866 1.70001 12.2C1.70001 11.8134 2.01341 11.5 2.40001 11.5H3.10001V6.59999C3.10001 4.13146 4.92541 2.08927 7.30001 1.74961V0.999988C7.30001 0.613388 7.61341 0.299988 8.00001 0.299988ZM4.50001 11.5H11.5V6.59999C11.5 4.66699 9.93301 3.09999 8.00001 3.09999C6.06702 3.09999 4.50001 4.66699 4.50001 6.59999V11.5Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
