import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'numbersFour';
    sizeManagement?: 'self' | 'parent';
}

export const withNumbersFourIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'numbersFour',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7 4.00002C7.55228 4.00002 8 4.44774 8 5.00002V19C8 19.5523 7.55228 20 7 20C6.44772 20 6 19.5523 6 19V5.00002C6 4.44774 6.44772 4.00002 7 4.00002Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M10.7905 4.02222C11.3305 3.9065 11.8621 4.25047 11.9778 4.79049L14 14.2274L16.0222 4.79049C16.1379 4.25047 16.6695 3.9065 17.2095 4.02222C17.7496 4.13794 18.0935 4.66953 17.9778 5.20955L14.9778 19.2096C14.879 19.6706 14.4715 20 14 20C13.5285 20 13.121 19.6706 13.0222 19.2096L10.0222 5.20955C9.90648 4.66953 10.2504 4.13794 10.7905 4.02222Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.49999 2.39999C4.88659 2.39999 5.19999 2.71339 5.19999 3.09999V12.9C5.19999 13.2866 4.88659 13.6 4.49999 13.6C4.11339 13.6 3.79999 13.2866 3.79999 12.9V3.09999C3.79999 2.71339 4.11339 2.39999 4.49999 2.39999Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7.15332 2.41552C7.53134 2.33452 7.90345 2.5753 7.98445 2.95332L9.39999 9.55916L10.8155 2.95332C10.8965 2.5753 11.2686 2.33452 11.6467 2.41552C12.0247 2.49653 12.2655 2.86864 12.1844 3.24666L10.0844 13.0467C10.0153 13.3694 9.73006 13.6 9.39999 13.6C9.06991 13.6 8.78469 13.3694 8.71553 13.0467L6.61553 3.24666C6.53452 2.86864 6.7753 2.49653 7.15332 2.41552Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
