import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'errorTriangle';
    sizeManagement?: 'self' | 'parent';
}

export const withErrorTriangleIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'errorTriangle',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.5697 10.1202C10.4622 9.25987 11.133 8.5 12 8.5C12.867 8.5 13.5378 9.25987 13.4303 10.1202L12.9923 13.624C12.9297 14.1245 12.5043 14.5 12 14.5C11.4957 14.5 11.0703 14.1245 11.0077 13.624L10.5697 10.1202Z" fill="currentColor" /><path d="M13.5 17C13.5 17.8284 12.8285 18.5 12 18.5C11.1716 18.5 10.5 17.8284 10.5 17C10.5 16.1715 11.1716 15.5 12 15.5C12.8285 15.5 13.5 16.1715 13.5 17Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.5359 4C10.0755 1.33333 13.9245 1.33333 15.4641 4L22.3923 16C23.9319 18.6667 22.0074 22 18.9282 22H5.0718C1.99259 22 0.0680963 18.6667 1.6077 16L8.5359 4ZM13.7321 5C12.9623 3.66667 11.0378 3.66667 10.268 5L3.33975 17C2.56995 18.3333 3.5322 20 5.0718 20H18.9282C20.4678 20 21.4301 18.3333 20.6603 17L13.7321 5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.99881 6.68412C6.92353 6.08191 7.39309 5.55 7.99999 5.55C8.60688 5.55 9.07645 6.08191 9.00117 6.68412L8.69458 9.13682C8.65079 9.48712 8.35301 9.75 7.99999 9.75C7.64696 9.75 7.34918 9.48712 7.30539 9.13682L6.99881 6.68412Z" fill="currentColor" /><path d="M9.04999 11.5C9.04999 12.0799 8.57989 12.55 7.99999 12.55C7.42009 12.55 6.94999 12.0799 6.94999 11.5C6.94999 10.9201 7.42009 10.45 7.99999 10.45C8.57989 10.45 9.04999 10.9201 9.04999 11.5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.57512 2.4C6.65284 0.533333 9.34714 0.533333 10.4249 2.4L15.2746 10.8C16.3523 12.6667 15.0052 15 12.8497 15H3.15025C0.994804 15 -0.352345 12.6667 0.725376 10.8L5.57512 2.4ZM9.21243 3.1C8.67356 2.16667 7.32641 2.16667 6.78755 3.1L1.93781 11.5C1.39895 12.4333 2.07253 13.6 3.15025 13.6H12.8497C13.9275 13.6 14.601 12.4333 14.0622 11.5L9.21243 3.1Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
