import React, { ComponentProps, FC, MouseEvent, useCallback, useMemo } from 'react';

import { compose } from '@bem-react/core';
import { withSizeM } from '@yandex-lego/components/TabsMenu';
import { withLayoutHoriz, withViewDefault } from '@yandex-lego/components/TabsMenu/desktop';
import { ITabsMenuTabProps } from '@yandex-lego/components/TabsMenu/Tab/TabsMenu-Tab';
import { TabsMenu as TabsMenuDesktop } from '@yandex-lego/components/TabsMenu/TabsMenu';

import { withMarker } from '../../../TabsMenu';
import { cnPageHeader } from '../../PageHeader.cn';
import { ITabsMenuItem, TabsMenuItemClickHandler } from '../../PageHeader.types';

import './TabsMenu.css';

const TabsMenuBase = compose(
    withSizeM,
    withLayoutHoriz,
    withViewDefault,
    withMarker,
)(TabsMenuDesktop);

const LegoTabsMenu: React.FC<ComponentProps<typeof TabsMenuBase>> = (props) => <TabsMenuBase {...props} />;

export interface IPageHeaderMenuTabsProps {
    items: ITabsMenuItem[];
    onItemClick?: TabsMenuItemClickHandler;
}

export const TabsMenu: FC<IPageHeaderMenuTabsProps> = (props) => {
    const { items, onItemClick } = props;

    const activeTab = useMemo(() => {
        const active = (items || []).find((item) => item.isActive);
        return active?.id;
    }, [items]);

    const handleItemClick = useCallback((item: ITabsMenuItem) => {
        return (e: MouseEvent) => onItemClick && onItemClick(e, item);
    }, [onItemClick]);

    const tabs: ITabsMenuTabProps[] = useMemo(() => {
        return (items || []).map((item) => ({
            id: item.id,
            content: item.content,
            disabled: item.disabled,
            active: item.isActive,
            onClick: handleItemClick(item),
        }));
    }, [items]);

    if (!items.length) return null;

    return (
        <div className={cnPageHeader('Tabs')}>
            <LegoTabsMenu
                marker
                size="m"
                tabs={tabs}
                view="default"
                layout="horiz"
                activeTab={activeTab}
                className={cnPageHeader('TabsMenu')}
            />
        </div>
    );
};
