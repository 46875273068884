/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from 'react';

import { withBemMod } from '@bem-react/core';
import { IButtonViewDefaultProps } from '@yandex-lego/components/Button/_view/Button_view_default';
import { cnSelect, ISelectProps } from '@yandex-lego/components/Select/desktop';

export type SelectWithViewDefault = {
    view?: 'default';
};

export const withViewDefault = withBemMod<SelectWithViewDefault, ISelectProps>(
    cnSelect(),
    { view: 'default' },
    (Select) => ({ view, ...otherProps }) => {
        const renderTrigger = useMemo(() => {
            return (buttonProps: IButtonViewDefaultProps, Button: React.ComponentType<IButtonViewDefaultProps>) => {
                return (
                    <Button {...buttonProps} view="default" />
                );
            };
        }, []);
        return (
            <Select
                renderTrigger={renderTrigger}
                {...otherProps}
            />
        );
    },
);
