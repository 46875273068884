import React, { FC } from 'react';

import { cnErrorState } from '../ErrorState.cn';
import { IErrorStateActionsProps } from './ErrorState-Actions.types';

import './ErrorState-Actions.css';

export const ErrorStateActions: FC<IErrorStateActionsProps> = (props) => {
    const { actions } = props;

    if (!actions || !actions.length) {
        return null;
    }

    return (
        <div className={cnErrorState('Actions')}>
            {
                actions.map((action, index) => {
                    return (
                        <div key={index} className={cnErrorState('Action')}>
                            {action}
                        </div>
                    );
                })
            }
        </div>
    );
};
