import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'ruler';
    sizeManagement?: 'self' | 'parent';
}

export const withRulerIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'ruler',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.7332 7.45833L16.5868 2.26605C16.5011 2.18103 16.3994 2.11376 16.2877 2.06811C16.1759 2.02246 16.0562 1.99932 15.9355 2.00002C15.6948 2.00103 15.4642 2.09657 15.2933 2.26605L2.26676 15.2468C2.0959 15.4186 2 15.6511 2 15.8935C2 16.1359 2.0959 16.3684 2.26676 16.5402L7.45905 21.7325C7.54476 21.8175 7.64642 21.8848 7.75819 21.9305C7.86996 21.9761 7.98964 21.9993 8.11037 21.9986C8.35104 21.9975 8.58166 21.902 8.75253 21.7325L11.3487 19.1364L19.1371 11.348L21.7332 8.75182C21.9041 8.57994 22 8.34743 22 8.10508C22 7.86272 21.9041 7.63021 21.7332 7.45833ZM18.4858 9.40315L17.8344 8.75182C17.6626 8.58096 17.4301 8.48505 17.1877 8.48505C16.9453 8.48505 16.7128 8.58096 16.541 8.75182C16.455 8.8371 16.3867 8.93856 16.3401 9.05035C16.2936 9.16214 16.2696 9.28204 16.2696 9.40315C16.2696 9.52425 16.2936 9.64415 16.3401 9.75594C16.3867 9.86773 16.455 9.96919 16.541 10.0545L17.1923 10.7058L15.8896 11.9993L13.9448 10.0545C13.7693 9.90419 13.5436 9.82565 13.3127 9.83457C13.0818 9.84349 12.8628 9.9392 12.6994 10.1026C12.5361 10.266 12.4403 10.485 12.4314 10.7158C12.4225 10.9467 12.501 11.1725 12.6513 11.348L14.5961 13.2928L13.2935 14.5954L12.6513 13.9441C12.4786 13.7714 12.2443 13.6743 12 13.6743C11.7557 13.6743 11.5214 13.7714 11.3487 13.9441C11.1759 14.1168 11.0789 14.3511 11.0789 14.5954C11.0789 14.8397 11.1759 15.074 11.3487 15.2468L12 15.8889L10.7065 17.1916L8.75253 15.2468C8.58065 15.0759 8.34814 14.98 8.10579 14.98C7.86343 14.98 7.63093 15.0759 7.45905 15.2468C7.28819 15.4186 7.19228 15.6511 7.19228 15.8935C7.19228 16.1359 7.28819 16.3684 7.45905 16.5402L9.40386 18.4851L8.11037 19.7877L4.21158 15.8889L15.8896 4.21086L19.7884 8.10966L18.4858 9.40315Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.8133 4.82083L11.2108 1.18624C11.1508 1.12672 11.0796 1.07963 11.0014 1.04768C10.9231 1.01572 10.8394 0.999522 10.7548 1.00001C10.5864 1.00072 10.4249 1.0676 10.3053 1.18624L1.18673 10.2727C1.06713 10.393 1 10.5558 1 10.7255C1 10.8951 1.06713 11.0579 1.18673 11.1782L4.82133 14.8128C4.88134 14.8723 4.9525 14.9194 5.03073 14.9513C5.10897 14.9833 5.19275 14.9995 5.27726 14.999C5.44572 14.9983 5.60716 14.9314 5.72677 14.8128L7.54407 12.9955L12.996 7.54357L14.8133 5.72627C14.9329 5.60596 15 5.4432 15 5.27355C15 5.1039 14.9329 4.94115 14.8133 4.82083ZM12.54 6.1822L12.0841 5.72627C11.9638 5.60667 11.801 5.53954 11.6314 5.53954C11.4617 5.53954 11.299 5.60667 11.1787 5.72627C11.1185 5.78597 11.0707 5.85699 11.0381 5.93524C11.0055 6.0135 10.9887 6.09743 10.9887 6.1822C10.9887 6.26697 11.0055 6.35091 11.0381 6.42916C11.0707 6.50741 11.1185 6.57844 11.1787 6.63813L11.6346 7.09406L10.7227 7.9995L9.36137 6.63813C9.23852 6.53293 9.0805 6.47796 8.91889 6.4842C8.75727 6.49044 8.60397 6.55744 8.4896 6.67181C8.37524 6.78617 8.30824 6.93948 8.302 7.10109C8.29576 7.26271 8.35073 7.42073 8.45593 7.54357L9.8173 8.90494L8.90544 9.8168L8.45593 9.36087C8.33501 9.23995 8.17101 9.17202 8 9.17202C7.82899 9.17202 7.66499 9.23995 7.54407 9.36087C7.42315 9.48179 7.35522 9.64579 7.35522 9.8168C7.35522 9.98781 7.42315 10.1518 7.54407 10.2727L8 10.7222L7.09456 11.6341L5.72677 10.2727C5.60645 10.1531 5.4437 10.086 5.27405 10.086C5.1044 10.086 4.94165 10.1531 4.82133 10.2727C4.70173 10.393 4.6346 10.5558 4.6346 10.7255C4.6346 10.8951 4.70173 11.0579 4.82133 11.1782L6.1827 12.5395L5.27726 13.4514L2.5481 10.7222L10.7227 2.5476L13.4519 5.27676L12.54 6.1822Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
