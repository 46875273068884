import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'starChecked';
    sizeManagement?: 'self' | 'parent';
}

export const withStarCheckedIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'starChecked',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.80106 2.96335C10.7463 1.21592 13.2537 1.21591 14.1989 2.96335L16.0311 6.35058C16.1044 6.48609 16.2361 6.58025 16.388 6.60578L19.8108 7.18103C21.8098 7.51701 22.5989 9.96745 21.1715 11.4068L18.4999 14.1009C18.3869 14.2149 18.3355 14.3762 18.3616 14.5345L18.9432 18.053C19.2698 20.0292 17.2558 21.5679 15.4349 20.7333L12.2083 19.2543C12.0761 19.1937 11.9239 19.1937 11.7917 19.2543L8.56506 20.7333C6.74417 21.5679 4.7302 20.0292 5.05683 18.053L5.63836 14.5345C5.66453 14.3762 5.6131 14.2149 5.50008 14.1009L2.82844 11.4068C1.40108 9.96745 2.19018 7.51701 4.18923 7.18103L7.61197 6.60578C7.7639 6.58025 7.89558 6.48609 7.96888 6.35058L9.80106 2.96335Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.46077 1.67436C7.12242 0.451158 8.87763 0.451154 9.53927 1.67436L10.8218 4.04543C10.8731 4.14029 10.9653 4.20619 11.0716 4.22407L13.4676 4.62674C14.8669 4.86192 15.4193 6.57723 14.4201 7.58479L12.55 9.47065C12.4709 9.55042 12.4348 9.66333 12.4532 9.77417L12.8602 12.2371C13.0889 13.6205 11.6791 14.6976 10.4045 14.1133L8.14586 13.0781C8.05327 13.0356 7.94678 13.0356 7.85418 13.0781L5.59557 14.1133C4.32095 14.6976 2.91117 13.6205 3.13981 12.2371L3.54688 9.77417C3.5652 9.66333 3.52919 9.55042 3.45008 9.47065L1.57994 7.58479C0.580783 6.57724 1.13315 4.86193 2.53249 4.62674L4.9284 4.22407C5.03476 4.20619 5.12693 4.14028 5.17824 4.04543L6.46077 1.67436Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
