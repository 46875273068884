import React, { forwardRef, useCallback, useState } from 'react';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { cnPageHeader } from '../../PageHeader.cn';
import { Icon } from '../Icon/Icon';
import { MenuItemProps } from './MenuItem.types';
import { SimpleMenuItem } from './SimpleMenuItem';

const IconArrow = (className: string) => (
    <Icon className={cnPageHeader('MenuIcon', [className])} size="m" glyph="arrowShortDown" sizeManagement="parent" />
);

export const InlineMenuItemWithChildren = forwardRef<HTMLElement, MenuItemProps>((props, ref) => {
    const { item, onClick, disabled, className, width, size = 'm' } = props;
    const [open, setOpen] = useState(true);
    const { name, children = [] } = item;
    const handleClick = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const isActive = children.some((item) => item.isActive);

    return (
        <>
            <Button
                className={cnPageHeader('MenuItem', { open, isActive }, [className])}
                iconRight={IconArrow}
                disabled={disabled}
                view="clear"
                size={size}
                onClick={handleClick}
                pin="clear-clear"
                innerRef={ref}
                width={width}
            >
                {name}
            </Button>
            {
                open && children && children.map((item) => {
                    return (
                        <SimpleMenuItem
                            className={cnPageHeader('InnerMenuItem')}
                            item={item}
                            key={item.id}
                            onClick={onClick}
                            size={size}
                            width={width}
                        />
                    );
                })
            }
        </>
    );
});
