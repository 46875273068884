import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'textFormattingUnderline';
    sizeManagement?: 'self' | 'parent';
}

export const withTextFormattingUnderlineIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'textFormattingUnderline',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 19C3 18.4477 3.44772 18 4 18H20C20.5523 18 21 18.4477 21 19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7 4C7.55228 4 8 4.44772 8 5V10.5C8 12.7091 9.79086 14.5 12 14.5C14.2091 14.5 16 12.7091 16 10.5V5C16 4.44772 16.4477 4 17 4C17.5523 4 18 4.44772 18 5V10.5C18 13.8137 15.3137 16.5 12 16.5C8.68629 16.5 6 13.8137 6 10.5V5C6 4.44772 6.44772 4 7 4Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 12.9C1.70001 12.5134 2.01341 12.2 2.40001 12.2H13.6C13.9866 12.2 14.3 12.5134 14.3 12.9C14.3 13.2866 13.9866 13.6 13.6 13.6H2.40001C2.01341 13.6 1.70001 13.2866 1.70001 12.9Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4.50001 2.39999C4.88661 2.39999 5.20001 2.71339 5.20001 3.09999V6.94999C5.20001 8.49639 6.45361 9.74999 8.00001 9.74999C9.54641 9.74999 10.8 8.49639 10.8 6.95V3.09999C10.8 2.71339 11.1134 2.39999 11.5 2.39999C11.8866 2.39999 12.2 2.71339 12.2 3.09999V6.95C12.2 9.26959 10.3196 11.15 8.00001 11.15C5.68042 11.15 3.80001 9.26959 3.80001 6.94999V3.09999C3.80001 2.71339 4.11341 2.39999 4.50001 2.39999Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
