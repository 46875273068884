import { withBemMod } from '@bem-react/core';

import { cnUserAvatar } from '../UserAvatar.cn';

import './UserAvatar_size_m.css';

export type WithSizeMProps = {
    size?: 'm';
};

export const withSizeM = withBemMod<WithSizeMProps>(cnUserAvatar(), {
    size: 'm',
});
