import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'travelEntertaiment';
    sizeManagement?: 'self' | 'parent';
}

export const withTravelEntertaimentIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'travelEntertaiment',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.6 6.7C7.29698 6.47274 6.89157 6.43618 6.55279 6.60557C6.214 6.77496 6 7.12123 6 7.5V10.5C6 10.8788 6.214 11.225 6.55279 11.3944C6.89157 11.5638 7.29698 11.5273 7.6 11.3L9.6 9.8C9.85181 9.61115 10 9.31476 10 9C10 8.68524 9.85181 8.38885 9.6 8.2L7.6 6.7Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L8 2.6L5.7 0.3C5.3 -0.1 4.7 -0.1 4.3 0.3C3.9 0.7 3.9 1.3 4.3 1.7L5.6 3H2.75C1.7875 3 1 3.83077 1 4.84615V13.1538C1 14.1692 1.7875 15 2.75 15H13.25C14.2125 15 15 14.1692 15 13.1538V4.84615C15 3.83077 14.2125 3 13.25 3H10.4L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3ZM3 13H13V5H3V13Z" fill="currentColor" /><path d="M7.6 6.7C7.29698 6.47274 6.89157 6.43618 6.55279 6.60557C6.214 6.77496 6 7.12123 6 7.5V10.5C6 10.8788 6.214 11.225 6.55279 11.3944C6.89157 11.5638 7.29698 11.5273 7.6 11.3L9.6 9.8C9.85181 9.61115 10 9.31476 10 9C10 8.68524 9.85181 8.38885 9.6 8.2L7.6 6.7Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L8 2.6L5.7 0.3C5.3 -0.1 4.7 -0.1 4.3 0.3C3.9 0.7 3.9 1.3 4.3 1.7L5.6 3H2.75C1.7875 3 1 3.83077 1 4.84615V13.1538C1 14.1692 1.7875 15 2.75 15H13.25C14.2125 15 15 14.1692 15 13.1538V4.84615C15 3.83077 14.2125 3 13.25 3H10.4L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3ZM3 13H13V5H3V13Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
