import { ComponentProps } from 'react';

import { compose, composeU } from '@bem-react/core';
import {
    Menu as MenuBase,
    withSizeM,
    withSizeS,
    withViewDefault,
} from '@yandex-lego/components/Menu/desktop';

import { withSizeL } from '..';

export const Menu = compose(
    composeU(
        withSizeS,
        withSizeM,
        withSizeL,
    ),
    withViewDefault,
)(MenuBase);

export type MenuProps = ComponentProps<typeof Menu>;
