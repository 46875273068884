import { ComponentProps } from 'react';

import { compose, composeU } from '@bem-react/core';
import {
    RadioButton as RadioButtonBase,
    withSizeL,
    withSizeM,
    withSizeS,
    withViewDefault,
} from '@yandex-lego/components/RadioButton/desktop';

import { withFullWidth } from '..';

export const RadioButton = compose(
    composeU(withSizeS, withSizeM, withSizeL),
    withViewDefault,
    withFullWidth,
)(RadioButtonBase);

export type RadioButtonProps = ComponentProps<typeof RadioButton>;
