import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'profile';
    sizeManagement?: 'self' | 'parent';
}

export const withProfileIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'profile',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4ZM7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 15C8.35059 15 5.9121 16.1762 4.67046 16.9783C4.26067 17.243 4 17.758 4 18.4232C4 19.294 4.70598 20 5.57685 20H18.4232C19.294 20 20 19.294 20 18.4232C20 17.758 19.7393 17.243 19.3295 16.9783C18.0879 16.1762 15.6494 15 12 15ZM3.5852 15.2984C5.08777 14.3277 7.89739 13 12 13C16.1026 13 18.9122 14.3277 20.4148 15.2984C21.5358 16.0226 22 17.2659 22 18.4232C22 20.3986 20.3986 22 18.4232 22H5.57685C3.60141 22 2 20.3986 2 18.4232C2 17.2659 2.46417 16.0226 3.5852 15.2984Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 2.4C6.8402 2.4 5.9 3.3402 5.9 4.5C5.9 5.6598 6.8402 6.6 8 6.6C9.1598 6.6 10.1 5.6598 10.1 4.5C10.1 3.3402 9.1598 2.4 8 2.4ZM4.5 4.5C4.5 2.567 6.067 1 8 1C9.933 1 11.5 2.567 11.5 4.5C11.5 6.433 9.933 8 8 8C6.067 8 4.5 6.433 4.5 4.5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 10.1C5.44541 10.1 3.73847 10.9233 2.86932 11.4848C2.58247 11.6701 2.4 12.0306 2.4 12.4962C2.4 13.1058 2.89418 13.6 3.50379 13.6H12.4962C13.1058 13.6 13.6 13.1058 13.6 12.4962C13.6 12.0306 13.4175 11.6701 13.1307 11.4848C12.2615 10.9233 10.5546 10.1 8 10.1ZM2.10964 10.3089C3.16144 9.62939 5.12817 8.7 8 8.7C10.8718 8.7 12.8386 9.62939 13.8904 10.3089C14.6751 10.8158 15 11.6861 15 12.4962C15 13.879 13.879 15 12.4962 15H3.50379C2.12098 15 1 13.879 1 12.4962C1 11.6861 1.32492 10.8158 2.10964 10.3089Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
