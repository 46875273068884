import React from 'react';

import { Dropdown } from '@yandex-lego/components/Dropdown/desktop';

import { Menu, MenuProps } from '../../../Menu/desktop/bundle';
import { UserAvatar } from '../../../UserAvatar/bundle';
import { useCenterAvatarUrl } from '../../../utils';
import { cnPageHeader } from '../../PageHeader.cn';
import { HEADER_MAIN_OFFSET } from '../../PageHeader.constants';

import './User.css';

export interface UserProps {
    login: string;
    items?: MenuProps['items'];
}

export const User: React.FC<UserProps> = (props) => {
    const { login, items } = props;

    const { url: avatarUrl } = useCenterAvatarUrl({ login });

    const dropdownContent = items ? (
        <Menu
            className={cnPageHeader('UserMenu')}
            view="default"
            items={items}
            size="m"
        />
    ) : null;

    return (
        <div className={cnPageHeader('User')}>
            <Dropdown
                trigger="click"
                content={dropdownContent}
                direction={'bottom-end'}
                view="default"
                mainOffset={HEADER_MAIN_OFFSET}
            >
                <div
                    className={cnPageHeader('UserImageWrapper', {
                        hoverable: Boolean(items?.length),
                    })}
                >
                    <UserAvatar size="l" icon={avatarUrl} />
                </div>
            </Dropdown>
        </div>
    );
};
