import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'search';
    sizeManagement?: 'self' | 'parent';
}

export const withSearchIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'search',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5ZM3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 12.8487 18.3729 14.551 17.3199 15.9056L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L15.9056 17.3199C14.551 18.3729 12.8487 19 11 19C6.58172 19 3 15.4183 3 11Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.30001 3.10001C4.98042 3.10001 3.10001 4.98042 3.10001 7.30001C3.10001 9.61961 4.98042 11.5 7.30001 11.5C9.61961 11.5 11.5 9.61961 11.5 7.30001C11.5 4.98042 9.61961 3.10001 7.30001 3.10001ZM1.70001 7.30001C1.70001 4.20722 4.20722 1.70001 7.30001 1.70001C10.3928 1.70001 12.9 4.20722 12.9 7.30001C12.9 8.59411 12.4611 9.78568 11.7239 10.734L14.095 13.105C14.3684 13.3784 14.3684 13.8216 14.095 14.095C13.8216 14.3684 13.3784 14.3684 13.105 14.095L10.734 11.7239C9.78568 12.4611 8.59411 12.9 7.30001 12.9C4.20722 12.9 1.70001 10.3928 1.70001 7.30001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
