import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'notificationsUnread';
    sizeManagement?: 'self' | 'parent';
}

export const withNotificationsUnreadIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'notificationsUnread',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 21C14 22.1046 13.1046 23 12 23C10.8954 23 10 22.1046 10 21C10 19.8954 10.8954 20.0001 12 20.0001C13.1046 20.0001 14 19.8954 14 21Z" fill="currentColor" /><path d="M19 6.5C19 7.88071 17.8807 9 16.5 9C15.1193 9 14 7.88071 14 6.5C14 5.11929 15.1193 4 16.5 4C17.8807 4 19 5.11929 19 6.5Z" fill="currentColor" /><path d="M3.42042 16.276C2.87024 16.2279 2.38521 16.6349 2.33707 17.1851C2.28894 17.7353 2.69593 18.2203 3.24611 18.2684L19.1852 19.6629C19.7354 19.7111 20.2204 19.3041 20.2686 18.7539C20.3167 18.2037 19.9097 17.7187 19.3595 17.6705L18.3634 17.5834L18.9735 10.6099C18.9841 10.4878 18.9916 10.3661 18.996 10.2449C18.3948 10.6464 17.6916 10.9069 16.9335 10.9794L16.371 17.409L6.40903 16.5374L7.01912 9.56405C7.25447 6.87397 9.57876 4.86854 12.2539 5.00621C12.5247 4.2364 12.9989 3.56242 13.612 3.04881L13.6935 2.11743C13.7417 1.56725 13.3347 1.08221 12.7845 1.03408C12.2343 0.985943 11.7493 1.39293 11.7011 1.94312L11.6078 3.00993C8.18613 3.19766 5.33408 5.87669 5.02673 9.38974L4.41663 16.3632L3.42042 16.276Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.40001 14.3C9.40001 15.0732 8.7732 15.7 8.00001 15.7C7.22681 15.7 6.60001 15.0732 6.60001 14.3C6.60001 13.5268 7.22681 13.6 8.00001 13.6C8.7732 13.6 9.40001 13.5268 9.40001 14.3Z" fill="currentColor" /><path d="M12.9001 4.1499C12.9001 5.1164 12.1166 5.8999 11.1501 5.8999C10.1836 5.8999 9.40005 5.1164 9.40005 4.1499C9.40005 3.1834 10.1836 2.3999 11.1501 2.3999C12.1166 2.3999 12.9001 3.1834 12.9001 4.1499Z" fill="currentColor" /><path d="M1.99427 10.9936C1.60914 10.9599 1.26962 11.2448 1.23592 11.6299C1.20223 12.015 1.48712 12.3546 1.87225 12.3883L13.0296 13.3644C13.4148 13.3981 13.7543 13.1132 13.788 12.7281C13.8217 12.3429 13.5368 12.0034 13.1516 11.9697L12.4543 11.9087L12.8814 7.02708C12.8889 6.94158 12.8941 6.85639 12.8972 6.77158C12.4764 7.05259 11.9841 7.23499 11.4534 7.28571L11.0597 11.7864L4.08631 11.1763L4.51337 6.29498C4.67812 4.41192 6.30512 3.00812 8.17769 3.10449C8.36726 2.56562 8.69922 2.09384 9.12842 1.7343L9.18546 1.08234C9.21915 0.697212 8.93426 0.357689 8.54913 0.323995C8.164 0.290301 7.82448 0.575194 7.79079 0.960322L7.72545 1.70709C5.33028 1.8385 3.33384 3.71382 3.1187 6.17296L2.69162 11.0545L1.99427 10.9936Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
