import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'camera';
    sizeManagement?: 'self' | 'parent';
}

export const withCameraIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'camera',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.29289 3.29295C9.48043 3.10542 9.73478 3.00006 10 3.00006H14C14.2652 3.00006 14.5196 3.10542 14.7071 3.29295L16.4142 5.00006H18C20.2091 5.00006 22 6.79092 22 9.00006V17.0001C22 19.2092 20.2091 21.0001 18 21.0001H6C3.79086 21.0001 2 19.2092 2 17.0001V9.00006C2 6.79092 3.79086 5.00006 6 5.00006H7.58579L9.29289 3.29295ZM10.4142 5.00006L8.70711 6.70717C8.51957 6.8947 8.26522 7.00006 8 7.00006H6C4.89543 7.00006 4 7.89549 4 9.00006V17.0001C4 18.1046 4.89543 19.0001 6 19.0001H18C19.1046 19.0001 20 18.1046 20 17.0001V9.00006C20 7.89549 19.1046 7.00006 18 7.00006H16C15.7348 7.00006 15.4804 6.8947 15.2929 6.70717L13.5858 5.00006H10.4142Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.6193 10 9.5 11.1193 9.5 12.5C9.5 13.8807 10.6193 15 12 15C13.3807 15 14.5 13.8807 14.5 12.5C14.5 11.1193 13.3807 10 12 10ZM7.5 12.5C7.5 10.0147 9.51472 8 12 8C14.4853 8 16.5 10.0147 16.5 12.5C16.5 14.9853 14.4853 17 12 17C9.51472 17 7.5 14.9853 7.5 12.5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.10503 1.90504C6.2363 1.77376 6.41435 1.70001 6.6 1.70001H9.4C9.58565 1.70001 9.7637 1.77376 9.89497 1.90504L11.0899 3.10001H12.2C13.7464 3.10001 15 4.35361 15 5.90001V11.5C15 13.0464 13.7464 14.3 12.2 14.3H3.8C2.2536 14.3 1 13.0464 1 11.5V5.90001C1 4.35362 2.2536 3.10001 3.8 3.10001H4.91005L6.10503 1.90504ZM6.88995 3.10001L5.69497 4.29499C5.5637 4.42626 5.38565 4.50001 5.2 4.50001H3.8C3.0268 4.50001 2.4 5.12681 2.4 5.90001V11.5C2.4 12.2732 3.0268 12.9 3.8 12.9H12.2C12.9732 12.9 13.6 12.2732 13.6 11.5V5.90001C13.6 5.12681 12.9732 4.50001 12.2 4.50001H10.8C10.6143 4.50001 10.4363 4.42626 10.305 4.29499L9.11005 3.10001H6.88995Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 6.60001C7.0335 6.60001 6.25 7.38351 6.25 8.35001C6.25 9.31651 7.0335 10.1 8 10.1C8.9665 10.1 9.75 9.31651 9.75 8.35001C9.75 7.38351 8.9665 6.60001 8 6.60001ZM4.85 8.35001C4.85 6.61032 6.2603 5.20001 8 5.20001C9.7397 5.20001 11.15 6.61032 11.15 8.35001C11.15 10.0897 9.7397 11.5 8 11.5C6.2603 11.5 4.85 10.0897 4.85 8.35001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
