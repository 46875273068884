import React, { ComponentProps, FC } from 'react';

import { compose, composeU } from '@bem-react/core';
import { Registry, withRegistry } from '@bem-react/di';
import { Icon as BaseIcon, IIconProps } from '@yandex-lego/components/Icon';
import {
    cnSelect,
    withBaseline,
    withWidthMax,
} from '@yandex-lego/components/Select/desktop';
import {
    ISelectProps,
    Select as BaseSelect,
} from '@yandex-lego/components/Select/desktop/bundle';

import { Button } from '../../Button/desktop/bundle';
import { withArrowShortDownIcon } from '../../Icon/_glyph/ArrowShortDown';
import { Menu } from '../../Menu/desktop/bundle';
import {
    withSizeL,
    withSizeM,
    withSizeS,
    withViewClear,
    withViewDefault,
} from '..';

const Icon = composeU(
    withArrowShortDownIcon,
)(BaseIcon);

export const IconForSelect = (props: IIconProps) => (
    <Icon
        {...props}
        className={cnSelect('Icon', [props.className])}
        sizeManagement="parent"
    />
);

const SelectWithView: FC<ISelectProps> = (props) => {
    return (
        <BaseSelect
            {...props}
            // Леговскому селекту нельзя назначить никакой другой view
            view="default"
        />
    );
};

const ComposedSelect = compose(
    withBaseline,
    withWidthMax,
    composeU(
        withViewClear,
        withViewDefault,
    ),
    composeU(
        withSizeL,
        withSizeM,
        withSizeS,
    ),
)(SelectWithView);

export type SelectProps = ComponentProps<typeof Select>;

const registry = new Registry({ id: cnSelect() })
    .set('Trigger', Button)
    .set('Icon', IconForSelect)
    .set('Menu', Menu);

export const Select = withRegistry(registry)(ComposedSelect);
