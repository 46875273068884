import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'equal';
    sizeManagement?: 'self' | 'parent';
}

export const withEqualIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'equal',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 15C3 14.4477 3.44772 14 4 14H20C20.5523 14 21 14.4477 21 15C21 15.5523 20.5523 16 20 16H4C3.44772 16 3 15.5523 3 15Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M3 9C3 8.44772 3.44772 8 4 8H20C20.5523 8 21 8.44772 21 9C21 9.55228 20.5523 10 20 10H4C3.44772 10 3 9.55228 3 9Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 5.9C1.70001 5.5134 2.01341 5.2 2.40001 5.2H13.6C13.9866 5.2 14.3 5.5134 14.3 5.9C14.3 6.2866 13.9866 6.6 13.6 6.6H2.40001C2.01341 6.6 1.70001 6.2866 1.70001 5.9ZM1.70001 10.1C1.70001 9.7134 2.01341 9.4 2.40001 9.4H13.6C13.9866 9.4 14.3 9.7134 14.3 10.1C14.3 10.4866 13.9866 10.8 13.6 10.8H2.40001C2.01341 10.8 1.70001 10.4866 1.70001 10.1Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
