import React from 'react';

import { withBemMod } from '@bem-react/core';
import { ITabsMenuProps } from '@yandex-lego/components/TabsMenu/desktop';

import { cnTabsMenu } from '../TabsMenu.cn';

import './TabsMenu_marker.css';

export interface TabsMenuWithMarkerProps {
    marker?: boolean
}

export const withMarker = withBemMod<TabsMenuWithMarkerProps, ITabsMenuProps>(
    cnTabsMenu(),
    { marker: true },
    (TabsMenu) => ({ className, marker, ...props }) => {
        return (
            <TabsMenu
                {...props}
                className={cnTabsMenu({ marker: true }, [className])}
            />
        );
    },
);
