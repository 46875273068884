import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'flag';
    sizeManagement?: 'self' | 'parent';
}

export const withFlagIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'flag',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M20.3162 3.05132C20.7246 3.18743 21 3.56957 21 4V14C21 14.8379 20.7355 15.804 19.9505 16.4737C19.1792 17.1318 17.8203 18 16 18C14.572 18 13.0564 17.4485 11.7512 16.9736C11.7201 16.9623 11.6891 16.951 11.6583 16.9398C10.2341 16.4219 9.038 16 8 16C7.02553 16 6.22773 16.3891 5.65067 16.8087C5.36702 17.015 5.14729 17.2214 5 17.3746V20C5 20.5523 4.55228 21 4 21C3.44772 21 3 20.5523 3 20V7.00005C3 6.16209 3.2645 5.19596 4.04954 4.52624C4.82084 3.86823 6.17973 3.00008 7.99996 3C9.428 2.99994 10.9437 3.55146 12.2489 4.0264C12.28 4.0377 12.3109 4.04896 12.3418 4.06017C13.7659 4.57806 14.962 5 16 5C16.9745 5 17.7723 4.61095 18.3493 4.19127C18.6359 3.98283 18.8573 3.7743 19.0046 3.6206C19.0778 3.54418 19.1315 3.48257 19.1647 3.44307C19.1812 3.42336 19.1925 3.40928 19.1985 3.40176L19.2031 3.39583C19.4619 3.05453 19.9095 2.91576 20.3162 3.05132ZM19 6.15491C18.2348 6.60867 17.2209 7 16 7C14.572 7 13.0565 6.44852 11.7513 5.9736C11.7201 5.96227 11.6891 5.95098 11.6582 5.93975C10.2341 5.42187 9.03801 4.99996 8.00004 5C6.83593 5.00005 5.92298 5.55689 5.34758 6.04777C5.14493 6.22066 5 6.53973 5 7.00005V14.8451C5.76524 14.3913 6.77907 14 8 14C9.42798 14 10.9436 14.5515 12.2488 15.0264C12.2799 15.0377 12.3109 15.049 12.3417 15.0602C13.7659 15.5781 14.962 16 16 16C17.1641 16 18.0771 15.4431 18.6524 14.9522C18.8551 14.7794 19 14.4603 19 14V6.15491Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.8214 1.73593C14.1072 1.83121 14.3 2.09871 14.3 2.40001V9.40001C14.3 9.98656 14.1149 10.6628 13.5654 11.1316C13.0255 11.5923 12.0742 12.2 10.8 12.2C9.80042 12.2 8.73948 11.814 7.82584 11.4815C7.80407 11.4736 7.78239 11.4657 7.76079 11.4579C6.7639 11.0954 5.92661 10.8 5.20001 10.8C4.51788 10.8 3.95943 11.0724 3.55548 11.3661C3.35693 11.5105 3.20312 11.655 3.10001 11.7623V13.6C3.10001 13.9866 2.78661 14.3 2.40001 14.3C2.01341 14.3 1.70001 13.9866 1.70001 13.6V4.50004C1.70001 3.91347 1.88516 3.23718 2.43469 2.76838C2.9746 2.30777 3.92582 1.70007 5.19998 1.70001C6.19961 1.69997 7.26059 2.08603 8.17425 2.41849C8.196 2.4264 8.21766 2.43428 8.23924 2.44213C9.23614 2.80465 10.0734 3.10001 10.8 3.10001C11.4821 3.10001 12.0406 2.82767 12.4445 2.5339C12.6452 2.38799 12.8001 2.24202 12.9032 2.13443C12.9545 2.08093 12.9921 2.03781 13.0153 2.01016C13.0269 1.99636 13.0348 1.98651 13.039 1.98124L13.0419 1.9775L13.0411 1.97851M3.10001 9.99158C3.63568 9.67394 4.34536 9.40001 5.20001 9.40001C6.1996 9.40001 7.26054 9.78605 8.17418 10.1185C8.19595 10.1264 8.21763 10.1343 8.23923 10.1422C9.23613 10.5047 10.0734 10.8 10.8 10.8C11.6149 10.8 12.254 10.4102 12.6567 10.0666C12.7986 9.94556 12.9 9.72222 12.9 9.40001V3.90844C12.3643 4.22608 11.6547 4.50001 10.8 4.50001C9.80045 4.50001 8.73953 4.11397 7.82591 3.78153C7.80412 3.7736 7.78241 3.7657 7.76079 3.75784C6.76389 3.39532 5.92662 3.09998 5.20004 3.10001C4.38516 3.10005 3.7461 3.48983 3.34332 3.83345C3.20147 3.95447 3.10001 4.17782 3.10001 4.50004V9.99158ZM13.0403 1.97959C13.0409 1.97876 13.0413 1.97832 13.0419 1.9775C13.223 1.73856 13.5367 1.64104 13.8214 1.73593" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
