import React, { useMemo } from 'react';

import { withBemMod } from '@bem-react/core';
import { IMenuSizeMProps as MenuWithSizeMProps } from '@yandex-lego/components/Menu/_size/Menu_size_m';
import { cnSelect, ISelectProps } from '@yandex-lego/components/Select';

export type SelectWithSizeMProps = {
    size?: 'm';
};

export const withSizeM = withBemMod<SelectWithSizeMProps, ISelectProps>(
    cnSelect(),
    { size: 'm' },
    (Select) => ({ view, ...otherProps }) => {
        const renderMenu = useMemo(() => {
            return (menuProps: MenuWithSizeMProps, Menu: React.ComponentType<MenuWithSizeMProps>) => {
                return (
                    <Menu
                        {...menuProps}
                        size="m"
                    />
                );
            };
        }, []);
        return (
            <Select
                // @ts-ignore
                renderMenu={renderMenu}
                {...otherProps}
            />
        );
    },
);
