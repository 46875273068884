import React, { ReactElement } from 'react';

import { IIconProps } from '@yandex-lego/components/Icon/Icon';
import {
    cnLink,
    ILinkProps as ILinkPropsBase,
    Link as LinkBase,
} from '@yandex-lego/components/Link';

export declare type IconProvider = (className: string) => ReactElement<IIconProps>;

export interface ILinkProps extends ILinkPropsBase {
    iconLeft?: IconProvider;
    iconRight?: IconProvider;
}

export const Link: React.FC<ILinkProps> = (props) => {
    const { iconRight, iconLeft, children } = props;
    const content = (
        <>
            {iconLeft && iconLeft(cnLink('IconLeft'))}
            {children}
            {iconRight && iconRight(cnLink('IconRight'))}
        </>
    );

    return (<LinkBase {...props} children={content} />);
};
