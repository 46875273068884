import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'dollar';
    sizeManagement?: 'self' | 'parent';
}

export const withDollarIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'dollar',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 2.5C12.5523 2.5 13 2.94772 13 3.5V4.5H14C16.2091 4.5 18 6.29086 18 8.5C18 9.05228 17.5523 9.5 17 9.5C16.4477 9.5 16 9.05228 16 8.5C16 7.39543 15.1046 6.5 14 6.5H10C8.89543 6.5 8 7.39543 8 8.5V9C8 10.1046 8.89543 11 10 11H14C16.2091 11 18 12.7909 18 15V15.5C18 17.7091 16.2091 19.5 14 19.5H13V20.5C13 21.0523 12.5523 21.5 12 21.5C11.4477 21.5 11 21.0523 11 20.5V19.5H10C7.79086 19.5 6 17.7091 6 15.5C6 14.9477 6.44772 14.5 7 14.5C7.55228 14.5 8 14.9477 8 15.5C8 16.6046 8.89543 17.5 10 17.5H14C15.1046 17.5 16 16.6046 16 15.5V15C16 13.8954 15.1046 13 14 13H10C7.79086 13 6 11.2091 6 9V8.5C6 6.29086 7.79086 4.5 10 4.5H11V3.5C11 2.94772 11.4477 2.5 12 2.5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.99999 1.34998C8.38659 1.34998 8.69999 1.66338 8.69999 2.04998V2.74998H9.39999C10.9464 2.74998 12.2 4.00358 12.2 5.54998C12.2 5.93658 11.8866 6.24998 11.5 6.24998C11.1134 6.24998 10.8 5.93658 10.8 5.54998C10.8 4.77678 10.1732 4.14998 9.39999 4.14998H6.59999C5.82679 4.14998 5.19999 4.77678 5.19999 5.54998V5.89998C5.19999 6.67318 5.82679 7.29998 6.59999 7.29998H9.39999C10.9464 7.29998 12.2 8.55358 12.2 10.1V10.45C12.2 11.9964 10.9464 13.25 9.39999 13.25H8.69999V13.95C8.69999 14.3366 8.38659 14.65 7.99999 14.65C7.61339 14.65 7.29999 14.3366 7.29999 13.95V13.25H6.59999C5.05359 13.25 3.79999 11.9964 3.79999 10.45C3.79999 10.0634 4.11339 9.74998 4.49999 9.74998C4.88659 9.74998 5.19999 10.0634 5.19999 10.45C5.19999 11.2232 5.82679 11.85 6.59999 11.85H9.39999C10.1732 11.85 10.8 11.2232 10.8 10.45V10.1C10.8 9.32678 10.1732 8.69998 9.39999 8.69998H6.59999C5.05359 8.69998 3.79999 7.44637 3.79999 5.89998V5.54998C3.79999 4.00358 5.05359 2.74998 6.59999 2.74998H7.29999V2.04998C7.29999 1.66338 7.61339 1.34998 7.99999 1.34998Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
