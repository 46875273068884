import { composeU } from '@bem-react/core';
import { Icon as IconPresenter } from '@yandex-lego/components/Icon';

import { withArrowShortDownIcon } from '../../../Icon/_glyph/ArrowShortDown';
import { withArrowShortRightIcon } from '../../../Icon/_glyph/ArrowShortRight';
import { withGridDotsIcon } from '../../../Icon/_glyph/GridDots';
import { withMenuIcon } from '../../../Icon/_glyph/Menu';

export const Icon = composeU(
    withGridDotsIcon,
    withArrowShortDownIcon,
    withArrowShortRightIcon,
    withMenuIcon,
)(IconPresenter);
