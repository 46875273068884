import { withBemMod } from '@bem-react/core';

import { cnUserAvatar } from '../UserAvatar.cn';

import './UserAvatar_size_l.css';

export type WithSizeLProps = {
    size?: 'l';
};

export const withSizeL = withBemMod<WithSizeLProps>(cnUserAvatar(), {
    size: 'l',
});
