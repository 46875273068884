import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'shield';
    sizeManagement?: 'self' | 'parent';
}

export const withShieldIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'shield',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.9424 1.60341C10.7 1.81582 10.3606 2.10707 9.97299 2.42217C9.17743 3.06895 8.25103 3.75645 7.55279 4.10557C6.87247 4.44573 5.98052 4.6753 5.21237 4.81895C4.78481 4.8989 4.3524 4.96605 3.91871 5.00331C3.39997 5.04594 3 5.47944 3 6V13C3 16.5107 5.35337 18.7751 7.47 20.098C8.54253 20.7683 9.60703 21.2331 10.3989 21.5301C10.8457 21.6977 11.2987 21.8402 11.7575 21.9701C11.9167 22.01 12.0833 22.01 12.2425 21.9701C12.7037 21.8547 13.1566 21.6968 13.6011 21.5301C14.393 21.2331 15.4575 20.7683 16.53 20.098C18.6466 18.7751 21 16.5107 21 13V6.00064C21 5.47995 20.6005 5.04578 20.0815 5.00333C19.6478 4.96624 19.2152 4.89891 18.7876 4.81895C18.0195 4.6753 17.1275 4.44573 16.4472 4.10557C15.749 3.75645 14.8226 3.06895 14.027 2.42217C13.6394 2.10707 13.3 1.81582 13.0576 1.60341C12.876 1.44434 12.7498 1.33027 12.7 1.28497C12.3084 0.928087 11.6939 0.926045 11.3 1.28497C11.2502 1.33027 11.124 1.44434 10.9424 1.60341ZM15.5528 5.89443C14.6409 5.43846 13.5673 4.62596 12.7654 3.97402C12.4792 3.74134 12.2184 3.52149 12 3.33371C11.7816 3.52149 11.5208 3.74134 11.2346 3.97402C10.4327 4.62596 9.35915 5.43846 8.44721 5.89443C7.37532 6.43037 6.17951 6.70341 5 6.8832V13C5 15.4893 6.64663 17.2249 8.53 18.402C9.45747 18.9817 10.393 19.3919 11.1011 19.6574C11.4861 19.8018 11.7992 19.9016 12 19.9615C12.2008 19.9016 12.5139 19.8018 12.8989 19.6574C13.607 19.3919 14.5425 18.9817 15.47 18.402C17.3534 17.2249 19 15.4893 19 13V6.8832C17.8205 6.70341 16.6247 6.43038 15.5528 5.89443Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.74032 0.722366C8.65559 0.648128 8.5881 0.587919 8.54215 0.546597C8.20663 0.242716 7.78406 0.251315 7.45788 0.546597C7.41192 0.587919 7.34443 0.648128 7.2597 0.722366C7.09 0.871056 6.85241 1.07493 6.5811 1.2955C6.02422 1.74825 5.37573 2.2295 4.88696 2.47388C4.41074 2.712 3.78637 2.87269 3.24867 2.97325C2.9861 3.02235 2.75634 3.05494 2.593 3.07516C2.5104 3.08539 2.42768 3.09429 2.34483 3.10215C1.98543 3.13274 1.70001 3.43806 1.70001 3.80023V8.69998C1.70001 11.1575 3.34737 12.7426 4.82901 13.6686C5.57978 14.1378 6.32493 14.4632 6.87923 14.671C7.15751 14.7754 7.39058 14.8512 7.55605 14.9014L7.8297 14.9789C7.94117 15.0068 8.05832 15.007 8.16979 14.9791L8.44397 14.9014C8.60944 14.8512 8.84251 14.7754 9.1208 14.671C9.67509 14.4632 10.4202 14.1378 11.171 13.6686C12.6527 12.7426 14.3 11.1575 14.3 8.69998V3.79998C14.3 3.4355 14.0202 3.13201 13.6569 3.1023C13.5735 3.09513 13.4901 3.08545 13.407 3.07516C13.2437 3.05494 13.0139 3.02235 12.7514 2.97325C12.2136 2.87269 11.5893 2.712 11.1131 2.47388C10.6243 2.2295 9.97581 1.74825 9.41892 1.2955C9.14762 1.07493 8.91002 0.871056 8.74032 0.722366ZM3.10001 8.69998C3.10001 10.4425 4.25265 11.6574 5.57101 12.4814C6.22024 12.8872 6.87509 13.1743 7.3708 13.3602C7.64027 13.4612 7.85944 13.5311 8.00001 13.573C8.14059 13.5311 8.35976 13.4612 8.62923 13.3602C9.12493 13.1743 9.77978 12.8872 10.429 12.4814C11.7474 11.6574 12.9 10.4425 12.9 8.69998V4.41844C12.7766 4.39963 12.6399 4.37667 12.494 4.34939C11.9135 4.24083 11.1379 4.05153 10.487 3.72608C9.84861 3.40691 9.09709 2.83816 8.53576 2.3818C8.33543 2.21892 8.15289 2.06503 8.00001 1.93358C7.84713 2.06503 7.6646 2.21892 7.46426 2.3818C6.90293 2.83816 6.15141 3.40691 5.51306 3.72608C4.86217 4.05153 4.08653 4.24083 3.50601 4.34939C3.36016 4.37667 3.22341 4.39963 3.10001 4.41844V8.69998Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
