import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'link';
    sizeManagement?: 'self' | 'parent';
}

export const withLinkIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'link',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_922_2863)"><path fillRule="evenodd" clipRule="evenodd" d="M4.42414 15.5137C4.35838 16.5005 4.73986 17.4679 5.63607 18.3641C6.53601 19.264 7.52792 19.6621 8.46444 19.6621C9.40096 19.6621 10.3929 19.264 11.2929 18.364L12.7071 16.9498C13.6071 16.0498 14.0052 15.0579 14.0052 14.1214C14.0052 13.1849 13.6071 12.1929 12.7071 11.293C12.3166 10.9025 12.3166 10.2693 12.7071 9.87879C13.0977 9.48827 13.7308 9.48827 14.1214 9.87879C15.3427 11.1001 16.0052 12.583 16.0052 14.1214C16.0052 15.6597 15.3426 17.1427 14.1213 18.364L12.7071 19.7782C11.4857 20.9996 10.0028 21.6621 8.46445 21.6621C6.92611 21.6621 5.44318 20.9996 4.22186 19.7783C2.99681 18.5532 2.3176 17.0457 2.42857 15.3807C2.53799 13.739 3.40458 12.1102 4.9289 10.5858C5.31943 10.1953 5.95259 10.1953 6.34312 10.5858C6.73364 10.9764 6.73364 11.6095 6.34312 12C5.03901 13.3042 4.49145 14.5038 4.42414 15.5137ZM7.9948 9.87874C7.9948 8.34041 8.65733 6.85748 9.87863 5.63611L11.2929 4.22188C12.5142 3.00053 13.9972 2.33799 15.5355 2.33799C17.0739 2.33799 18.5568 3.00053 19.7781 4.22188C21.0032 5.44695 21.6824 6.95448 21.5714 8.61942C21.462 10.2612 20.5953 11.89 19.071 13.4143C18.6805 13.8048 18.0474 13.8048 17.6568 13.4143C17.2663 13.0237 17.2663 12.3906 17.6568 12C18.9609 10.6959 19.5085 9.49627 19.5759 8.48638C19.6416 7.49968 19.2602 6.53233 18.3639 5.63609C17.464 4.73612 16.472 4.33799 15.5355 4.33799C14.599 4.33799 13.6071 4.73612 12.7071 5.63609L11.2929 7.05028C11.2929 7.05029 11.2929 7.05028 11.2929 7.05028C10.3929 7.95029 9.9948 8.94225 9.9948 9.87874C9.9948 10.8152 10.3929 11.8071 11.2929 12.7071C11.6834 13.0976 11.6835 13.7307 11.293 14.1213C10.9024 14.5118 10.2693 14.5118 9.87874 14.1213C8.65735 12.9 7.9948 11.4171 7.9948 9.87874Z" fill="currentColor" /></g><defs><clipPath id="clip0_922_2863"><rect width={24} height={24} fill="white" /></clipPath></defs></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_921_2739)"><path fillRule="evenodd" clipRule="evenodd" d="M2.69694 10.4597C2.6509 11.1504 2.91794 11.8276 3.54529 12.4549C4.17525 13.0849 4.86959 13.3636 5.52515 13.3635C6.18071 13.3635 6.87506 13.0849 7.50504 12.4549L8.49499 11.4649C9.12497 10.8349 9.40366 10.1406 9.40367 9.48503C9.40367 8.82947 9.12499 8.13512 8.49504 7.50517C8.22167 7.2318 8.22167 6.78859 8.49504 6.51522C8.7684 6.24185 9.21162 6.24185 9.48499 6.51522C10.3399 7.37015 10.8037 8.4082 10.8037 9.48504C10.8037 10.5619 10.3399 11.5999 9.48494 12.4549L8.49499 13.4448C7.64005 14.2998 6.60199 14.7635 5.52516 14.7635C4.44832 14.7636 3.41027 14.2998 2.55534 13.4449C1.69781 12.5873 1.22236 11.5321 1.30004 10.3666C1.37663 9.21734 1.98325 8.07718 3.05027 7.01015C3.32364 6.73679 3.76685 6.73679 4.04022 7.01015C4.31359 7.28352 4.31359 7.72673 4.04022 8.0001C3.12735 8.91298 2.74405 9.75275 2.69694 10.4597ZM5.1964 6.51519C5.1964 5.43835 5.66017 4.4003 6.51508 3.54534L7.50504 2.55538C8.35999 1.70044 9.39805 1.23666 10.4749 1.23666C11.5517 1.23666 12.5898 1.70044 13.4447 2.55538C14.3023 3.41293 14.7777 4.4682 14.7 5.63366C14.6234 6.7829 14.0168 7.92304 12.9498 8.99005C12.6764 9.26342 12.2332 9.26342 11.9598 8.99005C11.6864 8.71668 11.6865 8.27347 11.9598 8.0001C12.8727 7.08722 13.256 6.24746 13.3031 5.54054C13.3492 4.84984 13.0822 4.1727 12.4548 3.54533C11.8248 2.91535 11.1305 2.63666 10.4749 2.63666C9.81933 2.63666 9.12497 2.91535 8.49499 3.54533L7.50506 4.53526C7.50505 4.53527 7.50506 4.53526 7.50506 4.53526C6.8751 5.16527 6.5964 5.85964 6.5964 6.51519C6.5964 7.17073 6.87508 7.86506 7.50508 8.49501C7.77845 8.76837 7.77847 9.21159 7.50511 9.48496C7.23175 9.75834 6.78853 9.75835 6.51516 9.48499C5.66018 8.63007 5.1964 7.59203 5.1964 6.51519Z" fill="currentColor" /></g><defs><clipPath id="clip0_921_2739"><rect width={16} height={16} fill="white" /></clipPath></defs></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
