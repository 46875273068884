import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'question';
    sizeManagement?: 'self' | 'parent';
}

export const withQuestionIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'question',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 19.5C13.5 20.3284 12.8284 21 12 21C11.1716 21 10.5 20.3284 10.5 19.5C10.5 18.6716 11.1716 18 12 18C12.8284 18 13.5 18.6716 13.5 19.5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11.9999 5C10.1674 5 8.68176 6.4856 8.68176 8.31818C8.68176 8.87047 8.23405 9.31818 7.68176 9.31818C7.12948 9.31818 6.68176 8.87047 6.68176 8.31818C6.68176 5.38103 9.06279 3 11.9999 3C14.9371 3 17.3181 5.38103 17.3181 8.31818C17.3181 10.5361 15.9003 11.8763 14.7837 12.7695C14.5534 12.9538 14.348 13.1107 14.1617 13.253C13.8487 13.4921 13.5897 13.69 13.357 13.9072C13.1889 14.0641 13.0931 14.1811 13.0417 14.2663C13.0178 14.3059 13.0078 14.3317 13.0036 14.3451C12.9999 14.3569 12.9999 14.3636 12.9999 14.3636V15.5C12.9999 16.0523 12.5522 16.5 11.9999 16.5C11.4477 16.5 10.9999 16.0523 10.9999 15.5V14.3636C10.9999 13.4914 11.5474 12.8603 11.9923 12.4451C12.293 12.1644 12.6891 11.8607 13.0574 11.5783C13.2262 11.4489 13.3891 11.3239 13.5343 11.2078C14.5769 10.3737 15.3181 9.55477 15.3181 8.31818C15.3181 6.4856 13.8325 5 11.9999 5Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.04994 13.2502C9.04994 13.8301 8.57984 14.3002 7.99994 14.3002C7.42004 14.3002 6.94994 13.8301 6.94994 13.2502C6.94994 12.6703 7.42004 12.2002 7.99994 12.2002C8.57984 12.2002 9.04994 12.6703 9.04994 13.2502Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8.00001 3.09989C6.7172 3.09989 5.67728 4.13981 5.67728 5.42262C5.67728 5.80922 5.36388 6.12262 4.97728 6.12262C4.59068 6.12262 4.27728 5.80922 4.27728 5.42262C4.27728 3.36661 5.944 1.69989 8.00001 1.69989C10.056 1.69989 11.7227 3.36661 11.7227 5.42262C11.7227 6.97519 10.7303 7.91327 9.94866 8.53854C9.78742 8.66754 9.64365 8.77737 9.51326 8.87698C9.29414 9.04438 9.11282 9.1829 8.94993 9.33492C8.83226 9.44475 8.76522 9.52665 8.72922 9.58631C8.71249 9.61404 8.70548 9.6321 8.70258 9.64144C8.70001 9.64975 8.70001 9.6544 8.70001 9.6544V10.4499C8.70001 10.8365 8.38661 11.1499 8.00001 11.1499C7.61341 11.1499 7.30001 10.8365 7.30001 10.4499V9.65444C7.30001 9.04386 7.68325 8.60212 7.99469 8.31145C8.20518 8.11499 8.48245 7.90237 8.74024 7.70469C8.85839 7.6141 8.97243 7.52665 9.07409 7.44533C9.80386 6.86151 10.3227 6.28823 10.3227 5.42262C10.3227 4.13981 9.28282 3.09989 8.00001 3.09989Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
