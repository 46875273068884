import React, { ComponentProps, FC, memo, useMemo } from 'react';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { Menu as LegoMenu } from '../../../Menu/desktop/bundle';
//import { cnPageHeader } from '../../PageHeader.cn';
import { IMenuItem, MenuItemClickHandler } from '../../PageHeader.types';
import { InlineMenuItemWithChildren, SimpleMenuItem } from '../MenuItem';

import './Menu.css';

export type MenuItemSize = ComponentProps<typeof Button>['size'];

export const useMenuItems = (
    items: IMenuItem[],
    size: MenuItemSize,
    onItemClick?: MenuItemClickHandler,
    inline?: boolean,
) => {
    // const [openedPopupId, setOpenedPopup] = useState<string | null>(null);

    // const handleShowChildren = useCallback((isVisible, id: string) => {
    //     if (isVisible) {
    //         setOpenedPopup(id);
    //     }
    //     if (!isVisible && openedPopupId === id) {
    //         setOpenedPopup(null);
    //     }
    // }, [openedPopupId]);
    return useMemo(() => items.map((item) => {
        let content = (
            <SimpleMenuItem
                item={item}
                onClick={onItemClick}
                size={size}
                width="max"
            />
        );

        if (item.children) {
            if (inline) {
                content = (
                    <InlineMenuItemWithChildren
                        item={item}
                        onClick={onItemClick}
                        size={size}
                        width="max"
                    />
                );
            } else {
                // https://st.yandex-team.ru/LEGOSUPPORT-410
                content = (
                    <InlineMenuItemWithChildren
                        item={item}
                        onClick={onItemClick}
                        size={size}
                        width="max"
                />
                );
            // Должно быть как ниже, но есть нюанс со вложенными попапами
            // Узнаем у Лего и вернем взад
            // https://st.yandex-team.ru/LEGOSUPPORT-410
            // content = (
            //     <PopupMenuItemWithChildren
            //         items={item.children}
            //         isPopupVisible={openedPopupId === item.id}
            //         setPopupVisible={handleShowChildren}
            //         direction="right-start"
            //         icon="rightX"
            //         width="max"
            //         id={item.id}
            //         label={item.name}
            //         onItemClick={onItemClick}
            //         className={cnPageHeader('MainMenuItem', [cnPageHeader('MenuItem')])}
            //     />
            // );
            }
        }

        return {
            value: item.id,
            content,
        };
    }), [inline, items, onItemClick, size]);
};

export interface MenuProps {
    items: IMenuItem[];
    onItemClick?: MenuItemClickHandler;
}

export const Menu: FC<MenuProps> = memo(({ items, onItemClick }) => {
    const menuItems = useMenuItems(items, 'm', onItemClick, false);

    return (
        <LegoMenu view="default" size="m" items={menuItems} />
    );
});
