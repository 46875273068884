import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'swap';
    sizeManagement?: 'self' | 'parent';
}

export const withSwapIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'swap',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 17C19 17.5523 18.5523 18 18 18H9C8.3188 18 7.52159 17.884 6.76642 17.731L6.62275 17.8741C6.6684 17.914 6.71812 17.9506 6.77194 17.9832C7.5903 18.4798 8.51947 19.11 9.15635 19.7421L9.70708 20.2929C10.0976 20.6834 10.0976 21.3166 9.70708 21.7071C9.31868 22.0955 8.69027 22.0976 8.29926 21.7134L4.29289 17.7071C3.95348 17.3676 3.90906 16.8449 4.15963 16.4576C4.19736 16.3992 4.24177 16.344 4.29287 16.2929L8.29287 12.2929C8.6834 11.9024 9.31656 11.9024 9.70708 12.2929C10.0967 12.6824 10.0976 13.3135 9.70994 13.7042L9.70708 13.7071L9.17157 14.2426C8.53435 14.8798 7.59687 15.5161 6.77196 16.0166C6.71811 16.0493 6.66836 16.0859 6.62269 16.1258L6.76642 16.269C7.52159 16.116 8.3188 16 9 16L18 16C18.5523 16 19 16.4477 19 17Z" fill="currentColor" /><path d="M5 6.99998C5 7.55227 5.44772 7.99998 6 7.99998H15C15.6812 7.99998 16.4784 7.88398 17.2336 7.73098L17.3772 7.87414C17.3316 7.914 17.2819 7.95055 17.2281 7.98321C16.4097 8.47978 15.4805 9.10998 14.8436 9.74215L14.2929 10.2929C13.9024 10.6834 13.9024 11.3166 14.2929 11.7071C14.6813 12.0955 15.3097 12.0976 15.7007 11.7134L19.7071 7.70706C20.0465 7.36764 20.0909 6.84494 19.8404 6.45755C19.8026 6.39923 19.7582 6.34398 19.7071 6.29288L15.7071 2.29288C15.3166 1.90235 14.6834 1.90235 14.2929 2.29288C13.9033 2.68245 13.9024 3.31347 14.2901 3.70422L14.2929 3.70709L14.8284 4.24259C15.4657 4.87982 16.4031 5.51611 17.228 6.01663C17.2819 6.0493 17.3316 6.08588 17.3773 6.12577L17.2336 6.26899C16.4784 6.11599 15.6812 5.99998 15 5.99998H6C5.44772 5.99998 5 6.4477 5 6.99998Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8999 11.5C12.8999 11.8866 12.5865 12.2 12.1999 12.2L5.89994 12.2C5.4231 12.2 4.86505 12.1188 4.33643 12.0117L4.23588 12.1119C4.26783 12.1398 4.30265 12.1654 4.34032 12.1882C4.91318 12.5358 5.56361 12.977 6.00942 13.4195L6.39492 13.805C6.66829 14.0784 6.66829 14.5216 6.39492 14.7949C6.12305 15.0668 5.68319 15.0683 5.40948 14.7994L2.60499 11.9949C2.3706 11.7605 2.33718 11.4013 2.50472 11.1313C2.53258 11.0864 2.566 11.044 2.60497 11.005L5.40497 8.205C5.67834 7.93163 6.12156 7.93163 6.39492 8.205C6.66759 8.47766 6.66829 8.9193 6.39702 9.19284L6.39492 9.19495L6.02006 9.56979C5.57401 10.0159 4.91777 10.4613 4.34034 10.8116C4.30264 10.8345 4.2678 10.8601 4.23584 10.888L4.33643 10.9883C4.86505 10.8812 5.4231 10.8 5.89994 10.8H12.1999C12.5865 10.8 12.8999 11.1134 12.8999 11.5Z" fill="currentColor" /><path d="M3.10004 4.49997C3.10004 4.88657 3.41344 5.19997 3.80004 5.19997H10.1C10.5769 5.19997 11.1349 5.11876 11.6635 5.01167L11.7641 5.11183C11.7321 5.13975 11.6973 5.16535 11.6596 5.18822C11.0868 5.5358 10.4364 5.9769 9.9906 6.41938L9.60498 6.80499C9.33161 7.07836 9.33161 7.52158 9.60498 7.79494C9.87683 8.0668 10.3167 8.0683 10.5904 7.79946L13.3949 4.99492C13.6336 4.75626 13.6639 4.38815 13.4858 4.11661C13.4599 4.07713 13.4296 4.0397 13.3949 4.00499L10.5949 1.20499C10.3216 0.931628 9.87835 0.931628 9.60498 1.20499C9.33228 1.47769 9.33161 1.9194 9.60297 2.19293L9.60498 2.19494L9.97984 2.56979C10.4259 3.01585 11.0821 3.46126 11.6596 3.81162C11.6973 3.83451 11.7321 3.86012 11.7641 3.88806L11.6635 3.98827C11.1349 3.88118 10.5769 3.79997 10.1 3.79997H3.80004C3.41344 3.79997 3.10004 4.11337 3.10004 4.49997Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
