import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'mobile';
    sizeManagement?: 'self' | 'parent';
}

export const withMobileIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'mobile',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6ZM15 4V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89543 17.1046 4 16 4H15Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.40002 3.8C2.40002 2.2536 3.65363 1 5.20002 1H10.8C12.3464 1 13.6 2.2536 13.6 3.8V12.2C13.6 13.7464 12.3464 15 10.8 15H5.20003C3.65363 15 2.40002 13.7464 2.40002 12.2V3.8ZM10.1 2.4V3.1C10.1 3.4866 9.78662 3.8 9.40002 3.8H6.60002C6.21342 3.8 5.90002 3.4866 5.90002 3.1V2.4H5.20002C4.42683 2.4 3.80002 3.0268 3.80002 3.8V12.2C3.80002 12.9732 4.42683 13.6 5.20003 13.6H10.8C11.5732 13.6 12.2 12.9732 12.2 12.2V3.8C12.2 3.0268 11.5732 2.4 10.8 2.4H10.1Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
