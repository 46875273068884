import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'handBaggageOff';
    sizeManagement?: 'self' | 'parent';
}

export const withHandBaggageOffIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'handBaggageOff',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 6H14.5C14.5 5.17157 13.8284 4.5 13 4.5H11C10.1715 4.5 9.49997 5.17157 9.49997 6H7.99997C7.99997 4.34315 9.34311 3 11 3H13C14.6568 3 16 4.34315 16 6Z" fill="currentColor" /><path d="M10.2803 11.7197C9.98744 11.4268 9.51256 11.4268 9.21967 11.7197C8.92678 12.0126 8.92678 12.4874 9.21967 12.7803L10.9373 14.498L9.21967 16.2156C8.92678 16.5085 8.92678 16.9834 9.21967 17.2763C9.51256 17.5691 9.98744 17.5691 10.2803 17.2763L11.998 15.5586L13.7156 17.2763C14.0085 17.5691 14.4834 17.5691 14.7763 17.2763C15.0691 16.9834 15.0691 16.5085 14.7763 16.2156L13.0586 14.498L14.7763 12.7803C15.0691 12.4874 15.0691 12.0126 14.7763 11.7197C14.4834 11.4268 14.0085 11.4268 13.7156 11.7197L11.998 13.4373L10.2803 11.7197Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M8 7.5C4.68629 7.5 2 10.1863 2 13.5V15.5C2 18.8137 4.68629 21.5 8 21.5H16C19.3137 21.5 22 18.8137 22 15.5V13.5C22 10.1863 19.3137 7.5 16 7.5H8ZM16 9.25H8C5.65279 9.25 3.75 11.1528 3.75 13.5V15.5C3.75 17.8472 5.65279 19.75 8 19.75H16C18.3472 19.75 20.25 17.8472 20.25 15.5V13.5C20.25 11.1528 18.3472 9.25 16 9.25Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.6666 4H9.66664C9.66664 3.44772 9.21892 3 8.66664 3H7.3333C6.78102 3 6.3333 3.44772 6.3333 4H5.33331C5.33331 2.89543 6.22874 2 7.3333 2H8.66664C9.77121 2 10.6666 2.89543 10.6666 4Z" fill="currentColor" /><path d="M6.85355 7.81311C6.65829 7.61785 6.3417 7.61785 6.14644 7.81311C5.95118 8.00838 5.95118 8.32496 6.14644 8.52022L7.29153 9.66531L6.14644 10.8104C5.95118 11.0057 5.95118 11.3222 6.14644 11.5175C6.3417 11.7128 6.65829 11.7128 6.85355 11.5175L7.99864 10.3724L9.14372 11.5175C9.33899 11.7128 9.65557 11.7128 9.85083 11.5175C10.0461 11.3222 10.0461 11.0057 9.85083 10.8104L8.70574 9.66531L9.85083 8.52022C10.0461 8.32496 10.0461 8.00838 9.85083 7.81311C9.65557 7.61785 9.33899 7.61785 9.14372 7.81311L7.99864 8.9582L6.85355 7.81311Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M5.33333 5C3.12419 5 1.33333 6.79086 1.33333 9V10.3333C1.33333 12.5425 3.12419 14.3333 5.33333 14.3333H10.6667C12.8758 14.3333 14.6667 12.5425 14.6667 10.3333V9C14.6667 6.79086 12.8758 5 10.6667 5H5.33333ZM10.6667 6.16667H5.33333C3.76852 6.16667 2.49999 7.43519 2.49999 9V10.3333C2.49999 11.8981 3.76852 13.1667 5.33333 13.1667H10.6667C12.2315 13.1667 13.5 11.8981 13.5 10.3333V9C13.5 7.43519 12.2315 6.16667 10.6667 6.16667Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
