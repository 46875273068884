import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'unlock';
    sizeManagement?: 'self' | 'parent';
}

export const withUnlockIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'unlock',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 15.5C13.5 16.3284 12.8284 17 12 17C11.1716 17 10.5 16.3284 10.5 15.5C10.5 14.6716 11.1716 14 12 14C12.8284 14 13.5 14.6716 13.5 15.5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13.5 15.5C13.5 16.3284 12.8284 17 12 17C11.1716 17 10.5 16.3284 10.5 15.5C10.5 14.6716 11.1716 14 12 14C12.8284 14 13.5 14.6716 13.5 15.5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M17 9C19.2091 9 21 10.7909 21 13V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V13C3 10.7909 4.79086 9 7 9V6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6V9H17ZM17 11C18.1046 11 19 11.8954 19 13V18C19 19.1046 18.1046 20 17 20H7C5.89543 20 5 19.1046 5 18V13C5 11.8954 5.89543 11 7 11H17Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.05001 10.45C9.05001 11.0299 8.57991 11.5 8.00001 11.5C7.42011 11.5 6.95001 11.0299 6.95001 10.45C6.95001 9.87009 7.42011 9.39999 8.00001 9.39999C8.57991 9.39999 9.05001 9.87009 9.05001 10.45Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M11.5 5.89999C13.0464 5.89999 14.3 7.15359 14.3 8.69999V12.2C14.3 13.7464 13.0464 15 11.5 15H4.50001C2.95361 15 1.70001 13.7464 1.70001 12.2V8.69999C1.70001 7.15359 2.95361 5.89999 4.50001 5.89999L4.50001 3.79999C4.50001 1.86699 6.06701 0.299988 8.00001 0.299988C9.93301 0.299988 11.5 1.86699 11.5 3.79999C11.5 4.18659 11.1866 4.49999 10.8 4.49999C10.4134 4.49999 10.1 4.18659 10.1 3.79999C10.1 2.64019 9.15981 1.69999 8.00001 1.69999C6.84021 1.69999 5.90001 2.64019 5.90001 3.79999V5.89999H11.5ZM11.5 7.29999C12.2732 7.29999 12.9 7.92679 12.9 8.69999V12.2C12.9 12.9732 12.2732 13.6 11.5 13.6H4.50001C3.72681 13.6 3.10001 12.9732 3.10001 12.2V8.69999C3.10001 7.92679 3.72681 7.29999 4.50001 7.29999H11.5Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M9.05001 10.45C9.05001 11.0299 8.57991 11.5 8.00001 11.5C7.42011 11.5 6.95001 11.0299 6.95001 10.45C6.95001 9.87009 7.42011 9.39999 8.00001 9.39999C8.57991 9.39999 9.05001 9.87009 9.05001 10.45Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
