import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'copy';
    sizeManagement?: 'self' | 'parent';
}

export const withCopyIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'copy',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 10C2 7.79086 3.79086 6 6 6H14C16.2091 6 18 7.79086 18 10V18C18 20.2091 16.2091 22 14 22H6C3.79086 22 2 20.2091 2 18V10ZM6 8C4.89543 8 4 8.89543 4 10V18C4 19.1046 4.89543 20 6 20H14C15.1046 20 16 19.1046 16 18V10C16 8.89543 15.1046 8 14 8H6Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M21 16C21.5523 16 22 15.5523 22 15V9C22 5.13401 18.866 2 15 2H9C8.44772 2 8 2.44771 8 3C8 3.55229 8.44772 4 9 4H15C17.7614 4 20 6.23858 20 9V15C20 15.5523 20.4477 16 21 16Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 6.6C1 5.05361 2.2536 3.8 3.8 3.8H9.4C10.9464 3.8 12.2 5.0536 12.2 6.6V12.2C12.2 13.7464 10.9464 15 9.4 15H3.8C2.25361 15 1 13.7464 1 12.2V6.6ZM3.8 5.2C3.0268 5.2 2.4 5.8268 2.4 6.6V12.2C2.4 12.9732 3.0268 13.6 3.8 13.6H9.4C10.1732 13.6 10.8 12.9732 10.8 12.2V6.6C10.8 5.8268 10.1732 5.2 9.4 5.2H3.8Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M14.3 10.8C14.6866 10.8 15 10.4866 15 10.1V5.9C15 3.19381 12.8062 1 10.1 1H5.9C5.5134 1 5.2 1.3134 5.2 1.7C5.2 2.0866 5.5134 2.4 5.9 2.4H10.1C12.033 2.4 13.6 3.967 13.6 5.9V10.1C13.6 10.4866 13.9134 10.8 14.3 10.8Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
