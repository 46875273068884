import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'night';
    sizeManagement?: 'self' | 'parent';
}

export const withNightIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'night',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.99665 4.25265C11.8118 5.51705 13 7.61909 13 10C13 13.866 9.866 17 6 17C5.91664 17 5.83359 16.9985 5.75089 16.9956C7.21755 18.8278 9.47256 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C11.3076 4 10.6365 4.08775 9.99665 4.25265ZM7.01924 3.32692C8.48662 2.4826 10.1885 2 12 2C17.5229 2 22 6.47715 22 12C22 17.5228 17.5229 22 12 22C7.87872 22 4.34196 19.5071 2.8111 15.951C2.6505 15.5779 2.73151 15.1447 3.0161 14.8549C3.30069 14.5651 3.73231 14.4762 4.10825 14.63C4.69049 14.8682 5.32861 15 6 15C8.76143 15 11 12.7614 11 10C11 7.67697 9.41488 5.72164 7.26569 5.16133C6.87286 5.05892 6.58092 4.72918 6.52687 4.32683C6.47281 3.92449 6.66736 3.52938 7.01924 3.32692Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.59763 2.57685C7.86824 3.46193 8.69998 4.93336 8.69998 6.6C8.69998 9.3062 6.50617 11.5 3.79998 11.5C3.74162 11.5 3.68349 11.499 3.6256 11.4969C4.65226 12.7795 6.23076 13.6 7.99998 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 7.99998 2.4C7.51533 2.4 7.04551 2.46142 6.59763 2.57685ZM4.51344 1.92884C5.5406 1.33782 6.7319 1 7.99998 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 7.99998 15C5.11508 15 2.63935 13.255 1.56775 10.7657C1.45532 10.5045 1.51203 10.2013 1.71125 9.99844C1.91046 9.79557 2.21259 9.73336 2.47575 9.84101C2.88331 10.0077 3.33 10.1 3.79998 10.1C5.73297 10.1 7.29998 8.533 7.29998 6.6C7.29998 4.97388 6.19039 3.60515 4.68596 3.21293C4.41097 3.14124 4.20662 2.91043 4.16878 2.62878C4.13094 2.34714 4.26713 2.07057 4.51344 1.92884Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
