import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'heart';
    sizeManagement?: 'self' | 'parent';
}

export const withHeartIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'heart',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.7352 4.50994C11.83 4.59614 11.9182 4.68246 12 4.76784C12.0818 4.68246 12.17 4.59614 12.2648 4.50994C13.1239 3.72892 14.4849 2.99988 16.5 2.99988C18.031 2.99988 19.4349 3.61907 20.4432 4.78245C21.4446 5.93791 22 7.56188 22 9.49988C22 11.874 20.6034 14.1815 19.0429 16.0505C17.4539 17.9537 15.5441 19.5791 14.2238 20.6082C12.9071 21.6344 11.0929 21.6344 9.7762 20.6082C8.4559 19.5791 6.54606 17.9537 4.95708 16.0505C3.3966 14.1815 2 11.874 2 9.49988C2 7.56188 2.55541 5.93791 3.55681 4.78245C4.56507 3.61907 5.96899 2.99988 7.5 2.99988C9.5151 2.99988 10.8761 3.72892 11.7352 4.50994ZM5.06819 6.09231C4.44459 6.81184 4 7.93788 4 9.49988C4 11.1433 5.00451 12.9867 6.49232 14.7687C7.95163 16.5166 9.73727 18.0421 11.0057 19.0308C11.5995 19.4936 12.4005 19.4936 12.9943 19.0308C14.2627 18.0421 16.0484 16.5166 17.5077 14.7687C18.9955 12.9867 20 11.1433 20 9.49988C20 7.93788 19.5554 6.81184 18.9318 6.09231C18.3151 5.38068 17.469 4.99988 16.5 4.99988C15.0151 4.99988 14.1261 5.52084 13.6102 5.98982C13.3441 6.2317 13.1652 6.47144 13.0558 6.6443C12.9741 6.77327 12.9328 6.8625 12.9251 6.87963L12.9244 6.88131C12.7701 7.25531 12.4053 7.49988 12 7.49988C11.5947 7.49988 11.2299 7.25531 11.0756 6.8813L10.9442 6.6443C10.8348 6.47144 10.6559 6.2317 10.3898 5.98982C9.87395 5.52084 8.9849 4.99988 7.5 4.99988C6.53101 4.99988 5.68493 5.38068 5.06819 6.09231Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.81462 2.75705C7.881 2.8174 7.94272 2.87782 8 2.93758C8.05729 2.87782 8.119 2.8174 8.18538 2.75705C8.78676 2.21034 9.73943 1.70001 11.15 1.70001C12.2217 1.70001 13.2045 2.13345 13.9102 2.94781C14.6112 3.75664 15 4.89341 15 6.25001C15 7.91192 14.0224 9.52716 12.93 10.8355C11.8178 12.1677 10.4809 13.3055 9.55666 14.0258C8.635 14.7442 7.365 14.7442 6.44334 14.0258C5.51913 13.3055 4.18224 12.1677 3.06996 10.8355C1.97762 9.52716 1 7.91192 1 6.25001C1 4.89341 1.38879 3.75664 2.08977 2.94781C2.79555 2.13345 3.77829 1.70001 4.85 1.70001C6.26057 1.70001 7.21324 2.21034 7.81462 2.75705ZM3.14773 3.86471C2.71121 4.36839 2.4 5.15661 2.4 6.25001C2.4 7.4004 3.10316 8.69082 4.14463 9.93821C5.16614 11.1617 6.41609 12.2296 7.30398 12.9216C7.71965 13.2456 8.28035 13.2456 8.69602 12.9216C9.58391 12.2296 10.8339 11.1617 11.8554 9.93821C12.8968 8.69082 13.6 7.4004 13.6 6.25001C13.6 5.15661 13.2888 4.36839 12.8523 3.86471C12.4205 3.36658 11.8283 3.10001 11.15 3.10001C10.1106 3.10001 9.48824 3.46468 9.12712 3.79297C8.94087 3.96229 8.81567 4.1301 8.73903 4.25111C8.68185 4.34139 8.65296 4.40385 8.6476 4.41584L8.64708 4.41701C8.53908 4.67881 8.2837 4.85001 8 4.85001C7.7163 4.85001 7.46092 4.67881 7.35292 4.41701L7.26097 4.25111C7.18433 4.1301 7.05913 3.96229 6.87288 3.79297C6.51176 3.46468 5.88943 3.10001 4.85 3.10001C4.17171 3.10001 3.57945 3.36658 3.14773 3.86471Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
