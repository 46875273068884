import React, { useMemo } from 'react';

import { withBemMod } from '@bem-react/core';
import { cnSelect, ISelectProps } from '@yandex-lego/components/Select';

import { WithSizeLProps as MenuWithSizeLProps } from '../../Menu/_size/Menu_size_l';

export type SelectWithSizeLProps = {
    size?: 'l';
};

export const withSizeL = withBemMod<SelectWithSizeLProps, ISelectProps>(
    cnSelect(),
    { size: 'l' },
    (Select) => ({ view, ...otherProps }) => {
        const renderMenu = useMemo(() => {
            return (menuProps: MenuWithSizeLProps, Menu: React.ComponentType<MenuWithSizeLProps>) => {
                return (
                    <Menu
                        {...menuProps}
                        size="l"
                    />
                );
            };
        }, []);
        return (
            <Select
                // @ts-ignore Не распознается существующая в Лего пропса
                renderMenu={renderMenu}
                {...otherProps}
            />
        );
    },
);
