import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'repeat';
    sizeManagement?: 'self' | 'parent';
}

export const withRepeatIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'repeat',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 12C19 8.13401 15.866 5 12 5C10.8198 5 9.56204 5.35219 7.70712 7.20711L7.03557 7.87867C6.55389 8.36035 5.90815 8.84203 5.26598 9.26783L5.26562 9.47073C5.32611 9.46665 5.38716 9.45733 5.44834 9.44236C6.38556 9.21298 7.49838 9.00001 8.39956 9.00001L9.15688 9L9.16093 9.00001C9.71135 9.00219 10.1569 9.44907 10.1569 10C10.1569 10.5523 9.70916 11 9.15688 11L3.50002 11C2.94775 11 2.50006 10.5523 2.50006 10L2.50006 4.33415C2.50489 3.78601 2.95074 3.34315 3.50002 3.34315C4.05231 3.34315 4.50002 3.79086 4.50002 4.34315L4.50002 5.122C4.49668 6.01935 4.28529 7.12199 4.05774 8.0518C4.04278 8.11294 4.03347 8.17395 4.02938 8.2344L4.23219 8.23405C4.65799 7.59188 5.13968 6.94614 5.62136 6.46445L6.29291 5.79289C8.43799 3.64781 10.1803 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C9.52278 21 7.27768 19.9978 5.6513 18.3792C5.25984 17.9896 5.25834 17.3564 5.64793 16.965C6.03753 16.5735 6.67069 16.572 7.06214 16.9616C8.32902 18.2225 10.0728 19 12 19C15.866 19 19 15.866 19 12Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9 8.00001C12.9 5.29382 10.7062 3.10001 8 3.10001C7.12315 3.10001 5.90512 3.38484 4.64498 4.64499L4.17486 5.11507C3.83768 5.45225 3.38566 5.78943 2.93614 6.08749L2.93589 6.22952C2.97824 6.22666 3.02097 6.22014 3.06379 6.20966C3.71985 6.04909 4.49882 5.90002 5.12965 5.90002L5.65977 5.90001L5.66261 5.90002C6.0479 5.90154 6.35977 6.21436 6.35977 6.60001C6.35977 6.98661 6.04637 7.30001 5.65977 7.30001L1.69997 7.30001C1.31339 7.30001 1 6.98661 1 6.60002L1 2.63392C1.00338 2.25022 1.31547 1.94021 1.69997 1.94021C2.08657 1.94021 2.39997 2.25361 2.39997 2.64021L2.39997 3.18541C2.39764 3.81355 2.24966 4.5854 2.09038 5.23627C2.07991 5.27906 2.07338 5.32177 2.07052 5.36409L2.21249 5.36384C2.51055 4.91432 2.84773 4.4623 3.18491 4.12513L3.65503 3.65504C5.19488 2.11518 6.77685 1.70001 8 1.70001C11.4794 1.70001 14.3 4.52062 14.3 8.00001C14.3 11.4794 11.4794 14.3 8 14.3C6.26593 14.3 4.69437 13.5985 3.5559 12.4654C3.28188 12.1927 3.28083 11.7495 3.55354 11.4755C3.82626 11.2015 4.26947 11.2004 4.54349 11.4731C5.43031 12.3557 6.65092 12.9 8 12.9C10.7062 12.9 12.9 10.7062 12.9 8.00001Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
