import React from 'react';

import { withBemMod } from '@bem-react/core';
import { cnIcon, IIconProps as LegoIconProps } from '@yandex-lego/components/Icon/desktop';

import '../Icon_glyph.css';

interface HrIconProps {
    glyph?: 'divide';
    sizeManagement?: 'self' | 'parent';
}

export const withDivideIcon = withBemMod<HrIconProps, LegoIconProps>(
    cnIcon(),
    {
        glyph: 'divide',
    },
    (Icon) => ({ className, sizeManagement = 'self', ...props }) => {
        let svg;

        switch (props.size) {
            case 'l':
                svg = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" fill="currentColor" /><path d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z" fill="currentColor" /><path d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z" fill="currentColor" /></svg>;
                break;

            default:
                svg = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70001 7.99999C1.70001 7.61339 2.01341 7.29999 2.40001 7.29999H13.6C13.9866 7.29999 14.3 7.61339 14.3 7.99999C14.3 8.38659 13.9866 8.69999 13.6 8.69999H2.40001C2.01341 8.69999 1.70001 8.38659 1.70001 7.99999Z" fill="currentColor" /><path d="M9.40001 3.79999C9.40001 4.57319 8.77321 5.19999 8.00001 5.19999C7.22681 5.19999 6.60001 4.57319 6.60001 3.79999C6.60001 3.0268 7.22681 2.39999 8.00001 2.39999C8.77321 2.39999 9.40001 3.0268 9.40001 3.79999Z" fill="currentColor" /><path d="M9.40001 12.2C9.40001 12.9732 8.77321 13.6 8.00001 13.6C7.22681 13.6 6.60001 12.9732 6.60001 12.2C6.60001 11.4268 7.22681 10.8 8.00001 10.8C8.77321 10.8 9.40001 11.4268 9.40001 12.2Z" fill="currentColor" /></svg>;
                break;
        }

        return (
            <Icon {...props} className={cnIcon({ hasGlyph: 'noFill', sizeManagement }, [className])}>
                { svg }
            </Icon>
        );
    },
);
